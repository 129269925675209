import * as Yup from "yup";
import { Menu } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import useMutateRequest from "../../../../../hooks/getMutation.query";
import useGetRequest from "../../../../../hooks/getRequest.query";
import {
    getAllCourses,
    searchCourseByTitle,
    getAllCoursesCategories,
} from "../../../../../services/web-content/WebContent.hook";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { stringTruncate } from "../../../../../common/utils";
import FilterTile from "../../../../../components/filter-tile";

const searchSchema = Yup.object().shape({
    courseTitle: Yup.string()
        .min(3, "Minimum 3 Letters Required")
        .label("Search"),
});

const filterSchema = Yup.object().shape({
    chwGrade: Yup.string().label("CHW Grade"),
    courseLanguage: Yup.string().label("Course Language"),
    courseCategory: Yup.string().label("Course Category"),
});

const CoursePage = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit] = useState(10);
    // Begins Menu Here
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here

    const [filterData, setFilterData]: any = useState({
        chwGrade: "",
        courseLanguage: "",
        courseCategory: "",
    });

    const {
        data: coursesList,
        isLoading,
        isFetching,
        refetch,
        isError: isGetCoursesError,
        error,
    }: any = useGetRequest(
        ["getAllCourses", pageNumber],
        async () =>
            await getAllCourses({
                page: pageNumber,
                limit: pageLimit,
                chwGrade: filterData?.chwGrade,
                courseLanguage: filterData?.courseLanguage,
                courseCategory: filterData?.courseCategory,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const totalCoursesEntites = coursesList?.data?.results;
    const courseData = coursesList?.data?.data?.courses;
    const totalCoursesPages = Math.ceil(totalCoursesEntites / pageLimit);
    const courseErrorMsg = error?.response?.data?.message;

    // Setting the Search term
    const [searchData, setSearchData] = useState([]);
    const [searchFormProps, setSearchFormProps] = useState("");

    // API For Search CHW
    const {
        data,
        mutate,
        isError: isSearchError,
        error: ErrorMsg,
        isLoading: searchCoursesLoader,
        reset,
    }: any = useMutateRequest((data: string) => searchCourseByTitle(data));

    const totalSearchEntites = data?.data?.results;
    const totalSearchCoursesPages = Math.ceil(totalSearchEntites / pageLimit);
    const totalPages =
        searchData?.length > 0 ? totalSearchCoursesPages : totalCoursesPages;

    const searchErrorMsg = ErrorMsg?.response?.data?.message;

    // Here

    const onSearchSubmit = (values: any, onSubmitProps: any) => {
        try {
            setSearchFormProps(onSubmitProps);

            const payload = new FormData();
            payload.append("courseTitle", values.courseTitle?.trim());

            setPageNumber(1);
            mutate(payload, {
                onSuccess: (data: any) => {
                    const searchTerm = data?.data?.data?.courses;
                    setSearchData(searchTerm);
                    onSubmitProps.resetForm();
                },
                onError: (error: any) => {
                    console.log(error);
                    onSubmitProps.resetForm();
                    setSearchData([]);
                },
            });
        } catch (error) {
            console.log(error);
            setSearchData([]);
        }
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                chwGrade: values?.chwGrade,
                courseLanguage: values?.courseLanguage,
                courseCategory: values?.courseCategory,
            };
            setFilterData(payload);
            handleClose();
            onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            onSubmitProps.resetForm();
        }
    };

    const clearData = (searchFormProps: any) => {
        if (searchFormProps) {
            searchFormProps.resetForm();
            setSearchData([]);
            reset();
        }
        const payload = {
            chwGrade: "",
            courseLanguage: "",
            courseCategory: "",
        };
        setFilterData(payload);
    };

    let allCourses;
    if (searchData?.length > 0) {
        allCourses = searchData;
    } else {
        allCourses = courseData;
    }

    // Get List of all Course Category
    const {
        data: coursesCategoryList,
        refetch: refetchCoursesCategory,
        isSuccess: responseSuccess,
    }: any = useGetRequest(
        "getAllCoursesCategories",
        async () => await getAllCoursesCategories(),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const coursesCategories = coursesCategoryList?.data?.data?.category;

    useEffect(() => {
        refetch();
        refetchCoursesCategory();
    }, [filterData]);

    return (
        <div className="p-5 h-100">
            <div className="p-5 d-flex align-items-center mb-5">
                <h1 className="fs-1 fw-bolder">Course Management</h1>
                <i
                    onClick={() => clearData(searchFormProps)}
                    className="fas fa-redo fs-3 cursor-pointer ms-5"
                ></i>
            </div>
            <div className="d-flex justify-content-between">
                {/* Search Bar */}
                <div className="me-20">
                    <Formik
                        initialValues={{
                            courseTitle: "",
                        }}
                        validationSchema={searchSchema}
                        onSubmit={onSearchSubmit}
                    >
                        {(searchFormProps: any) => (
                            // @ts-ignore
                            <Form
                                onSubmit={searchFormProps.handleSubmit}
                                className="w-100 d-flex flex-column align-items-center justify-content-center"
                            >
                                <div className="d-flex ">
                                    <Field
                                        name="courseTitle"
                                        placeholder="Search Course"
                                        className="form-control form-control-md form-control-solid w-600px"
                                    />
                                    <button
                                        disabled={
                                            searchFormProps.isSubmitting ||
                                            !(
                                                searchFormProps.isValid &&
                                                searchFormProps.dirty
                                            )
                                        }
                                        type="submit"
                                        className="ms-3 btn btn-lg btn-primary"
                                    >
                                        Search
                                    </button>
                                </div>
                                <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                    <ErrorMessage name="courseTitle" />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* END:: Search Bar */}

                <button
                    onClick={handleClick}
                    className="btn btn-md btn-primary h-50px"
                >
                    <i className="fas fa-filter"></i>
                    FILTER
                </button>
            </div>

            {/* Filter Options */}
            <div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <Formik
                        initialValues={filterData}
                        validationSchema={filterSchema}
                        onSubmit={onFilterSubmit}
                        enableReinitialize
                    >
                        {(formProps: any) => (
                            // @ts-ignore
                            <Form onSubmit={formProps.handleSubmit}>
                                <div className="py-5 px-8">
                                    <div className="w-250px mt-5 mb-8">
                                        <label className="form-label fs-4 fw-bolder">
                                            CHW Grade
                                        </label>
                                        <Field
                                            as="select"
                                            name="chwGrade"
                                            className="form-select form-select-lg form-select-solid"
                                        >
                                            <option value="">All</option>
                                            <option value="FCHW - Frontline CHW">
                                                Frontline CHW
                                            </option>
                                            <option value="CHW Senior">
                                                CHW Senior
                                            </option>
                                            <option value="CHW Junior">
                                                CHW Junior
                                            </option>
                                            <option value="Community Health Mobilizer">
                                                Community Health Mobilizer
                                            </option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="chwGrade" />
                                        </div>
                                    </div>

                                    <div className="w-250px mt-5 mb-8">
                                        <label className="form-label fs-4 fw-bolder">
                                            Course Category
                                        </label>
                                        <Field
                                            id="courseCategory"
                                            as="select"
                                            name="courseCategory"
                                            className="form-select form-select-light form-select-lg"
                                        >
                                            <option value="">All</option>
                                            {coursesCategories?.map(
                                                (coursesCategorie: any) => {
                                                    return (
                                                        <>
                                                            <option
                                                                value={
                                                                    coursesCategorie?._id
                                                                }
                                                            >
                                                                {
                                                                    coursesCategorie?.categoryName
                                                                }
                                                            </option>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="courseCategory" />
                                        </div>
                                    </div>

                                    <div className="w-250px mt-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            Course Language
                                        </label>
                                        <Field
                                            id="courseLanguage"
                                            as="select"
                                            name="courseLanguage"
                                            className="form-select form-select-light form-select-lg"
                                        >
                                            <option value="">
                                                Select Language
                                            </option>
                                            <option value="English">
                                                English
                                            </option>
                                            <option value="Bengali">
                                                Bengali
                                            </option>
                                            <option value="Hindi">Hindi</option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="courseLanguage" />
                                        </div>
                                    </div>
                                </div>

                                <div className="my-5 d-flex justify-content-center align-items-center">
                                    <button
                                        type="submit"
                                        disabled={
                                            !formProps.isValid ||
                                            formProps.isSubmitting
                                        }
                                        className="w-250px btn btn-sm btn-primary"
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Menu>
            </div>
            <div className="mt-5">
                <FilterTile
                    filterData={filterData}
                    keyNames={{
                        chwGrade: "CHW Grade",
                        courseLanguage: "Course Language",
                        courseCategory: "Course Category",
                    }}
                    clearAll={() =>
                        setFilterData({
                            chwGrade: "",
                            courseLanguage: "",
                            courseCategory: "",
                        })
                    }
                />
            </div>
            <div className="mt-5 h-50">
                {searchCoursesLoader || isLoading || isFetching === true ? (
                    <>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </div>
                    </>
                ) : isSearchError || isGetCoursesError === true ? (
                    <>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <h1 className="text-danger">
                                {isSearchError
                                    ? searchErrorMsg
                                    : courseErrorMsg
                                    ? "No Data Available"
                                    : "Something went wrong!!"}
                            </h1>
                        </div>
                    </>
                ) : Array?.isArray(allCourses) ? (
                    allCourses?.map((course: any) => {
                        let courseDescription = stringTruncate(
                            course?.courseDescription,
                            50
                        );
                        return (
                            <>
                                <Link
                                    className="mb-5"
                                    to={`/training/overview?courseId=${course?._id}`}
                                >
                                    <div
                                        style={{ backgroundColor: "#f2f6fa" }}
                                        className="mb-5 border border-2 border-gray-400 border-hover cursor-pointer"
                                        // onClick={handleRoute}
                                    >
                                        <div className="d-flex">
                                            <div className="p-2 justify-content-center align-items-center">
                                                <img
                                                    src={
                                                        course?.courseImage !==
                                                        ""
                                                            ? course?.courseImage
                                                            : toAbsoluteUrl(
                                                                  "/media/ikure/content-builder/youtube.svg"
                                                              )
                                                    }
                                                    alt={course?.courseImage}
                                                    className="w-250px h-150px mt-2"
                                                />
                                            </div>
                                            <div className="p-5 w-650px">
                                                <div className="fs-1 fw-bolder text-dark">
                                                    {course?.courseTitle}
                                                </div>

                                                <p className="text-gray-700 fw-bold fs-5 mt-1">
                                                    {courseDescription}
                                                </p>
                                                <p className="text-gray-500 fw-bold fs-5 mt-1">
                                                    {course?.coursePrimaryFocus}
                                                </p>
                                                <p className="text-gray-500 fw-bold fs-5 mt-1">
                                                    Duration{" "}
                                                    {course?.courseDuration}
                                                </p>
                                            </div>
                                            <div className="ms-15 d-flex justify-content-between align-items-start p-5">
                                                <span
                                                    className={`badge badge-light-success fw-bolder me-auto px-4 py-3`}
                                                >
                                                    {course?.courseLanguage}
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "200px",
                                                    }}
                                                >
                                                    {course?.chwGrade?.map(
                                                        (grade: any) => {
                                                            return (
                                                                <span
                                                                    className={`mb-2 ms-5 badge badge-light-info fw-bolder me-auto px-4 py-3`}
                                                                >
                                                                    {
                                                                        grade?.gradeName
                                                                    }
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </>
                        );
                    })
                ) : (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <h1 className="text-danger">{allCourses}</h1>
                    </div>
                )}
                {/* Pagination */}
                <div className="mt-10 d-flex flex-stack flex-wrap">
                    <div className="fs-6 fw-bold text-gray-700 me-5 mb-5">
                        {isSearchError || isGetCoursesError
                            ? `No Record Found`
                            : searchData?.length > 0
                            ? // Search Based
                              `Showing ${pageNumber} to ${totalPages} of ${totalSearchEntites} total Entries`
                            : //  Get API Based
                              `Showing ${pageNumber} to ${totalCoursesPages} of ${totalCoursesEntites} total Entries`}
                    </div>

                    {isSearchError || isGetCoursesError === true ? (
                        <span
                            className="bg-primary"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                width: "30px",
                                height: "35px",
                                border: "none",
                                borderRadius: "5px",
                            }}
                        >
                            <h3
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    textAlign: "center",
                                    marginBottom: "0px",
                                }}
                            >
                                0
                            </h3>
                        </span>
                    ) : (
                        <div className="d-flex align-items-center justify-content-center">
                            <span>
                                <button
                                    onClick={() =>
                                        setPageNumber(
                                            (prevPageNumber: number) =>
                                                prevPageNumber - 1
                                        )
                                    }
                                    disabled={pageNumber === 1}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        cursor:
                                            pageNumber === 1
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                >
                                    <i className="fas fa-angle-double-left"></i>
                                </button>
                            </span>
                            <span
                                className="bg-primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    width: "30px",
                                    height: "35px",
                                    border: "none",
                                    borderRadius: "5px",
                                }}
                            >
                                <h3
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        textAlign: "center",
                                        marginBottom: "0px",
                                    }}
                                >
                                    {pageNumber}
                                </h3>
                            </span>
                            <span>
                                <button
                                    onClick={() =>
                                        setPageNumber(
                                            (prevPageNumber: number) =>
                                                prevPageNumber + 1
                                        )
                                    }
                                    disabled={pageNumber === totalPages}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        cursor:
                                            pageNumber === totalPages
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                >
                                    <i className="fas fa-angle-double-right"></i>
                                </button>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CoursePage;
