import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the UTC plugin

dayjs.extend(utc); // Extend dayjs with the UTC plugin
dayjs.extend(advancedFormat); // Extend dayjs with the advancedFormat plugin
dayjs.extend(timezone);

// Not in use
// export const todayDate = moment().format("YYYY-MM-DD");
// export const tomorrowDate = moment().add(1, "days").format("YYYY-MM-DD");
// export const yesterdateDate = moment().subtract(1, "days").format("YYYY-MM-DD");

export const todayDate = dayjs().format("YYYY-MM-DD");
export const tomorrowDate = dayjs().add(1, "day").format("YYYY-MM-DD");
export const yesterdayDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");

export let prevMonth: any = [];

let currentYear = new Date().getFullYear();

function daysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
}

for (let i = 0; i <= 2; i++) {
    prevMonth.push({
        format: moment().subtract(i, "months").format("MMM YY"),
        daysInMonth: daysInMonth(
            moment().subtract(i, "months").format("M"),
            currentYear
        ),
        month: moment().subtract(i, "months").format("MMMM"),
        year: moment().subtract(i, "months").format("YYYY"),
        monthAndYear: moment().subtract(i, "months").format("MMMM ( YYYY )"),
        monthYear: moment().subtract(i, "months").format("MMMM YYYY"),
    });
}

export const dateFormatter = (data: Date) => dayjs(data).format("DD-MM-YYYY");

/**
 * Converts a date string in yyyy-mm-dd format to a UTC timestamp.
 *
 * @param {string} dateStr - The date string in yyyy-mm-dd format.
 * @returns {number} The UTC timestamp.
 */
export function convertToUtcTimestamp(dateStr: string): number {
    // Parse the date string and convert it to a UTC timestamp
    return dayjs.utc(dateStr, "YYYY-MM-DD").valueOf();
}

/**
 * Converts a UTC epoch timestamp to a local date string in a human-readable format.
 *
 * @param {number} timestamp - The UTC epoch timestamp in milliseconds.
 * @param {string} format - The desired format of the output date string.
 * @returns {string} The formatted date string in the local timezone.
 */
export function convertUtcTimestampToLocalDate(
    timestamp: number,
    format: string = "MMMM D, YYYY, h:mm A"
): string {
    // Convert the UTC timestamp to a dayjs object in the local timezone and format it
    return dayjs.utc(timestamp).local().format(format);
    // return dayjs.utc(timestamp).tz("Asia/Kolkata").format(format);
}
