import React, { useState } from "react";
import * as Yup from "yup";
import { Menu } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { todayDate } from "../../../../common/utils";

const filterSchema = Yup.object().shape({
    status: Yup.string(),
    fromDate: Yup.date().required("From date is required"),
    toDate: Yup.date()
        .required("To date is required")
        .when("fromDate", (fromDate, schema) => {
            return fromDate
                ? schema.required(
                      "To Date is required when From Date is specified"
                  )
                : schema.nullable();
        })
        .min(Yup.ref("fromDate"), "To date must be greater than From date")
        .max(new Date(), "Date must not be more than the current date"),
});

const IndentFilter = ({ filterData, setFilterData }: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                indentStatus: values.indentStatus,
                fromDate: values.fromDate,
                toDate: values.toDate,
            };
            // console.log(payload);
            setFilterData(payload);
            handleClose();
            // onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            // onSubmitProps.resetForm();
        }
    };
    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-md btn-primary"
                style={{ marginLeft: "0.8rem" }}
                data-testid="filter-btn"
            >
                {" "}
                <i className="fas fa-filter"></i> FILTER
            </button>
            <Menu
                id="basic-menu"
                data-testid="menu-bar"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Formik
                    initialValues={filterData}
                    validationSchema={filterSchema}
                    onSubmit={onFilterSubmit}
                    enableReinitialize
                >
                    {(formProps: any) => (
                        <Form>
                            <div className="py-5 px-8">
                                <div className="w-250px mt-5 mb-8">
                                    <label className="form-label fs-4 fw-bolder">
                                        STATUS
                                    </label>
                                    <Field
                                        as="select"
                                        name="indentStatus"
                                        className="form-select form-select-lg form-select-solid"
                                        data-testid="status-select"
                                    >
                                        <option value="">All</option>
                                        <option value="0">Draft</option>
                                        <option value="1">Submitted</option>
                                        <option value="2">Accepted</option>
                                        <option value="3">Rejected</option>
                                        <option value="4">Cancelled</option>
                                    </Field>
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="indentStatus" />
                                    </div>
                                </div>

                                <div className="w-250px mt-5 mb-5">
                                    <label
                                        className="form-label fs-4 fw-bolder required"
                                        htmlFor="fromDate"
                                    >
                                        FROM DATE
                                    </label>
                                    <Field
                                        id="fromDate"
                                        as="input"
                                        data-testid="from-date"
                                        type="date"
                                        max={todayDate}
                                        name="fromDate"
                                        className="form-control cursor-pointer"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="fromDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder required">
                                        TO DATE
                                    </label>
                                    <Field
                                        onChange={(e: any) => {
                                            formProps.handleChange(e);
                                            formProps.setFieldTouched(
                                                "fromDate"
                                            );
                                        }}
                                        id="toDate"
                                        data-testid="to-date"
                                        type="date"
                                        name="toDate"
                                        max={todayDate}
                                        className="form-control"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="toDate" />
                                    </div>
                                </div>
                            </div>

                            <div className="my-5 d-flex justify-content-center align-items-center">
                                <button
                                    type="submit"
                                    disabled={
                                        !formProps.isValid ||
                                        formProps.isSubmitting
                                    }
                                    className="w-250px btn btn-sm btn-primary"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Menu>
        </>
    );
};

export default IndentFilter;
