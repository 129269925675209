import { Link } from "react-router-dom";
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from "../../../../../components/Table";
import useSearchParams from "../../../../../hooks/useSearchParams";

const FocusedGroupsDetails = ({ data }: any) => {
  const { pId: id }: any = useSearchParams();
  return (
    <>
      <>
        <h1 className="">VISIT DETAILS</h1>
        <Table nested>
          <TableHeadRow>
            <TableHead width={50} align="center">
              SL No
            </TableHead>
            <TableHead width={100} align="center">
              VISIT NUMBER
            </TableHead>
            <TableHead width={100}>VISITED BY</TableHead>
            <TableHead width={100}>VISIT DATE</TableHead>
            <TableHead width={100}>ACTION</TableHead>
          </TableHeadRow>
          <TableBodyContainer isLoading={false}>
            {data?.map((item: any, index: any) => {
              let visitedDate = new Date(item?.focusedgroup?.createdAt);
              let taskId = item?.focusedgroup?.taskId;
              let focusedGroupId = item?.focusedgroup?.focusedGroupId;
              let uhid = item?.focusedgroup?.uhid;
              let formId = item?.focusedgroup?._id;

              let { firstName, lastName, middleName } = item?.chwData || {};
              let chwName = `${firstName} ${middleName} ${lastName}`;

              return (
                <>
                  {(item?.visitStatus || item?.isFormSubmitted) === false ? (
                    <TableRow collapsible={false}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell isDanger>Not completed yet</TableCell>
                      <TableCell isDanger>Not completed yet</TableCell>
                      <TableCell isDanger>Not completed yet</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow collapsible={false}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {item?.visitNumber ? item?.visitNumber : "N/A"}
                      </TableCell>
                      <TableCell>{chwName ? chwName : "N/A"}</TableCell>
                      <TableCell>
                        {visitedDate ? visitedDate?.toDateString() : "N/A"}
                      </TableCell>

                      <TableCell>
                        {item?.isFormSubmitted !== false ? (
                          <Link
                            state={{
                              id: formId,
                            }}
                            to={{
                              // pathname: `/patient/${id}/overview/patient-entries`,
                              pathname: `/patient/overview/patient-entries`,
                              search: `?pId=${id}`,
                            }}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm "
                          >
                            ENTIRES
                          </Link>
                        ) : (
                          // <span className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm ">
                          //   Not Completed
                          // </span>
                          ""
                        )}
                        {item?.visitStatus !== false ? (
                          <Link
                            state={{
                              uhid,
                              taskId,
                              focusedGroupId,
                            }}
                            to={{
                              pathname: `/patient/overview/patient-vitals`,
                              search: `?pId=${id}`,
                              // state: {
                              //   uhid,
                              //   taskId,
                              //   focusedGroupId,
                              // },
                            }}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          >
                            VITALS
                          </Link>
                        ) : (
                          // <span className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2">
                          //   Not Completed
                          // </span>
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBodyContainer>
        </Table>
      </>
    </>
  );
};

export default FocusedGroupsDetails;
