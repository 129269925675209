import { FC, useState } from "react";
import { Field, ErrorMessage } from "formik";

const Step1: FC = () => {
    const [showEye, setShowEye] = useState<boolean>(false);

    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Profile Details</h2>
            </div>

            {/* First Name */}
            <div className="fv-row mb-10">
                <label className="form-label required">First Name</label>

                <Field
                    data-testid="first-name"
                    type="input"
                    name="firstName"
                    placeholder="Enter your first name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="firstName" />
                </div>
            </div>

            {/* Middle Name */}
            <div className="fv-row mb-10">
                <label className="form-label">Middle Name</label>

                <Field
                    type="input"
                    data-testid="middle-name"
                    name="middleName"
                    placeholder="Enter your middle name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="middleName" />
                </div>
            </div>

            {/* Last Name */}
            <div className="fv-row mb-10">
                <label className="form-label required">Last Name</label>

                <Field
                    type="input"
                    data-testid="last-name"
                    name="lastName"
                    placeholder="Enter your last name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="lastName" />
                </div>
            </div>

            {/* Password */}
            <div className="fv-row col-lg-12 mb-10 position-relative">
                <label className="form-label required disabled">Password</label>
                <Field
                    data-testid="password"
                    type={showEye === true ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    className="form-control disabled form-control-lg form-control-solid"
                />
                <span onClick={() => setShowEye(!showEye)}>
                    {showEye ? (
                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                            <i className="fs-2 fas fa-eye mt-8 "></i>
                        </div>
                    ) : (
                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                            <i className="fs-2 fas fa-eye-slash mt-8"></i>
                        </div>
                    )}
                </span>
                <div className="text-danger mt-2">
                    <ErrorMessage name="password" />
                </div>
            </div>

            {/* Email */}
            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    Email Id
                </label>

                <Field
                    data-testid="email"
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="email" />
                </div>
            </div>

            {/* Phone Number */}
            <div className="fv-row mb-10">
                <label className="form-label required">Phone Number</label>

                <Field
                    data-testid="phone-number"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="phoneNumber" />
                </div>
            </div>
        </div>
    );
};

export { Step1 };
