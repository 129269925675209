export const netRate = (mrp: number = 0, disc: number = 0, gst: number = 0) => {
    const amount = mrp - +discountAmount(mrp, disc);
    return amount.toFixed(2);
};

const discountAmount = (mrp: number = 0, disc: number = 0) => {
    const val = (mrp * disc) / 100;
    return val.toFixed(2);
};

// const gstAmount = (mrp: number = 0, disc: number = 0, gst: number = 0) => {
//     const amount = ((mrp - +discountAmount(mrp, disc)) * gst) / 100;
//     return amount.toFixed(2);
// };

const discountGrossAmount = (
    mrp: number = 0,
    disc: number = 0,
    quantity: number = 0
) => {
    const val = ((mrp * disc) / 100) * quantity;
    return val.toFixed(2);
};

const grossGstAmount = (
    mrp: number = 0,
    disc: number = 0,
    gst: number = 0,
    quantity: number = 0
) => {
    const discount = +discountAmount(mrp, disc);
    const amt = mrp - discount;
    const gstPercent = gst / 100;
    const gstAmt = amt * gstPercent;
    const amount = gstAmt * quantity;

    return amount.toFixed(2);
};

export const aggregateGrossTotal = (salesProductList: any) => {
    let aggTotalAmount = 0;
    let disc = 0;
    let gstAmt = 0;

    salesProductList?.forEach((ele: any) => {
        aggTotalAmount +=
            (ele.ratePerUnit * ele.totalQuantity * (100 - ele.GST)) / 100;
        disc += +discountGrossAmount(
            ele.ratePerUnit,
            ele.discount,
            ele.totalQuantity
        );
        gstAmt += +grossGstAmount(
            ele.ratePerUnit,
            ele.discount,
            ele.GST,
            ele.totalQuantity
        );
    });

    const TotalAmount = aggTotalAmount - disc + gstAmt;
    return {
        grossTotal: aggTotalAmount.toFixed(2),
        grossDisc: disc.toFixed(2),
        gstAmount: gstAmt.toFixed(2),
        totalAmount: TotalAmount.toFixed(2),
    };
};
