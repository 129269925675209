import { useEffect, useState } from "react";
import { useNavbar } from "../../../context/NavbarContext";
import useGetRequest from "../../../hooks/getRequest.query";
import { getAllFocusedGroup } from "../../../services/focused-group/FocusedGroup.hook";

import FocusedGroupCounterPage from "./FocusedGroupCounterPage";
import FocusedGroupTable from "./table/FocusedGroupTable";

const FocusedGroupPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ["getAllFocusedGroup", pageNumber, pageLimit],
      async () =>
        await getAllFocusedGroup({
          page: pageNumber,
          limit: pageLimit,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedFacility]);

  const totalFocusedGroupsEntites = data?.data?.results;
  const focusedGroupsData = data?.data?.data?.focusedGroup;
  const countersData = data?.data?.data?.focusedGroupsCount;
  const totalAvailablePages = Math.ceil(totalFocusedGroupsEntites / pageLimit);
  const ErrorMsg = error?.response?.data?.message;

  return (
    <>
      <div>
        <FocusedGroupCounterPage
          isLoading={isLoading}
          isFetching={isFetching}
          countersData={countersData}
        />
      </div>
      <div className="mt-5">
        <FocusedGroupTable
          pageLimit={pageLimit}
          currentPageNumber={pageNumber}
          handlePageNumber={setPageNumber}
          totalFocusedGroupsEntites={totalFocusedGroupsEntites}
          totalAvailablePages={totalAvailablePages}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          FgErrorMsg={ErrorMsg}
          bodyProps={focusedGroupsData}
          refetch={refetch}
          setPageLimit={setPageLimit}
        />
      </div>
    </>
  );
};

export default FocusedGroupPage;
