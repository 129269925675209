import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Menu } from "@mui/material";
import { todayDate } from "../../../common/utils";

type Props = {
    setFilterData: Function;
    getData: Function;
    filterData: any;
};

const Filter = ({ setFilterData, getData, filterData }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                // facilityCode: values.facilityCode,
                fromDate: values.fromDate,
                toDate: values.toDate,
            };
            setFilterData(payload);
            getData();
            handleClose();
            // onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            // onSubmitProps.resetForm();
        }
    };

    return (
        <div className="d-flex align-items-center">
            <button onClick={handleClick} className="btn btn-sm btn-primary">
                <i className="fas fa-filter"></i>
                FILTER
            </button>
            {/* Filter Options */}
            <div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <Formik
                        initialValues={{
                            // facilityCode: "",
                            ...filterData,
                        }}
                        enableReinitialize
                        onSubmit={onFilterSubmit}
                    >
                        {(formProps: any) => (
                            <Form>
                                <div className="py-5 px-8">
                                    <div className="w-250px mt-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            FROM DATE
                                        </label>
                                        <Field
                                            id="fromDate"
                                            as="input"
                                            type="date"
                                            name="fromDate"
                                            max={todayDate}
                                            className="form-control cursor-pointer"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="fromDate" />
                                        </div>
                                    </div>
                                    <div className="w-250px mt-5 mb-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            TO DATE
                                        </label>
                                        <Field
                                            id="toDate"
                                            type="date"
                                            name="toDate"
                                            max={todayDate}
                                            className="form-control"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="toDate" />
                                        </div>
                                    </div>
                                    {/* <div className="w-250px mt-5 ">
                                <label className="form-label fs-4 fw-bolder">
                                    FACILITY NAME
                                </label>
                                <Field
                                    id="facilityCode"
                                    as="select"
                                    name="facilityCode"
                                    className="form-select form-select-light form-select-lg required"
                                >
                                    <option value="" selected>
                                        All
                                    </option>
                                    {allFacilites
                                        ?.filter(
                                            (
                                                facilities: any
                                            ) => {
                                                return (
                                                    facilities?.status !==
                                                    false
                                                );
                                            }
                                        )
                                        .map(
                                            (facility: any) => {
                                                const facilityName =
                                                    facility?.facilityFirstName +
                                                    " " +
                                                    facility?.facilityLastName;
                                                return (
                                                    <>
                                                        <option
                                                            value={
                                                                facility?.facilityCode
                                                            }
                                                        >
                                                            {
                                                                facilityName
                                                            }
                                                        </option>
                                                    </>
                                                );
                                            }
                                        )}
                                </Field>
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="visitNumber" />
                                </div>
                            </div> */}
                                </div>

                                <div className="my-5 d-flex justify-content-center align-items-center">
                                    <button
                                        type="submit"
                                        disabled={
                                            !formProps.isValid ||
                                            formProps.isSubmitting
                                        }
                                        className="w-250px btn btn-sm btn-primary"
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Menu>
            </div>
            {/* End Here */}
        </div>
    );
};

export default Filter;
