import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";

import {
  activeCriterias,
  checkIfDisable,
  getKPITargets,
  initalValuesForPerformanceMonitoring,
  PerformanceMonitoringTypesValidationSchema,
} from "../models/PerformanceMonitoringModel";

import { allKPIdata } from "../data/data";
import CustomTooltip from "../../../../components/CustomTooltip";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { setCHWTargets } from "../../../../services/settings/Settings.hook";

const PerformanceMonitoring = ({
  onClose,
  criterias,
  perfMonitId,
  styles,
}: any) => {
  // Get Id
  const getSelectedCriteriaId = (criteria: any) => {
    return criterias
      ?.filter((modules: any) => modules?.criteriaName === criteria)
      ?.map((x: any) => {
        return x?._id;
      })[0];
  };

  const [msg, setMsg] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);

  const { isLoading, isError, data, error, mutate, isSuccess }: any =
    useMutateRequest((data: any) => setCHWTargets(data));

  const facilityCode = localStorage.getItem("facilityCode");
  const errorMsg = error?.response?.data?.message;

  const allActiveCriterias = Object.values(activeCriterias);

  const onSubmit = (values: any) => {
    let allPayload = allActiveCriterias?.map((item) => {
      return {
        criteriaName: item[0],
        criteriaId: getSelectedCriteriaId(item[0]),
        targets: getKPITargets(values[item[1]]),
      };
    });

    let customPayload = allPayload?.filter((payload: any) => {
      return payload?.targets?.length > 0;
    });

    let payload = {
      moduleId: perfMonitId,
      facilityCode,
      criterias: customPayload,
    };

    try {
      mutate(payload, {
        onSuccess(data: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(true);
          setTimeout(() => {
            setSpinner(false);
          }, 3000);
        },
        onError(error: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(true);
          setTimeout(() => {
            setSpinner(false);
          }, 3000);
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className="card mb-5 mb-xl-10">
          <div className="card-header border-0 cursor-pointer" role="button">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Performance Monitoring</h3>
            </div>

            <h1 onClick={() => onClose()} className=" mt-5">
              <i className="fs-2 text-danger fas fa-window-close cursor-pointer"></i>
            </h1>
          </div>
          {isLoading || spinner === true ? (
            <div className="card w-100 h-450px d-flex flex-column align-items-center justify-content-center p-15">
              <ClipLoader size={40} speedMultiplier={0.6} />
            </div>
          ) : msg ? (
            <div
              style={{
                backgroundColor: styles?.backgroundColor,
                width: styles?.width,
                height: styles?.msgHeight,
                padding: styles?.padding,
              }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              {isError === true ? (
                <>
                  <p className="fs-1 fw-bolder text-center text-dark">
                    {errorMsg}
                  </p>
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <button
                      onClick={onClose}
                      className="w-150px btn btn-lg btn-danger me-5"
                    >
                      Close
                    </button>
                    <button
                      onClick={() => setMsg(false)}
                      className="w-150px btn btn-lg btn-primary me-5"
                    >
                      Please Retry
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="fs-1 fw-bolder text-center text-dark">
                    Successfully set the target
                  </p>
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <button
                      onClick={onClose}
                      className="w-150px btn btn-lg btn-danger me-5"
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div style={{ height: "450px", overflowX: "scroll" }}>
              <Formik
                initialValues={initalValuesForPerformanceMonitoring}
                validationSchema={PerformanceMonitoringTypesValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {(formProps) => {
                  let checkDisableForPatientRegistration = checkIfDisable(
                    formProps?.values?.patientRegistrationDetails
                  );
                  let checkDisableForTeleconsultation = checkIfDisable(
                    formProps?.values?.teleconsultationDetails
                  );
                  let checkDisableForBloodGlucose = checkIfDisable(
                    formProps?.values?.bloodGlucoseDetails
                  );
                  let checkDisableForBloodPressure = checkIfDisable(
                    formProps?.values?.bloodPressureDetails
                  );
                  let checkDisableForECG = checkIfDisable(
                    formProps?.values?.ecgDetails
                  );
                  let checkDisableForHeight = checkIfDisable(
                    formProps?.values?.heightDetails
                  );
                  let checkDisableForWeight = checkIfDisable(
                    formProps?.values?.weightDetails
                  );
                  let checkDisableForHemoglobin = checkIfDisable(
                    formProps?.values?.hemoglobinDetails
                  );
                  let checkDisableForPulse = checkIfDisable(
                    formProps?.values?.pulseDetails
                  );
                  let checkDisableForRespiratory = checkIfDisable(
                    formProps?.values?.respiratoryDetails
                  );
                  let checkDisableForTemperature = checkIfDisable(
                    formProps?.values?.temperatureDetails
                  );
                  let checkDisableForOxygenSaturation = checkIfDisable(
                    formProps?.values?.oxygenSaturationDetails
                  );

                  let setDisable1 = checkDisableForPatientRegistration?.some(
                    (el: any) => el === true
                  );
                  let setDisable2 = checkDisableForTeleconsultation?.some(
                    (el: any) => el === true
                  );
                  let setDisable3 = checkDisableForBloodGlucose?.some(
                    (el: any) => el === true
                  );
                  let setDisable4 = checkDisableForBloodPressure?.some(
                    (el: any) => el === true
                  );

                  let setDisable5 = checkDisableForECG?.some(
                    (el: any) => el === true
                  );

                  let setDisable6 = checkDisableForHeight?.some(
                    (el: any) => el === true
                  );
                  let setDisable7 = checkDisableForWeight?.some(
                    (el: any) => el === true
                  );
                  let setDisable8 = checkDisableForHemoglobin?.some(
                    (el: any) => el === true
                  );
                  let setDisable9 = checkDisableForPulse?.some(
                    (el: any) => el === true
                  );
                  let setDisable10 = checkDisableForRespiratory?.some(
                    (el: any) => el === true
                  );
                  let setDisable11 = checkDisableForTemperature?.some(
                    (el: any) => el === true
                  );
                  let setDisable12 = checkDisableForOxygenSaturation?.some(
                    (el: any) => el === true
                  );

                  let setDisable =
                    (setDisable1 ||
                      setDisable2 ||
                      setDisable3 ||
                      setDisable4 ||
                      setDisable5 ||
                      setDisable6 ||
                      setDisable7 ||
                      setDisable8 ||
                      setDisable9 ||
                      setDisable10 ||
                      setDisable11 ||
                      setDisable12) &&
                    formProps?.isValid !== false;

                  return (
                    <Form>
                      {allKPIdata?.map((kpi) => {
                        const kpiFormProps =
                          formProps?.values &&
                          formProps?.values?.[kpi?.kpiName];
                        return (
                          <>
                            <div className="card-body border-top px-9 py-9">
                              {/* Label */}
                              <label className="form-check form-check-custom form-check-solid align-items-start">
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  name={kpi.kpiName}
                                  defaultChecked={false}
                                  onChange={(e: any) =>
                                    formProps.setFieldValue(
                                      `${kpi.kpiName}`,
                                      !kpiFormProps
                                    )
                                  }
                                />

                                <span className="form-check-label d-flex flex-column align-items-start">
                                  <span className="fw-bolder fs-5 mb-0">
                                    {kpi.inputName}
                                  </span>
                                  <span className="text-muted fs-6">
                                    {kpi.inputDesc}
                                  </span>
                                </span>
                              </label>

                              {/* Separator */}
                              {kpi.showSeparator === true && (
                                <div className="separator separator-dashed my-3"></div>
                              )}

                              {/* Placeholder to set the target */}
                              {kpiFormProps === true && (
                                <div className="border border-dark border-dotted  border-1 rounded rounded-1 p-5">
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                    className="d-flex justify-content-start"
                                  >
                                    {kpi?.kpiData?.map(
                                      (month: any, index: any) => {
                                        return (
                                          <>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                              <Field
                                                name={`${kpi.fieldName}[${index}].targetName`}
                                                placeholder="0"
                                                className="w-60px ms-2 form-control form-control-lg form-control-solid"
                                              />
                                              <CustomTooltip
                                                title={`${month?.daysInMonth} Days`}
                                                placement="top"
                                              >
                                                <span className="mt-2 fs-5 fw-bolder">
                                                  {
                                                    month?.monthNameAndCurrentYear
                                                  }
                                                </span>
                                              </CustomTooltip>
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: "100px",
                                                  right: "5px",
                                                  width: "100%",
                                                }}
                                                className="fs-6 text-center text-danger w-100"
                                              >
                                                {kpiFormProps === true &&
                                                  // setDisable === false &&
                                                  formProps?.isValid ==
                                                    false && (
                                                    <ErrorMessage
                                                      name={`${kpi.fieldName}[${index}].targetName`}
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Error Message */}
                              {kpiFormProps === true &&
                                setDisable === false && (
                                  <div
                                    hidden={formProps?.isValid == false}
                                    className="my-5 fs-6 text-center text-danger w-100"
                                  >
                                    {kpi?.errorMsg}
                                  </div>
                                )}
                            </div>
                          </>
                        );
                      })}

                      {/* Submit Button */}
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          disabled={setDisable === false}
                          type="submit"
                          className="btn btn-md btn-primary w-100px"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PerformanceMonitoring;
