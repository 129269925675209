import _ from "lodash";

export const splitString = (acceptedString: string, position: number) => {
    if (!acceptedString) {
        return null;
    }
    return acceptedString?.split(";")[position];
};

export const stringTruncate = (acceptedString: string, limit: number) => {
    if (acceptedString?.length <= limit) {
        return acceptedString;
    }
    return acceptedString?.slice(0, limit) + "...";
};

export const capitalizeStrings = (acceptedString: string) => {
    if (!acceptedString) {
        return "--";
    }
    return _.startCase(acceptedString);
};

export const customRecords = (acceptedField: any) => {
    if (!acceptedField) {
        return "--";
    }
    return acceptedField ? _.startCase(acceptedField) : "--";
};
