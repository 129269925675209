import ClipLoader from "react-spinners/ClipLoader";
import CustomTooltip from "../../components/CustomTooltip";

const CHWCounterPage = ({
    chwCounterData,
    isLoading,
    isFetching,
    filterData,
    totalCount,
}: any) => {
    const showSimpleCounter = Object?.values(filterData)?.some(
        (value) => value !== ""
    );

    return (
        <div className="p-10 card">
            <div className="d-flex">
                <h1 className="fs-1 text-dark fw-bolder">CHW MANAGEMENT</h1>
                <CustomTooltip
                    style={{ fontSize: "550px" }}
                    title="Overview of ON & OFF FIELD CHW's"
                    placement="right"
                    // arrow
                >
                    <i className="fs-2x fas fa-info-circle ms-2 bg-transparent border-none"></i>
                </CustomTooltip>
            </div>
            {showSimpleCounter ? (
                <>
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-success">
                                {totalCount ? totalCount : "No Data"}
                            </h3>
                            <h3>Total Count</h3>
                        </div>
                    )}
                </>
            ) : (
                <div className="d-flex mt-5">
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-success">
                                {chwCounterData?.onFieldCounter
                                    ? chwCounterData?.onFieldCounter
                                    : "No Data"}
                            </h3>
                            <h3>ON FIELD</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-danger">
                                {chwCounterData?.offFieldCounter
                                    ? chwCounterData?.offFieldCounter
                                    : "No Data"}
                            </h3>
                            <h3>OFF FIELD</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-175px h-125px border-gray-500 border-dotted">
                            <h3 className="text-info">
                                {chwCounterData?.allChwCounter
                                    ? chwCounterData?.allChwCounter
                                    : "No Data"}
                            </h3>
                            <h3>TOTAL CHWS</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CHWCounterPage;
