import React, { useMemo } from "react";
import ClipLoader from "react-spinners/ClipLoader";

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
  isError?: boolean;
  errorMessage?: string;
};

const TableBodyContainer = (props: Props) => {
  const { children, isLoading, isError, errorMessage } = props;
  const errorString = useMemo(() => errorMessage, [errorMessage]);
  const errorBool = useMemo(() => isError, [isError]);
  const loading = useMemo(() => isLoading, [isLoading]);
  return (
    <>
      {loading || errorBool ? (
        <tbody
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            left: "450px",
            top: "50px",
          }}
        >
          {loading && <ClipLoader size={70} speedMultiplier={0.6} />}
          {errorBool && (
            <h1 className="text-danger text-nowrap">
              {errorString ? errorString : "Could not load data"}
            </h1>
          )}
        </tbody>
      ) : (
        <tbody className="col-xl-8">{children}</tbody>
      )}
    </>
  );
};

export default TableBodyContainer;
