import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

import useMutateRequest from "../../../../../../hooks/getMutation.query";
import useGetRequest from "../../../../../../hooks/getRequest.query";
import {
    addSubscription,
    getHealthCardPlanInfo,
} from "../../../../../../services/patient/PatientHealthcard.service";

type Props = {
    setIsOpen: Function;
    patientDetails: any;
    setHealthCardNo: any;
};

const validationSchema = Yup.object().shape({
    healthCardNo: Yup.number()
        .typeError("Health Card Number must be a number")
        .required("Health Card Number is required"),
    selectMonth: Yup.string().required("Select Month is required"),
});

const AddSubscription = (props: Props) => {
    const [patientHealthCardNo, setPatientHealthCardNo] = useState("");

    const {
        data,
        isError: isFailedHealthCard,
        error: errorHealthCard,
    }: any = useGetRequest(
        ["getHealthCardPlanInfo", patientHealthCardNo],
        async () =>
            patientHealthCardNo &&
            (await getHealthCardPlanInfo({
                patientHealthCardNo,
            })),
        {
            retry: false,
            // staleTime: 100000,
            // keepPreviousData: true,
            // refetchOnWindowFocus: false,
        }
    );
    const healthCardInfo = data?.data?.data?.healthCard?.healthCardDetails;

    const errorHealthCardMsg = errorHealthCard?.response?.data?.message;

    // Add healthcard submission

    const { isLoading, isError, error, mutate, isSuccess }: any =
        useMutateRequest((data: any) => addSubscription(data));
    const errorMsg = error?.response?.data?.message;

    const submitStep = (values: any, actions: FormikValues) => {
        try {
            let formData = new FormData();
            const durationInMonths = values?.selectMonth?.split(";")[0];
            const pricingId = values?.selectMonth?.split(";")[1];
            formData.append("uhid", props?.patientDetails?.uhid);
            formData.append(
                "healthCardNumber",
                String(values.healthCardNo)?.trim()
            );
            formData.append("pricingId", pricingId);
            formData.append("durationInMonths", durationInMonths);

            mutate(formData, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        props.setIsOpen(false);
                        props.setHealthCardNo(patientHealthCardNo);
                    }, 3000);
                },
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span
                    data-testid="success-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center "
                >
                    Successfully added subscription
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = (str: string) => {
        toast.error(
            (t) => (
                <span
                    data-testid="error-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                >
                    {str ? str : "Something went wrong."}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        if (isError || isFailedHealthCard) {
            errorToastMsg(isError ? errorMsg : errorHealthCardMsg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isFailedHealthCard]);
    return (
        <>
            {/* Spinner */}
            {isLoading ? (
                <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader
                        data-testid="spinner"
                        size={70}
                        speedMultiplier={0.6}
                    />
                </div>
            ) : (
                <div>
                    <h1 className="text-center text-bolder">
                        Add Health Card Subscription
                    </h1>
                    <div className="mt-5" style={{ width: "100%" }}>
                        <div className=" d-flex justify-content-between">
                            <p className="fw-bold  fs-5">Patient Name :</p>
                            <p className="fs-5">
                                {props?.patientDetails
                                    ? props?.patientDetails?.name
                                    : "N/A"}
                            </p>
                        </div>
                        <div className=" d-flex justify-content-between">
                            <p className="fw-bold  fs-5">UHID :</p>
                            <p className="fs-5">
                                {props?.patientDetails
                                    ? props?.patientDetails?.uhid
                                    : "N/A"}
                            </p>
                        </div>
                        <div className=" d-flex justify-content-between">
                            <p className="fw-bold  fs-5">Phone No :</p>
                            <p className="fs-5">
                                {props?.patientDetails?.demographicDetails
                                    .telecom
                                    ? props?.patientDetails?.demographicDetails
                                          .telecom
                                    : "N/A"}
                            </p>
                        </div>
                        <Formik
                            initialValues={{
                                healthCardNo: "",
                                selectMonth: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={submitStep}
                        >
                            {(formProps) => {
                                const selectedMonth =
                                    formProps.values.selectMonth?.split(";")[0];
                                const planPricing =
                                    healthCardInfo?.pricings?.filter(
                                        (price: any) =>
                                            price?.durationInMonths ===
                                            Number(selectedMonth)
                                    );
                                const price = planPricing?.at(0)?.price;

                                return (
                                    <Form>
                                        <div className="d-flex mb-5 align-items-center">
                                            <div
                                                className="fs-5 fw-bold text-dark required mr-10"
                                                style={{ width: "100%" }}
                                            >
                                                Add Health Card :
                                            </div>
                                            <div>
                                                <Field
                                                    placeholder="Health Card Number"
                                                    style={{ width: "300px" }}
                                                    className="form-control form-control-lg form-control-solid "
                                                    type="text"
                                                    name="healthCardNo"
                                                    autoComplete="off"
                                                    onChange={(e: any) => {
                                                        formProps.handleChange(
                                                            e
                                                        );
                                                        _.debounce(
                                                            // @ts-ignore
                                                            () =>
                                                                setPatientHealthCardNo(
                                                                    e.target
                                                                        .value
                                                                ),
                                                            2000
                                                        )();
                                                    }}
                                                    value={
                                                        formProps.values
                                                            .healthCardNo
                                                    }
                                                />
                                                <div className="text-danger  mt-2">
                                                    <ErrorMessage name="healthCardNo" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" d-flex justify-content-between">
                                            <p className="fw-bold  fs-5">
                                                Plan:
                                            </p>
                                            <p className="fs-5">
                                                {healthCardInfo?.planName
                                                    ? healthCardInfo?.planName
                                                    : "N/A"}
                                            </p>
                                        </div>
                                        <div className="d-flex mb-5 align-items-center">
                                            <div
                                                className="fs-5 fw-bold text-dark required mr-10"
                                                style={{ width: "100%" }}
                                            >
                                                Select No. of Month :
                                            </div>

                                            <div>
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{ width: "300px" }}
                                                >
                                                    <Field
                                                        id="selectMonth"
                                                        as="select"
                                                        name="selectMonth"
                                                        className="form-select form-select-light form-select-lg w-25 "
                                                        style={{
                                                            minWidth: "90px",
                                                        }}
                                                    >
                                                        <option value="">
                                                            --
                                                        </option>
                                                        {healthCardInfo?.pricings
                                                            ? healthCardInfo?.pricings?.map(
                                                                  (
                                                                      price: any
                                                                  ) => {
                                                                      return (
                                                                          <option
                                                                              value={`${price?.durationInMonths};${price?.planId}`}
                                                                          >
                                                                              {price?.durationInMonths
                                                                                  ? price?.durationInMonths
                                                                                  : "N/A"}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : "N/A"}
                                                    </Field>

                                                    <p
                                                        className="fs-6"
                                                        style={{
                                                            width: "80%",
                                                            marginLeft: "1rem",
                                                        }}
                                                    >
                                                        Valid upto:
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            {dayjs()
                                                                .add(
                                                                    +formProps.values.selectMonth?.split(
                                                                        ";"
                                                                    )[0],
                                                                    "month"
                                                                )
                                                                .format(
                                                                    "DD-MM-YYYY"
                                                                )}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="text-danger mt-2">
                                                    <ErrorMessage name="selectMonth" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" d-flex justify-content-between">
                                            <p className="fw-bold  fs-5">
                                                Total Price :
                                            </p>
                                            <p className="fs-5">
                                                {price ? price : "N/A"}
                                            </p>
                                        </div>
                                        <div className="d-flex my-5 text-info text-center justify-content-between">
                                            Info: You have selected subscription
                                            for &nbsp;
                                            {healthCardInfo?.planName
                                                ? healthCardInfo?.planName
                                                : "N/A"}
                                            &nbsp; Card. Any other card mapped
                                            with this UHID will deactivated
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <button
                                                className="btn btn-lg btn-danger fs-4 fw-bolder w-100 p-4"
                                                style={{ marginRight: "1rem" }}
                                                onClick={() =>
                                                    props.setIsOpen(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={
                                                    !formProps.isValid ||
                                                    formProps.isSubmitting
                                                }
                                                type="submit"
                                                className="btn btn-lg btn-primary fs-4 fw-bolder w-100 p-4"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddSubscription;
