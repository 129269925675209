import * as Yup from "yup";

export type beneficiaryExportValueTypes = {
    exportFormat: string;
    fgName: string;
    visitNumber: string;
    facilityCode: string;
    fromDate: string;
    toDate: string;
};

export const beneficiaryInitalValues: beneficiaryExportValueTypes = {
    exportFormat: "CSV",
    fgName: "",
    visitNumber: "",
    facilityCode: "",
    fromDate: "",
    toDate: "",
};

export const beneficiaryExportValidationSchema = Yup.object({
    fgName: Yup.string().label("focused group is required"),
    visitNumber: Yup.string().label("visit number is required"),
    facilityCode: Yup.string().label("facility code is required"),
    fromDate: Yup.string().required("From date is required"),
    toDate: Yup.string().required("To date is required"),
});
