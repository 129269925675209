import { useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { Autocomplete, Menu, TextField } from "@mui/material";
import {
  initalValues,
  assignCHWValidationSchema,
} from "../model/ReassignCHWModel";
import useGetRequest from "../../../hooks/getRequest.query";
import useMutateRequest from "../../../hooks/getMutation.query";
import {
  getAllFacilities,
  assignCHWToFacility,
} from "../../../services/CHWManagement.hook";

type ExportProps = {
  onClose: () => void;
  styles?: any;
  chwName: string;
  chwId: string;
};

const ReassignCHW = ({ onClose, styles, chwName, chwId }: ExportProps) => {
  const [msg, setMsg] = useState<boolean>(false);
  const [chwSelect, setChwSelect] = useState("");
  const [spinner, setSpinner] = useState<boolean>(false);

  // Get list of all facilities
  const { data, isLoading }: any = useGetRequest(
    "getAllFacilities",
    () => getAllFacilities(),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const facilities = data?.data?.data?.facility
    ?.filter((facility: any) => {
      return facility?.status !== false;
    })
    .map((facility: any) => {
      const facilityCode = facility?.facilityCode;
      const facilityName =
        facility?.facilityFirstName + " " + facility?.facilityLastName;
      return {
        label: `${facilityName} ( ${facilityCode} )`,
        value: facilityCode,
      };
    });

  // Assign a CHW to other facility
  const {
    isLoading: assignCHWLoader,
    mutate,
    isError,
    error,
  }: any = useMutateRequest((data: any) => assignCHWToFacility(data));

  const ErrorMsg = error?.response?.data?.message;

  const onSubmit = (values: any) => {
    const payload = {
      facilityCode: values.facility,
      chwId,
    };

    try {
      mutate(payload, {
        onSuccess() {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },

        onError(error: any) {
          console.log(error);
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading || assignCHWLoader || spinner ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg ? (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.msgHeight,
            padding: styles?.padding,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {isError === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-danger">
                {ErrorMsg}
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-primary me-5"
                >
                  Please Retry
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Your request has been successfully submitted
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={assignCHWValidationSchema}
            onSubmit={onSubmit}
          >
            {(formProps: any) => (
              <Form>
                <h1 className="text-center">REASSIGN CHW</h1>
                <div className="mt-5">
                  <label
                    htmlFor="chwName"
                    className="form-label fs-5 fw-bolder"
                  >
                    CHW NAME
                  </label>
                  <Field
                    readOnly
                    id="chwName"
                    value={chwName}
                    name="chwName"
                    style={{ cursor: "not-allowed" }}
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>
                <div className="mt-8">
                  <label
                    htmlFor="facility"
                    className="form-label fs-5 fw-bolder required"
                  >
                    FACILITIES
                  </label>
                  <Autocomplete
                    className="fw-bolder"
                    disablePortal
                    id="facility"
                    options={facilities}
                    getOptionLabel={(option: any) => option?.label}
                    sx={{ width: "100%", fontWeight: 600 }}
                    onChange={(e: object, item: any | null) => {
                      if (item) {
                        setChwSelect(item?.value);
                        formProps?.setFieldValue("facility", item?.value);
                      } else {
                        setChwSelect("");
                        formProps?.setFieldValue("facility", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="fw-bolder"
                        name="facility"
                        style={{ border: ".5px solid black" }}
                      />
                    )}
                  />
                  <div className="text-muted fw-bolder mt-2 ms-2">
                    Do select the facility to which the CHW should be assigned.
                  </div>
                  <div className="text-danger mt-5">
                    <ErrorMessage name="facility" />
                  </div>
                </div>
                <div className="mt-10 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="w-150px btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    className="w-150px btn btn-md btn-primary"
                  >
                    Reassign
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default ReassignCHW;
