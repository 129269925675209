import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import UnusedHealthCardsExport from "../components/UnusedHealthCardsExport";
import Modal from "../../../components/Modal";
import { useNavbar } from "../../../context/NavbarContext";
import {
    getUnusedHealthCards,
    searchUnusedHealthCards,
} from "../../../services/HealthCard.hook";
import useGetRequest from "../../../hooks/getRequest.query";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import TableErrorComponent from "../../../components/Error/TableError";
import { Permissions, hasAccessToModule } from "../../../rbac";

import useMutateRequest from "../../../hooks/getMutation.query";

const searchSchema = Yup.object().shape({
    searchName: Yup.number()
        .typeError("Health Card must be a number")
        .integer("Health Card must be an integer")
        .positive("Health Card must be a positive number")
        .test(
            "len",
            "Health Card must be exactly 16 digits",
            (val: any) => val && val.toString().length === 16
        )

        .label("Health Card"),
});
const tableNames = [
    "BATCH NUMBER",
    "HEALTH CARD NUMBER",
    "PLAN",
    "USAGE STATUS",
    "STATUS",
];
const UnusedCardsTable = () => {
    const { selectedFacility } = useNavbar();
    // API Logic
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    const facilityCode = localStorage.getItem("facilityCode");

    const {
        data,
        isLoading,
        isFetching,
        isError: isUnusedHealthCardError,
        error,
        refetch,
    }: any = useGetRequest(
        ["getUnusedHealthCards", currentPageNumber],
        () =>
            getUnusedHealthCards({
                facilityCode,
                page: currentPageNumber,
                limit: pageLimit,
            }),
        { retry: false, staleTime: 100000, refetchOnWindowFocus: false }
    );
    const unusedHealthCardsData = data?.data?.data?.healthcards;
    const totalUnusedHealthCardEntites = data?.data?.results;
    const errorMsg = error?.response?.data?.message;
    const successMsg = data?.data?.data?.message;

    useEffect(() => {
        refetch();
    }, [selectedFacility]);
    // APP Logic
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    // Role setting
    const canExport = hasAccessToModule(
        "LIST_UNUSED_HEALTHCARDS",
        Permissions.export_button
    );

    // Setting the Search term
    const [searchData, setSearchData]: any = useState([]);
    const [searchFormProps, setSearchFormProps] = useState("");

    // API For Search CHW
    const {
        data: searchedUnusedHealthcardData,
        mutate,
        isError: isSearchError,
        error: ErrorMsg,
        isLoading: searchCHWLoader,
        reset,
    }: any = useMutateRequest((data: any) => searchUnusedHealthCards(data));

    const totalSearchEntites = searchedUnusedHealthcardData?.data?.results;

    const totalPages =
        searchData?.length > 0
            ? totalSearchEntites
            : totalUnusedHealthCardEntites;

    const searchErrorMsg = ErrorMsg?.response?.data?.message;

    // Begins Menu Here

    const onSearchSubmit = (values: any, onSubmitProps: any) => {
        try {
            setSearchFormProps(onSubmitProps);
            const payload = {
                healthCardNumber: String(values?.searchName)?.trim(),
                facilityCode,
            };

            mutate(payload, {
                onSuccess: (data: any) => {
                    const searchTerm = data?.data?.data?.healthCard;
                    setSearchData(searchTerm);
                    onSubmitProps.resetForm();
                },
                onError: (error: any) => {
                    setSearchData([]);
                    onSubmitProps.resetForm();
                },
            });
        } catch (error) {
            // onSubmitProps.resetForm();
            setSearchData([]);
        }
    };

    const clearData = (searchFormProps: any) => {
        if (searchFormProps) {
            searchFormProps.resetForm();
            setSearchData([]);
            reset();
        }
        refetch();
    };

    let allUnusedHealthCards: any;
    if (searchData?.length > 0) {
        allUnusedHealthCards = searchData;
    } else {
        allUnusedHealthCards = unusedHealthCardsData;
    }
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUnusedHealthCards]);
    return (
        <>
            <div className="card text-dark">
                <Modal open={isOpen}>
                    <UnusedHealthCardsExport
                        onClose={handleModal}
                        styles={{
                            backgroundColor: "white",
                            width: "650px",
                            height: "525px",
                            msgHeight: "200px",
                            padding: "50px",
                        }}
                    />
                </Modal>
                <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                    <div className="d-flex align-items-center justify-content-center h-50px">
                        <h1>UNUSED HEALTHCARDS</h1>
                        <i
                            onClick={() => clearData(searchFormProps)}
                            className="fas fa-redo fs-3 cursor-pointer ms-5"
                        ></i>
                    </div>
                    {!isUnusedHealthCardError && (
                        <div className="card-toolbar">
                            {/* Search */}
                            <div className="card-toolbar">
                                {/* Search Bar */}
                                <div className="py-2 me-20">
                                    <Formik
                                        initialValues={{
                                            searchName: "",
                                        }}
                                        validationSchema={searchSchema}
                                        onSubmit={onSearchSubmit}
                                    >
                                        {(formProps: any) => (
                                            <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                                                <div className="d-flex ">
                                                    <Field
                                                        name="searchName"
                                                        placeholder="Search healthcard"
                                                        className="form-control form-control-md form-control-solid"
                                                    />
                                                    <button
                                                        disabled={
                                                            formProps.isSubmitting ||
                                                            !(
                                                                formProps.isValid &&
                                                                formProps.dirty
                                                            )
                                                        }
                                                        type="submit"
                                                        className="ms-3 btn btn-lg btn-primary"
                                                    >
                                                        Search
                                                    </button>
                                                </div>
                                                <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                                    <ErrorMessage name="searchName" />
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>

                            {/* Export */}
                            <button
                                hidden={!canExport}
                                onClick={handleModal}
                                className="btn btn-sm btn-light-primary me-5"
                            >
                                <i className="fas fa-download"></i>
                                EXPORT
                            </button>
                        </div>
                    )}
                </div>
                {isSearchError || isUnusedHealthCardError ? (
                    <TableErrorComponent
                        errorMessage={errorMsg || searchErrorMsg}
                    />
                ) : (
                    <div className="card-body py-3">
                        <Table>
                            <TableHeadRow>
                                <TableHead width={140} align="center">
                                    SL NO
                                </TableHead>

                                {tableNames?.map((name) => {
                                    return (
                                        <TableHead width={180} key={name}>
                                            {name}
                                        </TableHead>
                                    );
                                })}
                            </TableHeadRow>

                            <TableBodyContainer
                                isLoading={
                                    isLoading || isFetching || searchCHWLoader
                                }
                                isError={
                                    isUnusedHealthCardError || isSearchError
                                }
                                errorMessage={errorMsg || searchErrorMsg}
                            >
                                {allUnusedHealthCards?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <TableRow
                                                collapsible={false}
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {currentPageNumber &&
                                                        (currentPageNumber -
                                                            1) *
                                                            pageLimit +
                                                            index +
                                                            1}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.batch
                                                        ? item?.batch
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.healthCardNumber
                                                        ? item?.healthCardNumber
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell>
                                                    <span
                                                        className={`badge badge-light-${
                                                            item?.plan?.name ===
                                                            "Starter"
                                                                ? "success"
                                                                : item?.plan
                                                                      ?.name ===
                                                                  "Platinum"
                                                                ? "primary"
                                                                : item?.plan
                                                                      ?.name ===
                                                                  "Golden"
                                                                ? "info"
                                                                : item?.plan
                                                                      ?.name ===
                                                                  "Silver"
                                                                ? "dark"
                                                                : "danger"
                                                        } text-center `}
                                                    >
                                                        {item?.plan?.name
                                                            ? item?.plan?.name
                                                            : "N/A"}
                                                    </span>
                                                </TableCell>

                                                <TableCell>
                                                    <span
                                                        className={`badge badge-light-${
                                                            item?.usageStatus ===
                                                            "Unassigned"
                                                                ? "danger"
                                                                : "success"
                                                        } text-center `}
                                                    >
                                                        {item?.usageStatus
                                                            ? item?.usageStatus
                                                            : "N/A"}
                                                    </span>
                                                </TableCell>

                                                <TableCell>
                                                    <span
                                                        className={`badge badge-light-${
                                                            item?.status ===
                                                            "Active"
                                                                ? "success"
                                                                : "danger"
                                                        } text-center `}
                                                    >
                                                        {item?.status
                                                            ? item?.status
                                                            : "N/A"}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBodyContainer>
                        </Table>

                        {/* Pagination */}
                        {!isSearchError && !isUnusedHealthCardError && (
                            <Pagination
                                dataCount={totalUnusedHealthCardEntites}
                                handleCallback={(val: any) => {
                                    setPageLimit(val);
                                }}
                                handleNext={() =>
                                    setCurrentPageNumber(
                                        (prevPageNumber: number) =>
                                            prevPageNumber + 1
                                    )
                                }
                                handlePrev={() =>
                                    setCurrentPageNumber(
                                        (prevPageNumber: number) =>
                                            prevPageNumber - 1
                                    )
                                }
                                nextDisabled={currentPageNumber === totalPages}
                                pageNumber={currentPageNumber}
                                pageLimit={pageLimit}
                                prevDisabled={currentPageNumber === 1}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default UnusedCardsTable;
