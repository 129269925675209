import React, { useMemo, useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { todayDate } from "../../../../common/utils";
import { Autocomplete, TextField, Menu } from "@mui/material";
import { filterSchema } from "./salesFilterSchema";
import useGetRequest from "../../../../hooks/getRequest.query";

import { getAllCHWForSales } from "../../../../services/supply-chain/sales-order/sales-order-list/sales-order-list";
import _ from "lodash";

type Props = {
    setFilterData: Function;
    setPage: Function;
};

const SalesOrderFilter = (props: Props) => {
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { data } = useGetRequest(["getChwList"], () =>
        getAllCHWForSales({ facilityCode, page: 1, limit: 1000 })
    );
    const chwList = useMemo(() => {
        return data?.data?.data?.chw?.map((ele: any) => {
            return { label: _.startCase(ele.name), chwId: ele.chwId };
        });
    }, [data]);

    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-md btn-primary"
                style={{ marginLeft: "0.8rem" }}
                data-testid="filter-btn"
            >
                {" "}
                <i className="fas fa-filter"></i> FILTER
            </button>
            <Menu
                id="basic-menu"
                data-testid="menu-bar"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                style={{ maxHeight: "600px" }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Formik
                    initialValues={{
                        status: "",
                        fromDate: "",
                        toDate: "",
                        chwName: "",
                        paymentStatus: "",
                    }}
                    validationSchema={filterSchema}
                    onSubmit={(values, onSubmitProps) => {
                        props.setFilterData(values);
                        props.setPage(1);
                        onSubmitProps.resetForm();
                        handleClose();
                    }}
                >
                    {(formProps: any) => (
                        <Form>
                            <div className="py-5 px-8">
                                <div className="w-250px mt-5 mb-8">
                                    <label className="form-label fs-4 fw-bolder">
                                        DELIVERY STATUS
                                    </label>
                                    <Field
                                        as="select"
                                        name="status"
                                        className="form-select form-select-lg form-select-solid"
                                        data-testid="deliveryStatus"
                                    >
                                        <option value="">All</option>
                                        <option value="0">
                                            Order Initiated
                                        </option>
                                        <option value="1">Order Placed</option>
                                        <option value="2">
                                            Order Processed
                                        </option>
                                        <option value="3">Order Packed</option>
                                        <option value="4">Order Shipped</option>
                                        <option value="5">
                                            Order Delivered
                                        </option>
                                        <option value="6">
                                            Order Cancelled
                                        </option>
                                    </Field>
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="grade" />
                                    </div>
                                </div>

                                <div className="w-250px mt-5 mb-5">
                                    <label
                                        className="form-label fs-4 fw-bolder required"
                                        htmlFor="fromDate"
                                    >
                                        FROM DATE
                                    </label>
                                    <Field
                                        id="fromDate"
                                        as="input"
                                        data-testid="fromDate"
                                        type="date"
                                        max={todayDate}
                                        name="fromDate"
                                        className="form-control cursor-pointer"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="fromDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder required">
                                        TO DATE
                                    </label>
                                    <Field
                                        onChange={(e: any) => {
                                            formProps.handleChange(e);
                                            formProps.setFieldTouched(
                                                "fromDate"
                                            );
                                        }}
                                        id="toDate"
                                        data-testid="ToDate"
                                        type="date"
                                        name="toDate"
                                        max={todayDate}
                                        className="form-control"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="toDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder">
                                        CHW NAME
                                    </label>
                                    <Autocomplete
                                        data-testid="chwName"
                                        disablePortal
                                        autoHighlight
                                        id="combo-box-demo"
                                        options={chwList}
                                        style={{ width: "250px" }}
                                        sx={{ width: 300 }}
                                        // className="form-control form-control-solid"
                                        onChange={(e, value: any) => {
                                            // setSelectedProductDetails(value);
                                            formProps?.setFieldValue(
                                                "chwName",
                                                value?.chwId
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />

                                    <div className="text-danger mt-2">
                                        <ErrorMessage name="chwName" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5 mb-8">
                                    <label className="form-label fs-4 fw-bolder">
                                        PAYMENT STATUS
                                    </label>
                                    <Field
                                        as="select"
                                        name="paymentStatus"
                                        className="form-select form-select-lg form-select-solid"
                                        data-testid="paymentStatus"
                                    >
                                        <option value="">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">
                                            Partially Paid
                                        </option>
                                        <option value="2">Paid</option>
                                        <option value="3">
                                            Not Applicable
                                        </option>
                                    </Field>
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="grade" />
                                    </div>
                                </div>
                            </div>

                            <div className="my-5 d-flex justify-content-center align-items-center">
                                <button
                                    type="submit"
                                    disabled={
                                        !formProps.isValid ||
                                        formProps.isSubmitting
                                    }
                                    className="w-250px btn btn-sm btn-primary"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Menu>
        </>
    );
};

export default SalesOrderFilter;
