import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { IProductsArr } from "../billingForm/IForms";
import {
    aggregateDiscount,
    aggregateGst,
    aggregateTotal,
    totalAmt,
} from "./CalcFunctions";
import useMutateRequest from "../../../../../hooks/getMutation.query";
import { addBill } from "../../../../../services/supply-chain/sales-order/add-bill/AddBill.services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SelectMenu, TextInput } from "../../../../../components/Inputs";
import ErrorMessageText from "../../../../../components/ErrorMessageText/ErrorMessageText";
import Modal from "../../../../../components/Modal";

type Props = {
    productsArr: IProductsArr;
};

const successToastMsg = () => {
    toast.success(
        (t) => (
            <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                Successfully generated the bill
                <button
                    className="ms-15"
                    style={{ backgroundColor: "transparent", border: "0" }}
                    onClick={() => toast.dismiss(t.id)}
                >
                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                </button>
            </span>
        ),
        {
            duration: 5000,
            position: "top-right",
            id: "success-toast-1",
        }
    );
};

const errorToastMsg = (err: string) => {
    toast.error(
        (t) => (
            <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                {err || "Failed to generate the bill"}
                <button
                    className="ms-15"
                    style={{ backgroundColor: "transparent", border: "0" }}
                    onClick={() => toast.dismiss(t.id)}
                >
                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                </button>
            </span>
        ),
        {
            duration: 5000,
            position: "top-right",
            id: "error-toast-1",
        }
    );
};

type IPayment = {
    paymentMode: string;
    UPITransactionId: string;
    advancePaymentValue: string;
    netAmount: number;
    billingFor: string;
};

const validationSchemaForPaymentInfo = Yup.object().shape({
    paymentMode: Yup.string()
        .required("Payment mode is required")
        .oneOf(
            ["Cash", "UPI", "none"],
            "Payment mode must be either Cash or UPI"
        ),
    UPITransactionId: Yup.string().when("paymentMode", {
        is: "UPI",
        then: Yup.string().required("UPI Transaction ID is required"),
    }),
    advancePaymentValue: Yup.number()
        .typeError("Advance payment must be a number")
        .min(0, "Advance payment must be greater than or equal to 0")
        .test({
            name: "maxOrEqual",
            exclusive: false,
            message: "Advance payment must be less than or equal to net value",
            test: function (value: any) {
                const netAmount = this.parent.netAmount || 0;
                return parseInt(value) <= parseInt(netAmount);
            },
        }),
    netAmount: Yup.number()
        .typeError("Net amount must be a number")
        .min(0, "Net amount must be greater than or equal to 0"),
    billingFor: Yup.string()
        .required("Billing for is required")
        .oneOf(["Clinic", "Camp"], "Payment mode must be either Cash or UPI"),
});

const FinalBillSummary = ({ productsArr }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { mutate, isLoading, isSuccess } = useMutateRequest((data: any) =>
        addBill(data)
    );
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const [selectedRadio, setSelectedRadio] = useState<"full" | "advance">(
        "full"
    );
    const facilityManagerId = localStorage.getItem(
        "facilityManagerId"
    ) as string;
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            paymentMode: "none",
            UPITransactionId: "",
            advancePaymentValue: "0",
            netAmount: 0,
            billingFor: "",
        },
        validationSchema: validationSchemaForPaymentInfo,
        onSubmit: (values: IPayment, { resetForm }) => {
            handleGenerateBill();
            // resetForm();
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            "netAmount",
            +Math.round(
                +aggregateTotal(productsArr) +
                    +aggregateGst(productsArr) -
                    +aggregateDiscount(productsArr)
            ).toFixed(2)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsArr]);

    const handleGenerateBill = () => {
        const finalProductArr = productsArr.map((ele: any) => {
            return {
                productId: ele.productId,
                batch: ele.batch,
                GST: +ele.gstTotal,
                IGST: 0,
                CGST: +ele.gstTotal / 2,
                SGST: +ele.gstTotal / 2,
                totalQuantity: ele.units,
                ratePerUnit: ele.mrpPerUnit,
                discount: +ele.discountPercent,
                totalPriceValue: +totalAmt(
                    +ele.mrpPerUnit,
                    +ele.units,
                    +ele.discountPercent
                ),
                inventoryId: ele.inventoryId,
            };
        });
        const formData = new FormData();
        formData.append("customerType", "2");
        formData.append("products", JSON.stringify(finalProductArr));
        formData.append("facilityCode", facilityCode);
        formData.append(
            "totalAmount",
            JSON.stringify(
                Math.round(
                    +aggregateTotal(productsArr) +
                        +aggregateGst(productsArr) -
                        +aggregateDiscount(productsArr)
                )
            )
        );

        formData.append(
            "uhid",
            productsArr && productsArr[0]?.patientObj?.uhid
        );
        formData.append(
            "totalGST",
            JSON.stringify(Math.round(+aggregateGst(productsArr)))
        );
        formData.append(
            "totalDiscount",
            JSON.stringify(Math.round(+aggregateDiscount(productsArr)))
        );
        formData.append("facilityManagerId", facilityManagerId);

        // Formik for adding payment New Changes

        // formData.append("advanceReceived", formik.values.advancePaymentValue);
        formData.append(
            "advanceReceived",
            selectedRadio === "advance"
                ? formik.values.advancePaymentValue
                : JSON.stringify(
                      Math.round(
                          +aggregateTotal(productsArr) +
                              +aggregateGst(productsArr) -
                              +aggregateDiscount(productsArr)
                      )
                  )
        );

        if (formik.values.paymentMode === "UPI") {
            formData.append("UPITransactionId", formik.values.UPITransactionId);
        }
        formData.append("salesOrderType", formik.values.billingFor);

        //#1 When customer will pay 0 as advance and make full payment when the delivery is done
        if (
            selectedRadio === "advance" &&
            +formik.values.advancePaymentValue === 0
        ) {
            formData.append("paymentStatus", "Pending");
            formData.append("deliveryStatus", JSON.stringify(0));
        }
        //#2 When customer will make part payment or full payment as advance
        if (
            selectedRadio === "advance" &&
            +formik.values.advancePaymentValue > 0
        ) {
            formData.append("paymentType", formik.values.paymentMode);
            formData.append(
                "paymentStatus",
                +formik.values.advancePaymentValue === +formik.values.netAmount
                    ? "Paid"
                    : "Partially Paid"
            );
            formData.append("deliveryStatus", JSON.stringify(0));
        }
        if (selectedRadio === "full") {
            // if #1 & #2 is not satisfied we will treat it as cash payment and full settlement
            formData.append("paymentType", formik.values.paymentMode);
            formData.append("paymentStatus", "Paid");
            formData.append("deliveryStatus", JSON.stringify(1));
        }
        mutate(formData, {
            onSuccess: (data: any) => {
                successToastMsg();
                setTimeout(() => {
                    navigate(-1);
                }, 1500);
            },
            onError: (error: any) => {
                errorToastMsg(error?.response?.data?.message);
            },
        });
    };
    const handleSubmitForm = () => {
        formik.handleSubmit();
    };

    const onPaymentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio(e.currentTarget.value as "full" | "advance");
        formik.setFieldValue("advancePaymentValue", 0);
    };

    return (
        <>
            {/* TODO Form */}
            <Toaster />
            <div className="row">
                <div className="col-6"></div>

                <div className="col-sm">
                    <form>
                        <table
                            className="table table-bordered mb-4"
                            style={{ borderBottom: "1px solid #eff2f5" }}
                        >
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border">
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ textAlign: "right" }}
                                    >
                                        Total (Before Tax)
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{ textAlign: "right" }}
                                    >
                                        {aggregateTotal(productsArr)}
                                    </td>
                                </tr>

                                <tr className="border">
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ textAlign: "right" }}
                                    >
                                        <p className="text font-weight-bold">
                                            Total Tax (GST)
                                        </p>
                                        <p className="text font-weight-bold">
                                            IGST
                                        </p>
                                        <p className="text font-weight-bold">
                                            CGST
                                        </p>
                                        <p className="text font-weight-bold">
                                            SGST/UTGST
                                        </p>
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{ textAlign: "right" }}
                                    >
                                        <p className="text font-weight-bold">
                                            {aggregateGst(productsArr)}
                                        </p>
                                        <p className="text font-weight-bold">
                                            0.00
                                        </p>
                                        <p className="text font-weight-bold">
                                            {(
                                                +aggregateGst(productsArr) / 2
                                            ).toFixed(2)}
                                        </p>
                                        <p className="text font-weight-bold">
                                            {(
                                                +aggregateGst(productsArr) / 2
                                            ).toFixed(2)}
                                        </p>
                                    </td>
                                </tr>
                                <tr className="border">
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ textAlign: "right" }}
                                    >
                                        OverAll Disc Value
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{ textAlign: "right" }}
                                    >
                                        {aggregateDiscount(productsArr)}
                                    </td>
                                </tr>
                                <tr className="border">
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ textAlign: "right" }}
                                    >
                                        Total Amount
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{ textAlign: "right" }}
                                    >
                                        {(
                                            +aggregateTotal(productsArr) +
                                            +aggregateGst(productsArr) -
                                            +aggregateDiscount(productsArr)
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                                <tr className="border">
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ textAlign: "right" }}
                                    >
                                        Round Off
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{ textAlign: "right" }}
                                    >
                                        {(
                                            Math.round(
                                                +aggregateTotal(productsArr) +
                                                    +aggregateGst(productsArr) -
                                                    +aggregateDiscount(
                                                        productsArr
                                                    )
                                            ) -
                                            (+aggregateTotal(productsArr) +
                                                +aggregateGst(productsArr) -
                                                +aggregateDiscount(productsArr))
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                                <tr
                                    className="border"
                                    style={{ textAlign: "right" }}
                                >
                                    <td
                                        className="border p-2 font-weight-bold"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Net Value
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: "right",
                                        }}
                                    >
                                        {Math.round(
                                            +aggregateTotal(productsArr) +
                                                +aggregateGst(productsArr) -
                                                +aggregateDiscount(productsArr)
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                                <tr
                                    className="border"
                                    style={{ textAlign: "right" }}
                                >
                                    <td
                                        className="border p-2 font-weight-bold flex"
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Billing For
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{
                                            fontWeight: "bold",
                                            width: "200px",
                                        }}
                                    >
                                        <SelectMenu
                                            label=""
                                            name="billingFor"
                                            data-testid="billingFor-input"
                                            onChange={formik.handleChange(
                                                "billingFor"
                                            )}
                                            className="mb-3 "
                                            value={formik.values.billingFor}
                                        >
                                            <option value={""}>Select</option>
                                            <option value={"Clinic"}>
                                                Clinic
                                            </option>
                                            <option value={"Camp"}>Camp</option>
                                        </SelectMenu>
                                        {formik.touched.billingFor &&
                                            formik.errors.billingFor && (
                                                <ErrorMessageText
                                                    message={
                                                        formik.errors.billingFor
                                                    }
                                                />
                                            )}
                                    </td>
                                </tr>

                                <tr
                                    className="border"
                                    style={{ textAlign: "right" }}
                                >
                                    <td
                                        className="border p-2 font-weight-bold flex"
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Payment Mode
                                    </td>
                                    <td
                                        className="border p-2"
                                        style={{
                                            fontWeight: "bold",
                                            width: "200px",
                                        }}
                                    >
                                        <SelectMenu
                                            label=""
                                            name="paymentMode"
                                            data-testid="paymentMode-input"
                                            onChange={formik.handleChange(
                                                "paymentMode"
                                            )}
                                            className="mb-3 "
                                            value={formik.values.paymentMode}
                                        >
                                            <option value={"none"}>None</option>
                                            <option value={"Cash"}>Cash</option>
                                            <option value={"UPI"}>UPI</option>
                                        </SelectMenu>
                                        {formik.touched.paymentMode &&
                                            formik.errors.paymentMode && (
                                                <ErrorMessageText
                                                    message={
                                                        formik.errors
                                                            .paymentMode
                                                    }
                                                />
                                            )}
                                    </td>
                                </tr>
                                {formik.values.paymentMode === "UPI" && (
                                    <tr
                                        className="border"
                                        style={{ textAlign: "right" }}
                                    >
                                        <td
                                            className="border p-2 font-weight-bold flex"
                                            style={{
                                                fontWeight: "bold",
                                                alignContent: "baseline",
                                            }}
                                        >
                                            UPI Transaction Id
                                        </td>
                                        <td
                                            className="border p-2"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <TextInput
                                                className="mb-3 "
                                                controlId=""
                                                label=""
                                                placeholder="Add transaction Id"
                                                type="text"
                                                data-testid="UPITransactionId"
                                                name="UPITransactionId"
                                                onChange={formik.handleChange(
                                                    "UPITransactionId"
                                                )}
                                                value={
                                                    formik.values
                                                        .UPITransactionId
                                                }
                                            />

                                            {formik.touched.UPITransactionId &&
                                                formik.errors
                                                    .UPITransactionId && (
                                                    <ErrorMessageText
                                                        message={
                                                            formik.errors
                                                                .UPITransactionId
                                                        }
                                                    />
                                                )}
                                        </td>
                                    </tr>
                                )}
                                <tr
                                    className="border"
                                    style={{ textAlign: "right" }}
                                >
                                    <td
                                        className="border p-2 font-weight-bold flex"
                                        style={{
                                            fontWeight: "bold",
                                            alignContent: "baseline",
                                        }}
                                    >
                                        Payment Type
                                    </td>
                                    <td
                                        className="border p-2 d-flex"
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: "left",
                                            alignItems: "start",
                                        }}
                                    >
                                        <input
                                            className="mt-1"
                                            type="radio"
                                            name="full"
                                            value={"full"}
                                            checked={selectedRadio === "full"}
                                            onChange={onPaymentTypeChange}
                                        />
                                        <label htmlFor="full">
                                            Full Settlement
                                        </label>
                                        <input
                                            className="mt-1"
                                            type="radio"
                                            id="advance"
                                            name="advance"
                                            value={"advance"}
                                            checked={
                                                selectedRadio === "advance"
                                            }
                                            onChange={onPaymentTypeChange}
                                        />
                                         {" "}
                                        <label htmlFor="advance">Advance</label>
                                    </td>
                                </tr>
                                {selectedRadio !== "full" && (
                                    <tr
                                        className="border"
                                        style={{ textAlign: "right" }}
                                    >
                                        <td
                                            className="border p-2 font-weight-bold flex"
                                            style={{
                                                fontWeight: "bold",
                                                alignContent: "baseline",
                                            }}
                                        >
                                            Advance Payment
                                        </td>
                                        <td
                                            className="border p-2"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <TextInput
                                                className="mb-3 max-w-200px"
                                                controlId=""
                                                label=""
                                                placeholder="0"
                                                type="number"
                                                min="0"
                                                data-testid="advancePaymentValue"
                                                name="advancePaymentValue"
                                                onChange={formik.handleChange(
                                                    "advancePaymentValue"
                                                )}
                                                value={
                                                    formik.values
                                                        .advancePaymentValue
                                                }
                                            />

                                            {formik.touched
                                                .advancePaymentValue &&
                                                formik.errors
                                                    .advancePaymentValue && (
                                                    <ErrorMessageText
                                                        message={
                                                            formik.errors
                                                                .advancePaymentValue
                                                        }
                                                    />
                                                )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </form>
                    <div style={{ textAlign: "right" }}>
                        <button
                            className="btn btn-light"
                            style={{ marginRight: "1rem" }}
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                // formik.validateField("paymentMode");
                                // formik.validateField("billingFor");

                                if (selectedRadio === "advance") {
                                    // When we are receiving some amount as advance payment mode is necessary
                                    if (
                                        +formik.values.advancePaymentValue >
                                            0 &&
                                        formik.values.paymentMode !== "none" &&
                                        formik.values.billingFor.length > 2
                                    ) {
                                        setIsModalOpen(true);
                                    }

                                    // When we are not receiving any amount by its advance type, payment mode should be none
                                    if (
                                        +formik.values.advancePaymentValue ===
                                            0 &&
                                        formik.values.billingFor.length > 2
                                    ) {
                                        setIsModalOpen(true);
                                    }
                                } else {
                                    if (
                                        formik.values.paymentMode !== "none" &&
                                        formik.values.billingFor.length > 2 &&
                                        formik.isValid
                                    ) {
                                        setIsModalOpen(true);
                                    }
                                }
                            }}
                        >
                            Generate bill
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen}
                // styles=""
                // overlayStyles=""
            >
                <div className="bg-white rounded-2 p-6">
                    <p className="mb-4 fw-semibold fs-5 ">
                        {+formik.values.advancePaymentValue > 0 ||
                        selectedRadio === "advance"
                            ? `Recieved advance payment of Rs ${
                                  formik.values.advancePaymentValue
                              } ${
                                  +formik.values.advancePaymentValue > 0
                                      ? `via 
                                  ${formik.values.paymentMode}`
                                      : ""
                              }. The remaining amount to be paid is Rs ${
                                  +Math.round(
                                      +aggregateTotal(productsArr) +
                                          +aggregateGst(productsArr) -
                                          +aggregateDiscount(productsArr)
                                  ) - +formik.values.advancePaymentValue
                              }. Do you want to generate the bill?`
                            : `You have recieved  payment of Rs ${Math.round(
                                  +aggregateTotal(productsArr) +
                                      +aggregateGst(productsArr) -
                                      +aggregateDiscount(productsArr)
                              )} via ${
                                  formik.values.paymentMode
                              }. Do you want to generate the bill?`}
                    </p>

                    <div style={{ textAlign: "right", marginTop: "2rem" }}>
                        <button
                            className="btn btn-light"
                            style={{ marginRight: "1rem" }}
                            onClick={() => setIsModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={
                                formik.isSubmitting || isLoading || isSuccess
                            }
                            onClick={handleSubmitForm}
                        >
                            Yes, Generate bill
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FinalBillSummary;
