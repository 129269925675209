import React, { useMemo, useState } from "react";
import {
    AutoCompleteInput,
    SelectMenu,
    TextInput,
} from "../../../../../components/Inputs";
import { useFormik } from "formik";
import { validationSchemaForAddSalesOrder } from "./addSalesOrderFormikValidations";
import ErrorMessageText from "../../../../../components/ErrorMessageText/ErrorMessageText";
import { IProductsArr } from "./IForms";
import { IProduct, IProps } from "./IForms";
import useGetRequest from "../../../../../hooks/getRequest.query";
import { getFacilityStock } from "../../../../../services/supply-chain/view-stock/facility-stock/FacilityStock.services";
import { capitalize } from "lodash";
import dayjs from "dayjs";
import Modal from "../../../../../components/Modal";
import useMutateRequest from "../../../../../hooks/getMutation.query";
import { createSalesOrder } from "../../../../../services/supply-chain/sales-order/modify-sales-order/index.services";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const AddSalesProductForm = ({ setProductsArr, customerType }: IProps) => {
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const salesOrderId = searchParams.get("salesOrderId") as string;
    const navigate = useNavigate();
    const { mutate } = useMutateRequest((data: any) => createSalesOrder(data));

    const formik = useFormik({
        initialValues: {
            product: "",
            productId: "",
            manufacturer: "",
            batch: "",
            expiryDate: "",
            units: "",
            gstTotal: "5",
            mrpPerUnit: "",
            discountPercent: "0",
            batchArr: [],
            vendorObj: { label: "", year: "" },
            productObj: { label: "", year: "" },
            inventoryId: "",
        },
        validationSchema: validationSchemaForAddSalesOrder,
        onSubmit: (values: IProduct, { resetForm }) => {
            // Handle form submission

            setProductsArr((prevValue: IProductsArr) => {
                const uniqueArr = prevValue.every(
                    (ele) =>
                        ele.product !== values.product ||
                        ele.batch !== values.batch
                );
                if (uniqueArr) {
                    return [...prevValue, values];
                }
                return prevValue;
            });
            resetForm();
        },
    });

    const { data }: any = useGetRequest(
        ["getFacilityStock", facilityCode],
        () =>
            getFacilityStock({
                facilityCode,
                page: 1,
                limit: 1000,
                filterOption: 0,
                productName: "",
                genericName: "",
            }),
        { staleTime: 100000, refetchOnWindowFocus: false }
    );

    const productList = useMemo(() => {
        return data?.data?.data?.stock
            ? data?.data?.data?.stock.map((ele: any) => {
                  return {
                      label: capitalize(ele?.productData[0]?.productName),
                      productId: ele?.productId,
                      manufacturer: ele?.productData[0]?.manufacturerName,
                      batch: [ele.batch],
                      expiryDate: dayjs(ele.expiry).format("YYYY-MM-DD"),
                      mrp: ele?.ratePerUnit,
                      discount: ele?.discount,
                      gstTotal: ele?.GST,
                      inventoryId: ele?._id,
                  };
              })
            : {
                  label: "",
                  productId: "",
                  manufacturer: "",
                  batch: "",
                  expiryDate: "",
                  mrp: "",
                  discount: "",
                  gstTotal: "",
                  inventoryId: "",
              };
    }, [data]);
    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Sales order cancelled!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = (err: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {err}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };
    const handleCancelOrder = () => {
        const formData = new FormData();
        formData.append("salesOrderReferenceId", salesOrderId);
        formData.append("products", JSON.stringify([]));
        formData.append("facilityCode", facilityCode);
        formData.append("totalAmount", "0");
        formData.append("deliveryStatus", "Order Cancelled");
        mutate(formData, {
            onSuccess: (data: any) => {
                successToastMsg();
                setTimeout(() => {
                    navigate(-1);
                }, 1500);
            },
            onError: (error: any) => {
                errorToastMsg(error?.response?.data?.message);
            },
        });
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Toaster />
            <div className="row mb-3">
                <div className="col-sm-2">
                    <AutoCompleteInput
                        label={"Product"}
                        name="product"
                        data-testid="product-menu"
                        required
                        onChange={(e: any, value: any) => {
                            formik.setFieldValue("product", value.label);
                            formik.setFieldValue("productObj", value);
                            formik.setFieldValue(
                                "manufacturer",
                                value.manufacturer
                            );
                            formik.setFieldValue("batchArr", value.batch);
                            formik.setFieldValue("batch", value.batch[0]);
                            formik.setFieldValue(
                                "expiryDate",
                                value.expiryDate
                            );

                            formik.setFieldValue("productId", value.productId);
                            formik.setFieldValue("mrpPerUnit", value.mrp);
                            formik.setFieldValue(
                                "inventoryId",
                                value.inventoryId
                            );
                        }}
                        value={formik.values.productObj}
                        optionsArr={productList}
                        className="mb-3"
                    />
                    {formik.touched.product && formik.errors.product && (
                        <ErrorMessageText message={formik.errors.product} />
                    )}
                </div>
                <div className="col">
                    <SelectMenu
                        label="Batch"
                        name="batch"
                        data-testid="batch-input"
                        onChange={formik.handleChange("batch")}
                        className="mb-3"
                        value={formik.values.batch}
                        required
                    >
                        {" "}
                        {formik.values.batchArr.map((ele: string) => (
                            <option value={ele}>{ele}</option>
                        ))}
                    </SelectMenu>
                    {formik.touched.batch && formik.errors.batch && (
                        <ErrorMessageText message={formik.errors.batch} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="manufacturer"
                        label="Manufacturer"
                        placeholder="0"
                        type="text"
                        data-testid="manufacturer"
                        disabled
                        name="manufacturer"
                        onChange={formik.handleChange("manufacturer")}
                        value={formik.values.manufacturer}
                        required
                    />
                    {formik.touched.manufacturer &&
                        formik.errors.manufacturer && (
                            <ErrorMessageText
                                message={formik.errors.manufacturer}
                            />
                        )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="expiryDate"
                        label="Expiry Date"
                        placeholder="0"
                        disabled
                        type="date"
                        data-testid="expiry-date"
                        name="expiryDate"
                        onChange={formik.handleChange("expiryDate")}
                        value={formik.values.expiryDate}
                        required
                    />
                    {formik.touched.expiryDate && formik.errors.expiryDate && (
                        <ErrorMessageText message={formik.errors.expiryDate} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="units"
                        label="Units"
                        min="0"
                        placeholder="0"
                        type="number"
                        data-testid="unit-input"
                        name="units"
                        onChange={formik.handleChange("units")}
                        value={formik.values.units}
                        required
                    />
                    {formik.touched.units && formik.errors.units && (
                        <ErrorMessageText message={formik.errors.units} />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="mrpPerUnit"
                        label="MRP/unit"
                        data-testid="mrp-strip"
                        placeholder="0"
                        type="number"
                        min="0"
                        disabled
                        name="mrpPerUnit"
                        onChange={formik.handleChange("mrpPerUnit")}
                        value={formik.values.mrpPerUnit}
                        required
                    />
                    {formik.touched.mrpPerUnit && formik.errors.mrpPerUnit && (
                        <ErrorMessageText message={formik.errors.mrpPerUnit} />
                    )}
                </div>
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="discount"
                        data-testid="disc-input"
                        label="Discount %"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="discountPercent"
                        onChange={formik.handleChange("discountPercent")}
                        value={formik.values.discountPercent}
                    />
                    {formik.touched.discountPercent &&
                        formik.errors.discountPercent && (
                            <ErrorMessageText
                                message={formik.errors.discountPercent}
                            />
                        )}
                </div>

                <div className="col-sm-5 d-flex">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        style={{ height: "80%" }}
                        data-testid="add-btn"
                    >
                        +
                    </button>
                    <button
                        style={{ marginLeft: "1rem", height: "80%" }}
                        className="btn btn-primary"
                        disabled={customerType === "CHW"}
                    >
                        Download Prescription <i className="bi bi-download" />
                    </button>
                    <button
                        style={{ marginLeft: "1rem", height: "80%" }}
                        className="btn btn-secondary"
                        onClick={() => setOpen(true)}
                    >
                        Cancel Order
                    </button>
                </div>
                <Modal open={open}>
                    <div className="card p-5 text-dark">
                        <p className="fs-3">
                            Are you sure that you want to cancel the order?
                        </p>
                        <div className="d-flex justify-content-between mt-5">
                            <button
                                className="btn btn-light"
                                onClick={() => setOpen(false)}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setOpen(false);
                                    handleCancelOrder();
                                }}
                            >
                                Yes, Cancel Order
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </form>
    );
};

export default AddSalesProductForm;
