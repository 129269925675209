import * as Yup from "yup";

export type exportValueTypes = {
  exportFormat: string;
  usageStatus: string;
  cardStatus: string;
};

export const initalValues: exportValueTypes = {
  exportFormat: "CSV",
  usageStatus: "Unassigned",
  cardStatus: "Active",
};

export const exportValidationSchema = Yup.object({
  exportFormat: Yup.string().required("Facility is Required"),
});
