const enum questionType {
    DROPDOWN = "Dropdown",
    TEXTFIELD = "TextField",
    CHECKBOX = "Checkbox",
    DATE = "Date",
    RADIO = "Radio",
    IMAGE = "Image",
}

const QuestionDetails = ({ question, index }: any) => {
    console.log("desc", question);
    return (
        <>
            <div className="fv-row row col-lg-12 mb-5">
                <span>
                    <span className="fw-bolder">
                        {index + 1}
                        {/* {question?.questionNumber} */}
                    </span>
                    <label
                        className={
                            question?.questionRequired === true
                                ? `text-capitalize fw-bolder ms-2 form-label required `
                                : `text-capitalize fw-bolder ms-2 form-label`
                        }
                    >
                        {question?.questionName}
                    </label>
                </span>
                <label className="text-capitalize fw-bolder ms-2 form-label">
                    {question?.description}
                </label>
                {question?.questionType === questionType.DROPDOWN && (
                    <select
                        name=""
                        className="form-select form-select-lg form-select-solid"
                    >
                        <option value="">--select--</option>
                        {question?.options?.map((option: any) => {
                            return (
                                <>
                                    <option
                                        value=""
                                        defaultValue={option?.isDefault}
                                    >
                                        {option?.option}
                                    </option>
                                </>
                            );
                        })}
                    </select>
                )}
                {question?.questionType === questionType.TEXTFIELD && (
                    <input
                        name={question?.questionName}
                        placeholder="Enter your details"
                        className="form-control form-control-lg form-control-solid"
                    />
                )}
                {question?.questionType === questionType.CHECKBOX && (
                    <div className="mt-4">
                        {question?.options?.map((option: any) => {
                            return (
                                <>
                                    <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        name={option?.option}
                                        defaultChecked={option?.isDefault}
                                    />
                                    <label className="me-5">
                                        {option?.option}
                                    </label>
                                </>
                            );
                        })}
                    </div>
                )}
                {question?.questionType === questionType.RADIO && (
                    <div className="mt-4">
                        {question?.options?.map((option: any) => {
                            return (
                                <>
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        id={option?.option}
                                        name={question?.questionName}
                                        defaultChecked={option?.isDefault}
                                    />
                                    <label className="me-5">
                                        {option?.option}
                                    </label>
                                </>
                            );
                        })}
                    </div>
                )}
                {question?.questionType === questionType.DATE && (
                    <>
                        <input
                            id="date"
                            type="date"
                            name="date"
                            className="form-control form-control-lg form-control-solid cursor-pointer"
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default QuestionDetails;
