import * as Yup from "yup";

export type removeCHWTypes = {
  confirmRemove: string;
};

export const initalValues: removeCHWTypes = {
  confirmRemove: "",
};

export const removeCHWValidationSchema = Yup.object({
  confirmRemove: Yup.string().required("Confirmation is Required"),
});
