import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useMutateRequest from "../../../hooks/getMutation.query";
import useSearchParams from "../../../hooks/useSearchParams";
import { getLatestPatientVitals } from "../../../services/patient/PatientManagement.hook";

const VitalDetails = ({ patientDetails }: any) => {
  const { pId: id }: any = useSearchParams();
  const age = patientDetails?.demographicDetails?.age;
  const patientBirthDate = patientDetails?.demographicDetails?.birthDate;
  const patientCurrentAge = (patientBirthDate: number) =>
    Math.floor(
      (Number(new Date()) - Number(new Date(patientBirthDate).getTime())) /
        3.15576e10
    );
  const showAge =
    patientDetails?.demographicDetails?.birthDate !== ""
      ? patientCurrentAge(patientBirthDate)
      : age;
  const patientGender = patientDetails?.demographicDetails?.sex;
  const facilityCode: any = localStorage.getItem("facilityCode");

  const { data, isLoading, error, mutate, isError }: any = useMutateRequest(
    (data: any) =>
      getLatestPatientVitals({
        uhid: id,
        facilityCode,
        age: showAge,
        gender: patientGender,
      })
  );

  const getData = () => {
    try {
      const formData = new FormData();
      formData.append("uhid", id);
      formData.append("facilityCode", facilityCode);
      formData.append("age", showAge);
      formData.append("gender", patientGender);

      mutate(formData, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {},
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) getData();
  }, [id]);

  const latestVitals = data?.data?.data;
  const ErrorMsg = error?.response?.data?.message;
  return (
    <>
      <div className="card">
        <div className="card-body p-9">
          <h1 className="py-4">LATEST VITALS</h1>
        </div>
      </div>
      {isLoading ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isError ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <h1 className="text-danger">No vitals found for this Patient</h1>
        </div>
      ) : (
        <div className="card">
          <div className="p-9 mt-0">
            <div className="row  col-lg-12">
              <label className="col-lg-3 fw-bold text-dark">Vital Name</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">Vital Value</span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">Vital Range</span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  Vital Collected Date
                </span>
              </div>
            </div>
            <hr className="mb-7" />
            {/* height */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Height</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.height === null
                    ? "No Data"
                    : latestVitals?.height?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.height === null
                    ? "No Data"
                    : latestVitals?.height?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.height === null
                    ? "No Data"
                    : latestVitals?.height?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Weight */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Weight</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.weight === null
                    ? "No Data"
                    : latestVitals?.weight?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.weight === null
                    ? "No Data"
                    : latestVitals?.weight?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.weight === null
                    ? "No Data"
                    : latestVitals?.weight?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Temperature */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Temperature</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.temperature === null
                    ? "No Data"
                    : latestVitals?.temperature?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.temperature === null
                    ? "No Data"
                    : latestVitals?.temperature?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.temperature === null
                    ? "No Data"
                    : latestVitals?.temperature?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Respiratory */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Respiratory</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.respiratory === null
                    ? "No Data"
                    : latestVitals?.respiratory?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.respiratory === null
                    ? "No Data"
                    : latestVitals?.respiratory?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.respiratory === null
                    ? "No Data"
                    : latestVitals?.respiratory?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Pulse */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Pulse</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.pulse === null
                    ? "No Data"
                    : latestVitals?.pulse?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.pulse === null
                    ? "No Data"
                    : latestVitals?.pulse?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.pulse === null
                    ? "No Data"
                    : latestVitals?.pulse?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Oxygen Saturation */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">
                Oxygen Saturation
              </label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.oxygenSaturation === null
                    ? "No Data"
                    : latestVitals?.oxygenSaturation?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.oxygenSaturation === null
                    ? "No Data"
                    : latestVitals?.oxygenSaturation?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.oxygenSaturation === null
                    ? "No Data"
                    : latestVitals?.oxygenSaturation?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {/* Hemoglobin */}
            <div className="row mb-7 col-lg-12">
              <label className="col-lg-3 fw-bold text-muted">Hemoglobin</label>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.hemoglobin === null
                    ? "No Data"
                    : latestVitals?.hemoglobin?.value}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.hemoglobin === null
                    ? "No Data"
                    : latestVitals?.hemoglobin?.vitalRange}
                </span>
              </div>
              <div className="col-lg-3">
                <span className="fw-bolder fs-6 text-dark">
                  {latestVitals?.hemoglobin === null
                    ? "No Data"
                    : latestVitals?.hemoglobin?.vitalCollectedDate}
                </span>
              </div>
            </div>

            {showAge >= 5 && (
              <>
                {/* ECG */}
                <div className="row mb-7 col-lg-12">
                  <label className="col-lg-3 fw-bold text-muted">ECG</label>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.ecg === null
                        ? "No Data"
                        : latestVitals?.ecg?.value}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.ecg === null
                        ? "No Data"
                        : latestVitals?.ecg?.vitalRange}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.ecg === null
                        ? "No Data"
                        : latestVitals?.ecg?.vitalCollectedDate}
                    </span>
                  </div>
                </div>

                {/* Blood Pressure */}
                <div className="row mb-7 col-lg-12">
                  <label className="col-lg-3 fw-bold text-muted">
                    Blood Pressure
                  </label>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodPressure === null
                        ? "No Data"
                        : latestVitals?.bloodPressure?.value}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodPressure === null
                        ? "No Data"
                        : latestVitals?.bloodPressure?.vitalRange}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodPressure === null
                        ? "No Data"
                        : latestVitals?.bloodPressure?.vitalCollectedDate}
                    </span>
                  </div>
                </div>

                {/* Blood Glucose */}
                <div className="row mb-7 col-lg-12">
                  <label className="col-lg-3 fw-bold text-muted">
                    Blood Glucose
                  </label>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodGlucose === null
                        ? "No Data"
                        : latestVitals?.bloodGlucose?.value}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodGlucose === null
                        ? "No Data"
                        : latestVitals?.bloodGlucose?.vitalRange}
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="fw-bolder fs-6 text-dark">
                      {latestVitals?.bloodGlucose === null
                        ? "No Data"
                        : latestVitals?.bloodGlucose?.vitalCollectedDate}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VitalDetails;
