import { useRBAC } from "../../../../context/RBACContext";

type RBACComponentTypes = {
  children: any;
  whichRoles?: any;
  allRoles?: boolean;
};

export const RBACComponent = ({
  children,
  whichRoles,
  allRoles = false,
}: RBACComponentTypes) => {
  const { userRole } = useRBAC();

  return (
    <>
      {whichRoles?.includes(userRole) === true ? children : ""}

      {allRoles && children}
    </>
  );
};
