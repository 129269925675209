import $axiosProtected from "../../common/utils/axiosUtils";

// Create a focused group
export const createFocusedGroup = async (payload: any) => {
    return await $axiosProtected().post("/vitals/focusedgroup", payload);
};

// Get all VitalsList
export const getAllVitalsList = async () => {
    return await $axiosProtected().get(`/vitals/list`);
};

// Get all focused groups
export const getAllFocusedGroup = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/web-portal/focused-group?page=${payload.page}&limit=${payload.limit}`
    );
};

// Search a focused groups by name
export const searchFocusedGroupByName = async (payload: any) => {
    return await $axiosProtected().post(
        `/vitals/web-portal/focused-group/search?page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Get indivdual focused groups by Id
export const getFocusedGroupById = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/focusedgroup/get/id?focusedGroupId=${
            payload.focusedGroupId || ""
        }`
    );
};

// Tab 1

// Get list of all vitals
export const getAllVitals = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/web-portal/focused-group/visit-number/list-vitals?focusedGroupId=${payload.focusedGroupId}&visitNumber=${payload.visitNumber}`
    );
};

// Attach vitals to specific focused groups
export const attachVitalsToFocusedGroup = async (payload: any) => {
    return await $axiosProtected().patch(
        `/vitals/focusedgroup/update/addvitals`,
        payload
    );
};

// Get form by visit
export const getFormByVisit = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/web-portal/forms/id/get?formId=${payload?.formId}`
    );
};

// Tab 2

// Get list of forms by focused group
export const getFormsByFocusedGroup = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/web-portal/focused-group/forms/list?focusedGroupId=${payload.focusedGroupId}`
    );
};

// Get list of patient details by visit number
export const getPatientDetailsByVisits = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/focusedgroup/form/patients/get?facilityCode=${payload.facilityCode}&formId=${payload.formId}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get form entries by patient
export const getFormEntriesByPatient = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/focusedgroup/form/submit/get?id=${payload.id}`
    );
};

// Get Patient Vitals
export const getPatientVitals = async (payload: any) => {
    return await $axiosProtected().post(
        `/vitals/focusedgroup/focusedgroupdata`,
        payload
    );
};

// Tab 3

// Get list of patinets by focused group
export const getPatientsByFocusedGroup = async (payload: any) => {
    return await $axiosProtected().get(
        `/vitals/web-portal/focusedgroup/patients/list/get?focusedGroupId=${
            payload.focusedGroupId || ""
        }&facilityCode=${payload.facilityCode}&chwId=${
            payload.chwId
        }&visitNumber=${payload?.visitNumber}&fromDate=${
            payload?.fromDate
        }&toDate=${payload?.toDate}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get List of all CHW Details
export const getAllCHWByFacility = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/facility/all-chws?page=${payload.page}&limit=${payload.limit}&facilityCode=${payload.facilityCode}`
    );
};

// Get List of all category
export const getAllCategory = async () => {
    return await $axiosProtected().get(`/vitals/focusedgroup/category/all`);
};
