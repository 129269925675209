import $axiosProtected from "../common/utils/axiosUtils";

// Get List of All primary subscription
export const getListOfAllPrimarySubscription = async (payload: any) => {
    return await $axiosProtected().get(
        `/patient/healthcard/subscription/primary/uhid/list/web-portal?limit=${payload.limit}&page=${payload.page}&facilityCode=${payload.facilityCode}`
    );
};

// search primary subscriber
export const searchPrimarySubscriber = async (payload: any) => {
    return await $axiosProtected().post(
        "/patient/healthcard/subscription/search/web-portal",
        payload
    );
};

// Get List of All Plans
export const getListOfAllPlans = async () => {
    return await $axiosProtected().get(`/healthcard/plan/get/all`);
};

// Generate Health Cards
export const generateHealthCard = async (payload: any) => {
    return await $axiosProtected().post("/healthcard/store", payload);
};

// Get All Batches
export const getAllBatches = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/batch/list/get?facilityCode=${payload.facilityCode}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get Health Cards based on Batches
export const getHealthCards = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/batch/get?batch=${payload.batchId}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get Unused Health Cards based on Facility Code
export const getUnusedHealthCards = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/get/unused?facilityCode=${payload.facilityCode}&page=${payload.page}&limit=${payload.limit}`
    );
};

// search Unused Health Cards based on Facility Code
export const searchUnusedHealthCards = async (payload: any) => {
    return await $axiosProtected().post(
        "/healthcard/unassigned/search/web-portal",
        payload
    );
};

// Get Batch Details by Facility Code
export const getBatchDetails = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/batch/id/get?facilityCode=${payload.facilityCode}&batch=${payload.batch}`
    );
};

// Export Unused Healthcards from a Batch
export const exportHealthCardDetails = async (payload: any) => {
    return await $axiosProtected().get(
        `/healthcard/batch/export/unused?batch=${payload.batchId}`
    );
};
