import ClipLoader from "react-spinners/ClipLoader";
import CustomTooltip from "../../../components/CustomTooltip";
import { useMemo } from "react";

type Props = {
    isLoading: boolean;
    isFetching: boolean;
    data: any;
    filter: any;
};

const PaymentCounters = ({ isLoading, isFetching, data, filter }: Props) => {
    const counters = useMemo(
        () => data?.data?.data?.paymentsCounter,
        [data?.data?.data?.paymentsCounter]
    );
    const totalResults = data?.data?.results;
    const showSimpleCounter = Object?.values(filter)?.some(
        (value) => value !== ""
    );
    return (
        <div className="p-10 card">
            <div className="d-flex">
                <h1 className="fs-3 text-dark fw-bolder">TODAY'S PAYMENTS</h1>
                <CustomTooltip
                    style={{ fontSize: "550px" }}
                    title="Overview of payments"
                    placement="right"
                    // arrow
                >
                    <i className="fs-1 fas fa-info-circle ms-2 bg-transparent border-none"></i>
                </CustomTooltip>
            </div>
            {showSimpleCounter ? (
                <>
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-danger">
                                {totalResults ? totalResults : "No Data"}
                            </h3>
                            <h3>TOTAL COUNT</h3>
                        </div>
                    )}
                </>
            ) : (
                <div className="d-flex mt-5">
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-success">
                                {counters?.cashPayments > 0
                                    ? counters?.cashPayments
                                    : "No Data"}
                            </h3>
                            <h3>CASH</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-danger">
                                {counters?.UPIPayments > 0
                                    ? counters?.UPIPayments
                                    : "No Data"}
                            </h3>
                            <h3>UPI</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-175px h-125px border-gray-500 border-dotted">
                            <h3 className="text-info">
                                {" "}
                                {counters?.totalPayments > 0
                                    ? counters?.totalPayments
                                    : "No Data"}
                            </h3>
                            <h3>TOTAL</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentCounters;
