import { useState } from "react";

import ChartViewPage from "./chart-view/ChartViewPage";
import TableViewPage from "./table-view/TableViewPage";

const analysisButtons = [
  {
    btnText: "Chart View",
  },
  {
    btnText: "Table View",
  },
];

type AnalyticsType = {
  chwName: string;
};

const AnalyticsPage = ({ chwName }: AnalyticsType) => {
  const [viewType, setViewType] = useState<string>("Chart View");

  return (
    <>
      <div className="card p-5">
        <div className="mt-1 d-flex justify-content-between">
          <div className="d-flex">
            {analysisButtons.map((analysisButtons, i) => {
              return (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <button
                        key={i}
                        name={analysisButtons?.btnText}
                        onClick={() => setViewType(analysisButtons?.btnText)}
                        className={
                          analysisButtons?.btnText === viewType
                            ? "fw-bold me-5 nav-link btn btn-lg btn-color-muted btn-active-primary active"
                            : "fw-bold me-5 nav-link btn btn-lg btn-color-muted"
                        }
                      >
                        {`${analysisButtons?.btnText}  `}
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <>
          {viewType === "Chart View" ? (
            <>
              <ChartViewPage />
            </>
          ) : (
            <TableViewPage chwName={chwName} />
          )}
        </>
      </div>
    </>
  );
};

export default AnalyticsPage;
