import { createContext, useContext, useEffect, useState } from "react";
import decode from "jwt-decode";

type NavbarContextProviderProps = {
  children: React.ReactNode;
};

type UserType = {
  userName: string;
  email: string;
};

const user = {
  userName: "",
  email: "",
};

type NavbarContextType = {
  loggedInUser: UserType | null;
  facilityData: any;
  handleAllFacility: (value: any) => void;
  userPhoto: string;
  handleUserPhoto: (value: any) => void;
  userRole: string;
  selectedFacility: any;
  handleSelectedFacility: (value: any) => void;
};

const NavbarContext = createContext({} as NavbarContextType);

export const NavbarProvider = ({ children }: NavbarContextProviderProps) => {
  const [loggedInUser, setloggedInUser] = useState<UserType | null>(null);
  const [userPhoto, setUserPhoto] = useState("");
  const [userRole, setUserRole] = useState("");
  const [facilityData, setFacilityData] = useState<string[]>([]);
  const [selectedFacility, setSelectedFacility]: any = useState([]);

  const setLoggedInData = () => {
    const photo: any = localStorage.getItem("photo");
    setUserPhoto(photo);

    try {
      const accessToken = JSON.parse(
        localStorage.getItem("access_token") || "{}"
      );
      //@ts-ignore
      const { name, email, realm_access }: any = decode(accessToken);

      const role =
        realm_access?.roles.indexOf("FACILITY_MANAGER") !== -1
          ? "FACILITY MANAGER"
          : "ORGAINZATION ADMIN";

      setUserRole(role);

      setloggedInUser({
        userName: name,
        email: email,
      });
    } catch (error) {}
  };

  useEffect(() => {
    setLoggedInData();
  }, [facilityData, userPhoto, selectedFacility]);

  const handleUser = (data: any) => {
    setloggedInUser({
      userName: data?.userName,
      email: data?.email,
    });
  };

  const handleSelectedFacility = (data: any) => {
    setSelectedFacility(data);
  };

  const handleAllFacility = (data: any) => {
    setFacilityData(data);
  };
  const handleUserPhoto = (data: any) => {
    setUserPhoto(data);
  };

  const values = {
    loggedInUser,
    handleAllFacility,
    facilityData,
    userPhoto,
    handleUserPhoto,
    userRole,
    selectedFacility,
    handleSelectedFacility,
  };

  return (
    <NavbarContext.Provider value={values}>{children}</NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  return useContext(NavbarContext);
};
