import $axiosProtected from "../../../../common/utils/axiosUtils";

// Get GRN by Purchase Id
export const getSalesOrderList = async ({
    page,
    limit,
    facilityCode,
    chwId,
    deliveryStatus,
    fromDate,
    paymentStatus,
    toDate,
}: {
    page: number;
    limit: number;
    facilityCode: string;
    deliveryStatus?: string;
    paymentStatus?: string;
    chwId?: string;
    fromDate?: number | string;
    toDate?: number | string;
}) => {
    return await $axiosProtected().get(`/inventory/salesOrder/get/all/web`, {
        params: {
            facilityCode,
            chwId,
            startDate: fromDate,
            endDate: toDate,
            deliveryStatus,
            paymentStatus,
            limit,
            page,
        },
    });
};

export const getAllCHWForSales = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/facility/all-chws?facilityCode=${payload.facilityCode}&page=${payload.page}&limit=${payload.limit}`
    );
};

export const getSoBySearch = async (payload: {
    orderId: string;
    facilityCode: string;
}) => {
    return await $axiosProtected().post(
        `/inventory/salesOrder/search`,
        payload
    );
};
