import { useQuery, UseQueryOptions } from "react-query";

const useGetRequest = (
  queryName: string | string[] | any,
  axiosHook: any,
  options?: UseQueryOptions<any, unknown, any, string | string[]> | undefined
) => {
  const queryData = useQuery(queryName, () => axiosHook(), options);
  return queryData;
};

export default useGetRequest;
