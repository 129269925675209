import * as Yup from "yup";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import { IFormValues } from "../../../types/Login.types";
import { createLogin } from "../../../services/auth/Login.hook";
import useMutateRequest from "../../../hooks/getMutation.query";

import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import TokenService from "../../../common/utils/TokenService";
import OrgFooter from "./components/OrgFooter";

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .required("Password is required"),
});

var qs = require("qs");

const Login = () => {
    const { isLoading, isError, error, mutate }: any = useMutateRequest(
        (data: string) => createLogin(data)
    );
    const navigate = useNavigate();
    const { handleAuth }: any = useAuth();
    const [showEye, setShowEye] = useState<boolean>(false);
    const errorMsg = error?.response?.data?.error_description;

    const orgName = JSON.parse(localStorage.getItem("orgName") || "{}");
    const organizationLogo = JSON.parse(
        localStorage.getItem("organizationLogo") || "{}"
    );

    const onSubmit = async (values: IFormValues, onSubmitProps: any) => {
        try {
            var payload = qs.stringify({
                client_id: "chw-fe-360",
                username: values.username,
                password: values.password,
                grant_type: "password",
            });
            mutate(payload, {
                onSuccess: (data: any) => {
                    localStorage.setItem(
                        "access_token",
                        JSON.stringify(data?.data?.access_token)
                    );
                    localStorage.setItem(
                        "refresh_token",
                        JSON.stringify(data?.data?.refresh_token)
                    );
                    localStorage.setItem(
                        "status",
                        JSON.stringify(data?.status)
                    );
                    const validToken = data?.data?.access_token;

                    if (validToken) {
                        navigate("/dashboard");
                        window.location.reload();
                        handleAuth(true);
                        onSubmitProps.resetForm();
                    }

                    onSubmitProps.resetForm();
                },
                onError: (error: unknown) => {
                    onSubmitProps.resetForm();
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    // Toast Change's
    const errorToastMsg = (str: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {str
                        ? str
                        : "Something went wrong, Our team looking into this."}

                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "random-id",
            }
        );
    };

    useEffect(() => {
        if (isError) {
            errorToastMsg(isError && errorMsg);
        }
    }, [isError, errorMsg]);

    const handleLocalStorage = () => {
        TokenService.removeUser();
        window.location.href = `/`;
    };
    return (
        <div className=" d-flex flex-column justify-content-center align-items-center h-100 bg-white">
            <Toaster />

            {isLoading === true ? (
                <div className="card w-100 h-550px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <>
                    <Formik
                        initialValues={{
                            username: "",
                            password: "",
                        }}
                        validationSchema={loginSchema}
                        onSubmit={onSubmit}
                    >
                        {(formProps) => (
                            <Form className="w-100">
                                <div className="card h-100 d-flex flex-column align-items-center p-20">
                                    <div className="text-center mb-10">
                                        {
                                            <img
                                                alt="Logo"
                                                src={organizationLogo}
                                                className="h-60px mb-10"
                                            />
                                        }

                                        <div className="w-800px text-center">
                                            <h1
                                                style={{ fontSize: "48px" }}
                                                className="text-dark mb-8 fw-bold text-center"
                                            >
                                                {`Sign in to ${orgName}`}
                                            </h1>
                                        </div>
                                    </div>
                                    {/* {isError === true && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {ErrMsg ? ErrMsg : "Something went wrong"}
                      </div>
                    </div>
                  )} */}
                                    <div className="w-450px">
                                        <div className="fv-row mb-15">
                                            <label className="form-label fs-4 fw-bolder text-dark required">
                                                Email
                                            </label>
                                            <Field
                                                placeholder="Email"
                                                className="form-control form-control-lg form-control-solid"
                                                type="email"
                                                name="username"
                                                autoComplete="off"
                                            />
                                            <div className="text-danger mt-2">
                                                <ErrorMessage name="username" />
                                            </div>
                                        </div>

                                        <div className="fv-row my-10 ">
                                            <div className="d-flex justify-content-between mt-n5">
                                                <div className="d-flex mb-2">
                                                    <label className="form-label fw-bolder text-dark fs-4 required">
                                                        Password
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between position-relative">
                                                <Field
                                                    placeholder="Password"
                                                    type={
                                                        showEye === true
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password"
                                                    className="form-control form-control-lg form-control-solid"
                                                />
                                                <span
                                                    onClick={() =>
                                                        setShowEye(!showEye)
                                                    }
                                                >
                                                    {showEye ? (
                                                        <div className="position-absolute translate-middle-y top-50 end-0 me-5 cursor-pointer">
                                                            <i className="fs-2 fas fa-eye mt-0"></i>
                                                        </div>
                                                    ) : (
                                                        <div className="position-absolute translate-middle-y top-50 end-0 me-5 cursor-pointer">
                                                            <i className="fs-2 fas fa-eye-slash mt-0 "></i>
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                            <div className="text-danger mt-2">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <button
                                                disabled={
                                                    formProps.values
                                                        .username === "" ||
                                                    formProps.values
                                                        .password === "" ||
                                                    !formProps.isValid ||
                                                    formProps.isSubmitting
                                                }
                                                type="submit"
                                                className="btn btn-lg btn-primary fs-4 fw-bolder w-100 p-4"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <span className="d-flex">
                                            <p className="me-2">
                                                Looking for another
                                                organization?
                                            </p>
                                            <p
                                                onClick={handleLocalStorage}
                                                className="cursor-pointer text-primary"
                                            >
                                                Find your organization
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <OrgFooter />
                </>
            )}
        </div>
    );
};

export default Login;
