import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import ErrorBoundaryFallBack from "../components/ErrorBoundaryFallBack";
import { AuthProvider } from "../context/AuthContext";
import { FilterRangeProvider } from "../context/FilterRangeContext";
import { NavbarProvider } from "../context/NavbarContext";
import { RBACProvider } from "../context/RBACContext";
import GrowthBookWrapper from "../integrations/growthBook/GrowthBookWrapper";
import GrowthBookAPI from "../integrations/growthBook/GrowthBookAPI";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            useErrorBoundary: (error: any) => error.response?.status >= 500,
        },
    },
});

const { PUBLIC_URL } = process.env;

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<LayoutSplashScreen />}>
                    <BrowserRouter basename={PUBLIC_URL}>
                        <LayoutProvider>
                            <RBACProvider>
                                <FilterRangeProvider>
                                    <NavbarProvider>
                                        <AuthProvider>
                                            <GrowthBookAPI>
                                                {/* <GrowthBookWrapper> */}
                                                <ErrorBoundary
                                                    FallbackComponent={
                                                        ErrorBoundaryFallBack
                                                    }
                                                >
                                                    {children}
                                                </ErrorBoundary>
                                                {/* </GrowthBookWrapper> */}
                                            </GrowthBookAPI>
                                        </AuthProvider>
                                    </NavbarProvider>
                                </FilterRangeProvider>
                            </RBACProvider>
                        </LayoutProvider>
                    </BrowserRouter>
                </Suspense>
            </QueryClientProvider>
        </>
    );
};
