import * as Yup from "yup";

export type confirmValueTypes = {
  confirmDelete: string;
};

export const initalValues: confirmValueTypes = {
  confirmDelete: "",
};

export const confirmValidationSchema = Yup.object({
  confirmDelete: Yup.string().required("Confirmation is Required"),
});
