import $axiosProtected from "../../common/utils/axiosUtils";

// Create a new coure
export const createWebContent = async (payload: any) => {
    return await $axiosProtected().post(
        `/training-cms/course/create`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

// Add content to the course
export const addContent = async (payload: any) => {
    return await $axiosProtected().post(
        `/training-cms/course/content/create`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

// Get List of all Courses
export const getAllCourses = async (payload: any) => {
    return await $axiosProtected().get(
        `/training-cms/course/get/all?page=${payload.page}&limit=${payload.limit}&courseLanguage=${payload.courseLanguage}&chwGrade=${payload.chwGrade}&courseCategory=${payload.courseCategory}`
    );
};

// Search Individual Course Details By Title
export const searchCourseByTitle = async (payload: any) => {
    return await $axiosProtected().post(
        `/training-cms/course/search?page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Create Course Category
export const createCourseCategory = async (payload: any) => {
    return await $axiosProtected().post(
        `/training-cms/course/category/create`,
        payload
    );
};

// Create Course Subcategory
export const createCourseSubcategory = async (payload: any) => {
    return await $axiosProtected().post(
        `/training-cms/course/sub-category/create`,
        payload
    );
};

// Get List of all Course Category
export const getAllCoursesCategories = async () => {
    return await $axiosProtected().get(`/training-cms/course/category/get`);
};

// Get List of all Course Subcategory
export const getAllCoursesSubcategory = async (payload: any) => {
    return await $axiosProtected().get(
        `/training-cms/course/sub-category/get?categoryId=${payload.categoryId}`
    );
};

// Get Course Content By Id
export const getCourseContentById = async (payload: any) => {
    return await $axiosProtected().get(
        `/training-cms/course/content/get?courseId=${payload.courseId}`
    );
};
