export const medicalCouncils = [
  "Andhra Pradesh Medical Council",
  "Arunachal Pradesh Medical Council",
  "Assam Medical Council",
  "Bihar Medical Council",
  "Chattisgarh Medical Council",
  "Delhi Medical Council",
  "Goa Medical Council",
  "Gujarat Medical Council",
  "Haryana Medical Council",
  "Himanchal Pradesh Medical Council",
  "Jammu & Kashmir Medical Council",
  "Jharkhand Medical Council",
  "Karnataka Medical Council",
  "Madhya Pradesh Medical Council",
  "Maharashtra Medical Council",
  "Manipur Medical Council",
  "Medical Council of India",
  "Mizoram Medical Council",
  "Nagaland Medical Council",
  "Orissa Council of Medical Registration",
  "Punjab Medical Council",
  "Rajasthan Medical Council",
  "Sikkim Medical Council",
  "Tamil Nadu Medical Council",
  "Telangana State Medical Council",
  "Travancore Cochin Medical Council",
  "Tripura State Medical Council",
  "Uttarakhand Medical Council",
  "Uttar Pradesh Medical Council",
  "West Bengal Medical Council",
];
