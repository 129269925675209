import { Field, ErrorMessage } from "formik";
import { month } from "../../../settings/facility-settings/utils/dateUtils";

const TDStep1 = ({
  PERFORMANCE_MONTIORING,
  kpiData,
  currentMonthTarget,
  currentMonth,
}: any) => {
  return (
    <div>
      <div className="w-100">
        {/* Module */}
        <div className="fv-row mb-5">
          <label className="form-label">Selected Module</label>

          <Field
            readOnly
            disabled={true}
            style={{
              cursor: "not-allowed",
              fontSize: "15px",
            }}
            value={PERFORMANCE_MONTIORING}
            className="form-control form-control-lg form-control-solid"
          />
        </div>

        {/* KPI */}
        <div className="fv-row mb-5">
          <label className="form-label required">KPI</label>

          <Field
            as="select"
            name="selectedKPI"
            className="form-select form-select-lg form-select-solid"
          >
            <option value="">Select the KPI</option>
            {kpiData?.map((kpi: any) => {
              return <option value={kpi?._id}>{kpi?.criteriaName}</option>;
            })}
          </Field>
          <div className="text-danger mt-2">
            <ErrorMessage name="selectedKPI" />
          </div>
        </div>

        {/* Month Selection */}
        <div className="fv-row mb-5">
          <label className="form-label required">Select month</label>
          <Field
            as="select"
            name="selectedMonth"
            className="form-select form-select-lg form-select-solid"
          >
            <option value="">Select Month</option>
            {month?.map((month: any) => {
              return (
                <>
                  <option selected={month?.month} value={month?.monthYear}>
                    {month?.monthAndYear}
                  </option>
                </>
              );
            })}
          </Field>
          <div className="text-danger mt-2">
            <ErrorMessage name="selectedMonth" />
          </div>
        </div>

        {/* Target for the month */}
        <div className="fv-row mb-2">
          <label className="form-label">Target for the month</label>

          <Field
            readOnly
            name="targetValueForMonth"
            disabled={true}
            style={{
              cursor: "not-allowed",
              fontSize: "15px",
            }}
            // value={ErrorMsg === "undefined" ? currentMonthTarget : ErrorMsg}
            value={currentMonthTarget}
            className="form-control form-control-lg form-control-solid"
          />
        </div>
      </div>
    </div>
  );
};

export default TDStep1;
