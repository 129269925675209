import React, { useEffect, useState } from "react";
import SearchBoxSo from "./SalesOrderSearchBox";
import SalesOrderFilter from "./SalesOrderFilter";
import SalesOrderListTable from "./SalesOrderListTable";
import useGetRequest from "../../../../hooks/getRequest.query";
import {
    getSalesOrderList,
    getSoBySearch,
} from "../../../../services/supply-chain/sales-order/sales-order-list/sales-order-list";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import ExportSelector from "./exports";
import { useNavbar } from "../../../../context/NavbarContext";
import { convertToUtcTimestamp } from "../../../../common/utils";

const payload = {
    status: "",
    fromDate: "",
    toDate: "",
    chwName: "",
    paymentStatus: "",
};
const SalesOrderList = () => {
    const { selectedFacility } = useNavbar();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const userRole = localStorage.getItem("userRole") as string;
    const [apiData, setApiData] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filterData, setFilterData] = useState(payload);
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const { data, isSuccess, isError, error }: any = useGetRequest(
        [
            "getSalesOrderList",
            filterData,
            page,
            limit,
            facilityCode,
            selectedFacility,
        ],
        () =>
            getSalesOrderList({
                page: page,
                limit: limit,
                facilityCode: selectedFacility?.facilityCode
                    ? selectedFacility?.facilityCode
                    : facilityCode,
                deliveryStatus: filterData.status,
                paymentStatus: filterData.paymentStatus,
                chwId: filterData.chwName,
                fromDate:
                    filterData.fromDate !== ""
                        ? convertToUtcTimestamp(filterData.fromDate)
                        : "",
                toDate:
                    filterData.toDate !== ""
                        ? convertToUtcTimestamp(filterData.toDate)
                        : "",
            })
    );

    useEffect(() => {
        setApiData(data?.data);
    }, [data]);
    const {
        mutate,
        isError: searchError,
        error: searchErrorMessage,
    }: any = useMutateRequest((data: any) => getSoBySearch(data));
    console.log("kk", searchError);
    return (
        <>
            <Modal
                open={isOpen}
                styles={{
                    width: "650px",
                    backgroundColor: "white",
                }}
            >
                <ExportSelector handleModal={handleModal} />
            </Modal>
            <div className="card text-dark p-3">
                <h1>
                    Order List{" "}
                    <i
                        onClick={() => {
                            setFilterData(payload);
                            setApiData(data?.data);
                        }}
                        className="mt-2 fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </h1>
                <div>
                    <div className="row justify-content-end">
                        <div className="col-sm-8">
                            <SearchBoxSo
                                mutate={mutate}
                                setApiData={setApiData}
                                setPage={setPage}
                            />
                        </div>
                        <div className="col-sm-auto mt-4">
                            <button
                                onClick={handleModal}
                                className="btn btn-md btn-light-primary"
                            >
                                <i className="fas fa-download"></i>
                                EXPORT
                            </button>
                        </div>
                        <div className="col-sm-auto mt-4">
                            {userRole === "FACILITY_MANAGER" && (
                                <Link
                                    to={"/sales-order/add-bill"}
                                    className="btn btn-md btn-primary"
                                >
                                    Generate bill
                                </Link>
                            )}
                            <SalesOrderFilter
                                setPage={setPage}
                                setFilterData={setFilterData}
                            />
                        </div>
                    </div>
                    {isSuccess && (
                        <div className="mt-4">
                            <SalesOrderListTable
                                apiData={apiData}
                                limit={limit}
                                setLimit={setLimit}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    )}
                    {(isError || searchError) && (
                        <div
                            className=" d-flex align-items-center justify-content-center"
                            style={{ height: "50vh" }}
                        >
                            <p className="text-danger fs-2 fw fw-bolder">
                                {error?.response?.data?.message ||
                                    searchErrorMessage?.response?.data
                                        ?.message ||
                                    "There are no Sales order to show"}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SalesOrderList;
