import { useMemo, useState } from "react";
import Modal from "../../../../../components/Modal";
import ClipLoader from "react-spinners/ClipLoader";
// import useSearchParams from "../../../../../hooks/useSearchParams";
import ModalUI from "./ModalUI";

const CourseCurriculum = ({
  courseContent,
  courseContentRefetch,
  isLoading,
  isFetching,
}: any) => {
  // const CourseCurriculum = () => {
  // const { courseId: id }: any = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [clicked, setClicked]: any = useState("0");
  const [userSelectedData, setUserSelectedData] = useState<any>();

  const isContentAdded = useMemo(
    () => courseContent?.data?.data?.isContentExist,
    [courseContent]
  );
  const courseContentSections = useMemo(
    () => courseContent?.data?.data?.courseContent,
    [courseContent]
  );

  // const courseContentErrorMsg = courseContentError?.response?.data?.message;

  const handleModal = (userSelectedData: any) => {
    setUserSelectedData(userSelectedData);
    setIsModalOpen(!isModalOpen);
  };

  const handleToggle = (index: any) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        overlayStyles={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <ModalUI
          styles={{
            backgroundColor: "white",
            width: "900px",
            height: "550px",
          }}
          onClose={handleModal}
          userData={userSelectedData}
        />
      </Modal>

      {isLoading || isFetching ? (
        <div className="card h-350px d-flex justify-content-center align-items-center">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isContentAdded === false ? (
        <div className="card h-350px d-flex justify-content-center align-items-center">
          <h1 className="fw-bolder text-danger">There is no course content</h1>
        </div>
      ) : (
        <>
          {courseContentSections?.sections?.map((section: any, index: any) => {
            return (
              <div className="card mt-5 p-3">
                <div
                  onClick={() => handleToggle(index)}
                  className="ms-2 mb-5 fs-3 fw-bolder"
                >
                  {clicked === index ? (
                    <i className="cursor-pointer fs-3 fas fa-chevron-up"></i>
                  ) : (
                    <i className="cursor-pointer fs-3 fas fa-greater-than"></i>
                  )}
                  <span className="ms-8">
                    {`${
                      section?.sectionName
                        ? section?.sectionName
                        : `Section ${index + 1}`
                    }`}
                  </span>
                </div>
                {section?.lessons?.map((lesson: any) => {
                  return (
                    <>
                      {clicked === index && (
                        <div className="ms-20 d-flex justify-content-between align-items-center">
                          <div className="fw-bolder mb-6">
                            <span>{lesson?.lessonNumber}</span>
                            <span className="ms-4">{lesson?.lessonName}</span>
                          </div>
                          <>
                            {lesson?.lessonType === "Document" && (
                              <>
                                <button
                                  className="btn btn-primary btn-sm w-150px"
                                  onClick={() => handleModal(lesson)}
                                >
                                  View Document
                                </button>
                              </>
                            )}
                          </>
                          <>
                            {lesson?.lessonType === "Video" && (
                              <>
                                <button
                                  onClick={() => handleModal(lesson)}
                                  className="btn btn-primary btn-sm w-150px"
                                >
                                  View Video
                                </button>
                              </>
                            )}
                          </>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default CourseCurriculum;
