import { useEffect, useState } from "react";

import { DoctorHeader } from "./components/DoctorHeader";
import DoctorInsights from "./features/insights/DoctorInsights";
import DoctorProfileDetails from "./features/doctor-profile-details/DoctorProfileDetails";

import DoctorAppointmentPage from "./features/appointment-list/DoctorAppointmentPage";

import TabContainer from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import { useNavbar } from "../../context/NavbarContext";
import useMutateRequest from "../../hooks/getMutation.query";
import useGetRequest from "../../hooks/getRequest.query";
import useSearchParams from "../../hooks/useSearchParams";
import { getDoctorInsights } from "../../services/doctor/features/DoctorInsights.hook";
import { getDoctorDetailsById } from "../../services/doctor/features/GetDoctorDetails.hook";
import { getAllFacilites } from "../../services/facility/Facility.hook";
import { convertToUtcTimestamp } from "../../common/utils";

export const DoctorOverview = () => {
    const { selectedFacility } = useNavbar();

    const { dId: id }: any = useSearchParams();

    const [filterData, setFilterData]: any = useState({
        fromDate: "",
        toDate: "",
        facilityCode: "",
    });

    const { data, isLoading, mutate, isError, error }: any = useMutateRequest(
        async (data: any) => await getDoctorDetailsById(data)
    );
    const doctorData = data?.data?.data?.doctor;

    const getData = () => {
        try {
            const payload = {
                id: id,
            };

            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const {
        data: doctorInsightsData,
        isLoading: loadingDoctorInsights,
        isFetching: fetchingDoctorInsights,
        refetch: refetchDoctorInsights,
        isError: isDoctorInsightsError,
        error: isDoctorError,
    }: any = useGetRequest(
        ["getDoctorInsights", id],
        async () =>
            await getDoctorInsights({
                fromDate:
                    filterData?.fromDate !== ""
                        ? convertToUtcTimestamp(filterData?.fromDate)
                        : "",
                toDate:
                    filterData?.toDate !== ""
                        ? convertToUtcTimestamp(filterData?.toDate)
                        : "",
                facilityCode: filterData?.facilityCode,
                doctorId: id,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    useEffect(() => {
        if (id) {
            getData();
            refetchDoctorInsights();
        }
    }, [id, selectedFacility, filterData]);

    const consultationCountPerDay =
        doctorInsightsData?.data?.data?.consultationCountPerDay;
    const doctorInsights = doctorInsightsData?.data?.data?.doctorInsights;

    const { data: facilityData, refetch } = useGetRequest(
        "getAllFacilites-doctor",
        async () => await getAllFacilites(),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    let allFacilites = facilityData?.data?.data?.facility;

    return (
        <>
            <div className="#">
                <DoctorHeader
                    doctorData={doctorData}
                    consultationCountPerDay={consultationCountPerDay}
                    isLoading={isLoading}
                />
            </div>
            <TabContainer initialValue="overview">
                <Tab eventKey="overview" title="OVERVIEW">
                    <DoctorInsights
                        allFacilites={allFacilites}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        doctorInsights={doctorInsights}
                        fetchingDoctorInsights={fetchingDoctorInsights}
                        loadingDoctorInsights={loadingDoctorInsights}
                    />
                </Tab>
                <Tab eventKey="details" title="DETAILS">
                    <DoctorProfileDetails
                        isLoading={isLoading}
                        doctorData={doctorData}
                    />
                </Tab>
                <Tab eventKey="appoinment" title="APPOINTMENT LIST">
                    <DoctorAppointmentPage />
                </Tab>
            </TabContainer>

            {/* <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path="/doctor/:id/overview" exact={true}>
            <DoctorInsights
              allFacilites={allFacilites}
              setFilterData={setFilterData}
              doctorInsights={doctorInsights}
              fetchingDoctorInsights={fetchingDoctorInsights}
              loadingDoctorInsights={loadingDoctorInsights}
            />
          </Route>

          <Route path="/doctor/:id/overview/details">
            <DoctorProfileDetails
              isLoading={isLoading}
              // isFetching={isFetching}
              doctorData={doctorData}
            />
          </Route>

          <Route path="/doctor/:id/overview/doctor-appointments">
            <DoctorAppointmentPage />
          </Route>

          <Redirect to="error/404" />
        </Switch>
      </Suspense> */}
        </>
    );
};
