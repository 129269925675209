import * as Yup from "yup";

export type patientExportValueTypes = {
    exportFormat: string;
    facilityCode: string;
    chwId: string;
    patientType: string;
    fromDate: string;
    toDate: string;
};

export const patientInitalValues: patientExportValueTypes = {
    exportFormat: "CSV",
    facilityCode: "",
    patientType: "",
    chwId: "",
    fromDate: "",
    toDate: "",
};

export const patientExportValidationSchema = Yup.object({
    exportFormat: Yup.string().label("Export format is required"),
    facilityCode: Yup.string().label("Facility name is required"),
    patientType: Yup.string().label("Patient Type is required"),
    chwId: Yup.string().label("CHW name is required"),
    fromDate: Yup.string().required("From date is required"),
    toDate: Yup.string().required("To date is required"),
});
