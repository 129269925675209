import React, { useState } from "react";

import AddProductForm from "./Forms/addProductForm";
import AddedProductTable from "./Forms/AddedProductTable";

export type IProductsArr =
    | {
          grnNo: string;
          product: string;
          productId: string;
          vendorName: string;
          invoiceNumber: string;
          invoiceDate: string;
          batch: string;
          expiryDate: string;
          unitsPerStrip: string;
          noOfStrips: string;
          freeStrips: string;
          gstTotal: string;
          pricePerStrip: string;
          mrpPerStrip: string;
          discountPercent: string;
          hsnCode: string;
          rackNo: string;
          boxNo: string;
      }[]
    | [];

const AddGrn = () => {
    const [productsArr, setProductsArr] = useState<IProductsArr>([]);
    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceNumber: "",
        invoiceDate: "",
        vendorId: "",
    });
    const [apiProductArr, setApiProductArr] = useState<any>([]);
    const [radioValue, setRadioValue] = useState("2");
    const [inputVal, setInputVal] = useState("");
    const [amtInputVal, setAmtInputVal] = useState("");

    return (
        <div className="card text-dark p-3">
            <h1>Add Goods Receipt Note (GRN)</h1>
            <div className="mt-5">
                <AddProductForm
                    setProductsArr={setProductsArr}
                    setInvoiceDetails={setInvoiceDetails}
                    setApiProductArr={setApiProductArr}
                />
            </div>
            {productsArr.length > 0 && (
                <div className="mt-5" data-testid="table-container">
                    <AddedProductTable
                        invoiceDetails={invoiceDetails}
                        productsArr={productsArr}
                        setProductsArr={setProductsArr}
                        setApiProductArr={setApiProductArr}
                        apiProductArr={apiProductArr}
                        amtInputVal={amtInputVal}
                        inputVal={inputVal}
                        radioValue={radioValue}
                        setAmtInputVal={setAmtInputVal}
                        setInputVal={setInputVal}
                        setRadioValue={setRadioValue}
                    />
                </div>
            )}
        </div>
    );
};

export default AddGrn;
