import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import {
    Table,
    TableHead,
    TableBodyContainer,
    TableHeadRow,
    TableCell,
    TableRow,
} from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getPOByIndentReferenceId } from "../../../../services/supply-chain/indent/Indent.services";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../../../common/utils";

type Props = {};
const POTableNames = [
    "PO ID",
    "PO Date",
    "Indent ID",
    "Vendor Name",
    "Amounnt",
    "Status",
];
const PurchaseOrderTable = (props: Props) => {
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const facilityCode = localStorage.getItem("facilityCode");
    const [searchParams] = useSearchParams();
    const indentReferenceId = searchParams.get("id");
    // Table API
    const { data, isLoading, isFetching, isError, error, refetch }: any =
        useGetRequest(
            ["getPOByIndentReferenceId", pageLimit],
            () =>
                getPOByIndentReferenceId({
                    facilityCode,
                    indentReferenceId,
                    page: currentPageNumber,
                    limit: pageLimit,
                }),
            { staleTime: 100000, refetchOnWindowFocus: false }
        );

    const purchaseOrdersList = data?.data?.data?.purchaseOrders;
    const totalEntites = data?.data?.results;
    const totalPages = Math.ceil(totalEntites / pageLimit);
    const errorMsg = error?.response?.data?.message;
    const successMsg = data?.data?.data?.message;

    useEffect(() => {
        refetch();
    }, [indentReferenceId]);

    return (
        <div>
            <div>
                <h4 className="mb-4 mt-2">Purchase Order</h4>
                <Table>
                    <TableHeadRow>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>
                        {POTableNames?.map((name) => {
                            return (
                                <TableHead width={180} key={name} align="left">
                                    {name.toUpperCase()}
                                </TableHead>
                            );
                        })}
                    </TableHeadRow>
                    <TableBodyContainer
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        errorMessage={errorMsg}
                    >
                        {purchaseOrdersList?.map((ele: any, idx: number) => {
                            return (
                                <TableRow collapsible={false}>
                                    <TableCell align="center">
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/purchase-order/details?purchaseOrderId=${ele._id}&orderId=${ele.purchaseOrderId}`}
                                            className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                        >
                                            {ele.purchaseOrderId}
                                        </Link>
                                    </TableCell>

                                    {/* <TableCell>
                                        {ele?.purchaseOrderId
                                            ? ele?.purchaseOrderId
                                            : "N/A"}
                                    </TableCell> */}
                                    <TableCell>
                                        {ele?.createdAt
                                            ? dateFormatter(ele?.createdAt)
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {ele?.indentId ? ele?.indentId : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {ele.vendorId?.vendorName
                                            ? ele.vendorId?.vendorName
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {ele?.totalAmount
                                            ? ele?.totalAmount
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                color:
                                                    ele?.poOrderStatus ===
                                                    "Order Completed"
                                                        ? "#50cd89"
                                                        : ele?.poOrderStatus ===
                                                          "Order Placed"
                                                        ? "#FFA533"
                                                        : ele?.poOrderStatus ===
                                                          "Order Cancelled"
                                                        ? "#FF4933"
                                                        : "",
                                            }}
                                        >
                                            {ele?.poOrderStatus
                                                ? ele?.poOrderStatus
                                                : "N/A"}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBodyContainer>
                </Table>
                <Pagination
                    dataCount={10}
                    handleCallback={(val: number | string) => {
                        // setPageLimit(val);
                    }}
                    handleNext={
                        () => null
                        // handlePageNumber(
                        //     (prevPageNumber: number) =>
                        //         prevPageNumber + 1
                        // )
                    }
                    handlePrev={
                        () => null
                        // handlePageNumber(
                        //     (prevPageNumber: number) =>
                        //         prevPageNumber - 1
                        // )
                    }
                    nextDisabled={true} //{currentPageNumber === totalPages}
                    pageNumber={1} //{currentPageNumber}
                    pageLimit={10} //{pageLimit}
                    prevDisabled={true} //{currentPageNumber === 1}
                />
            </div>
        </div>
    );
};

export default PurchaseOrderTable;
