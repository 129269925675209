import React, { useEffect, useState } from "react";
import IndentForm from "./components/IndentForm";
import ModifyIndentTable from "./components/ModifyIndentTable";
import { useSearchParams } from "react-router-dom";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getIndentById } from "../../../../services/supply-chain/indent/Indent.services";

export type IModifyIndentItem = {
    productName: string;
    genericName: string;
    manufacturerName: string;
    unitPerStrip: number;
    noOfStrips: number;
};
export type IModifyIndentTable =
    | {
          productName: string;
          genericName: string;
          manufacturerName: string;
          unitPerStrip: number;
          noOfStrips: number;
      }[]
    | [];

const ModifyIndentPage = () => {
    const [searchParams] = useSearchParams();
    const indentId = searchParams.get("id");
    const indentStatus = searchParams.get("status");
    const facilityCode = localStorage.getItem("facilityCode");

    const { data, refetch }: any = useGetRequest(
        ["getIndentById", 1, indentId, facilityCode],
        () =>
            getIndentById({
                facilityCode,
                indentId,
                page: 1,
                limit: 100,
            })
    );
    const indentList = data?.data?.data?.indent?.products;

    const [productDetails, setProductDetails] = useState<IModifyIndentTable>(
        []
    );

    useEffect(() => {
        if (indentStatus === "Draft") {
            setProductDetails(
                indentList?.map((product: any) => {
                    return {
                        noOfstrips: product?.noOfstrips,
                        productId: product?.productId?._id,
                        productName: product?.productId?.productName,
                        genericName: product?.productId?.genericName,
                        manufacturerName: product?.manufacturerName,
                        totalUnit: product?.totalUnit,
                        unitPerStrip: product?.unitPerStrip,
                    };
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indentId, refetch, indentList]);

    return (
        <section>
            <IndentForm
                productDetails={productDetails}
                setProductDetails={setProductDetails}
                indentList={indentList}
            />
            <br />
            {productDetails?.length > 0 && (
                <ModifyIndentTable
                    productDetails={productDetails}
                    setProductDetails={setProductDetails}
                />
            )}
        </section>
    );
};

export default ModifyIndentPage;
