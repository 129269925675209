import * as Yup from "yup";

export type salesOrderExportValueTypes = {
    exportFormat: string;
    facilityCode: string;
    chwId: string;
    salesOrderType: string;
    deliveryStatus: string;
    paymentStatus: string;
    fromDate: string;
    toDate: string;
};

export const salesOrderInitalValues: salesOrderExportValueTypes = {
    exportFormat: "CSV",
    facilityCode: "",
    chwId: "",
    fromDate: "",
    toDate: "",
    paymentStatus: "",
    deliveryStatus: "",
    salesOrderType: "",
};

export const salesOrderExportValidationSchema = Yup.object({
    exportFormat: Yup.string().label("Export format is required"),
    facilityCode: Yup.string().label("Facility name is required"),
    salesOrderType: Yup.string().label("Sales Order Type is required"),
    paymentStatus: Yup.string().label("Payment Status is required"),
    deliveryStatus: Yup.string().label("Delivery Status is required"),
    chwId: Yup.string().label("CHW name is required"),
    fromDate: Yup.string().required("From date is required"),
    toDate: Yup.string().required("To date is required"),
});
