import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import _ from "lodash";
import { Toaster } from "react-hot-toast";

import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import useMutateRequest from "../../../hooks/getMutation.query";

import TableErrorComponent from "../../../components/Error/TableError";
import { searchPrimarySubscriber } from "../../../services/HealthCard.hook";

const tableHeadings = ["SL NO", "HEALTHCARD NO", "PATIENT NAME", "CARD STATUS"];

const searchSchema = Yup.object().shape({
    searchName: Yup.number()
        .typeError("Health Card must be a number")
        .integer("Health Card must be an integer")
        .positive("Health Card must be a positive number")
        .test(
            "len",
            "Health Card must be exactly 16 digits",
            (val: any) => val && val.toString().length === 16
        )

        .label("Health Card"),
});

export const HealthCardTypeTable = ({
    bodyProps,
    getPatientLoader,
    getPatientFetcher,
    isCHWError,
    chwErrorMsg,
    currentPageNumber,
    handlePageNumber,
    totalGetCHWPages,
    pageLimit,
    refetch,
    setPageLimit,
}: any) => {
    // Setting the Search term
    const [searchData, setSearchData]: any = useState([]);
    const [searchPageNumber] = useState(1);
    const [searchFormProps, setSearchFormProps] = useState("");

    // const facilityCode: any = localStorage.getItem("facilityCode");
    // const showPromptMsg = facilityCode === "";
    // API For Search CHW
    const {
        data,
        mutate,
        isError: isSearchError,
        error: ErrorMsg,
        isLoading: searchCHWLoader,
        reset,
    }: any = useMutateRequest((data: any) => searchPrimarySubscriber(data));

    const totalSearchEntites = data?.data?.results;
    const totalSearchCHWPages = Math.ceil(totalSearchEntites / pageLimit);
    const totalPages =
        searchData?.length > 0
            ? totalSearchCHWPages
            : Math.ceil(totalGetCHWPages / pageLimit);

    const searchErrorMsg = ErrorMsg?.response?.data?.message;

    // Begins Menu Here

    const onSearchSubmit = (values: any, onSubmitProps: any) => {
        try {
            setSearchFormProps(onSubmitProps);
            const payload = {
                healthCardNumber: values.searchName?.trim(),
                limit: pageLimit,
                page: searchPageNumber,
            };

            mutate(payload, {
                onSuccess: (data: any) => {
                    const searchTerm = data?.data?.data?.patient;
                    setSearchData(searchTerm);
                    onSubmitProps.resetForm();
                },
                onError: (error: any) => {
                    setSearchData([]);
                    onSubmitProps.resetForm();
                },
            });
        } catch (error) {
            // onSubmitProps.resetForm();
            setSearchData([]);
        }
    };

    const clearData = (searchFormProps: any) => {
        if (searchFormProps) {
            searchFormProps.resetForm();
            setSearchData([]);
            reset();
        }
        refetch();
    };

    let allPatients: any;
    if (searchData?.length > 0) {
        allPatients = searchData;
    } else {
        allPatients = bodyProps;
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPatients]);

    return (
        <div className="card text-dark">
            <Toaster />

            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>HEALTHCARD SUBSCRIPTION LIST</h1>
                    <i
                        onClick={() => clearData(searchFormProps)}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                {
                    <>
                        {!isCHWError && (
                            <div className="card-toolbar">
                                {/* Search Bar */}
                                <div className="py-2 me-20">
                                    <Formik
                                        initialValues={{
                                            searchName: "",
                                        }}
                                        validationSchema={searchSchema}
                                        onSubmit={onSearchSubmit}
                                    >
                                        {(formProps: any) => (
                                            <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                                                <div className="d-flex ">
                                                    <Field
                                                        name="searchName"
                                                        placeholder="Search healthcard"
                                                        className="form-control form-control-md form-control-solid"
                                                    />
                                                    <button
                                                        disabled={
                                                            formProps.isSubmitting ||
                                                            !(
                                                                formProps.isValid &&
                                                                formProps.dirty
                                                            )
                                                        }
                                                        type="submit"
                                                        className="ms-3 btn btn-lg btn-primary"
                                                    >
                                                        Search
                                                    </button>
                                                </div>
                                                <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                                    <ErrorMessage name="searchName" />
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </>
                }
            </div>
            {
                // showPromptMsg ? (
                //     <>
                //         <div className="bg-white w-100 h-500px p-5 d-flex justify-content-center align-items-center">
                //             <h1 className="font-bolder text-danger">
                //                 Please select any facility
                //             </h1>
                //         </div>
                //     </>
                // ) :
                <>
                    {isSearchError || isCHWError ? (
                        <TableErrorComponent
                            errorMessage={chwErrorMsg || searchErrorMsg}
                        />
                    ) : (
                        <div className="card-body py-3">
                            <Table>
                                <TableHeadRow>
                                    {tableHeadings.map((ele) => (
                                        <TableHead
                                            width={
                                                ele.toLowerCase() === "sl no"
                                                    ? 50
                                                    : 100
                                            }
                                            key={ele}
                                        >
                                            {ele}
                                        </TableHead>
                                    ))}
                                </TableHeadRow>
                                <TableBodyContainer
                                    isLoading={
                                        getPatientLoader ||
                                        getPatientFetcher ||
                                        searchCHWLoader
                                    }
                                    isError={isSearchError || isCHWError}
                                    errorMessage={chwErrorMsg || searchErrorMsg}
                                >
                                    {Array?.isArray(allPatients) &&
                                        allPatients?.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        collapsible={false}
                                                        key={index}
                                                    >
                                                        <TableCell>
                                                            {currentPageNumber &&
                                                                (currentPageNumber -
                                                                    1) *
                                                                    pageLimit +
                                                                    index +
                                                                    1}
                                                        </TableCell>
                                                        <TableCell hover>
                                                            <Link
                                                                className="text-black"
                                                                to={`/patient/overview?pId=${item?.uhid}`}
                                                            >
                                                                {item?.healthCardNumber
                                                                    ? item
                                                                          ?.healthCardNumber[0]
                                                                          ?.id
                                                                    : "N/A"}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            {_.startCase(
                                                                item?.name
                                                            )}
                                                        </TableCell>

                                                        <TableCell>
                                                            <span
                                                                className={`badge badge-light-${
                                                                    item
                                                                        ?.healthCardNumber[0]
                                                                        ?.status ===
                                                                    true
                                                                        ? "success"
                                                                        : "danger"
                                                                } text-center `}
                                                            >
                                                                {item
                                                                    ?.healthCardNumber[0]
                                                                    ?.status
                                                                    ? item
                                                                          ?.healthCardNumber[0]
                                                                          ?.status ===
                                                                      true
                                                                        ? "Active"
                                                                        : "Not active"
                                                                    : "N/A"}
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                </TableBodyContainer>
                            </Table>
                            {!isSearchError && !isCHWError && (
                                <Pagination
                                    dataCount={totalGetCHWPages}
                                    handleCallback={(val: number | string) => {
                                        setPageLimit(val);
                                    }}
                                    handleNext={() =>
                                        handlePageNumber(
                                            (prevPageNumber: number) =>
                                                prevPageNumber + 1
                                        )
                                    }
                                    handlePrev={() =>
                                        handlePageNumber(
                                            (prevPageNumber: number) =>
                                                prevPageNumber - 1
                                        )
                                    }
                                    nextDisabled={
                                        currentPageNumber === totalPages
                                    }
                                    pageNumber={currentPageNumber}
                                    pageLimit={pageLimit}
                                    prevDisabled={currentPageNumber === 1}
                                />
                            )}
                        </div>
                    )}
                </>
            }
        </div>
    );
};
