import React from "react";
import MasterProductsTable from "./table/MasterProductsTable";

type Props = {};

const MasterProductPage = (props: Props) => {
    return (
        <div>
            <MasterProductsTable />
        </div>
    );
};

export default MasterProductPage;
