import { useState } from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import HealthCardsExport from "../components/HealthCardsExport";
import Modal from "../../../components/Modal";
import CustomTooltip from "../../../components/CustomTooltip";
import { KTSVG } from "../../../_metronic/helpers";
import Pagination from "../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import TableErrorComponent from "../../../components/Error/TableError";
import { Permissions, hasAccessToSubModule } from "../../../rbac";

const HealthCardTable = ({
    bodyProps,
    isLoading,
    isFetching,
    errorMsg,
    successMsg,
    setCurrentPageNumber,
    currentPageNumber,
    totalHealthCardPages,
    totalHealthCardEntites,
    pageLimit,
    setPageLimit,
    isHealthCardError,
}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleCopy = (textData: any) => {
        copyToClipboard();
        return navigator.clipboard.writeText(textData);
    };
    const copyToClipboard = () =>
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Copied to Clipboard
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
            }
        );
    const tableNames = ["HEALTH CARD NUMBER", "USAGE STATUS", "STATUS"];

    // Role setting
    const canExport = hasAccessToSubModule(
        "LIST_BATCH",
        "LIST_HEALTHCARDS",
        Permissions.export_button
    );

    return (
        <>
            <div className="card text-dark">
                <Toaster />
                <Modal open={isOpen}>
                    <HealthCardsExport
                        onClose={handleModal}
                        styles={{
                            backgroundColor: "white",
                            width: "650px",
                            height: "525px",
                            msgHeight: "200px",
                            padding: "50px",
                        }}
                    />
                </Modal>
                <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                    <h1>HEALTHCARD LIST</h1>
                    <div className="card-toolbar">
                        <button
                            hidden={!canExport}
                            onClick={handleModal}
                            className="btn btn-sm btn-light-primary me-5"
                        >
                            <i className="fas fa-download"></i>
                            EXPORT
                        </button>
                    </div>
                </div>
                {isHealthCardError ? (
                    <TableErrorComponent errorMessage={errorMsg} />
                ) : (
                    <div className="card-body py-3">
                        <Table>
                            <TableHeadRow>
                                <TableHead width={140} align="center">
                                    SL NO
                                </TableHead>

                                {tableNames?.map((name) => {
                                    return (
                                        <TableHead width={180} key={name}>
                                            {name}
                                        </TableHead>
                                    );
                                })}
                            </TableHeadRow>

                            <TableBodyContainer
                                isLoading={isLoading}
                                isError={isHealthCardError}
                                errorMessage={errorMsg}
                            >
                                {bodyProps?.map((item: any, index: any) => {
                                    return (
                                        <TableRow
                                            collapsible={false}
                                            key={index}
                                        >
                                            <TableCell align="center">
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>

                                            <TableCell>
                                                <span className="me-8">
                                                    {item?.healthCardNumber}
                                                </span>
                                                <CustomTooltip
                                                    key={index}
                                                    title="Copy Healthcard"
                                                    placement="right"
                                                    arrow
                                                >
                                                    <span
                                                        className="text-hover-primary"
                                                        onClick={() =>
                                                            handleCopy(
                                                                item?.healthCardNumber
                                                            )
                                                        }
                                                    >
                                                        <KTSVG
                                                            path="/media/ikure/copy.svg"
                                                            className={`svg-icon-2 svg-icon cursor-pointer`}
                                                        ></KTSVG>
                                                    </span>
                                                </CustomTooltip>
                                            </TableCell>

                                            <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        item?.usageStatus ===
                                                        "Unassigned"
                                                            ? "danger"
                                                            : "success"
                                                    } text-center `}
                                                >
                                                    {item?.hasOwnProperty(
                                                        "uhid"
                                                    ) ? (
                                                        <>
                                                            <CustomTooltip
                                                                title="View Assigned Patient"
                                                                placement="right"
                                                                arrow
                                                            >
                                                                <Link
                                                                    style={{
                                                                        color: "#50cd89",
                                                                    }}
                                                                    to={`/patient/overview?pId=${item?.uhid}`}
                                                                >
                                                                    <span>
                                                                        {
                                                                            item?.usageStatus
                                                                        }
                                                                    </span>
                                                                </Link>
                                                            </CustomTooltip>
                                                        </>
                                                    ) : (
                                                        <span>
                                                            {item?.usageStatus}
                                                        </span>
                                                    )}
                                                </span>
                                            </TableCell>

                                            <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        item?.status ===
                                                        "Active"
                                                            ? "success"
                                                            : "danger"
                                                    } text-center `}
                                                >
                                                    {item?.status}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBodyContainer>
                        </Table>

                        {/* Pagination */}
                        <Pagination
                            dataCount={totalHealthCardEntites}
                            handleCallback={(val) => setPageLimit(+val)}
                            handleNext={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={
                                currentPageNumber === totalHealthCardPages
                            }
                            prevDisabled={currentPageNumber === 1}
                            pageLimit={pageLimit}
                            pageNumber={currentPageNumber}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default HealthCardTable;
