import * as Yup from "yup";

export type PatientRegistrationTypes = {
  patientRegistration: any;
};

export const initalValues: PatientRegistrationTypes = {
  patientRegistration: "",
};

export const patientRegistrationValidationSchema = Yup.object({
  patientRegistration: Yup.number().required(
    "Patient Registration is required"
  ),
});
