import { AppProviders } from "./providers/AppProviders";
import Routes from "./routing/Routes";

const App = () => {
  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  );
};

export { App };
