import React, { FC, useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { useNavbar } from "../../../../../context/NavbarContext";

const Step1: FC = () => {
    const { selectedFacility } = useNavbar();
    const facilityName: any = localStorage.getItem("facilityName");

    const [name, setName] = useState("");

    useEffect(() => {
        setName(facilityName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacility]);

    return (
        <>
            <div className="w-100">
                <div className="pb-10 pb-lg-12">
                    <h2 className="fw-bolder text-dark">Basic Details</h2>
                    {!name && (
                        <span className="text-danger fw-bolder fs-3">
                            Please select a facility
                        </span>
                    )}
                    {name && (
                        <div className="text-gray-800 fw-bold fs-6">
                            Facility manager onboarded to &nbsp;
                            <span className="text-dark fw-bolder">
                                {name?.toUpperCase()}
                            </span>
                            .
                        </div>
                    )}
                </div>

                {/* First Name */}
                <div className="fv-row mb-10">
                    <label className="form-label required">First Name</label>

                    <Field
                        name="firstName"
                        data-testid="first-name"
                        placeholder="Enter your first name"
                        className="form-control form-control-lg form-control-solid"
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="firstName" />
                    </div>
                </div>
                {/* Middle Name */}
                <div className="fv-row mb-10">
                    <label className="form-label">Middle Name</label>

                    <Field
                        name="middleName"
                        data-testid="middle-name"
                        placeholder="Enter your middle name"
                        className="form-control form-control-lg form-control-solid"
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="middleName" />
                    </div>
                </div>
                {/* Last Name */}
                <div className="fv-row mb-10">
                    <label className="d-flex align-items-center form-label">
                        <span className="required">Last Name</span>
                    </label>

                    <Field
                        name="lastName"
                        data-testid="last-name"
                        placeholder="Enter your last name"
                        className="form-control form-control-lg form-control-solid"
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="lastName" />
                    </div>
                </div>
                {/* Phone Number */}
                <div className="fv-row mb-10">
                    <label className="form-label required">Phone Number</label>

                    <Field
                        name="telecom"
                        data-testid="phone-number"
                        placeholder="Enter your phone number"
                        className="form-control form-control-lg form-control-solid"
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="telecom" />
                    </div>
                </div>
                <div className="fv-row mb-0">
                    <label className="fs-6 fw-bold form-label required">
                        Personal Email
                    </label>

                    <Field
                        data-testid="email"
                        name="email"
                        placeholder="Enter your email id"
                        className="form-control form-control-lg form-control-solid"
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="email" />
                    </div>
                </div>
            </div>
        </>
    );
};

export { Step1 };
