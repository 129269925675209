import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const homePaths = [
    "/dashboard",
    "/chw/",
    "/chw/chw-management",
    "/chw/chw-onboarding",
    "/chw/chw-settings",
    "/doctor",
    "/doctor/doctor-management",
    "/doctor/doctor-onboarding",
    "/doctor/doctor-settings",
];
const Breadcrumbs = () => {
    const { pathname, search } = useLocation();

    const pathnames = pathname.split("/").filter((x) => x);

    const displayHome = homePaths.includes(pathname);

    return (
        <div style={{ marginLeft: "1rem" }}>
            {/* @ts-ignore */}
            <Breadcrumb>
                {displayHome && (
                    //     @ts-ignore
                    <Breadcrumb.Item
                        linkAs={Link}
                        linkProps={{ to: "/dashboard" }}
                        className="text-capitalize text-dark"
                        style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: "#c4c4c4 !important",
                        }}
                    >
                        Home
                    </Breadcrumb.Item>
                )}

                {pathnames.map((name, index) => {
                    const routeTo = `${pathnames
                        .slice(0, index + 1)
                        .join("/")}${search}`;
                    const pathName = name.split("-").join(" ");
                    const isLast = index === pathnames.length - 1;

                    return isLast ? (
                        // @ts-ignore
                        <Breadcrumb.Item
                            key={name}
                            className="text-capitalize text-dark fw-bold"
                            style={{ fontWeight: "bold", fontSize: "1rem" }}
                            active
                        >
                            {pathName}
                        </Breadcrumb.Item>
                    ) : (
                        // @ts-ignore
                        <Breadcrumb.Item
                            key={name}
                            linkAs={Link}
                            className="text-capitalize text-dark"
                            style={{
                                fontWeight: 500,
                                fontSize: "1rem",
                                color: "#c4c4c4",
                            }}
                            linkProps={{ to: `/${routeTo}` }}
                        >
                            {pathName}
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};

export default Breadcrumbs;
