export const isImage = (url: string) => {
  return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

// 👇️ true
// console.log(isImage("https://example.com/photo.jpg"));

export const isPDF = (pdf: string) => {
  return /^https?:\/\/.+\.(pdf)$/.test(pdf);
};

// let s = "https:\\something.pdf";

// console.log(s.endsWith(".pdf"));
