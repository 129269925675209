import React, { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import { useNavbar } from "../../../../../context/NavbarContext";
import useMutateRequest from "../../../../../hooks/getMutation.query";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { getPatientAssignedFocusedGroup } from "../../../../../services/patient/PatientAssignedFocusedGroup.hook";
import FocusedGroupsDetails from "./FocusedGroupsDetails";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";
import CollapsibleTableIcon from "../../../../../components/IconAnimations/CollapsibleTableIcon";
import TableErrorComponent from "../../../../../components/Error/TableError";
const AssignedPatientFocusedGroupTable = () => {
    const { selectedFacility } = useNavbar();
    const { pId: id }: any = useSearchParams();
    const [clicked, setClicked]: any = useState("0");

    const handleToggle = (index: any) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    // API to fetch patient health condtions
    const { isLoading, isError, data, error, mutate }: any = useMutateRequest(
        (data: any) => getPatientAssignedFocusedGroup(data)
    );

    let errorMsg = error?.response?.data?.message;

    let focusedGroupData = data?.data?.data?.focusedGroups;

    const getData = () => {
        try {
            let payload = {
                uhid: id,
            };
            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) getData();
    }, [id, selectedFacility]);

    return (
        <div className="card d-flex flex-column">
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="my-2 d-flex align-items-center justify-content-center h-50px">
                    <h1>ASSIGNED FOCUSED GROUPS</h1>
                </div>
            </div>
            {isError ? (
                <TableErrorComponent errorMessage={errorMsg} />
            ) : (
                <Table>
                    <TableHeadRow>
                        <TableHead width={120} align={"center"}>
                            -
                        </TableHead>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>
                        <TableHead width={140}>FOCUSED GROUP NAME</TableHead>
                        <TableHead width={140}>CATEGORY NAME</TableHead>
                        <TableHead width={140}>FOCUSED GROUP TYPE</TableHead>
                        <TableHead width={120} align="center">
                            TOTAL NUMBER OF VISITS
                        </TableHead>
                    </TableHeadRow>
                    {isLoading ? (
                        <tbody
                            style={{
                                height: "400px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                left: "500px",
                                top: "50px",
                            }}
                        >
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </tbody>
                    ) : isError === true ? (
                        <tbody
                            style={{
                                height: "400px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                left: "400px",
                                top: "5px",
                            }}
                        >
                            <h1 className="text-danger">
                                {isError === true
                                    ? errorMsg
                                    : "Something went wrong!!"}
                            </h1>
                        </tbody>
                    ) : Array?.isArray(focusedGroupData) ? (
                        <TableBodyContainer
                            isLoading={isLoading}
                            isError={isError}
                        >
                            {focusedGroupData?.map((item: any, index: any) => {
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            collapsible
                                            onClick={() => handleToggle(index)}
                                            show={clicked === index}
                                            collapsibleBody={
                                                <>
                                                    {clicked === index && (
                                                        <div className="card p-6 d-flex flex-column justify-content-center ms-10 w-100">
                                                            <FocusedGroupsDetails
                                                                data={
                                                                    item?.visitTracking
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        >
                                            <TableCell hover align="center">
                                                <CollapsibleTableIcon
                                                    isClicked={
                                                        clicked === index
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                <span className="text-capitalize">
                                                    {item?.focusedGroupId?.name
                                                        ? item?.focusedGroupId
                                                              ?.name
                                                        : "N/A"}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="text-capitalize">
                                                    {item?.focusedGroupId
                                                        .categoryId
                                                        ? item?.focusedGroupId
                                                              ?.categoryId
                                                              ?.categoryName
                                                        : "N/A"}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        item?.focusedGroupId
                                                            ?.type === "Visit"
                                                            ? "success"
                                                            : "danger"
                                                    } text-center `}
                                                >
                                                    {item?.focusedGroupId?.type
                                                        ? item?.focusedGroupId
                                                              ?.type
                                                        : "N/A"}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span>
                                                    {item?.focusedGroupId
                                                        ?.totalVisits
                                                        ? item?.focusedGroupId
                                                              ?.totalVisits
                                                        : "N/A"}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBodyContainer>
                    ) : (
                        ""
                    )}
                </Table>
            )}
        </div>
    );
};

export default AssignedPatientFocusedGroupTable;
