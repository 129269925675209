import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCard = () => {
  return (
    <SkeletonTheme
      baseColor="#f7f0f0"
      highlightColor="#bdb3b3"
      enableAnimation
      duration={2}
    >
      <div className="card card-xl-stretch mb-xl-3 mb-5 p-5">
        <Skeleton count={1} style={{ height: "20px", marginTop: "10px" }} />
        <div className="d-flex my-10 justify-content-between">
          <Skeleton count={1} style={{ height: "30px", width: "150px" }} />
          <Skeleton count={1} style={{ height: "30px", width: "75px" }} />
        </div>
        <div className="mb-5">
          <Skeleton count={1} style={{ height: "20px" }} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonCard;
