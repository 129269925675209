import { useEffect } from "react";
import { KTSVG } from "../../_metronic/helpers";

type Props = {
  steps: { title: string; subTitle: string }[];
  stepComponent: JSX.Element;
  activeIndex: number;
  setActiveIndex: Function;
  formikProps?: any;
};

export const VerticalLine = () => <div className="stepper-line w-40px" />;

const Stepper = (props: Props) => {
  const { steps, stepComponent, activeIndex, setActiveIndex, formikProps } =
    props;

  useEffect(() => {
    formikProps?.validateForm();
  }, [activeIndex]);

  return (
    <div
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
      id="kt_create_account_stepper"
    >
      <div className="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
        <div className="px-6 px-lg-10 px-xxl-15 py-20">
          <div className="stepper-nav">
            {steps.map((ele, currentIdx: number) => (
              <div
                className={`stepper-item ${
                  currentIdx === activeIndex && "current"
                } ${activeIndex > currentIdx && "completed"}`}
                data-kt-stepper-element="nav"
                key={ele.title}
              >
                <VerticalLine />

                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas fa-check" />
                  <span className="stepper-number ">{currentIdx + 1}</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">{ele?.title}</h3>
                  <div className="stepper-desc fw-bold">{ele?.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* #########################     STEPS BODY       ############################ */}
      <div className="d-flex flex-column flex-center bg-white rounded">
        {/* Switch Case */}
        {stepComponent}

        {/* Button Conditions */}
        <div
          className={`d-flex justify-content-${
            activeIndex > 0 ? "between" : "end"
          } w-100 p-5`}
        >
          {activeIndex > 0 && (
            <button
              className="btn btn-lg btn-primary me-3"
              type="button"
              onClick={() => setActiveIndex((prev: number) => prev - 1)}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr063.svg"
                className="svg-icon-4 me-1"
              />
              Back
            </button>
          )}

          {activeIndex === steps.length - 1 ? (
            <button
              data-testid="submit-btn"
              disabled={!formikProps?.isValid}
              type="button"
              className="btn btn-lg btn-primary me-3"
              onClick={() => formikProps.handleSubmit()}
            >
              Submit
            </button>
          ) : (
            <button
              data-testid="continue-btn"
              disabled={!formikProps?.isValid}
              type="button"
              className="justify-content-right btn btn-lg btn-primary me-3"
              onClick={() => {
                setActiveIndex((prev: number) => prev + 1);
              }}
            >
              Continue
              <KTSVG
                path="/media/icons/duotune/arrows/arr064.svg"
                className="svg-icon-3 ms-2 me-0"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stepper;

// DO NOT REMOVE *** TO BE ADDED WHERE EVER WE NEED STEPPER
// const [activeIndex, setActiveIndex] = useState(0);

// SWITCH CASE TO BE ADDED TO MANAGE STATES OUTSIDE COMPONENT FUNCTION
// const StepsContent = (activeStep: number) => {
//     switch (activeStep) {
//       case 0:
//         return (
//           <div>
//             <h1> Step 1</h1>
//           </div>
//         );

//       case 1:
//         return (
//           <div>
//             <h1> Step 2</h1>
//           </div>
//         );
//       case 2:
//         return (
//           <div>
//             <h1> Step 3</h1>
//           </div>
//         );
//       default:
//         return (
//           <div>
//             <h1>No Step</h1>
//           </div>
//         );
//     }
//   };
