import { useEffect, useRef, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import { ICreateAccount } from "./types/CreateFocusedGroup.types";

import Modal from "../../../components/Modal";
import AlertScreen from "./components/AlertScreen";
import useMutateRequest from "../../../hooks/getMutation.query";
import { createFocusedGroup } from "../../../services/focused-group/FocusedGroup.hook";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../_metronic/helpers";

const inits: ICreateAccount = {
    categoryId: "",
    focusedGroupType: "Visit",
    focusedGroupName: "",
    totalVisits: "",
    vitals: [
        {
            selectedVitals: [],
        },
    ],
};

// const numberRegExp = /^[0-9]*$/;
const createAccountSchemas = [
    // Step 1
    Yup.object({
        focusedGroupType: Yup.string().required(
            "Focused group type is required"
        ),
    }),
    // Step 2
    Yup.object({
        categoryId: Yup.string().required("Focused category name is required"),
        focusedGroupName: Yup.string().required(
            "Focused group name is required"
        ),
        totalVisits: Yup.number()
            .moreThan(0, "Visit number should be greater than 0")
            .integer("Visit number in valid")
            .when("focusedGroupType", {
                is: "Visit",
                then: Yup.number().required("Visit number is required"),
            }),
    }),
];

const CreateFocusedGroupPage: any = () => {
    const navigate = useNavigate();
    // const { id }: any = useLocation();
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
    const [isSubmitButton, setSubmitButton] = useState(false);

    // Toast
    const [open, setOpen] = useState<boolean>(false);
    const [msg, setMsg] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [initValues] = useState<ICreateAccount>(inits);
    const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
    const { isError, error, mutate }: any = useMutateRequest((data: any) =>
        createFocusedGroup(data)
    );

    const errorMsg = error?.response?.data?.message;

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(
            stepperRef.current as HTMLDivElement
        );
    };

    const prevStep = () => {
        if (!stepper.current) {
            return;
        }

        if (stepper.current.currentStepIndex !== 1) {
            setSubmitButton(false);
            stepper.current.goPrev();
            setCurrentSchema(
                createAccountSchemas[stepper.current.currentStepIndex - 1]
            );
        }
    };

    const submitStep = (values: any, actions: FormikValues) => {
        if (!stepper.current) {
            return;
        }
        setSubmitButton(
            stepper.current.currentStepIndex ===
                stepper.current.totatStepsNumber! - 1
        );
        setCurrentSchema(
            createAccountSchemas[stepper.current.currentStepIndex]
        );
        if (
            stepper.current.currentStepIndex !==
            stepper.current.totatStepsNumber
        ) {
            stepper.current.goNext();
        } else {
            stepper.current.goto(1);

            let totalVisits =
                values.totalVisits === "" ? 0 : values.totalVisits;

            try {
                let payload = {
                    categoryId: values.categoryId,
                    name: values.focusedGroupName,
                    type: values.focusedGroupType,
                    totalVisits: Number(totalVisits),
                    vitals: values.vitals,
                };
                mutate(payload, {
                    onSuccess: (data: any) => {
                        setSpinner(true);
                        setOpen(true);
                        setMsg(true);
                        setTimeout(() => {
                            setSpinner(false);
                            setOpen(false);
                            setIsModelOpen(false);
                            navigate(`/focused-group/management`);
                            // redirectTo.push(`/focused-group/${id}/forms`);
                        }, 1500);
                    },
                    onError: (error: any) => {
                        setSpinner(true);
                        setOpen(true);
                        setMsg(false);
                        setIsModelOpen(false);
                        setTimeout(() => {
                            setSpinner(false);
                            setOpen(false);
                            setIsModelOpen(false);
                            navigate("/focused-group/create-focused-group");
                        }, 1500);
                    },
                });
            } catch (error) {
                setSpinner(true);
                setOpen(true);
                setMsg(false);
                setIsModelOpen(false);
                setTimeout(() => {
                    setSpinner(false);
                    actions.resetForm();
                    setIsModelOpen(false);
                    // redirectTo.push("/focused-group/create-focused-group");
                }, 3000);
            }
        }
    };

    useEffect(() => {
        if (!stepperRef.current) {
            return;
        }
        loadStepper();
    }, [stepperRef]);

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Successfully Created Focused Group !!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {errorMsg}
                    {/* Failed to Create Focused Group !! */}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };
    const handleModal = () => {
        setIsModelOpen(!isModelOpen);
    };

    return (
        <div
            ref={stepperRef}
            className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_create_account_stepper"
        >
            <>
                <Toaster />
                {open && msg === true && successToastMsg()}
                {open && isError === true && errorToastMsg()}
                <div className="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
                    <div className="px-6 px-lg-10 px-xxl-15 py-20">
                        <div className="stepper-nav">
                            <div
                                className="stepper-item current"
                                data-kt-stepper-element="nav"
                            >
                                <div className="stepper-line w-40px"></div>

                                <div className="stepper-icon w-40px h-40px">
                                    <i className="stepper-check fas fa-check"></i>
                                    <span className="stepper-number">1</span>
                                </div>

                                <div className="stepper-label">
                                    <h3 className="stepper-title">
                                        Focused Group Type
                                    </h3>
                                    <div className="stepper-desc fw-bold">
                                        Details of focused group type
                                    </div>
                                </div>
                            </div>

                            <div
                                className="stepper-item"
                                data-kt-stepper-element="nav"
                            >
                                <div className="stepper-line w-40px"></div>

                                <div className="stepper-icon w-40px h-40px">
                                    <i className="stepper-check fas fa-check"></i>
                                    <span className="stepper-number">2</span>
                                </div>

                                <div className="stepper-label">
                                    <h3 className="stepper-title">
                                        Focused Group
                                    </h3>
                                    <div className="stepper-desc fw-bold">
                                        Provide details of focused group
                                    </div>
                                </div>
                            </div>

                            <>
                                <div
                                    className="stepper-item"
                                    data-kt-stepper-element="nav"
                                >
                                    <div className="stepper-line w-40px"></div>
                                    <div className="stepper-icon w-40px h-40px">
                                        <i className="stepper-check fas fa-check"></i>
                                        <span className="stepper-number">
                                            3
                                        </span>
                                    </div>
                                    <div className="stepper-label">
                                        <h3 className="stepper-title">
                                            Vitals Details
                                        </h3>
                                        <div className="stepper-desc fw-bold">
                                            Provide list of vitals available
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid flex-center bg-white rounded">
                    {/* Spinner */}
                    {spinner === true ? (
                        <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        // Form
                        <Formik
                            validationSchema={currentSchema}
                            initialValues={initValues}
                            onSubmit={submitStep}
                            enableReinitialize={true}
                        >
                            {(formikProps) => {
                                return (
                                    <>
                                        <Form
                                            className="py-20 w-100 w-xl-700px px-9"
                                            id="kt_create_account_form"
                                        >
                                            <div
                                                className="current"
                                                data-kt-stepper-element="content"
                                            >
                                                <Step1 />
                                            </div>

                                            <div data-kt-stepper-element="content">
                                                <Step2 {...formikProps} />
                                            </div>

                                            <div data-kt-stepper-element="content">
                                                <Step3 {...formikProps} />
                                            </div>

                                            <div className="d-flex flex-stack pt-10">
                                                <div className="mr-2">
                                                    <button
                                                        onClick={prevStep}
                                                        type="button"
                                                        className="btn btn-lg btn-light-primary me-3"
                                                        data-kt-stepper-action="previous"
                                                    >
                                                        <KTSVG
                                                            path="/media/icons/duotune/arrows/arr063.svg"
                                                            className="svg-icon-4 me-1"
                                                        />
                                                        Back
                                                    </button>
                                                </div>

                                                <div>
                                                    <button
                                                        onClick={(e) => {
                                                            if (
                                                                isSubmitButton ===
                                                                true
                                                            ) {
                                                                e.preventDefault();
                                                                isSubmitButton &&
                                                                    setIsModelOpen(
                                                                        true
                                                                    );
                                                            }
                                                        }}
                                                        // disabled={!formikProps.isValid}
                                                        className="btn btn-lg btn-primary me-3"
                                                    >
                                                        <span className="indicator-label">
                                                            {!isSubmitButton
                                                                ? "Continue"
                                                                : "Confirm"}

                                                            <KTSVG
                                                                path="/media/icons/duotune/arrows/arr064.svg"
                                                                className="svg-icon-3 ms-2 me-0"
                                                            />
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <Modal open={isModelOpen}>
                                                <AlertScreen
                                                    onClose={handleModal}
                                                    // handleSubmit={handleFormProps}
                                                    handleSubmit={
                                                        formikProps.handleSubmit
                                                    }
                                                    styles={{
                                                        backgroundColor:
                                                            "white",
                                                        width: "650px",
                                                        height: "200px",
                                                        padding: "20px",
                                                    }}
                                                />
                                            </Modal>
                                        </Form>
                                    </>
                                );
                            }}
                        </Formik>
                    )}
                </div>
            </>
        </div>
    );
};

export default CreateFocusedGroupPage;
