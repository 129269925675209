import ConditonsTable from "./table-view/ConditonsTable";

const HealthConditionsPage = () => {
  return (
    <div>
      <ConditonsTable />
    </div>
  );
};

export default HealthConditionsPage;
