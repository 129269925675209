import { Link } from "react-router-dom";

import "../components/styles/TaskDetails.css";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import CustomTooltip from "../../../components/CustomTooltip";

const PatientTaskPage = ({ data }: any) => {
    return (
        <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div className="me-7 mb-4">
                        <div className="symbol symbol-lg-150px symbol-fixed position-relative">
                            <img
                                src={
                                    data?.patientData?.demographicDetails?.photo
                                        ? toAbsoluteUrl(
                                              data?.patientData
                                                  ?.demographicDetails?.photo
                                          )
                                        : toAbsoluteUrl(
                                              "/media/ikure/blank.png"
                                          )
                                }
                                alt="Avatar"
                                className="mt-2"
                            />
                            {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
                        </div>
                    </div>

                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <Link
                                        to={`/patient/overview?pId=${data?.patient?.uhid}`}
                                    >
                                        <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1 ms-5">
                                            {data?.patientData?.name}
                                        </span>
                                    </Link>
                                </div>
                                <div className="mt-5 d-flex flex-column mb-width">
                                    <div className="d-flex justify-content-between flex-wrap fw-bold fs-5 mt-3 mb-4 pe-2">
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-70px">
                                                Facility :
                                            </span>
                                            <span className="text-gray-800">
                                                {localStorage.getItem(
                                                    "facilityName"
                                                )
                                                    ? localStorage.getItem(
                                                          "facilityName"
                                                      )
                                                    : "All"}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-70px">
                                                Gender :
                                            </span>
                                            <span className="text-gray-800">
                                                {
                                                    data?.patientData
                                                        ?.demographicDetails
                                                        ?.sex
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-70px">
                                                Status :
                                            </span>
                                            <span
                                                className={`badge badge-${
                                                    data?.taskStatus === "Done"
                                                        ? "success"
                                                        : data?.taskStatus ===
                                                          "Cancelled"
                                                        ? "danger"
                                                        : data?.taskStatus ===
                                                          "In Progress"
                                                        ? "info"
                                                        : data?.taskStatus ===
                                                          "To Do"
                                                        ? "primary"
                                                        : null
                                                } text-hover-white`}
                                            >
                                                {data?.taskStatus}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap fw-bold fs-5 mt-3 mb-4 pe-2">
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-100px">
                                                CHW Name :
                                            </span>
                                            <CustomTooltip
                                                title={data?.chwData?.chwId}
                                                placement="top"
                                            >
                                                <span className="text-gray-800">
                                                    {data?.chwData?.name}
                                                </span>
                                            </CustomTooltip>
                                        </div>
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-50x">
                                                Age :
                                            </span>
                                            <span className="text-gray-800">
                                                {data?.patientData
                                                    ?.demographicDetails?.age ||
                                                    data?.patientData
                                                        ?.demographicDetails
                                                        ?.birthDate ||
                                                    "N/A"}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2">
                                            <span className="me-5 w-50px">
                                                Date :
                                            </span>
                                            <span className="text-gray-800">
                                                {" "}
                                                {data?.taskDate + " "}{" "}
                                                {data?.taskTime
                                                    ? data?.taskTime
                                                    : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PatientTaskPage };
