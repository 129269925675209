import * as Yup from "yup";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import toast, { Toaster } from "react-hot-toast";

import Modal from "../../../components/Modal";

import AppointmentListExport from "../features/exports/AppointmentListExport";
import CustomTooltip from "../../../components/CustomTooltip";

import { toAbsoluteUrl } from "../../../_metronic/helpers";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import TableErrorComponent from "../../../components/Error/TableError";
import {
    dateFormatter,
    stringTruncate,
    todayDate,
} from "../../../common/utils";
import { Permissions, hasAccessToModule } from "../../../rbac";
import useGetRequest from "../../../hooks/getRequest.query";
import { getDoctorSpecializations } from "../../../services/patient/PatientAppointments.hook";
import FilterTile from "../../../components/filter-tile";

const filterSchema = Yup.object().shape({
    fromDate: Yup.date().required("From date is required"),
    toDate: Yup.date()
        .required("To date is required")
        .when("fromDate", (fromDate, schema) => {
            return fromDate
                ? schema.required(
                      "To date is required when From date is specified"
                  )
                : schema.nullable();
        })
        .min(Yup.ref("fromDate"), "To date must be greater than From date")
        .max(new Date(), "Date must not be more than the current date"),
    status: Yup.string().label("Status"),
});

const AppointmentListTable = ({
    setCurrentPageNumber,
    currentPageNumber,
    filterData,
    setFilterData,
    patientConsultationDetails,
    doctorDetails,
    chwData,
    errorMsg,
    isLoading,
    pageLimit,
    setPageLimit,
    totalPatientConsultationPages,
    isPatientConsultationError,
    totalPatientConsultationEntites,
}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // Begins Menu Here
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                fromDate: values.fromDate,
                toDate: values.toDate,
                status: values.status,
                chwName: values.chwName,
                doctorName: values.doctorName,
                consultationType: values.consultationType,
                specialization: values.specialization,
            };

            setFilterData(payload);
            handleClose();
        } catch (error) {
            handleClose();
            console.log(error);
            onSubmitProps.resetForm();
        }
    };

    const clearData = () => {
        const payload = {
            fromDate: "",
            toDate: "",
            status: "",
            chwName: "",
            doctorName: "",
            consultationType: "",
            specialization: "",
        };
        setFilterData(payload);
    };

    const downloadPresciption = () =>
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Presciption Downloaded
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
            }
        );

    // ROLE SETTINGS
    const canExport = hasAccessToModule(
        "LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT",
        Permissions.export_button
    );

    const canDownload = hasAccessToModule(
        "LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT",
        Permissions.export_button
    );

    const { data }: any = useGetRequest(
        ["getDoctorSpecializations"],
        async () => await getDoctorSpecializations(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const specializationData = useMemo(
        () => data?.data?.data?.specializations,
        [data]
    );
    return (
        <div className="card text-dark">
            <Toaster />
            <Modal
                open={isOpen}
                styles={{
                    width: "650px",
                    backgroundColor: "white",
                }}
            >
                <AppointmentListExport onClose={handleModal} />
            </Modal>
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>APPOINTMENTS LIST</h1>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>

                <div className="card-toolbar">
                    <button
                        hidden={!canExport}
                        onClick={handleModal}
                        className="btn btn-sm btn-light-primary me-5"
                    >
                        <i className="fas fa-download"></i>
                        EXPORT
                    </button>
                    <button
                        onClick={handleClick}
                        className="btn btn-sm btn-primary"
                    >
                        <i className="fas fa-filter"></i>
                        FILTER
                    </button>

                    {/* Filter Options */}
                    <div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <Formik
                                initialValues={filterData}
                                validationSchema={filterSchema}
                                onSubmit={onFilterSubmit}
                                enableReinitialize
                            >
                                {(formProps: any) => (
                                    <Form>
                                        <div className="py-5 px-8">
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    CHW NAME
                                                </label>

                                                <Field
                                                    as="select"
                                                    name="chwName"
                                                    className="form-select form-select-lg form-select-solid"
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {chwData?.map(
                                                        (chw: any) => {
                                                            return (
                                                                <>
                                                                    <option
                                                                        key={
                                                                            chw?.name
                                                                        }
                                                                        value={
                                                                            chw?.name
                                                                        }
                                                                    >
                                                                        {
                                                                            chw?.name
                                                                        }
                                                                    </option>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </Field>
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="chwName" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    DOCTOR NAME
                                                </label>

                                                <Field
                                                    as="select"
                                                    name="doctorName"
                                                    className="form-select form-select-lg form-select-solid"
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {doctorDetails?.map(
                                                        (
                                                            doctorDetail: any,
                                                            index: any
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <option
                                                                        key={
                                                                            doctorDetail?.name
                                                                        }
                                                                        value={
                                                                            doctorDetail?.name
                                                                        }
                                                                    >
                                                                        {
                                                                            doctorDetail?.name
                                                                        }
                                                                    </option>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </Field>
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="doctorName" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label required fs-4 fw-bolder">
                                                    FROM DATE
                                                </label>
                                                <Field
                                                    id="fromDate"
                                                    as="input"
                                                    type="date"
                                                    name="fromDate"
                                                    max={todayDate}
                                                    className="form-control cursor-pointer"
                                                />
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="fromDate" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5">
                                                <label className="form-label required fs-4 fw-bolder">
                                                    TO DATE
                                                </label>
                                                <Field
                                                    id="toDate"
                                                    type="date"
                                                    name="toDate"
                                                    max={todayDate}
                                                    className="form-control"
                                                />
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="toDate" />
                                                </div>
                                            </div>

                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    CONSULTATION TYPE
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="consultationType"
                                                    className="form-select form-select-lg form-select-solid"
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    <option value="physical">
                                                        Physical consultation
                                                    </option>
                                                    <option value="online">
                                                        Teleconsultation
                                                    </option>
                                                </Field>
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="consultationType" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    SPECIALIZATION
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="specialization"
                                                    className="form-select form-select-lg form-select-solid"
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {specializationData?.map(
                                                        (
                                                            item: any,
                                                            idx: number
                                                        ) => (
                                                            <option
                                                                value={item}
                                                                key={idx}
                                                            >
                                                                {item}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="specialization" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    STATUS
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    className="form-select form-select-lg form-select-solid"
                                                >
                                                    <option value="All">
                                                        All
                                                    </option>
                                                    <option value="Scheduled">
                                                        Scheduled
                                                    </option>
                                                    <option value="Done">
                                                        Done
                                                    </option>
                                                    <option value="InProgress">
                                                        In Progress
                                                    </option>
                                                    <option value="Cancelled">
                                                        Cancelled
                                                    </option>
                                                    <option value="Rescheduled">
                                                        Rescheduled
                                                    </option>
                                                    <option value="Payment Pending">
                                                        Payment Pending
                                                    </option>
                                                </Field>
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="status" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-5 d-flex justify-content-center align-items-center">
                                            <button
                                                type="submit"
                                                disabled={
                                                    !formProps.isValid ||
                                                    formProps.isSubmitting
                                                }
                                                className="w-250px btn btn-sm btn-primary"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Menu>
                    </div>
                    {/* End Here */}
                </div>
            </div>

            <FilterTile
                filterData={filterData}
                keyNames={{
                    fromDate: "From date",
                    toDate: "To date",
                    doctorName: "Doctor Name",
                    chwName: "CHW Name",
                    consultationType: "Consultation Type",
                    specialization: "Specialization",
                    status: "Status",
                }}
                clearAll={() =>
                    setFilterData({
                        fromDate: "",
                        toDate: "",
                        doctorName: "",
                        chwName: "",
                        consultationType: "",
                        specialization: "",
                        status: "",
                    })
                }
            />
            {isPatientConsultationError ? (
                <TableErrorComponent
                    errorMessage={errorMsg || "No data available"}
                />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={50} align="center">
                                SL NO
                            </TableHead>
                            <TableHead width={70} align="center">
                                DOCTOR NAME
                            </TableHead>
                            <TableHead width={70} align="center">
                                PATIENT NAME
                            </TableHead>
                            <TableHead width={70} align="center">
                                CONSULTATION TYPE
                            </TableHead>
                            <TableHead width={70} align="center">
                                STATUS
                            </TableHead>
                            <TableHead width={70} align="center">
                                SYMPTOMS
                            </TableHead>
                            <TableHead width={70} align="center">
                                SPECIALIZATION
                            </TableHead>
                            <TableHead width={100} align="center">
                                DATE
                            </TableHead>
                            <TableHead width={70} align="center">
                                TIME SLOTS / TOKEN NO
                            </TableHead>
                            <TableHead width={70} align="center">
                                SCHEDULED BY
                            </TableHead>
                            <TableHead width={70} align="center">
                                PRESCRIPTION
                            </TableHead>
                        </TableHeadRow>

                        {Array?.isArray(patientConsultationDetails) ===
                            true && (
                            <TableBodyContainer
                                isLoading={isLoading}
                                isError={isPatientConsultationError}
                                errorMessage={errorMsg}
                            >
                                {patientConsultationDetails?.map(
                                    (item: any, index: any) => {
                                        let symptoms = stringTruncate(
                                            item?.symptoms,
                                            5
                                        );
                                        return (
                                            <TableRow collapsible={false}>
                                                <TableCell align="center">
                                                    {currentPageNumber &&
                                                        (currentPageNumber -
                                                            1) *
                                                            pageLimit +
                                                            index +
                                                            1}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.doctorName?.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={`/patient/overview?pId=${item?.patient?.uhid}`}
                                                        className="text-dark text-dark fw-bolder text-hover-primary"
                                                    >
                                                        {item?.patient?.name}
                                                    </Link>
                                                </TableCell>
                                                <CustomTooltip
                                                    sx={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                    title={
                                                        item?.consultationType
                                                    }
                                                    placement="top"
                                                >
                                                    <TableCell align="center">
                                                        <img
                                                            width="30px"
                                                            height="30px"
                                                            style={{
                                                                objectFit:
                                                                    "cover",
                                                                borderRadius:
                                                                    "50%",
                                                            }}
                                                            src={
                                                                item?.consultationType ===
                                                                "physical consultation"
                                                                    ? toAbsoluteUrl(
                                                                          "/media/ikure/patient/physical.jpeg"
                                                                      )
                                                                    : toAbsoluteUrl(
                                                                          "/media/ikure/patient/online.jpeg"
                                                                      )
                                                            }
                                                            alt={
                                                                item?.consultationType
                                                            }
                                                        />
                                                    </TableCell>
                                                </CustomTooltip>
                                                <TableCell>
                                                    <span
                                                        className={`badge badge-light-${
                                                            item?.consultationStatus ===
                                                            "Scheduled"
                                                                ? "primary"
                                                                : item?.consultationStatus ===
                                                                  "Cancelled"
                                                                ? "danger"
                                                                : item?.consultationStatus ===
                                                                  "In Progress"
                                                                ? "info"
                                                                : item?.consultationStatus ===
                                                                  "Rescheduled"
                                                                ? "secondary"
                                                                : item?.consultationStatus ===
                                                                  "Done"
                                                                ? "success"
                                                                : item?.consultationStatus ===
                                                                  "Payment Pending"
                                                                ? "dark"
                                                                : null
                                                        } text-center `}
                                                    >
                                                        {
                                                            item?.consultationStatus
                                                        }
                                                    </span>
                                                </TableCell>
                                                <CustomTooltip
                                                    sx={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                    title={item?.symptoms}
                                                    placement="top"
                                                >
                                                    <TableCell>
                                                        {symptoms}
                                                    </TableCell>
                                                </CustomTooltip>
                                                <TableCell>
                                                    {item?.specialization}
                                                </TableCell>
                                                <TableCell>
                                                    {dateFormatter(
                                                        item?.taskDate
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.consultationType ===
                                                    "physical consultation"
                                                        ? item?.token
                                                        : item?.taskTime}
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        style={{
                                                            color: "black",
                                                            cursor: "pointer",
                                                        }}
                                                        to={`/chw/overview?chwId=${item?.consultationScheduledBy?.schedulerId}`}
                                                    >
                                                        <span className="text-hover-primary">
                                                            {
                                                                item
                                                                    ?.consultationScheduledBy
                                                                    ?.schedulerName
                                                            }
                                                        </span>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.consultationStatus ===
                                                    "Done" ? (
                                                        <>
                                                            <a
                                                                hidden={
                                                                    !canDownload
                                                                }
                                                                href={
                                                                    item
                                                                        ?.prescription
                                                                        ?.prescriptionStatus
                                                                        ? item
                                                                              ?.prescription
                                                                              ?.prescriptionFile
                                                                        : item
                                                                              ?.prescription
                                                                              ?.glassPrescriptionFile
                                                                }
                                                                target="_blank"
                                                            >
                                                                <i
                                                                    onClick={
                                                                        downloadPresciption
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                                                ></i>
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBodyContainer>
                        )}
                    </Table>

                    {/* Pagination */}

                    {!isPatientConsultationError && (
                        <Pagination
                            dataCount={totalPatientConsultationEntites}
                            handleCallback={(val: number | string) => {
                                setPageLimit(+val);
                            }}
                            handleNext={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={
                                currentPageNumber ===
                                totalPatientConsultationPages
                            }
                            pageNumber={currentPageNumber}
                            pageLimit={pageLimit}
                            prevDisabled={currentPageNumber === 1}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AppointmentListTable;
