import { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";

import FocusedGroupHeader from "./components/FocusedGroupHeader";

import FormsTable from "./table/FormsTable";
import EnableVitals from "./components/EnableVitals";

import BeneficiaryListPage from "./beneficiary-management/BeneficiaryListPage";
import TabContainer from "../../../components/Tabs/Tabs";
import Tab from "../../../components/Tabs/Tab";
import useGetRequest from "../../../hooks/getRequest.query";
import useSearchParams from "../../../hooks/useSearchParams";
import { getFocusedGroupById } from "../../../services/focused-group/FocusedGroup.hook";

const FocusedGroupOverview = () => {
    const { fgId: id, tab }: any = useSearchParams();
    const [tabInitials, setTabInitials] = useState("forms");

    useEffect(() => {
        if (tab) {
            setTabInitials(tab);
        }
    }, [tab]);

    const { data, isLoading, refetch }: any = useGetRequest(
        ["getFocusedGroupById", id],
        async () =>
            await getFocusedGroupById({
                focusedGroupId: id,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const fgHeader = data?.data?.data?.focusedGroup;
    const totalVisits = fgHeader?.totalVisits;

    useEffect(() => {
        refetch();
    }, [totalVisits]);

    return (
        <>
            {isLoading ? (
                <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <>
                    <div className="#">
                        <FocusedGroupHeader fgHeader={fgHeader} />
                    </div>
                    {/* <Suspense fallback={<FallbackView />}>
            <Switch>
              <Route path="/focused-group/:id/vitals">
                <EnableVitals totalVisits={totalVisits} />
              </Route>

              <Route path="/focused-group/:id/forms" exact={true}>
                <FormsTable formData={fgHeader} />
              </Route>

              <Route
                path="/focused-group/:id/form-entries"
                exact={true}
                // component={PatientEntries}
                component={VisitFormPage}
              />

              <Route path="/focused-group/:id/beneficiary-list" exact={true}>
                <BeneficiaryListPage
                  totalVisits={totalVisits}
                  focusedGroupName={fgHeader?.name}
                />
              </Route>

              <Route
                path="/focused-group/:id/entry-details"
                exact={true}
                component={EntriesForm}
              />

              <Route
                path="/focused-group/:id/vital-details"
                exact={true}
                component={PatientVitals}
              />

           

              <Redirect to="error/404" />
            </Switch>
          </Suspense> */}

                    <TabContainer initialValue={tabInitials}>
                        <Tab eventKey="forms" title="FORMS">
                            <FormsTable formData={fgHeader} />
                        </Tab>
                        <Tab eventKey="vitals" title="VITALS">
                            <EnableVitals totalVisits={totalVisits} />
                        </Tab>
                        <Tab eventKey="beneficiary" title="BENEFICIARY LIST">
                            <BeneficiaryListPage
                                totalVisits={totalVisits}
                                focusedGroupName={fgHeader?.name}
                            />
                        </Tab>
                    </TabContainer>

                    {/* <Route
            path="/focused-group/:id/form-entries"
            exact={true}
            // component={PatientEntries}
            component={VisitFormPage}
          />

          <Route path="/focused-group/:id/beneficiary-list" exact={true}>
           
          </Route>

          <Route
            path="/focused-group/:id/entry-details"
            exact={true}
            component={EntriesForm}
          />

          <Route
            path="/focused-group/:id/vital-details"
            exact={true}
            component={PatientVitals}
          />

          <Redirect
            from="/focused-group/:id"
            exact
            to="/focused-group/:id/forms"
          /> */}
                </>
            )}
        </>
    );
};

export default FocusedGroupOverview;
