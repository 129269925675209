import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import "./tabs.css";
type Iprops = {
  children: React.ReactNode;
  initialValue: string;
};

function TabContainer(props: Iprops) {
  const { children, initialValue } = props;
  const [key, setKey] = useState<any>("");

  useEffect(() => {
    if (initialValue) {
      setKey(initialValue);
    }
  }, [initialValue]);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-xl-10 bg-white"
    >
      {children}
    </Tabs>
  );
}

export default TabContainer;
