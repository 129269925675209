import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {
    beneficiaryExportValidationSchema,
    beneficiaryInitalValues,
} from "../models/BeneficiaryExportModel";
import useMutateRequest from "../../../../../hooks/getMutation.query";
import useGetRequest from "../../../../../hooks/getRequest.query";
import { getAllFacilites } from "../../../../../services/facility/Facility.hook";
import { generateReport } from "../../../../../services/report/ReportGeneration.hook";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { todayDate } from "../../../../../common/utils";

type ExportProps = {
    onClose: () => void;
    styles?: any;
    visitArray?: any;
    focusedGroupName?: any;
};

const BeneficiaryExport = ({
    onClose,
    styles,
    visitArray,
    focusedGroupName,
}: ExportProps) => {
    const { fgId: id }: any = useSearchParams();
    const navigate = useNavigate();
    const [msg, setMsg] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    // API Call
    const { mutate, isLoading, isError, isSuccess } = useMutateRequest(
        (data: any) => generateReport(data)
    );

    const { data, refetch } = useGetRequest(
        "getAllFacilites-beneficiary",
        async () => await getAllFacilites(),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    let allFacilites = data?.data?.data?.facility;

    useEffect(() => {
        if (isSuccess === true) {
            setSpinner(true);
            setTimeout(() => {
                onClose();
                setSpinner(false);
                navigate("/report/report-management");
            }, 3500);
        }
    }, [isSuccess]);

    let formatedVisits = visitArray?.map((visit: any) => {
        return {
            visitName: `Visit ${visit}`,
            visitValue: visit,
        };
    });

    const onSubmit = (values: any) => {
        let facilityCodeSelected = [
            {
                filterName: "focusedGroupId",
                filterValue: id,
            },
            {
                filterName: "facilityCode",
                filterValue: values?.facilityCode,
            },
            {
                filterName: "startDate",
                filterValue: values?.fromDate,
            },
            {
                filterName: "endDate",
                filterValue: values?.toDate,
            },
        ];
        let visitNumberSelected = [
            {
                filterName: "visitNumber",
                filterValue: Number(values?.visitNumber),
            },
            {
                filterName: "focusedGroupId",
                filterValue: id,
            },
            {
                filterName: "startDate",
                filterValue: values?.fromDate,
            },
            {
                filterName: "endDate",
                filterValue: values?.toDate,
            },
        ];
        let bothAreSelected = [
            {
                filterName: "visitNumber",
                filterValue: Number(values?.visitNumber),
            },
            {
                filterName: "focusedGroupId",
                filterValue: id,
            },
            {
                filterName: "facilityCode",
                filterValue: values?.facilityCode,
            },
            {
                filterName: "startDate",
                filterValue: values?.fromDate,
            },
            {
                filterName: "endDate",
                filterValue: values?.toDate,
            },
        ];
        let bothAreAll = [
            {
                filterName: "focusedGroupId",
                filterValue: id,
            },
            {
                filterName: "startDate",
                filterValue: values?.fromDate,
            },
            {
                filterName: "endDate",
                filterValue: values?.toDate,
            },
        ];

        let payload: any = {
            reportType: "exportFocusedGroupDataReport",
            reportRepository: "vitals",
            filter:
                values?.facilityCode === "" && values?.visitNumber === ""
                    ? bothAreAll
                    : values?.facilityCode !== "" && values?.visitNumber === ""
                    ? facilityCodeSelected
                    : values?.visitNumber !== "" && values?.facilityCode === ""
                    ? visitNumberSelected
                    : bothAreSelected,
        };
        try {
            mutate(payload, {
                onSuccess: (data: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 1500);
                },
                onError: (error: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 1500);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isLoading || spinner ? (
                <div
                    style={{ backgroundColor: "transparent" }}
                    className="h-225px d-flex flex-column justify-content-center align-items-center"
                >
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : msg ? (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.msgHeight,
                        padding: styles?.padding,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    {isError === true ? (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Failed to submit report on beneficiary list
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={() => setMsg(false)}
                                    className="w-150px btn btn-lg btn-primary me-5"
                                >
                                    Please Retry
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Your request has been successfully submitted
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        // height: styles?.height,
                        // padding: styles?.padding,
                    }}
                >
                    <>
                        <h1 className="text-center text-hover-primary">
                            EXPORT OPTIONS
                        </h1>
                    </>
                    <Formik
                        initialValues={beneficiaryInitalValues}
                        validationSchema={beneficiaryExportValidationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {(formProps: any) => (
                            <Form>
                                <div
                                    style={{
                                        height: "450px",
                                        overflow: "hidden",
                                        overflowY: "scroll",
                                        scrollbarWidth:
                                            "-moz-initial" /* Firefox */,
                                    }}
                                >
                                    <div className="mt-5">
                                        <label
                                            htmlFor="exportFormat"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            EXPORT FORMAT
                                        </label>
                                        <Field
                                            id="exportFormat"
                                            as="select"
                                            name="exportFormat"
                                            className="form-select form-select-light form-select-lg"
                                            disabled
                                            style={{ cursor: "not-allowed" }}
                                        >
                                            <option value="CSV">CSV</option>
                                            <option value="EXCEL">EXCEL</option>
                                            <option value="PDF">PDF</option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="exportFormat" />
                                        </div>
                                        <div className="text-muted fw-bolder mt-2 ms-2">
                                            By default all the focused group
                                            data would be exported in CSV
                                            format.
                                        </div>
                                    </div>

                                    <div className="mt-5">
                                        <label
                                            htmlFor="fgName"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            FOCUSED GROUP NAME
                                        </label>
                                        <Field
                                            id="fgName"
                                            as="select"
                                            name="fgName"
                                            className="form-select form-select-light form-select-lg"
                                            disabled
                                            style={{ cursor: "not-allowed" }}
                                        >
                                            <option value="">
                                                {focusedGroupName}
                                            </option>
                                        </Field>
                                    </div>
                                    <div className="mt-5">
                                        <label
                                            htmlFor="cardStatus"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            FACILITY NAME
                                        </label>
                                        <Field
                                            id="facilityCode"
                                            as="select"
                                            name="facilityCode"
                                            className="form-select form-select-light form-select-lg required"
                                        >
                                            <option value="">All</option>
                                            {allFacilites
                                                ?.filter((facilities: any) => {
                                                    return (
                                                        facilities?.status !==
                                                        false
                                                    );
                                                })
                                                .map((facility: any) => {
                                                    const facilityName =
                                                        facility?.facilityFirstName +
                                                        " " +
                                                        facility?.facilityLastName;
                                                    return (
                                                        <>
                                                            <option
                                                                value={
                                                                    facility?.facilityCode
                                                                }
                                                            >
                                                                {facilityName}
                                                            </option>
                                                        </>
                                                    );
                                                })}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="visitNumber" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <label
                                            htmlFor="cardStatus"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            VISIT NUMBER
                                        </label>
                                        <Field
                                            id="visitNumber"
                                            as="select"
                                            name="visitNumber"
                                            className="form-select form-select-light form-select-lg required"
                                        >
                                            {/* <option value="">Select the visit number</option> */}
                                            <option value="">All</option>
                                            {formatedVisits?.map(
                                                (formatedVisit: any) => {
                                                    return (
                                                        <>
                                                            <option
                                                                value={
                                                                    formatedVisit?.visitValue
                                                                }
                                                            >
                                                                {
                                                                    formatedVisit?.visitName
                                                                }
                                                            </option>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="visitNumber" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            FROM DATE
                                        </label>
                                        <Field
                                            id="fromDate"
                                            as="input"
                                            type="date"
                                            name="fromDate"
                                            max={todayDate}
                                            className="form-control cursor-pointer"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="fromDate" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            TO DATE
                                        </label>
                                        <Field
                                            id="toDate"
                                            type="date"
                                            name="toDate"
                                            max={todayDate}
                                            className="form-control"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="toDate" />
                                        </div>
                                    </div>
                                    <div className="mt-5 h-20px d-flex justify-content-center align-items-center">
                                        <button
                                            onClick={onClose}
                                            className="btn btn-md btn-danger me-5"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={
                                                !formProps.isValid ||
                                                formProps.isSubmitting
                                            }
                                            className="w-150px btn btn-md btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default BeneficiaryExport;
