import { Link } from "react-router-dom";

import CustomTooltip from "../../../components/CustomTooltip";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";

const DoctorTable = ({
    bodyProps,
    isLoading,
    isError,
    currentPageNumber,
    setCurrentPageNumber,
    totalDoctorEntites,
    totalAvailablePages,
    pageLimit,
    setPageLimit,
    refetch,
}: any) => {
    const clearData = () => {
        refetch();
    };

    let allDoctors = bodyProps;
    const tableNames = [
        "PHOTO",
        "NAME",
        "SPECIALIZATION",
        "PHONE NO",
        "GENDER",
        "EMAIL ID",
        "QUALIFICATION",
        "ACTION",
    ];

    return (
        <div className="card text-dark">
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>DOCTORS LIST</h1>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                <div className="card-toolbar"></div>
            </div>

            <div className="card-body py-3">
                <Table>
                    <TableHeadRow>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>
                        {tableNames?.map((name) => {
                            return (
                                <TableHead width={140} key={name} align="left">
                                    {name}
                                </TableHead>
                            );
                        })}
                    </TableHeadRow>

                    <TableBodyContainer isLoading={isLoading} isError={isError}>
                        {allDoctors?.map((item: any, index: any) => {
                            let numberOfSpecialization =
                                item?.specialization?.length;
                            const firstSpecialization =
                                item?.specialization[0]?.specializationName;

                            const listOfSpecialization =
                                item?.specialization?.map(
                                    (specialization: any) => {
                                        return specialization?.specializationName;
                                    }
                                );
                            return (
                                <TableRow collapsible={false} key={index}>
                                    <TableCell align="center">
                                        {currentPageNumber &&
                                            (currentPageNumber - 1) *
                                                pageLimit +
                                                index +
                                                1}
                                    </TableCell>
                                    <TableCell>
                                        <img
                                            src={
                                                item?.demographicDetails?.photo
                                                    ? item?.demographicDetails
                                                          ?.photo
                                                    : "N/A"
                                            }
                                            alt={
                                                item?.demographicDetails?.photo
                                            }
                                            width="40px"
                                            height="40px"
                                            style={{ borderRadius: "5px" }}
                                        />
                                    </TableCell>
                                    <TableCell hover>
                                        <CustomTooltip
                                            title={"click to view details page"}
                                            placement="top"
                                            // arrow
                                        >
                                            <Link
                                                className="text-dark text-dark fw-bolder text-hover-primary"
                                                to={`/doctor/overview?dId=${item?._id}`}
                                            >
                                                {item?.name}
                                            </Link>
                                        </CustomTooltip>
                                    </TableCell>
                                    <TableCell hover>
                                        <CustomTooltip
                                            title={listOfSpecialization}
                                            placement="top"
                                            // arrow
                                        >
                                            <span>
                                                {item?.specialization
                                                    ? numberOfSpecialization > 1
                                                        ? `${firstSpecialization} ...`
                                                        : firstSpecialization
                                                    : "N/A"}
                                            </span>
                                        </CustomTooltip>
                                    </TableCell>
                                    <TableCell>
                                        {item?.demographicDetails?.telecom
                                            ? item?.demographicDetails?.telecom
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {item?.demographicDetails?.sex
                                            ? item?.demographicDetails?.sex
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {item?.demographicDetails?.email
                                            ? item?.demographicDetails?.email
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {item?.qualification
                                            ? item?.qualification
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/doctor/overview?dId=${item?._id}`}
                                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                        >
                                            DETAILS
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBodyContainer>
                </Table>

                {/* Pagination */}
                <Pagination
                    dataCount={totalDoctorEntites}
                    handleCallback={(val) => setPageLimit(+val)}
                    handleNext={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber + 1
                        )
                    }
                    handlePrev={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber - 1
                        )
                    }
                    nextDisabled={currentPageNumber === totalAvailablePages}
                    prevDisabled={currentPageNumber === 1}
                    pageLimit={pageLimit}
                    pageNumber={currentPageNumber}
                />
            </div>
        </div>
    );
};

export { DoctorTable };
