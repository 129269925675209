/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
    className: string;
    data: {
        tasksDone: string;
        tasksInPending: string;
        tasksInProgress: string;
        totalTasks: string;
    };
};

const FacilityOverview = ({ className, data }: Props) => {
    return (
        <div className={`card ${className}`}>
            <div className="card-header border-0 mt-10 ">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-1 mb-1">
                        Facility Overview
                    </span>
                    <span className="text-black-50 fw-bolder fs-5 mt-3">
                        Facility insights overview for the day
                    </span>
                </h3>
            </div>
            <div className="m-5">
                <div className="d-flex mt-10 justify-content-evenly">
                    <div className="d-flex flex-column w-150px">
                        <div className="fs-5 text-dark fw-bolder">
                            Task Completed
                        </div>
                        <div className="fs-4 text-black-50 fw-bolder mt-5">
                            {data ? data?.tasksDone : "No Data"}
                        </div>
                    </div>
                    <div className="d-flex flex-column w-150px">
                        <div className="fs-5 text-dark fw-bolder">
                            In progress Task
                        </div>
                        <div className="fs-4 text-black-50 fw-bolder mt-5">
                            {data ? data?.tasksInProgress : "No Data"}
                        </div>
                    </div>
                </div>
                <div className="d-flex my-20 justify-content-evenly">
                    <div className="d-flex flex-column w-150px">
                        <div className="fs-5 text-dark fw-bolder">
                            Total Task
                        </div>
                        <div className="fs-4 text-black-50 fw-bolder mt-5">
                            {data ? data?.totalTasks : "No Data"}
                        </div>
                    </div>
                    <div className="d-flex flex-column w-150px">
                        <div className="fs-5 text-dark fw-bolder">
                            Pending Task
                        </div>
                        <div className="fs-4 text-black-50 fw-bolder mt-5">
                            {data ? data?.tasksInPending : "No Data"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { FacilityOverview };
