import React, { useEffect, useMemo, useState } from "react";
import PurchaseOrderDetailsTable from "./PurchaseOrderDetailsTable";
import PurchaseOrderGrnTable from "./PurchaseOrderGrnTable";
import { Link, useSearchParams } from "react-router-dom";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getPurchaseOrderDetails } from "../../../../services/supply-chain/purchase-order/purchaseOrderList";
import dayjs from "dayjs";
import { getGRNByPurchaseId } from "../../../../services/supply-chain/purchase-order/GetGRNByPurchaseId.services";
import { RBACComponent } from "../../../../common/config/rbac/components/RBACComponent";
import { permittedRoles } from "../../../../common/config/rbac/types/rbac.types";
import { dateFormatter } from "../../../../common/utils";

type Props = {};

const PurchaseOrderDetails = (props: Props) => {
    const [searchParams] = useSearchParams();
    const facilityCode: any = localStorage.getItem("facilityCode");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const purchaseOrderIdForApi = searchParams.get("purchaseOrderId") as string;
    const orderId = searchParams.get("orderId") as string;
    const { data } = useGetRequest(["getPurchaseOrderDetails"], () =>
        getPurchaseOrderDetails({ purchaseOrderId: purchaseOrderIdForApi })
    );

    const {
        data: productsByGRN,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useGetRequest(["getGRNByPurchaseId", page], () =>
        getGRNByPurchaseId({
            purchaseOrderId: orderId,
            facilityCode,
            page,
            limit,
        })
    );

    useEffect(() => {
        setTimeout(() => refetch(), 2000);
    }, [refetch]);
    const productsListByGRN = useMemo(
        () => productsByGRN?.data?.data?.grn,
        [productsByGRN?.data?.data?.grn]
    );
    const totalRecords = useMemo(
        () => productsByGRN?.data?.results,
        [productsByGRN?.data?.results]
    );
    const refreshPage = isLoading || isFetching;
    return (
        <div className="card text-dark p-4">
            <div className="row mt-4">
                <div className="col-sm">
                    <h4>
                        Purchase Order ID:{" "}
                        {data?.data?.data?.purchaseOrder?.purchaseOrderId}
                    </h4>
                </div>
                <div className="col-sm">
                    <h4>
                        Purchase Order Date:{" "}
                        {data?.data?.data?.purchaseOrder?.createdAt
                            ? dateFormatter(
                                  data?.data?.data?.purchaseOrder?.createdAt
                              )
                            : "N/A"}
                    </h4>
                </div>
                <div className="col-sm">
                    <h4>
                        Indent ID:{" "}
                        {
                            data?.data?.data?.purchaseOrder?.indentReferenceId
                                ?.indentRequestId
                        }
                    </h4>
                </div>
            </div>
            <RBACComponent whichRoles={[permittedRoles?.facilityManager]}>
                <div className="d-flex justify-content-end mt-5">
                    {/* <button className="btn btn-primary" data-testid="download-btn">
                    Download Pdf
                </button> */}
                    <Link
                        to={`/goods-receipt-note/add?purchaseOrderId=${orderId}&orderId=${purchaseOrderIdForApi}`}
                    >
                        <button
                            data-testid="add-grn-btn"
                            className="btn btn-primary"
                            style={{ marginLeft: "5rem" }}
                        >
                            Add GRN
                        </button>
                    </Link>
                </div>
            </RBACComponent>
            <div className="mt-4">
                {data?.data?.data?.purchaseOrder?.products.length > 0 && (
                    <PurchaseOrderDetailsTable
                        productsArr={data?.data?.data?.purchaseOrder?.products}
                    />
                )}
            </div>
            <div className="mt-5">
                <PurchaseOrderGrnTable
                    data={productsListByGRN}
                    isLoading={refreshPage}
                    isError={isError}
                    setPage={setPage}
                    setLimit={setLimit}
                    page={page}
                    limit={limit}
                    totalRecords={totalRecords}
                />
            </div>
        </div>
    );
};

export default PurchaseOrderDetails;
