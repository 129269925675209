import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import TableErrorComponent from "../../../components/Error/TableError";
import Pagination from "../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";

const BatchListTable = ({
    bodyProps,
    isLoading,
    isFetching,
    errorMsg,
    successMsg,
    setPageLimit,
    setCurrentPageNumber,
    currentPageNumber,
    totalBatchListPages,
    totalBatchListEntites,
    pageLimit,
    isBatchDataError,
}: any) => {
    const tableNames = [
        "BATCH NUMBER",
        "TOTAL CARDS",
        "ACTIVE CARDS",
        "CANCELLED CARDS",
        "USED CARDS",
        "UNUSED CARDS",
        "STATUS",
        "ACTIONS",
    ];

    return (
        <div className="card text-dark">
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <h1>HEALTHCARD BATCH LIST</h1>
            </div>
            {isBatchDataError ? (
                <TableErrorComponent errorMessage={errorMsg} />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={140} align="center">
                                SL NO
                            </TableHead>

                            {tableNames?.map((name) => {
                                return (
                                    <TableHead
                                        width={180}
                                        key={name}
                                        align="center"
                                    >
                                        {name}
                                    </TableHead>
                                );
                            })}
                        </TableHeadRow>

                        <TableBodyContainer
                            isLoading={isLoading}
                            isError={isBatchDataError}
                            errorMessage={errorMsg}
                        >
                            {bodyProps?.map((item: any, index: any) => {
                                return (
                                    <TableRow collapsible={false} key={index}>
                                        <TableCell align="center">
                                            {currentPageNumber &&
                                                (currentPageNumber - 1) *
                                                    pageLimit +
                                                    index +
                                                    1}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link
                                                to={`/batch/overview?batchId=${item?.batch}`}
                                                className="text-dark text-center fw-bolder text-hover-primary d-block mb-1 fs-6"
                                            >
                                                {item?.batch}
                                            </Link>
                                        </TableCell>

                                        <TableCell align="center">
                                            {item?.totalCards}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item?.countOfActiveCards}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item?.countOfCancelledCards}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item?.countOfUsedCards}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item?.countOfUnusedCards}
                                        </TableCell>

                                        <TableCell>
                                            <span
                                                className={`badge badge-light-${
                                                    item?.status === "Active"
                                                        ? "success"
                                                        : "danger"
                                                } text-center `}
                                            >
                                                {item?.status}
                                            </span>
                                        </TableCell>

                                        <TableCell>
                                            <Link
                                                to={`/batch/overview?batchId=${item?.batch}`}
                                                className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                            >
                                                DETAILS
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBodyContainer>
                    </Table>

                    {/* Pagination */}
                    <Pagination
                        dataCount={totalBatchListEntites}
                        handleCallback={(val) => setPageLimit(+val)}
                        handleNext={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber + 1
                            )
                        }
                        handlePrev={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber - 1
                            )
                        }
                        nextDisabled={currentPageNumber === totalBatchListPages}
                        prevDisabled={currentPageNumber === 1}
                        pageLimit={pageLimit}
                        pageNumber={currentPageNumber}
                    />
                </div>
            )}
        </div>
    );
};

export default BatchListTable;
