import React from "react";
import dayjs from "dayjs";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";

import Pagination from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import { permittedRoles } from "../../../../common/config/rbac/types/rbac.types";
import _, { isArray } from "lodash";
import { convertUtcTimestampToLocalDate } from "../../../../common/utils";

type Props = {
    apiData: any;
    limit: number;
    setLimit: Function;
    page: number;
    setPage: Function;
};
const tableNames = [
    "Order ID",
    "Customer",
    "Order Date",
    "Created By",
    "Delivery Status",
    "Amount",
    "Payment Status",
];
const SalesOrderListTable = ({
    apiData,
    page,
    limit,
    setLimit,
    setPage,
}: Props) => {
    const loggedInUser = localStorage.getItem("userRole");
    return (
        <>
            <Table>
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={false}
                    isError={false}
                    errorMessage={""}
                >
                    {isArray(apiData?.data?.salesOrder) ? (
                        apiData?.data?.salesOrder.map(
                            (ele: any, idx: number) => {
                                return (
                                    <TableRow collapsible={false}>
                                        <TableCell align="center">
                                            {page &&
                                                (page - 1) * limit + idx + 1}
                                        </TableCell>
                                        <TableCell>
                                            {loggedInUser ===
                                            permittedRoles.facilityManager ? (
                                                <Link
                                                    to={
                                                        ele.deliveryStatus ===
                                                        "Order Initiated"
                                                            ? `/sales-order/create?salesOrderId=${ele?._id}&orderId=${ele.orderId}`
                                                            : `/sales-order/details?orderId=${ele.orderId}&deliveryStatus=${ele.deliveryStatus}&paymentStatus=${ele.paymentStatus}`
                                                    }
                                                    className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                                >
                                                    {ele.orderId}
                                                </Link>
                                            ) : (
                                                <Link
                                                    to={
                                                        ele.deliveryStatus ===
                                                        "Order Initiated"
                                                            ? `/sales-order/list`
                                                            : `/sales-order/details?orderId=${ele.orderId}&deliveryStatus=${ele.deliveryStatus}&paymentStatus=${ele.paymentStatus}`
                                                    }
                                                    className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                                >
                                                    {ele.orderId}
                                                </Link>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.customerType === "CHW"
                                                ? _.startCase(
                                                      ele.chwData[0]?.name
                                                  ) || "---"
                                                : _.startCase(
                                                      ele.patientData[0]?.name
                                                  ) || "---"}
                                        </TableCell>
                                        <TableCell>
                                            {ele.createdAt
                                                ? convertUtcTimestampToLocalDate(
                                                      ele.createdAt
                                                  )
                                                : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.createdByRole ===
                                                "Facility Manager" &&
                                            ele?.facilityManagerData
                                                ? ele?.facilityManagerData[0]
                                                      ?.firstName
                                                    ? _.startCase(
                                                          ele
                                                              ?.facilityManagerData[0]
                                                              ?.firstName +
                                                              " " +
                                                              ele
                                                                  ?.facilityManagerData[0]
                                                                  ?.lastName
                                                      )
                                                    : "--"
                                                : _.startCase(
                                                      ele?.chwData[0]?.name
                                                  )}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            <span
                                                style={{
                                                    color:
                                                        ele?.deliveryStatus ===
                                                        "Order Delivered"
                                                            ? "#50cd89"
                                                            : ele?.deliveryStatus ===
                                                              "Order Initiated"
                                                            ? "#FFA533"
                                                            : "",
                                                }}
                                            >
                                                {ele.deliveryStatus}
                                            </span>
                                        </TableCell>
                                        <TableCell>{ele.totalAmount}</TableCell>
                                        <TableCell>
                                            <span
                                                style={{
                                                    color:
                                                        ele?.paymentStatus ===
                                                        "Full"
                                                            ? "#50cd89"
                                                            : ele?.paymentStatus ===
                                                              "Partially Paid"
                                                            ? "#FFA533"
                                                            : "",
                                                }}
                                            >
                                                {ele.paymentStatus}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )
                    ) : (
                        <TableRow collapsible={false}>
                            <TableCell align="center">
                                {" "}
                                {page && page - 1 + 1}
                            </TableCell>
                            <TableCell>
                                {loggedInUser ===
                                permittedRoles.facilityManager ? (
                                    <Link
                                        to={
                                            apiData?.data?.salesOrder
                                                .deliveryStatus ===
                                            "Order Initiated"
                                                ? `/sales-order/create?salesOrderId=${apiData?.data?.salesOrder?._id}&orderId=${apiData?.data?.salesOrder?.orderId}`
                                                : `/sales-order/details?orderId=${apiData?.data?.salesOrder?.orderId}&deliveryStatus=${apiData?.data?.salesOrder.deliveryStatus}&paymentStatus=${apiData?.data?.salesOrder.paymentStatus}`
                                        }
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                    >
                                        {apiData?.data?.salesOrder.orderId}
                                    </Link>
                                ) : (
                                    <Link
                                        to={
                                            apiData?.data?.salesOrder
                                                ?.deliveryStatus ===
                                            "Order Initiated"
                                                ? `/sales-order/list`
                                                : `/sales-order/details?orderId=${apiData?.data?.salesOrder?.orderId}&deliveryStatus=${apiData?.data?.salesOrder.deliveryStatus}&paymentStatus=${apiData?.data?.salesOrder.paymentStatus}`
                                        }
                                        className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                    >
                                        {apiData?.data?.salesOrder?.orderId}
                                    </Link>
                                )}
                            </TableCell>
                            {/* <TableCell>
                                {apiData?.data?.salesOrder.customerType ===
                                "CHW"
                                    ? _.startCase(
                                          apiData?.data?.salesOrder.chwData[0]
                                              ?.name
                                      )
                                    : _.startCase(
                                          apiData?.data?.salesOrder
                                              ?.patientData[0]?.name
                                      )}
                            </TableCell> */}
                            <TableCell>
                                {dayjs(
                                    new Date(
                                        apiData?.data?.salesOrder?.createdAt
                                    )
                                ).format("DD MMM,YYYY")}
                            </TableCell>
                            {/* <TableCell>
                                {apiData?.data?.salesOrder?.chwData[0]
                                    ? _.startCase(
                                          apiData?.data?.salesOrder?.chwData[0]
                                              ?.name
                                      )
                                    : "--"}
                            </TableCell> */}
                            <TableCell>
                                {" "}
                                <span
                                    style={{
                                        color:
                                            apiData?.data?.salesOrder
                                                ?.deliveryStatus ===
                                            "Order Delivered"
                                                ? "#50cd89"
                                                : apiData?.data?.salesOrder
                                                      ?.deliveryStatus ===
                                                  "Order Initiated"
                                                ? "#FFA533"
                                                : "",
                                    }}
                                >
                                    {apiData?.data?.salesOrder?.deliveryStatus}
                                </span>
                            </TableCell>
                            <TableCell>
                                {apiData?.data?.salesOrder?.totalAmount}
                            </TableCell>
                            <TableCell>
                                <span
                                    style={{
                                        color:
                                            apiData?.data?.salesOrder
                                                ?.paymentStatus === "Full"
                                                ? "#50cd89"
                                                : apiData?.data?.salesOrder
                                                      ?.paymentStatus ===
                                                  "Partially Paid"
                                                ? "#FFA533"
                                                : "",
                                    }}
                                >
                                    {apiData?.data?.salesOrder?.paymentStatus}
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBodyContainer>
            </Table>
            <Pagination
                dataCount={apiData?.results}
                handleCallback={(val: number | string) => {
                    setLimit(val);
                }}
                handleNext={() =>
                    setPage((prevPageNumber: number) => prevPageNumber + 1)
                }
                handlePrev={() =>
                    setPage((prevPageNumber: number) => prevPageNumber - 1)
                }
                nextDisabled={page === Math.ceil(apiData?.results / limit)}
                pageNumber={page}
                pageLimit={limit}
                prevDisabled={page === 1}
            />
        </>
    );
};

export default SalesOrderListTable;
