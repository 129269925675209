import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const PatientHeader = ({
    // healthcardValue,
    // isBeneficiaryPatient,
    patientDetails,
    showUpdate,
    handleProfileUpdate,
    setTabInitials,
}: any) => {
    return (
        <div className="card ">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex">
                    <div className="mt-5">
                        <img
                            src={
                                patientDetails
                                    ? patientDetails?.demographicDetails?.photo
                                        ? patientDetails?.demographicDetails
                                              ?.photo !== ""
                                            ? patientDetails?.demographicDetails
                                                  ?.photo
                                            : toAbsoluteUrl(
                                                  "/media/ikure/blank.png"
                                              )
                                        : toAbsoluteUrl(
                                              "/media/ikure/blank.png"
                                          )
                                    : toAbsoluteUrl("/media/ikure/blank.png")
                            }
                            alt={patientDetails?.name}
                            className="h-200px w-200px"
                        />
                    </div>
                    <div className="d-flex flex-column  ms-5 mt-2 w-950px">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                                <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1 ms-1">
                                    {patientDetails?.name
                                        ? patientDetails?.name
                                        : "N/A"}
                                </span>
                                <span
                                    className={`badge badge-${
                                        patientDetails?.onboardingStatus ===
                                        "Verified"
                                            ? "success"
                                            : "danger"
                                    } text-center ms-5`}
                                >
                                    {patientDetails?.onboardingStatus}
                                </span>
                            </div>
                            <div>
                                <i
                                    onClick={handleProfileUpdate}
                                    className="fs-3 fas fa-user-edit cursor-pointer text-hover-primary"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-5 h-200px">
                            <div className="d-flex justify-content-between align-items-center fw-bold fs-5">
                                <div className="w-250px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    <span className="me-5">UHID :</span>
                                    <span className="text-gray-800">
                                        {patientDetails?.uhid
                                            ? patientDetails?.uhid
                                            : "N/A"}
                                    </span>
                                </div>
                                <div className="w-300px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    {patientDetails?.demographicDetails
                                        ?.birthDate === "" ? (
                                        <>
                                            <span className="me-5">AGE :</span>
                                            <span className="text-gray-800">
                                                {patientDetails
                                                    ?.demographicDetails?.age
                                                    ? `${patientDetails?.demographicDetails?.age} years`
                                                    : "N/A"}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="me-5">
                                                Birth Date :
                                            </span>
                                            <span className="text-gray-800">
                                                {patientDetails
                                                    ?.demographicDetails
                                                    ?.birthDate
                                                    ? patientDetails
                                                          ?.demographicDetails
                                                          ?.birthDate
                                                    : "N/A"}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center fw-bold fs-5">
                                <div className="w-250px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    <span className="me-5">PHONE NO :</span>
                                    <span className="text-gray-800">
                                        {patientDetails?.demographicDetails
                                            ?.telecom
                                            ? patientDetails?.demographicDetails
                                                  ?.telecom
                                            : "N/A"}
                                    </span>
                                </div>
                                <div className="w-300px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    <span className="me-5">GENDER :</span>
                                    <span className="text-gray-800">
                                        {patientDetails?.demographicDetails?.sex
                                            ? patientDetails?.demographicDetails
                                                  ?.sex
                                            : "N/A"}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center fw-bold fs-5">
                                {/* <div className="w-350px d-flex align-items-center text-gray-600  mb-2 p-2">
                                    <span className="me-5">HEALTH CARD :</span>
                                    <span
                                        className="text-gray-800 text-hover-primary cursor-pointer"
                                        onClick={() =>
                                            setTabInitials("healthcard")
                                        }
                                    >
                                        {healthcardValue
                                            ? healthcardValue
                                            : "N/A"}
                                        {patientDetails?.healthCardNumber?.map(
                                            (pD: any) => {
                                                return (
                                                    <React.Fragment
                                                        key={pD?.id}
                                                    >
                                                        {pD?.id}
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                    </span>
                                </div> */}
                                <div className="w-300px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    <span className="me-0 w-200px">
                                        REGISTERED BY :
                                    </span>
                                    <span className="text-gray-800 w-200px">
                                        {patientDetails?.addedByChwName
                                            ? patientDetails?.addedByChwName
                                            : "N/A"}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center fw-bold fs-5">
                                <div className="w-500px d-flex align-items-center text-gray-600 text-hover-primary mb-2 p-2">
                                    <span className="me-5">ADDRESS :</span>
                                    <span className="text-gray-800">
                                        {patientDetails?.addresses?.addressLines
                                            ?.addressSiteName +
                                            " , " +
                                            // "Street Number : " +
                                            // patientDetails?.addresses?.addressLines
                                            //   ?.streetNumber +
                                            // " , " +
                                            // "Street Name : " +
                                            // patientDetails?.addresses?.addressLines?.streetName +
                                            patientDetails?.addresses?.town +
                                            " , " +
                                            patientDetails?.addresses
                                                ?.postalCode +
                                            " , " +
                                            patientDetails?.addresses?.state +
                                            " , " +
                                            patientDetails?.addresses?.country}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientHeader;
