import $axiosProtected from "../common/utils/axiosUtils";

// Get List of all CHW Details
export const getAllCHW = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/facility/all-chws?page=${payload.page}&limit=${payload.limit}&facilityCode=${payload.facilityCode}&grade=${payload.grade}&fieldstatus=${payload.fieldstatus}&access=${payload.access}`
    );
};

// Search Individual CHW Details By Name
export const searchCHWByName = async (payload: any) => {
    return await $axiosProtected().post(
        `/chwm/search?page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Get Individual CHW Details
export const getCHWProfile = async (payload: any) => {
    return await $axiosProtected().post(`/chwm/profile`, payload);
};

// Export CHW Details
export const exportCHWDetails = async (payload: any) => {
    return await $axiosProtected().post(`/chwm/profile`, payload);
};

// Get CHW Insights Details
export const getCHWInsights = async (payload: any) => {
    return await $axiosProtected().get(
        `/scheduler/task/stats/chw?chwId=${payload.chwId}&facilityCode=${payload.facilityCode}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    );
};

// Get List of Tasks assigned to CHW Details
export const getCHWTasks = async (payload: any) => {
    return await $axiosProtected().get(
        `/scheduler/task/facility/chw/get?chwId=${payload.chwId}&facilityCode=${payload.facilityCode}&taskStatus=${payload.taskStatus}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get CHW Attendance List
export const getCHWAttendanceList = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/duty-status/chw/get?chwId=${payload.chwId}&facilityCode=${payload.facilityCode}&month=${payload.month}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Get List of all facilities
export const getAllFacilities = async () => {
    return await $axiosProtected().get(`/organization/facility/all`);
};

// Export CHW Attendance Details
export const exportCHWAttendanceDetails = async (payload: any) => {
    return await $axiosProtected().post(`/report/generate`, payload);
};

// Assign CHW To other Facility
export const assignCHWToFacility = async (payload: any) => {
    return await $axiosProtected().patch(`/chwm/assign-facility`, payload);
};

// Remove CHW From Facility
export const removeCHWFromFacility = async (payload: any) => {
    return await $axiosProtected().patch(`/chwm/unassign-facility`, {
        data: payload,
    });
};
