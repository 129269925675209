import { KTSVG } from "../../../../_metronic/helpers";

type ExportProps = {
  // onClose: () => void;
  onClose: any;
  styles: any;
  handleSubmit: any;
};

const AlertScreen = ({ onClose, styles, handleSubmit }: ExportProps) => {
  return (
    <>
      <div
        style={{
          backgroundColor: styles?.backgroundColor,
          width: styles?.width,
          height: styles?.height,
          padding: styles?.padding,
        }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <>
          <div className="notice w-100 d-flex bg-light-warning rounded border-warning border border-dashed p-6">
            <KTSVG
              path="/media/icons/duotune/general/gen044.svg"
              className="svg-icon-2tx svg-icon-warning me-4"
            />
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-bold">
                <h4 className="text-gray-800 fw-bolder">
                  Carefull, We need your attention!
                </h4>
                <div className="fs-6 text-gray-600">
                  On submit you will be creating the Focused group with the
                  selected vitals.
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center align-items-center">
            <button
              onClick={onClose}
              className="w-175px btn btn-md btn-danger me-5"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-175px btn btn-md btn-primary me-5"
            >
              Submit
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default AlertScreen;
