import { Field, ErrorMessage } from "formik";
import { KTSVG } from "../../../../_metronic/helpers";

const Step1 = () => {
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark required">Focused Group Type</h2>
      </div>

      {/*  Focused Group Type  */}

      <div className="fv-row">
        <div className="row">
          <div className="col-lg-6">
            <Field
              type="radio"
              className="btn-check"
              name="focusedGroupType"
              value="Visit"
              id="kt_create_account_form_account_type_personal"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              htmlFor="kt_create_account_form_account_type_personal"
            >
              <KTSVG
                path="/media/ikure/focused-group/home-check.svg"
                className="svg-icon-3x me-5"
              />

              <span className="d-block fw-bold text-start">
                <span className="text-dark fw-bolder d-block fs-4 mb-2">
                  With Visits
                </span>
                <span className="text-gray-400 fw-bold fs-6">
                  Focused group with visits
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-6">
            <Field
              type="radio"
              className="btn-check"
              name="focusedGroupType"
              value="Non-visit"
              id="kt_create_account_form_account_type_corporate"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_create_account_form_account_type_corporate"
            >
              <KTSVG
                path="/media/ikure/focused-group/home-cancel.svg"
                className="svg-icon-3x me-5"
              />

              <span className="d-block fw-bold text-start">
                <span className="text-dark fw-bolder d-block fs-4 mb-2">
                  Without Visits
                </span>
                <span className="text-gray-400 fw-bold fs-6">
                  Focused group without visits
                </span>
              </span>
            </label>
          </div>

          <div className="text-danger mt-2">
            <ErrorMessage name="accountType" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
