import { useState } from "react";
import { Toaster } from "react-hot-toast";

import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";
import Modal from "../../../../../components/Modal";
import TableErrorComponent from "../../../../../components/Error/TableError";
import AddBeneficiaries from "../forms/addBeneficiaries";

export const MemberList = ({
    healthCardNo,
    beneficiaryData,
    isPatientLoader,
    isPatientError,
    patientErrorMsg,
    currentPageNumber,
    pageLimit,
    refetch,
}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="card text-dark">
            <Toaster />
            <Modal
                open={isOpen}
                styles={{
                    width: "650px",
                    backgroundColor: "white",
                }}
            >
                <AddBeneficiaries
                    onClose={handleModal}
                    healthCardNo={healthCardNo}
                />
            </Modal>
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>BENEFICIARY LIST</h1>

                    <i
                        onClick={() => refetch()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>

                <button
                    onClick={handleModal}
                    className="btn btn-sm btn-primary"
                >
                    <i className="fas fa-add"></i>
                    Add beneficiary
                </button>
            </div>

            {isPatientError || Array?.isArray(beneficiaryData) !== true ? (
                <TableErrorComponent
                    errorMessage={patientErrorMsg || beneficiaryData}
                />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={50} align="center">
                                SL NO
                            </TableHead>
                            <TableHead width={140}>PATIENT NAME</TableHead>
                            <TableHead width={140}>UHID</TableHead>
                        </TableHeadRow>

                        {isPatientError === true ? (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "500px",
                                    top: "200px",
                                }}
                            >
                                <h1 className="text-danger">
                                    {isPatientError
                                        ? patientErrorMsg
                                        : "Something went wrong!!"}
                                </h1>
                            </tbody>
                        ) : Array?.isArray(beneficiaryData) ? (
                            <TableBodyContainer
                                isLoading={isPatientLoader}
                                isError={isPatientError}
                            >
                                {beneficiaryData?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <TableRow
                                                collapsible={false}
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {currentPageNumber &&
                                                        (currentPageNumber -
                                                            1) *
                                                            pageLimit +
                                                            index +
                                                            1}
                                                </TableCell>
                                                <TableCell hover>
                                                    {item?.name
                                                        ? item?.name
                                                        : "N/A"}
                                                </TableCell>
                                                <TableCell hover>
                                                    {item?.uhid
                                                        ? item?.uhid
                                                        : "N/A"}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBodyContainer>
                        ) : (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "500px",
                                    top: "200px",
                                }}
                            >
                                <h1 className="text-danger text-nowrap">
                                    {beneficiaryData}
                                </h1>
                            </tbody>
                        )}
                        {/* </table> */}
                    </Table>

                    {/* end::Table */}
                </div>
            )}
        </div>
    );
};
