import { KPIMonth } from "../utils/dateUtils";

export let allKPIdata = [
  {
    kpiName: "patientRegistration",
    inputName: "Patient Registration",
    inputDesc: "To set monthly target for Patient Registration.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `patientRegistrationDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "teleconsultationRegistration",
    inputName: "Teleconsultation Registration",
    inputDesc: "To set monthly target for Teleconsultation Registration.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `teleconsultationDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "bloodGlucose",
    inputName: "Blood Glucose",
    inputDesc: "To set monthly target for Blood Glucose.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `bloodGlucoseDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "bloodPressure",
    inputName: "Blood Pressure",
    inputDesc: "To set monthly target for Blood Pressure.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `bloodPressureDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "ecg",
    inputName: "ECG",
    inputDesc: "To set monthly target for ECG.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `ecgDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "height",
    inputName: "Height",
    inputDesc: "To set monthly target for height.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `heightDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "weight",
    inputName: "Weight",
    inputDesc: "To set monthly target for weight.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `weightDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "hemoglobin",
    inputName: "Hemoglobin",
    inputDesc: "To set monthly target for Hemoglobin.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `hemoglobinDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "pulse",
    inputName: "Pulse",
    inputDesc: "To set monthly target for Pulse.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `pulseDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "respiratory",
    inputName: "Respiratory",
    inputDesc: "To set monthly target for Respiratory.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `respiratoryDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "temperature",
    inputName: "Temperature",
    inputDesc: "To set monthly target for Temperature.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `temperatureDetails`,
    errorMsg: "Please select atleast one value",
  },
  {
    kpiName: "oxygenSaturation",
    inputName: "Oxygen Saturation",
    inputDesc: "To set monthly target for Oxygen Saturation.",
    showSeparator: true,
    kpiData: KPIMonth(""),
    fieldName: `oxygenSaturationDetails`,
    errorMsg: "Please select atleast one value",
  },
];
