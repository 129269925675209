import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

interface Props {
    name: string;
    children: React.ReactNode;
    label: string;
    onChange: (e: string | React.ChangeEvent<any>) => void;
    required?: boolean;
    className?: string;
    value: string;
}

function SelectMenu({ label, children, required, ...rest }: Props) {
    return (
        // @ts-ignore
        <FloatingLabel
            controlId="floatingSelect"
            label={
                <>
                    {label}&nbsp;
                    {required && (
                        <span className="text-danger font-weight-bold">*</span>
                    )}
                </>
            }
        >
            {/* @ts-ignore */}
            <Form.Select aria-label="Floating label" {...rest}>
                {children}
            </Form.Select>
        </FloatingLabel>
    );
}

export default SelectMenu;
