import $axiosProtected from "../../../../common/utils/axiosUtils";

// Get Facility stock
export const getFacilityStock = async ({
    facilityCode,
    page,
    limit,
    filterOption,
    productName,
    genericName,
    productCode = "",
}: {
    facilityCode: string;
    page: number;
    limit: number;
    filterOption?: number;
    genericName?: string;
    productName?: string;
    productCode?: string;
}) => {
    return await $axiosProtected().get(
        `/inventory/stock/get/batch/web?page=${page}&limit=${limit}&facilityCode=${facilityCode}&filterOption=${filterOption}&genericName=${genericName}&productName=${productName}&productCode=${productCode}`
    );
};
