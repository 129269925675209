// export const redirectConfig = [
export default [
    {
        from: "/",
        to: "/dashboard",
        exact: true,
    },
    {
        from: "/auth",
        to: "/auth/check-org",
        exact: true,
    },
    {
        from: "/chw",
        to: "/chw/chw-management",
        exact: true,
    },
    // {
    //   from: "/chw/:id",
    //   to: "/chw/:id/overview",
    //   exact: true,
    // },
    // {
    //   from: "/chw/overview",
    //   to: "/chw/chw-management",
    //   exact: true,
    // },
    // {
    //   from: "/patient/:id",
    //   to: "/patient/:id/overview",
    //   exact: true,
    // },
    {
        from: "/patient",
        to: "/patient/patient-management",
        exact: true,
    },
    {
        from: "/doctor",
        to: "/doctor/doctor-management",
        exact: true,
    },
    // {
    //   from: "/doctor/:id",
    //   to: "/doctor/:id/overview",
    //   exact: true,
    // },
    {
        from: "/focused-group",
        to: "/focused-group/management",
        exact: true,
    },
    // {
    //   from: "/focused-group/:id",
    //   to: "/focused-group/:id/vitals",
    //   exact: false,
    // },
    {
        from: "/healthcard",
        to: "/healthcard/list",
        exact: true,
    },
    {
        from: "/batch",
        to: "/batch/list",
        exact: true,
    },
    // {
    //   from: "/batch/:id",
    //   to: "/batch/:id/overview",
    //   exact: true,
    // },
    {
        from: "/task",
        to: "/task/task-list",
        exact: true,
    },
    // {
    //   from: "/task/:id",
    //   to: "/task/:id/task-details",
    //   exact: true,
    // },
    {
        from: "/report",
        to: "/report/report-management",
        exact: true,
    },
    {
        from: "/training",
        to: "/training/course-management",
        exact: true,
    },
    {
        from: "/facility",
        to: "/facility/facility-management",
        exact: true,
    },
    {
        from: "/indent",
        to: "/indent/indent-list",
        exact: true,
    },
    {
        from: "/purchase-order",
        to: "/purchase-order/list",
        exact: true,
    },
    {
        from: "/goods-receipt-note",
        to: "/goods-receipt-note/list",
        exact: true,
    },
    {
        from: "/facility-stock",
        to: "/facility-stock/list",
        exact: true,
    },
    {
        from: "/sales-order",
        to: "/sales-order/list",
        exact: true,
    },
    {
        from: "/master-product",
        to: "/master-product/list",
        exact: true,
    },
    {
        from: "/chw-stock",
        to: "/chw-stock/list",
        exact: true,
    },
];
