const getRefreshToken = () => {
  return JSON.parse(window.localStorage.getItem("refresh_token") || "{}");
};

const getAccessToken = () => {
  return JSON.parse(localStorage.getItem("access_token") || "{}");
};

const getCurrentFacilityStatus = () => {
  return JSON.parse(localStorage.getItem("facilityStatus") || "{}");
};

const updateNewAccessToken = (token: any) => {
  let user = JSON.parse(localStorage.getItem("access_token") || "{}");
  user.accessToken = token;
  localStorage.setItem("access_token", JSON.stringify(user));
};

const getUser = (user: string) => {
  return JSON.parse(localStorage.getItem(user) || "{}");
};

const setAccessToken = (access_token: any) => {
  //   console.log(JSON.stringify(user));
  localStorage.setItem("access_token", JSON.stringify(access_token));
};
const setRefreshToken = (refresh_token: any) => {
  localStorage.setItem("refresh_token", JSON.stringify(refresh_token));
};
const setUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("status");
  localStorage.removeItem("logginSession");
  localStorage.removeItem("facilityName");
  localStorage.removeItem("facilityCode");
  localStorage.removeItem("photo");
  localStorage.removeItem("userRole");
  localStorage.removeItem("facilityStatus");
};

const setOrg = (org: any) => {
  //   console.log(JSON.stringify(user));
  localStorage.setItem("org", JSON.stringify(org));
};
const getOrg = () => {
  return JSON.parse(localStorage.getItem("org") || "{}");
};
const TokenService = {
  setAccessToken,
  setRefreshToken,
  updateNewAccessToken,
  setUser,
  setOrg,
  getOrg,
  getUser,
  removeUser,
  getAccessToken,
  getRefreshToken,
  getCurrentFacilityStatus,
};

export default TokenService;
