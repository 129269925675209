import React from "react";

type Props = {
    children: React.ReactNode;
    nested?: boolean;
    minHeight?: string;
};

const Table = (props: Props) => {
    const { children, nested, minHeight } = props;
    return (
        <div
            className={`table-responsive ${
                !nested && !minHeight && "min-h-400px"
            }`}
        >
            {/* begin::Table */}
            <table className="table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                {children}
            </table>
        </div>
    );
};

export default Table;
