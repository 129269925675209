import React, { useEffect, useMemo, useState } from "react";

import TableErrorComponent from "../../../../../components/Error/TableError";
import {
    Table,
    TableHeadRow,
    TableBodyContainer,
    TableHead,
    TableRow,
    TableCell,
} from "../../../../../components/Table";

import Pagination from "../../../../../components/Pagination";
import Modal from "../../../../../components/Modal";
import SearchBox from "../SearchBox";
import BulkUploadProducts from "../../bulk-upload/BulkUploadProducts";

// API
import useGetRequest from "../../../../../hooks/getRequest.query";

import {
    getAllProducts,
    getProductsBySearch,
} from "../../../../../services/supply-chain/product-master/MasterProduct.services";
import CustomTooltip from "../../../../../components/CustomTooltip";
import { RBACComponent } from "../../../../../common/config/rbac/components/RBACComponent";
import { permittedRoles } from "../../../../../common/config/rbac/types/rbac.types";
import useMutateRequest from "../../../../../hooks/getMutation.query";

type IMasterProductList = {
    categories: {
        categoryName: string;
        subCategoryName: string;
    };
    _id: string;
    productName: string;
    genericName: string;
    productCode: string;
    hsnCode: string;
    status: "Active";
    manufacturerName: string;
    schedule: "";
    type: "";
    incentive: number;
    quantityThreshold: number;
    createdAt: any;
    updatedAt: any;
    __v: 0;
};

const tableNames = [
    "PRODUCT NAME",
    "PRODUCT CODE",
    "GENERIC NAME",
    "MANUFACTURER NAME",
    "THRESHOLD QUANTITY",
    "CATEGORY NAME",
];

const MasterProductsTable = () => {
    // Modal
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchApiData, setSearchApiData] = useState<any>();
    const [apiData, setApiData] = useState<any>();
    const [isSearch, setIsSearch] = useState(false);
    // Table View API
    const { data, isLoading, isError, error, refetch }: any = useGetRequest(
        ["getAllProducts", pageLimit, currentPageNumber],
        () =>
            getAllProducts({
                page: currentPageNumber,
                limit: pageLimit,
            }),
        { keepPreviousData: true }
    );

    useEffect(() => {
        setApiData(data);
    }, [data]);

    const productList = useMemo(
        () =>
            isSearch
                ? searchApiData?.data?.data?.products
                : apiData?.data?.data?.products,
        [apiData, searchApiData]
    );
    const totalEntites = isSearch
        ? searchApiData?.data?.results
        : apiData?.data?.results;
    const totalPages = Math.ceil(totalEntites / pageLimit);

    const tableErrorMsg = error?.response?.data?.message;
    // const successMsg = data?.data?.data?.message;
    const { mutate } = useMutateRequest((data: any) =>
        getProductsBySearch(data)
    );

    return (
        <div>
            <Modal
                open={isModalOpen}
                overlayStyles={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <BulkUploadProducts
                    styles={{
                        backgroundColor: "white",
                        width: "650px",
                        height: "425px",
                        msgHeight: "200px",
                        padding: "50px",
                    }}
                    onClose={() => setIsModalOpen(!isModalOpen)}
                    refetch={refetch}
                />
            </Modal>
            <div className="bg-white border-0 d-flex justify-content-between align-items-center p-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>PRODUCT LIST</h1>
                    <i
                        onClick={() => {
                            setCurrentPageNumber(1);
                            refetch();
                            setIsSearch(false);
                        }}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>

                <SearchBox
                    mutate={mutate}
                    setApiData={setSearchApiData}
                    setPage={setCurrentPageNumber}
                    setIsSearch={setIsSearch}
                />
                <RBACComponent whichRoles={[permittedRoles?.organizationAdmin]}>
                    <button
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        className="btn btn-sm btn-primary"
                    >
                        Add New Product
                    </button>
                </RBACComponent>
            </div>
            {isError ? (
                <TableErrorComponent errorMessage={tableErrorMsg} />
            ) : (
                <div className="card text-dark">
                    <div className="card-body py-3">
                        <Table>
                            <TableHeadRow>
                                <TableHead width={140} align="center">
                                    SL NO
                                </TableHead>
                                {tableNames?.map((name) => {
                                    return (
                                        <TableHead width={180} key={name}>
                                            {name}
                                        </TableHead>
                                    );
                                })}
                            </TableHeadRow>

                            <TableBodyContainer
                                isLoading={isLoading}
                                isError={isError}
                                errorMessage={tableErrorMsg}
                            >
                                {productList?.map(
                                    (item: IMasterProductList, index: any) => {
                                        return (
                                            <TableRow
                                                collapsible={false}
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {currentPageNumber &&
                                                        (currentPageNumber -
                                                            1) *
                                                            pageLimit +
                                                            index +
                                                            1}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.productName
                                                        ? item?.productName
                                                        : "N/A"}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.productCode
                                                        ? item?.productCode
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.genericName
                                                        ? item?.genericName
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.manufacturerName
                                                        ? item?.manufacturerName
                                                        : "N/A"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.quantityThreshold
                                                        ? item?.quantityThreshold
                                                        : "N/A"}
                                                </TableCell>

                                                <TableCell>
                                                    <CustomTooltip
                                                        placement="right-start"
                                                        title={
                                                            item?.categories
                                                                ?.subCategoryName
                                                                ? item
                                                                      ?.categories
                                                                      ?.subCategoryName
                                                                : "N/A"
                                                        }
                                                    >
                                                        <span>
                                                            {item?.categories
                                                                ?.categoryName
                                                                ? item
                                                                      ?.categories
                                                                      ?.categoryName
                                                                : "N/A"}
                                                        </span>
                                                    </CustomTooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBodyContainer>
                        </Table>

                        {/* Pagination */}
                        <Pagination
                            dataCount={totalEntites}
                            handleCallback={(val) => setPageLimit(+val)}
                            handleNext={() => {
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                );
                                setIsSearch(false);
                            }}
                            handlePrev={() => {
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                );
                                setIsSearch(false);
                            }}
                            nextDisabled={currentPageNumber === totalPages}
                            prevDisabled={currentPageNumber === 1}
                            pageLimit={pageLimit}
                            pageNumber={currentPageNumber}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MasterProductsTable;
