import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useNavbar } from "../../../../context/NavbarContext";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
  const { config } = useLayout();
  const { userPhoto, loggedInUser } = useNavbar();

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      <div
        className={clsx("d-flex align-items-stretch", toolbarButtonMarginClass)}
      ></div>

      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img
            src={
              userPhoto ? userPhoto : toAbsoluteUrl("/media/ikure/blank.png")
            }
            alt={loggedInUser?.userName}
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  );
};

export { Topbar };
