import { useEffect, useState } from "react";
import { useNavbar } from "../../../context/NavbarContext";
import useGetRequest from "../../../hooks/getRequest.query";
import { getFacilityList } from "../../../services/facility/Facility.hook";

import FacilityCounterPage from "./FacilityCounterPage";
import FacilityTable from "./FacilityTable";

const FacilityPage = () => {
    const { selectedFacility } = useNavbar();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterData, setFilterData]: any = useState({
        grade: "",
        access: "",
        fieldstatus: "",
    });

    const facilityCode = localStorage.getItem("facilityCode");

    const { data, isLoading, isFetching, refetch, isError, error }: any =
        useGetRequest(
            ["getAllFacilites-facility-main", pageNumber, pageLimit],
            async () =>
                await getFacilityList({
                    page: pageNumber,
                    limit: pageLimit,
                }),
            {
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            }
        );

    useEffect(() => {
        refetch();
    }, [filterData, facilityCode, selectedFacility]);

    const totalEntites = data?.data?.results;
    const facilityData = data?.data?.data?.facility;
    const totalAvailablePages = Math.ceil(totalEntites / pageLimit);
    const errorMsg = error?.response?.data?.message;

    return (
        <>
            <div>
                <FacilityCounterPage
                    isLoading={isLoading}
                    isFetching={isFetching}
                    counterData={totalEntites}
                />
            </div>
            <div className="mt-5">
                <FacilityTable
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    currentPageNumber={pageNumber}
                    setCurrentPageNumber={setPageNumber}
                    totalEntites={totalEntites}
                    totalAvailablePages={totalAvailablePages}
                    getFacilityLoader={isLoading}
                    getFacilityFetcher={isFetching}
                    isFacilityError={isError}
                    facilityErrorMsg={errorMsg}
                    bodyProps={facilityData}
                    setFilterData={setFilterData}
                    refetch={refetch}
                />
            </div>
        </>
    );
};

export default FacilityPage;
