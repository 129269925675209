import { useEffect, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";
import { Step6 } from "./steps/Step6";
import { Step7 } from "./steps/Step7";

import useMutateRequest from "../../../../hooks/getMutation.query";
import { onboardDoctor } from "../../../../services/doctor/DoctorOnboarding.hook";
import Stepper from "../../../../components/Stepper";
import {
    doctorOnboardingSchemas,
    initsForDoctor,
} from "./model/DoctorOnboardingModel";
import { hasAccessToModule } from "../../../../rbac";
import { Permissions } from "../../../../rbac/types";

const DoctorOnboarding = () => {
    const { isError, error, mutate, isSuccess, isLoading }: any =
        useMutateRequest((data: any) => onboardDoctor(data));

    const errorMsg = error?.response?.data?.message;

    const navigate = useNavigate();

    const organizationId: any = localStorage.getItem("organizationId") || "{}";

    const submitStep = (values: any, actions: FormikValues) => {
        try {
            const formData = new FormData();
            // Step 1
            formData.append("userName", values.phoneNumber);
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("middleName", values.middleName);
            formData.append("password", values.password);
            formData.append("email", values.email);
            formData.append("telecom", values.phoneNumber);

            // Step 2

            formData.append("photo", values.photo);
            formData.append("sex", values.sex);
            formData.append("qualification", values.qualification);
            formData.append("language", values.language);
            formData.append("age", values.age);
            formData.append("birthdate", values.birthdate);

            // Step 3

            formData.append("floorNumber", values.floorNumber);
            formData.append("addressSiteName", values.addressSiteName);
            formData.append("streetName", values.streetName);
            formData.append("streetNumber", values.streetNumber);
            formData.append("district", values.district);
            formData.append("postalCode", values.postalCode);
            formData.append("town", values.town);
            formData.append("state", values.state);
            formData.append("country", values.country);

            // Step 4

            formData.append("license", values.license);
            formData.append("licenseNumber", values.licenseNumber);
            formData.append("licenseProvider", values.licenseProvider);

            // Step 5

            formData.append("facility", values.facility);
            formData.append("organization", organizationId);
            formData.append("specialization", values.specialization);
            formData.append("signature", values.signature);

            // Step 6

            formData.append(
                "physicalConsultationFee",
                values.physicalConsultationFee
            );
            formData.append(
                "physicalConsultationLimit",
                values.physicalConsultationLimit
            );
            formData.append(
                "onlineConsultationFee",
                values.onlineConsultationFee
            );
            formData.append(
                "onlineConsultationLimit",
                values.onlineConsultationLimit
            );
            mutate(formData, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        navigate("/doctor/doctor-management");
                    }, 3000);
                },
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span
                    data-testid="success-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center "
                >
                    {/* {successMsg} */}
                    Successfully Onboarded Doctor!!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span
                    data-testid="error-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                >
                    {errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    const handleInputKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };
    const formSteps = [
        {
            title: "Basic Info",
            subTitle: "Your basic details",
        },
        {
            title: "Profile Settings",
            subTitle: "Setup your profile settings",
        },
        {
            title: "Address Details",
            subTitle: "Provide your location details",
        },
        {
            title: "License Details",
            subTitle: "Provide your license details",
        },
        {
            title: "Doctor Assignment",
            subTitle: "Provide doctor assignment information",
        },
        {
            title: "Consultation Settings",
            subTitle: "Woah, we are here",
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    const StepsContent = (activeStep: number, formikProps: any) => {
        switch (activeStep) {
            case 0:
                return (
                    <div>
                        <Step1 />
                    </div>
                );

            case 1:
                return (
                    <div>
                        <Step2 {...formikProps} />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <Step3 {...formikProps} />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Step4 {...formikProps} />
                    </div>
                );
            case 4:
                return (
                    <div>
                        <Step5 {...formikProps} />
                    </div>
                );
            case 5:
                return (
                    <div>
                        <Step6 />
                    </div>
                );
            case 6:
                return (
                    <div>
                        <Step7 />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>No Step</h1>
                    </div>
                );
        }
    };
    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError]);

    const canReadPage = !hasAccessToModule("ONBOARD_DOCTOR", Permissions.read);
    return (
        <div hidden={canReadPage}>
            <Toaster />

            <div className="d-flex flex-row-fluid flex-center rounded">
                {/* Spinner */}
                {isLoading ? (
                    <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                        <ClipLoader size={70} speedMultiplier={0.6} />
                    </div>
                ) : (
                    <Formik
                        validationSchema={doctorOnboardingSchemas[activeIndex]}
                        initialValues={initsForDoctor}
                        onSubmit={submitStep}
                    >
                        {(formikProps) => (
                            <Form onKeyDown={handleInputKeyDown}>
                                <Stepper
                                    steps={formSteps}
                                    activeIndex={activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    stepComponent={
                                        <div className="py-20 w-100 w-xl-750px min-vh-50 px-9">
                                            {StepsContent(
                                                activeIndex,
                                                formikProps
                                            )}
                                        </div>
                                    }
                                    formikProps={formikProps}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export { DoctorOnboarding };
