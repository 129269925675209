import FacilityStockTable from "./table/FacilityStockTable";

const FacilityStockPage = () => {
    return (
        <>
            <FacilityStockTable />
        </>
    );
};

export default FacilityStockPage;
