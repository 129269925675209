import React from "react";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";

import { IProductsArr } from "..";
import {
    CalcDiscount,
    calcTaxAmt,
    calcTotalPrice,
    calcTotalUnits,
} from "./calcFunction";
import FinalBillSummary from "./FinalBillSummary";

type Props = {
    productsArr: IProductsArr;
    setProductsArr: any;
    radioValue: string;
    inputVal: string;
    amtInputVal: string;
    setRadioValue: Function;
    setInputVal: Function;
    setAmtInputVal: Function;
    setApiProductArr: Function;
    apiProductArr: any;
    invoiceDetails: {
        invoiceDate: string;
        invoiceNumber: string;
        vendorId: string;
    };
};

const tableNames = [
    "Product",
    "Batch",
    "GST %",
    "IGST %",
    "SGST/UTGST %",
    "CGST %",
    "Units / Strip",
    "Total Units",
    "Price / Strip",
    "MRP / Strip",
    "Discount",
    "In TAX (Rs.)",
    "Total Price",
];
const AddedProductTable = ({
    productsArr,
    setProductsArr,
    amtInputVal,
    inputVal,
    radioValue,
    setAmtInputVal,
    setInputVal,
    setRadioValue,
    apiProductArr,
    setApiProductArr,
    invoiceDetails,
}: Props) => {
    const deleteProduct = (product: string, productId: string) => {
        setProductsArr((prevValue: any) =>
            prevValue.filter((ele: any) => ele.product !== product)
        );
        setApiProductArr((prevValue: any) =>
            prevValue.filter((ele: any) => ele.productId !== productId)
        );
    };
    return (
        <>
            <Table minHeight="min-h-100px">
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                    <TableHead width={140} align="center">
                        ACTION
                    </TableHead>
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={false}
                    isError={false}
                    errorMessage={""}
                >
                    {productsArr.map((ele, idx) => {
                        return (
                            <TableRow collapsible={false} key={ele.product}>
                                <TableCell align="center">{idx + 1}</TableCell>
                                {/* Product */}
                                <TableCell>{ele.product}</TableCell>
                                {/* Batch */}
                                <TableCell>{ele.batch.toUpperCase()}</TableCell>
                                {/* GST % */}
                                <TableCell>{ele.gstTotal}</TableCell>
                                {/* IGST % */}
                                <TableCell>0</TableCell>
                                {/* SGST/UTGST % */}
                                <TableCell>
                                    {(parseInt(ele.gstTotal) / 2).toFixed(2)}
                                </TableCell>
                                {/* CGST % */}
                                <TableCell>
                                    {" "}
                                    {(parseInt(ele.gstTotal) / 2).toFixed(2)}
                                </TableCell>
                                {/* Units / Strip */}
                                <TableCell>{ele.unitsPerStrip}</TableCell>
                                {/* Total Units */}
                                <TableCell>
                                    {ele.freeStrips !== "" && (
                                        <span
                                            style={{ fontWeight: 200 }}
                                            className="text-danger"
                                        >
                                            ({ele.noOfStrips}+{ele.freeStrips})
                                        </span>
                                    )}

                                    <p className="text-danger">
                                        {calcTotalUnits(
                                            parseInt(ele.noOfStrips),
                                            ele.freeStrips
                                                ? parseInt(ele.freeStrips)
                                                : 0,
                                            parseInt(ele.unitsPerStrip)
                                        )}
                                    </p>
                                </TableCell>
                                {/* Price / Strip */}
                                <TableCell>{ele.pricePerStrip}</TableCell>
                                {/* MRP / Strip */}
                                <TableCell>{ele.mrpPerStrip}</TableCell>
                                {/* Discount */}
                                <TableCell>
                                    {ele.discountPercent ? (
                                        <>
                                            <span style={{ fontWeight: 200 }}>
                                                {`(${ele.discountPercent}%)`}
                                            </span>

                                            <p>
                                                {CalcDiscount(
                                                    parseInt(ele.noOfStrips),
                                                    parseInt(ele.pricePerStrip),
                                                    parseInt(
                                                        ele.discountPercent
                                                    )
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        "--"
                                    )}
                                </TableCell>

                                {/* In TAX (Rs.) */}
                                <TableCell>
                                    {calcTaxAmt(
                                        +ele.noOfStrips,
                                        +ele.pricePerStrip,
                                        +ele.discountPercent,
                                        0,
                                        +ele.gstTotal
                                    )}
                                </TableCell>
                                {/* Total Price */}
                                <TableCell>
                                    {calcTotalPrice(
                                        +ele.noOfStrips,
                                        +ele.pricePerStrip,
                                        +ele.discountPercent,
                                        0,
                                        +ele.gstTotal
                                    )}
                                </TableCell>
                                {/* Actions */}
                                <TableCell align="center">
                                    <i
                                        className="fa-sharp fa-solid fa-trash link-primary"
                                        data-testid="delete-btn"
                                        onClick={() =>
                                            deleteProduct(
                                                ele.product,
                                                ele.productId
                                            )
                                        }
                                    ></i>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBodyContainer>
            </Table>
            <hr className="hr" />
            <FinalBillSummary
                productsArr={productsArr}
                amtInputVal={amtInputVal}
                inputVal={inputVal}
                radioValue={radioValue}
                setAmtInputVal={setAmtInputVal}
                setInputVal={setInputVal}
                setRadioValue={setRadioValue}
                apiProductArr={apiProductArr}
                invoiceDetails={invoiceDetails}
            />
        </>
    );
};

export default AddedProductTable;
