import React from "react";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";

import { IProductsArr } from "../billingForm/IForms";
import FinalBillSummary from "./FinalBillSummary";
import { calcTaxAmount, totalAmt } from "./CalcFunctions";
// import {
//     CalcDiscount,
//     calSchemeDisc,
//     calcTaxAmt,
//     calcTotalPrice,
//     calcTotalUnits,
// } from "./calcFunction";
// import FinalBillSummary from "./FinalBillSummary";

type Props = {
    productsArr: IProductsArr;
    setProductsArr: any;
};

const tableNames = [
    "Product",
    "Batch",
    "Manufacturer",
    "GST %",
    "IGST %",
    "SGST/UTGST %",
    "CGST %",
    "Units",
    "MRP / Unit",
    "Discount %",
    "In TAX (Rs.)",
    "Total Price",
];
const SalesAddedProductTable = ({ productsArr, setProductsArr }: Props) => {
    const deleteProduct = (product: string) => {
        setProductsArr((prevValue: any) =>
            prevValue.filter((ele: any) => ele.product !== product)
        );
    };

    return (
        <>
            <Table minHeight="min-h-100px">
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                    <TableHead width={140} align="center">
                        ACTION
                    </TableHead>
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={false}
                    isError={false}
                    errorMessage={""}
                >
                    {productsArr.map((ele, idx) => {
                        return (
                            <TableRow collapsible={false} key={ele.product}>
                                <TableCell align="center">{idx + 1}</TableCell>
                                {/* Product */}
                                <TableCell>{ele.product}</TableCell>
                                {/* Batch */}
                                <TableCell>{ele.batch.toUpperCase()}</TableCell>
                                {/* Manufacturer */}
                                <TableCell>{ele.manufacturer}</TableCell>
                                {/* GST % */}
                                <TableCell>{ele.gstTotal}</TableCell>
                                {/* IGST % */}
                                <TableCell>0</TableCell>
                                {/* SGST/UTGST % */}
                                <TableCell>
                                    {(parseInt(ele.gstTotal) / 2).toFixed(2)}
                                </TableCell>
                                {/* CGST % */}
                                <TableCell>
                                    {" "}
                                    {(parseInt(ele.gstTotal) / 2).toFixed(2)}
                                </TableCell>
                                {/* Units / Strip */}
                                <TableCell>{ele.units}</TableCell>

                                {/* MRP / Strip */}
                                <TableCell>{ele.mrpPerUnit}</TableCell>
                                {/* Discount */}
                                <TableCell>{ele.discountPercent}</TableCell>

                                {/* In TAX (Rs.) */}
                                <TableCell>
                                    {calcTaxAmount(
                                        +ele.gstTotal,
                                        +ele.mrpPerUnit * +ele.units
                                    ).toFixed(2)}
                                </TableCell>
                                {/* Total Price */}
                                <TableCell>
                                    {totalAmt(
                                        +ele.mrpPerUnit,
                                        +ele.units,
                                        +ele.discountPercent
                                    )}
                                </TableCell>
                                {/* Actions */}
                                <TableCell align="center">
                                    <i
                                        className="fa-sharp fa-solid fa-trash link-primary"
                                        data-testid="delete-btn"
                                        onClick={() =>
                                            deleteProduct(ele.product)
                                        }
                                    ></i>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBodyContainer>
            </Table>
            <hr className="hr" />
            <FinalBillSummary productsArr={productsArr} />
        </>
    );
};

export default SalesAddedProductTable;
