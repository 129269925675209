import QuestionDetails from "./QuestionDetails";

const VisitFormDetails = ({ formData }: any) => {
  return (
    <>
      <div className="bg-white p-5 mt-5 d-flex justify-content-between ">
        <div className="text-capitalize">
          <span>
            <h4>Form Name : {formData?.formName}</h4>
          </span>
          <span>
            <h4>Visit Number : {formData?.visitNumber}</h4>
          </span>
        </div>
        <div className="">
          <span>
            <h4>Total no of sections : {formData?.sections?.length}</h4>
          </span>
          <span>
            <h4>Total no of questions : {formData?.totalQuestions}</h4>
          </span>
        </div>
      </div>

      <>
        {formData?.sections?.map((section: any) => {
          let questions = section?.questions;

          return (
            <>
              <div className="bg-white p-8 my-8">
                <div className="text-capitalize d-flex justify-content-between mb-5">
                  <h4>Section Name : {section?.sectionName}</h4>
                  <h4>Section Id : {section?.secId}</h4>
                </div>
                <h4>Question Details</h4>

                <div className="p-10">
                  {questions?.map((question: any, index: any) => {
                    return (
                      <>
                        <QuestionDetails question={question} index={index} />
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}
      </>
    </>
  );
};

export default VisitFormDetails;
