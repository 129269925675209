import * as Yup from "yup";

export type createCategoryTypes = {
  categoryName: string;
};

export const initalValues: createCategoryTypes = {
  categoryName: "",
};

export const categoryValidationSchema = Yup.object({
  categoryName: Yup.string().required("Category Name is required"),
});
