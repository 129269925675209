import $axiosProtected from "../../common/utils/axiosUtils";

// Get List of all Patient Details
export const getAllPatient = async (payload: any) => {
    return await $axiosProtected().get(
        `/patient/facility/get/web-portal?facilityCode=${
            payload.facilityCode
        }&page=${payload.page}&limit=${payload.limit}&status=${
            payload.status
        }&fromDate=${payload.fromDate}&toDate=${payload.toDate}&chwId=${
            payload.chwId
        }&name=${payload.name}${
            payload.patientType ? `&patientType=${payload.patientType}` : ""
        }`
    );
};

/**
 *  NOT IN USE
 *  Search Individual Patient Details By Name
 */

export const searchPatientByName = async (payload: any) => {
    return await $axiosProtected().post(
        `/patient/search/web-portal?page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Get a Patient Details
export const getPatientByUHID = async (payload: any) => {
    return await $axiosProtected().post(
        `/patient/get/uhid/web-portal`,
        payload
    );
};

// Get Patient Insights Details
export const getPatientInsights = async (payload: any) => {
    return await $axiosProtected().post(
        `scheduler/task/patient-insights/web-portal`,
        payload
    );
};

// Get Patient Consultation Details
export const getPatientConsultations = async (payload: any) => {
    return await $axiosProtected().post(
        `scheduler/schedule-consultation/status?status=${payload.status}&doctorName=${payload.doctorName}&consultationType=${payload.consultationType}&specialization=${payload.specialization}&page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Get Latest Patient Vitals
export const getLatestPatientVitals = async (payload: any) => {
    return await $axiosProtected().post(`vitals/latest`, payload);
};

// Get Patient Task Details based on UHID
export const getPatientTasks = async (payload: any) => {
    return await $axiosProtected().post(
        `scheduler/task/uhid?page=${payload.page}&limit=${payload.limit}`,
        payload
    );
};

// Get CHW list
export const getAllCHW = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/facility/list/all?facilityCode=${payload.facilityCode}`
    );
};

// Update Patient Profile
export const updatePatientProfile = async (payload: any) => {
    return await $axiosProtected().patch(`/patient/profile/update`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
