import $axiosProtected from "../../../common/utils/axiosUtils";

// Get list of all vendors
export const getAllVendors = async (payload: any) => {
    return await $axiosProtected().get(
        `/inventory/vendors/get/all?page=${payload.page}&limit=${payload.limit}`
    );
};

// Get vendor by id
export const getVendorById = async (payload: any) => {
    return await $axiosProtected().get(
        `/inventory/vendors/get/all?page=${payload.page}&limit=${payload.limit}`
    );
};

// Update vendors info
export const uploadVendorsInfo = async (payload: any) => {
    return await $axiosProtected().post(`inventory/products/store`, payload);
};
