import * as Yup from "yup";
import { Menu } from "@mui/material";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from "formik";

import Modal from "../../../components/Modal";
import AssignTask from "../components/AssignTask";
import CustomTooltip from "../../../components/CustomTooltip";
import Pagination from "../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import TableErrorComponent from "../../../components/Error/TableError";
import { dateFormatter } from "../../../common/utils";

const filterSchema = Yup.object().shape({
    date: Yup.string().label("Grade"),
});

const UnassignedTaskTable = ({
    bodyProps,
    isTasksLoading,
    isTasksFetching,
    isTaskError,
    currentPageNumber,
    setCurrentPageNumber,
    totalCHWEntites,
    totalAvailablePages,
    setFilterData,
    pageLimit,
    setPageLimit,
    refetch,
    errorMsg,
}: any) => {
    const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAssignModal = () => {
        setIsAssignModalOpen(!isAssignModalOpen);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                date: values.date,
            };
            setFilterData(payload);
            handleClose();
            onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            onSubmitProps.resetForm();
        }
    };

    const clearData = () => {
        const payload = {
            date: "",
        };
        setFilterData(payload);
    };

    let allTasks = bodyProps;

    const [selectedUser, setSelectedUser]: any = useState([]);

    useEffect(() => {
        setSelectedUser(allTasks);
        refetch();
    }, [allTasks]);

    const handleCheckbox = (e: any) => {
        const { name, checked } = e.target;

        let tempUser = selectedUser?.map((user: any, index: any) => {
            return user?._id === name ? { ...user, isChecked: checked } : user;
        });

        setSelectedUser(tempUser);
    };

    const checkCHWTasks = selectedUser?.filter((task: any) => {
        return task?.isChecked === true;
    });

    return (
        <div className="card text-dark">
            <Modal
                open={isAssignModalOpen}
                overlayStyles={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <AssignTask
                    styles={{
                        backgroundColor: "white",
                        width: "650px",
                        height: "350px",
                        msgHeight: "200px",
                        padding: "50px",
                    }}
                    onClose={handleAssignModal}
                    allTasks={selectedUser}
                    refetch={refetch}
                />
            </Modal>

            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>UNASSIGNED TASK LIST</h1>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                {checkCHWTasks?.length > 0 && (
                    <div className="card-toolbar">
                        {/* Assign task */}

                        <button
                            onClick={handleAssignModal}
                            className="btn btn-md btn-primary me-4"
                        >
                            ASSIGN
                        </button>

                        <button
                            onClick={handleClick}
                            className="btn btn-md btn-primary"
                        >
                            <i className="fas fa-filter"></i>
                            FILTER
                        </button>

                        {/* Filter Options */}
                        <div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                <Formik
                                    initialValues={{
                                        date: "",
                                    }}
                                    validationSchema={filterSchema}
                                    onSubmit={onFilterSubmit}
                                >
                                    {(formProps: any) => (
                                        <Form>
                                            <div className="py-5 px-8">
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        Date
                                                    </label>
                                                    <Field
                                                        id="date"
                                                        as="input"
                                                        type="date"
                                                        name="date"
                                                        className="form-control"
                                                    />
                                                    <div className="text-danger mt-5">
                                                        <ErrorMessage name="date" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-5 d-flex justify-content-center align-items-center">
                                                <button
                                                    type="submit"
                                                    disabled={
                                                        !formProps.isValid ||
                                                        formProps.isSubmitting
                                                    }
                                                    className="w-250px btn btn-sm btn-primary"
                                                >
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Menu>
                        </div>
                        {/* End Here */}
                    </div>
                )}
            </div>
            {isTaskError ? (
                <TableErrorComponent errorMessage={errorMsg} />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={100}>SELECT ROWS</TableHead>
                            <TableHead width={100}>SL NO</TableHead>
                            <TableHead width={100}>PATIENT NAME</TableHead>
                            <TableHead width={100}>PHONE</TableHead>
                            <TableHead width={100}>ADDRESS</TableHead>
                            <TableHead width={100}>TASK TYPE</TableHead>
                            <TableHead width={100}>REQ DATE</TableHead>
                            <TableHead width={100}>TASK STATUS</TableHead>
                            <TableHead width={100}>REQ BY</TableHead>
                        </TableHeadRow>

                        <TableBodyContainer
                            isLoading={isTasksLoading}
                            isError={isTaskError}
                            errorMessage={errorMsg}
                        >
                            {Array?.isArray(allTasks) &&
                                allTasks?.map((item: any, index: any) => {
                                    const patientName = item?.patientData?.map(
                                        (pa: any) => {
                                            return pa?.name;
                                        }
                                    );

                                    let addressSite;
                                    const patientAddress =
                                        item?.patientData?.map((pa: any) => {
                                            addressSite =
                                                pa?.addresses?.addressLines
                                                    ?.addressSiteName;
                                            let addressTown =
                                                pa?.addresses?.town;
                                            let addressDistrict =
                                                pa?.addresses?.district;
                                            let addressPostalCode =
                                                pa?.addresses?.postalCode;
                                            let addressState =
                                                pa?.addresses?.state;
                                            return `${addressSite} ${addressTown} ${addressDistrict} ${addressPostalCode} ${addressState}`;
                                        });

                                    const patientTelecom =
                                        item?.patientData?.map((pa: any) => {
                                            return pa?.demographicDetails
                                                ?.telecom;
                                        });

                                    const patientUHID = item?.patientData?.map(
                                        (pa: any) => {
                                            return pa?.uhid;
                                        }
                                    );
                                    return (
                                        <TableRow collapsible={false}>
                                            <TableCell align="center">
                                                <input
                                                    type="checkbox"
                                                    name={item?._id}
                                                    checked={item?.isChecked}
                                                    className="form-check-input"
                                                    onChange={handleCheckbox}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>

                                            <TableCell>
                                                <Link
                                                    to={`/patient/overview?pId=${patientUHID}`}
                                                    className="text-dark text-dark fw-bolder text-hover-primary"
                                                >
                                                    {patientName}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {patientTelecom}
                                            </TableCell>
                                            <TableCell>
                                                <CustomTooltip
                                                    title={patientAddress}
                                                    placement="top"
                                                >
                                                    <span className="border-none">
                                                        {addressSite}
                                                    </span>
                                                </CustomTooltip>
                                            </TableCell>
                                            <TableCell>
                                                {item?.taskType}
                                            </TableCell>
                                            <TableCell>
                                                {`${dateFormatter(
                                                    item?.taskDate
                                                )} ( ${item?.taskTime} )`}
                                            </TableCell>
                                            <TableCell>
                                                {item?.taskAssigned
                                                    ? "Assigned"
                                                    : "Unassigned"}
                                            </TableCell>
                                            <TableCell>
                                                {item?.requestMedium ===
                                                "WhatsApp"
                                                    ? "Patient"
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBodyContainer>
                    </Table>

                    {/* Pagination */}
                    {!isTaskError && (
                        <Pagination
                            dataCount={totalCHWEntites}
                            handleCallback={(val: number | string) => {
                                setPageLimit(+val);
                            }}
                            handleNext={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={
                                currentPageNumber === totalAvailablePages
                            }
                            pageNumber={currentPageNumber}
                            pageLimit={pageLimit}
                            prevDisabled={currentPageNumber === 1}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default UnassignedTaskTable;
