import $axiosProtected from "../../common/utils/axiosUtils";

// Generate report for any service
export const generateReport = async (payload: any) => {
  return await $axiosProtected().post(`/report/generate`, payload);
};

// Get all the reports

export const getAllReports = async (payload: any) => {
  return await $axiosProtected().post(
    `/report/request/all?page=${payload.page}&limit=${payload.limit}&reportType=${payload.reportType}`
  );
};

// Download a single report
export const getReport = async (payload: any) => {
  return await $axiosProtected().get(`/report/request?id=${payload.id}`);
};

// Get all reports
export const getAllReportTypes = async () => {
  return await $axiosProtected().get(`/report/reportType`);
};
