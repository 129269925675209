import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {
  initalValues,
  attendanceValidationSchema,
} from "../model/TaskListExportModel";
import useSearchParams from "../../../hooks/useSearchParams";
import { todayDate } from "../../../common/utils/datesUtils";
import useMutateRequest from "../../../hooks/getMutation.query";
import { generateReport } from "../../../services/report/ReportGeneration.hook";

type ExportProps = {
  onClose: () => void;
  styles: any;
  chwName: string;
};

const TaskListExport = ({ onClose, styles, chwName }: ExportProps) => {
  const { chwId: id }: any = useSearchParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const facilityCode: any = localStorage.getItem("facilityCode");

  const { mutate, isLoading, isError, isSuccess }: any = useMutateRequest(
    (data: any) => generateReport(data)
  );

  useEffect(() => {
    if (isSuccess === true) {
      setSpinner(true);
      setTimeout(() => {
        onClose();
        setSpinner(false);
        navigate("/report/report-management");
      }, 3500);
    }
  }, [isSuccess]);

  const onSubmit = (values: any) => {
    let payload = {
      reportType: "exportTask",
      reportRepository: "scheduler",
      filter: [
        {
          filterName: "chwId",
          filterValue: id,
        },
        {
          filterName: "startDate",
          filterValue: String(values.startDate),
        },
        {
          filterName: "endDate",
          filterValue: String(values.endDate),
        },
        {
          filterName: "facilityCode",
          filterValue: facilityCode,
        },
      ],
    };

    try {
      mutate(payload, {
        onSuccess: (data: any) => {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
        onError: (error: any) => {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading || spinner ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg === true ? (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.msgHeight,
            padding: styles?.padding,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {isError === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Failed to export task details for this chw
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-primary me-5"
                >
                  Please Retry
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Your request has been successfully submitted
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={attendanceValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formProps: any) => (
              <Form>
                <h1 className="text-center text-hover-primary">
                  EXPORT OPTIONS
                </h1>
                <div className="mt-5">
                  <label
                    htmlFor="exportFormat"
                    className="form-label fs-4 fw-bolder"
                  >
                    EXPORT FORMAT
                  </label>
                  <Field
                    id="exportFormat"
                    as="select"
                    name="exportFormat"
                    disabled
                    className="form-select form-select-light form-select-lg required"
                    style={{ cursor: "not-allowed" }}
                  >
                    <option value="CSV">CSV</option>
                    <option value="EXCEL">EXCEL</option>
                    <option value="PDF">PDF</option>
                  </Field>
                  <div className="text-danger mt-2">
                    <ErrorMessage name="exportFormat" />
                  </div>
                  <div className="text-muted fw-bolder mt-2 ms-2">
                    By default the records would be exported in CSV format.
                  </div>
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="exportFormat"
                    className="form-label fs-4 fw-bolder"
                  >
                    CHW NAME
                  </label>
                  <Field
                    value={chwName}
                    disabled
                    className="form-control form-control-light form-control-lg"
                    style={{ cursor: "not-allowed" }}
                  />
                </div>

                <div className="mt-5">
                  <label className="form-label fs-4 fw-bolder required">
                    START DATE
                  </label>
                  <Field
                    id="startDate"
                    as="input"
                    type="date"
                    name="startDate"
                    max={todayDate}
                    className="form-control datepicker"
                  />

                  <div className="text-danger mt-2">
                    <ErrorMessage name="startDate" />
                  </div>
                </div>
                <div className="mt-3 mb-8">
                  <label className="form-label fs-4 fw-bolder required">
                    END DATE
                  </label>
                  <Field
                    id="endDate"
                    as="input"
                    type="date"
                    name="endDate"
                    max={todayDate}
                    className="form-control"
                  />
                  <div className="text-muted fw-bolder mt-2 ms-2">
                    Select date range within a year.
                  </div>
                  <div className="text-danger mt-2">
                    <ErrorMessage name="endDate" />
                  </div>
                </div>
                <div className="mt-10 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="w-150px btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    className="w-150px btn btn-md btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default TaskListExport;
