import * as Yup from "yup";

export type TargetDistributionTypes = {
    distributionType: string;
    selectedKPI: string;
    selectedMonth: string;
    targetValueForMonth?: string;
    remainingTargetValue: string;
    approxNumToDistributeAtFacilityLevel?: string;
    approxNumToDistribute?: any;
    totalNumberOfCHWInFacility?: any;
};

export const initalValuesForTargetDistribution: TargetDistributionTypes = {
    distributionType: "Automatic",
    selectedKPI: "",
    selectedMonth: "",
    targetValueForMonth: "",
    remainingTargetValue: "",
    approxNumToDistributeAtFacilityLevel: "",
    totalNumberOfCHWInFacility: "",
    approxNumToDistribute: [
        {
            targetForChw: "",
        },
    ],
};

export const TargetDistributionValidationSchema = Yup.object({
    // Step 1
    selectedKPI: Yup.string().required().label("KPI"),
    selectedMonth: Yup.string().required().label("Month"),
    // Step 2
    approxNumToDistribute: Yup.array().of(
        Yup.object().shape({
            targetForChw: Yup.number()
                .when("distributionType", {
                    is: "Manual",
                    then: Yup.number().required("Target value is required"),
                })
                // .moreThan(0, "Should be greater than one")
                .typeError("It should be a number"),
            // Obj ends
        })
    ),
});
