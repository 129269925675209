import { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";

const Step7: FC = () => {
  return (
    <div className="w-100">
      <div className="pb-8 pb-lg-10">
        <h2 className="fw-bolder text-dark">Your Are Done!</h2>

        {/* <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please
          <Link to="/auth/login" className="link-primary fw-bolder">
            {" "}
            Sign In
          </Link>
          .
        </div> */}
      </div>

      <div className="mb-0">
        <div className="fs-6 text-gray-800 mb-5">
          Look's like you have gathered required information to onboard a doctor
          to our platform.
        </div>

        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-2tx svg-icon-warning me-4"
          />
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <h4 className="text-gray-800 fw-bolder">
                We need your attention!
              </h4>
              <div className="fs-6 text-gray-600">
                To start using our great platform. Please
                <a href="/doctor/doctor-management" className="fw-bolder">
                  {" "}
                  Onboard Doctor
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step7 };
