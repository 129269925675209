import $axiosProtected from "../../../common/utils/axiosUtils";

type IPayment = {
    paymentType?: string;
    limit: number;
    page: number;
    facilityCode: string;
    fromDate?: string;
    toDate?: string;
    orderId?: string;
    salesOrderType?: string;
};
// Get List of all Indents
export const getPaymentList = async ({
    facilityCode,
    limit,
    page,
    paymentType,
    fromDate,
    toDate,
    orderId,
    salesOrderType,
}: IPayment) => {
    return await $axiosProtected().get(
        `/inventory/salesOrder/payments/web?limit=${limit}&page=${page}&facilityCode=${facilityCode}${
            paymentType ? `&paymentType=${paymentType}` : ""
        }${fromDate ? `&startDate=${fromDate}` : ""}${
            toDate ? `&endDate=${toDate}` : ""
        }${orderId ? `&orderId=${orderId}` : ""}${
            salesOrderType ? `&salesOrderType=${salesOrderType}` : ""
        }`
    );
};
