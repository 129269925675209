import React, { useEffect, useMemo } from "react";
import {
    TableBodyContainer,
    TableCell,
    TableRow,
    Table,
    TableHead,
    TableHeadRow,
} from "../../../../components/Table";
import dayjs from "dayjs";
import Modal from "../../../../components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SelectMenu, TextInput } from "../../../../components/Inputs";
import ErrorMessageText from "../../../../components/ErrorMessageText/ErrorMessageText";
import _ from "lodash";
import { dateFormatter } from "../../../../common/utils";

type Props = {
    data: any;
    totalAmount: number;
    deliveryStatus: string;
    handleFinalAmountAndDelivery: Function;
    isModalOpen: boolean;
    setIsModalOpen: Function;
    isLoading: boolean;
    salesOrderProductList: any;
};
const tableNames = [
    "PAYMENT TYPE",
    "UPI TRANSACTION ID",
    "RECIEVED AMOUNT",
    "RECIEVED DATE",
];

const validationSchemaForPaymentInfo = Yup.object().shape({
    paymentMode: Yup.string()
        .required("Payment mode is required")
        .oneOf(["Cash", "UPI"], "Payment mode must be either Cash or UPI"),
    UPITransactionId: Yup.string().when("paymentMode", {
        is: "UPI",
        then: Yup.string().required("UPI Transaction ID is required"),
    }),
    advancePaymentValue: Yup.number()
        .typeError("Amount must be a number")
        .min(0, "Amount must be greater than or equal to 0")
        .test({
            name: "maxOrEqual",
            exclusive: false,
            message: "Amount must be equal to due amount",
            test: function (value: any) {
                const netAmount = this.parent.netAmount || 0;
                return parseInt(value) === parseInt(netAmount);
            },
        }),
    netAmount: Yup.number()
        .typeError("Net amount must be a number")
        .min(0, "Net amount must be greater than or equal to 0"),
});
type IPayment = {
    paymentMode: string;
    UPITransactionId: string;
    advancePaymentValue: string;
    netAmount: number;
};

const PaymentDetails = ({
    data,
    deliveryStatus,
    handleFinalAmountAndDelivery,
    isLoading,
    isModalOpen,
    salesOrderProductList,
    setIsModalOpen,
    totalAmount,
}: Props) => {
    const paymentStatus = useMemo(
        () =>
            salesOrderProductList &&
            _.isArray(salesOrderProductList) &&
            salesOrderProductList?.length > 0 &&
            salesOrderProductList[0]?.paymentStatus,
        [salesOrderProductList]
    );
    const formik = useFormik({
        initialValues: {
            paymentMode: "",
            UPITransactionId: "",
            advancePaymentValue: "0",
            netAmount: 0,
        },
        validationSchema: validationSchemaForPaymentInfo,
        onSubmit: (values: IPayment, { resetForm }) => {
            // handleGenerateBill();
            // resetForm();
            handleFinalAmountAndDelivery({
                advance: +values.advancePaymentValue,
                paymentMode: values.paymentMode,
                UPITransactionId: values.UPITransactionId,
            });
        },
    });

    useEffect(() => {
        let receivedAdvance = 0;
        data?.forEach((ele: any) => (receivedAdvance += ele?.amount));
        // setNetAmount(totalAmount - receivedAdvance);
        formik.setFieldValue("netAmount", totalAmount - receivedAdvance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, totalAmount]);

    // console.log(formik.values);

    return (
        <div style={{ marginBottom: "5rem", marginTop: "2rem" }}>
            <h5>Payment Details</h5>
            {data.length > 0 && (
                <Table minHeight="min-h-100px mb-4">
                    <TableHeadRow>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>
                        {tableNames?.map((name) => {
                            return (
                                <TableHead width={180} key={name} align="left">
                                    {name.toUpperCase()}
                                </TableHead>
                            );
                        })}
                    </TableHeadRow>
                    <TableBodyContainer
                        isLoading={false}
                        isError={false}
                        errorMessage={""}
                    >
                        {data?.map((ele: any, idx: number) => {
                            return (
                                <TableRow collapsible={false} key={idx}>
                                    <TableCell align="center">
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>{ele?.paymentType}</TableCell>
                                    <TableCell>
                                        {ele?.UPITransactionId
                                            ? ele?.UPITransactionId
                                            : "--"}
                                    </TableCell>
                                    <TableCell>{ele?.amount}</TableCell>
                                    <TableCell>
                                        {ele?.receivedDate
                                            ? dateFormatter(ele?.receivedDate)
                                            : "N/A"}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBodyContainer>
                </Table>
            )}
            {deliveryStatus !== "Order Delivered" && (
                <>
                    {formik.values.netAmount === 0 &&
                    paymentStatus === "Paid" ? (
                        <div className="d-flex align-items-center align-middle mt-4 ">
                            <p className="fw-bold ">
                                Full payment received. Please confirm Delivery
                            </p>
                            <button
                                className="btn btn-primary"
                                style={{ marginLeft: "1rem" }}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Confirm Delivery
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center align-middle mt-4 ">
                            <p className="fw-bold ">
                                Total Amount Due: {formik.values.netAmount}
                            </p>{" "}
                            <button
                                className="btn btn-primary"
                                style={{ marginLeft: "1rem" }}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Collect Due Amount
                            </button>
                        </div>
                    )}
                </>
            )}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen}
                // styles=""
                // overlayStyles=""
            >
                {formik.values.netAmount === 0 ? (
                    <div className="bg-white rounded-2 p-6">
                        <p className="mb-4 fw-semibold fs-5">
                            Are you sure, you want to confirm delivery ?
                        </p>
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                            <button
                                className="btn btn-light"
                                style={{ marginRight: "1rem" }}
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                disabled={isLoading}
                                onClick={() => {
                                    handleFinalAmountAndDelivery({
                                        advance:
                                            +formik.values.advancePaymentValue,
                                        paymentMode: formik.values.paymentMode,
                                        UPITransactionId:
                                            formik.values.UPITransactionId,
                                    });
                                }}
                            >
                                Yes, Confirm Delivery
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="bg-white rounded-2 p-6">
                        <p className="mb-4 fw-semibold fs-5 ">
                            Collect Payment
                        </p>
                        {/* <p>Add details of the amount due and mode of payment.</p> */}
                        <div>
                            <TextInput
                                className="mb-3"
                                controlId="advancePaymentValue"
                                label="Enter Amount"
                                placeholder="0"
                                type="text"
                                data-testid="advancePaymentValue"
                                name="advancePaymentValue"
                                onChange={formik.handleChange(
                                    "advancePaymentValue"
                                )}
                                value={formik.values.advancePaymentValue}
                                required
                            />
                            {formik.touched.advancePaymentValue &&
                                formik.errors.advancePaymentValue && (
                                    <ErrorMessageText
                                        message={
                                            formik.errors.advancePaymentValue
                                        }
                                    />
                                )}
                        </div>
                        <div>
                            <SelectMenu
                                label="Payment Mode"
                                required
                                name="paymentMode"
                                data-testid="paymentMode-input"
                                onChange={formik.handleChange("paymentMode")}
                                className="mb-3 "
                                value={formik.values.paymentMode}
                            >
                                <option value={""}>Select</option>
                                <option value={"Cash"}>Cash</option>
                                <option value={"UPI"}>UPI</option>
                            </SelectMenu>
                            {formik.touched.paymentMode &&
                                formik.errors.paymentMode && (
                                    <ErrorMessageText
                                        message={formik.errors.paymentMode}
                                    />
                                )}
                        </div>
                        <div>
                            {formik.values.paymentMode === "UPI" && (
                                <div>
                                    <TextInput
                                        className="mb-3 "
                                        controlId=""
                                        label="UPI Transaction Id"
                                        placeholder="Add transaction Id"
                                        type="text"
                                        data-testid="UPITransactionId"
                                        name="UPITransactionId"
                                        onChange={formik.handleChange(
                                            "UPITransactionId"
                                        )}
                                        value={formik.values.UPITransactionId}
                                    />

                                    {formik.touched.UPITransactionId &&
                                        formik.errors.UPITransactionId && (
                                            <ErrorMessageText
                                                message={
                                                    formik.errors
                                                        .UPITransactionId
                                                }
                                            />
                                        )}
                                </div>
                            )}
                        </div>

                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                            <button
                                className="btn btn-light"
                                style={{ marginRight: "1rem" }}
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isLoading}
                                className="btn btn-primary"
                                onClick={() => formik.handleSubmit()}
                            >
                                Recieve Money
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default PaymentDetails;
