import "bootstrap/dist/css/bootstrap.min.css";
import ClipLoader from "react-spinners/ClipLoader";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const DoctorHeader = ({
  doctorData,
  consultationCountPerDay,
  isLoading,
}: any) => {
  const doctorDetails = doctorData;

  return (
    <div className="card pb-5">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7">
            <div className="mt-2 symbol h-200px symbol-fixed position-relative">
              <img
                src={
                  doctorDetails?.demographicDetails?.photo
                    ? doctorDetails?.demographicDetails?.photo
                    : toAbsoluteUrl("/media/ikure/blank.png")
                }
                alt="Avatar"
                className="h-200px w-175px"
              />
              {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >
                    {doctorDetails?.name ? doctorDetails?.name : "N/A"}
                  </a>

                  <span className="fw-bolder fs-6 me-2 ms-2">
                    {doctorDetails?.onboardingStatus &&
                    doctorDetails?.onboardingStatus === "Entirely Completed" ? (
                      <span className="badge badge-success">Verified</span>
                    ) : (
                      <span className="badge badge-danger">Unverified</span>
                    )}
                  </span>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-5 mt-3 mb-4 pe-2">
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-600 text-hover-primary mb-2"
                  >
                    <span>Email Id :</span>
                    {doctorDetails?.demographicDetails?.email
                      ? doctorDetails?.demographicDetails?.email
                      : "N/A"}
                  </a>
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-600 text-hover-primary ms-5 mb-2"
                  >
                    <span>Telecom :</span>
                    {doctorDetails?.demographicDetails?.telecom
                      ? doctorDetails?.demographicDetails?.telecom
                      : "N/A"}
                  </a>
                </div>
              </div>
            </div>

            {/* Counters */}
            <div className="d-flex flex-wrap fw-bold fs-5 mb-1 pe-2 mt-2">
              <div className="d-flex ">
                <div className="w-25% p-2 h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-5 border-gray-500 border-dotted">
                  {isLoading ? (
                    <ClipLoader size={50} speedMultiplier={0.6} />
                  ) : (
                    <>
                      <h3 className="text-primary">
                        {consultationCountPerDay?.totalConsultation}
                      </h3>
                      <h3>TOTAL CONSULTATION</h3>
                    </>
                  )}
                </div>
                <div className="w-25% p-2 h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-5  border-gray-500 border-dotted">
                  {isLoading ? (
                    <ClipLoader size={50} speedMultiplier={0.6} />
                  ) : (
                    <>
                      <h3 className="text-danger">
                        {consultationCountPerDay?.pendingConsultation}
                      </h3>
                      <h3>PENDING CONSULTATION</h3>
                    </>
                  )}
                </div>
                <div className="w-25% p-2 h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly  me-5 border-gray-500 border-dotted">
                  {isLoading ? (
                    <ClipLoader size={50} speedMultiplier={0.6} />
                  ) : (
                    <>
                      <h3 className="text-success">
                        {consultationCountPerDay?.completedConsultation}
                      </h3>
                      <h3>COMPLETED CONSULTATION</h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Nav Links */}
        {/* <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/doctor/${id}/overview` && "active")
                }
                to={`/doctor/${id}/overview`}
              >
                OVERVIEW
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/doctor/${id}/overview/details` &&
                    "active")
                }
                to={`/doctor/${id}/overview/details`}
              >
                DETAILS
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/doctor/${id}/overview/doctor-appointments` && "active")
                }
                to={`/doctor/${id}/overview/doctor-appointments`}
              >
                APPOINTMENT LIST
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/doctor/${id}/overview/doctor-slot-booking` && "active")
                }
                to={`/doctor/${id}/overview/doctor-slot-booking`}
              >
                SLOT BOOKING
              </Link>
            </li> */}
        {/* </ul>
        </div> */}
      </div>
    </div>
  );
};

export { DoctorHeader };
