import { useState } from "react";
import Modal from "../../../../../components/Modal";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";

import AssetViewer from "../components/AssetViewer";
import { isPDF } from "../../../../../common/utils";

const PrescriptionAndLabTest = ({ data }: any) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [userSelectedData, setUserSelectedData] = useState<any>();
    const handleModal = (userSelectedData: any) => {
        setUserSelectedData(userSelectedData);
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                overlayStyles={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <AssetViewer
                    styles={{
                        backgroundColor: "white",
                        // backgroundColor: "rgba(0, 0, 0, 0.5)",
                        // width: "650px",
                        // height: "450px",
                        // padding: "10px",
                    }}
                    onClose={handleModal}
                    userData={userSelectedData}
                />
            </Modal>
            <div>
                <h1 className="">Prescription </h1>
                <Table nested>
                    <TableHeadRow>
                        <TableHead width={100} align="center">
                            SL No
                        </TableHead>
                        <TableHead width={100}>DATE</TableHead>
                        <TableHead width={100}>VIEW</TableHead>
                    </TableHeadRow>

                    {data?.tests?.length === 0 ? (
                        <>
                            <tbody>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <span className="text-center fw-bolder text-danger">
                                            No data
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    ) : (
                        <TableBodyContainer isLoading={false}>
                            {data?.prescriptions?.map(
                                (prescription: any, index: any) => {
                                    let prescriptionDate = new Date(
                                        prescription?.prescriptionDate
                                    );
                                    let pdfFile = isPDF(
                                        prescription?.prescriptionFile
                                    );

                                    return (
                                        <TableRow collapsible={false}>
                                            <TableCell align="center">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                {prescriptionDate?.toDateString()}
                                            </TableCell>

                                            <TableCell>
                                                {pdfFile === true ? (
                                                    <button
                                                        onClick={() => {
                                                            handleModal(
                                                                prescription
                                                            );
                                                        }}
                                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    >
                                                        View PDF
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => {
                                                            handleModal(
                                                                prescription
                                                            );
                                                        }}
                                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    >
                                                        View Image
                                                    </button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBodyContainer>
                    )}
                </Table>
            </div>
            <>
                <h1 className="">Lab Test</h1>
                <Table nested>
                    <TableHeadRow>
                        <TableHead width={100} align="center">
                            SL No
                        </TableHead>
                        <TableHead width={100}>DATE</TableHead>
                        <TableHead width={100}>VIEW</TableHead>
                    </TableHeadRow>

                    {data?.tests?.length === 0 ? (
                        <>
                            <tbody>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <span className="text-center fw-bolder text-danger">
                                            No data
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    ) : (
                        <TableBodyContainer isLoading={false}>
                            {data?.tests?.map((labTest: any, index: any) => {
                                let testDate = new Date(labTest?.testDate);
                                let pdfFile = isPDF(labTest?.testFile);
                                return (
                                    <TableRow collapsible={false}>
                                        <TableCell align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {testDate?.toDateString()}
                                        </TableCell>

                                        <TableCell>
                                            {pdfFile === true ? (
                                                <button
                                                    onClick={() => {
                                                        handleModal(labTest);
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    View PDF
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        handleModal(labTest);
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    View Image
                                                </button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBodyContainer>
                    )}
                </Table>
            </>
        </>
    );
};

export default PrescriptionAndLabTest;
