import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {
  exportValidationSchema,
  initalValues,
} from "../models/HealthCardsExportModel";
import { generateReport } from "../../../services/report/ReportGeneration.hook";
import useMutateRequest from "../../../hooks/getMutation.query";
import useSearchParams from "../../../hooks/useSearchParams";

type ExportProps = {
  onClose: () => void;
  styles: any;
};

const HealthCardsExport = ({ onClose, styles }: ExportProps) => {
  const { batchId: id }: any = useSearchParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);

  // API Call
  const { mutate, isLoading, isError, isSuccess }: any = useMutateRequest(
    (data: any) => generateReport(data)
  );

  useEffect(() => {
    if (isSuccess === true) {
      setSpinner(true);
      setTimeout(() => {
        onClose();
        setSpinner(false);
        navigate("/report/report-management");
      }, 3500);
    }
  }, [isSuccess]);

  const onSubmit = (values: any) => {
    let ifAll: any = [
      {
        filterName: "batch",
        filterValue: Number(id),
      },
    ];

    let notAll: any = [
      {
        filterName: "batch",
        filterValue: Number(id),
      },
      {
        filterName: "usageStatus",
        filterValue: values?.usageStatus,
      },
      {
        filterName: "status",
        filterValue: values?.cardStatus,
      },
    ];

    let payload: any = {
      reportRepository: "health-card",
      reportType: "exportHealthCard",
      filter: values.usageStatus === "All" ? ifAll : notAll,
    };

    try {
      mutate(payload, {
        onSuccess: (data: any) => {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
        onError: (error: any) => {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading || spinner ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg ? (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.msgHeight,
            padding: styles?.padding,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {isError === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                No Unused Healthcards in this Batch
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-primary me-5"
                >
                  Please Retry
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Your request has been successfully submitted
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={exportValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formProps: any) => (
              <Form>
                <h1 className="text-center text-hover-primary">
                  EXPORT OPTIONS
                </h1>
                <div className="mt-5">
                  <label
                    htmlFor="exportFormat"
                    className="form-label fs-4 fw-bolder"
                  >
                    EXPORT FORMAT
                  </label>
                  <Field
                    id="exportFormat"
                    as="select"
                    name="exportFormat"
                    disabled
                    className="form-select form-select-light form-select-lg required"
                    style={{ cursor: "not-allowed" }}
                  >
                    <option value="CSV">CSV</option>
                    <option value="EXCEL">EXCEL</option>
                    <option value="PDF">PDF</option>
                  </Field>
                  <div className="text-danger mt-5">
                    <ErrorMessage name="exportFormat" />
                  </div>
                  <div className="text-muted fw-bolder mt-2 ms-2">
                    By Default all the Unsused Healthcards would be exported in
                    CSV Format.
                  </div>
                </div>
                <div className="mt-5">
                  <label
                    htmlFor="usageStatus"
                    className="form-label fs-4 fw-bolder "
                  >
                    USAGE STATUS
                  </label>
                  <Field
                    id="usageStatus"
                    as="select"
                    name="usageStatus"
                    className="form-select form-select-light form-select-lg required"
                  >
                    <option value="All" selected>
                      All
                    </option>
                    <option value="Unassigned">Unassigned</option>
                    <option value="Assigned">Assigned</option>
                  </Field>
                  <div className="text-danger mt-5">
                    <ErrorMessage name="usageStatus" />
                  </div>
                </div>
                <div className="mt-5">
                  <label
                    htmlFor="cardStatus"
                    className="form-label fs-4 fw-bolder"
                  >
                    CARD STATUS
                  </label>
                  <Field
                    id="cardStatus"
                    as="select"
                    name="cardStatus"
                    className="form-select form-select-light form-select-lg required"
                  >
                    <option value="Active" selected>
                      Active
                    </option>
                    <option value="Inactive">Inactive</option>
                  </Field>
                  <div className="text-danger mt-5">
                    <ErrorMessage name="cardStatus" />
                  </div>
                </div>

                <div className="mt-10 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="w-150px btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    className="w-150px btn btn-md btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default HealthCardsExport;
