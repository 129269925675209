import * as Yup from "yup";

export type appointmentExportValueTypes = {
  exportFormat: string;
  facilityCode: string;
  chwId: string;
  appointmentStatus: string;
  doctorName: string;
  fromDate: string;
  toDate: string;
};

export const appointmentListInitalValues: appointmentExportValueTypes = {
  exportFormat: "CSV",
  facilityCode: "",
  chwId: "",
  doctorName: "",
  appointmentStatus: "",
  fromDate: "",
  toDate: "",
};

export const appointmentListExportValidationSchema = Yup.object({
  exportFormat: Yup.string().label("Export format is required"),
  facilityCode: Yup.string().label("Facility name is required"),
  chwId: Yup.string().label("CHW name is required"),
  appointmentStatus: Yup.string().label("CHW name is required"),
  doctorName: Yup.string().label("Doctor name is required"),
  fromDate: Yup.string().required("From date is required"),
  toDate: Yup.string().required("To date is required"),
});
