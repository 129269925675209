import React, { useMemo, useState } from "react";
import SalesDetailsInfo from "./SalesDetailsInfo";
import ProductListTable from "./ProductListTable";
import SalesFinalBill from "./SalesFinalBill";

import TrackingDetails from "./TrackingDetails";
// import PaymentDetails from "./PaymentDetails";
import CheckBox from "../../../../components/Checkbox";
import Modal from "../../../../components/Modal";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getSalesOrderDetails } from "../../../../services/supply-chain/sales-order/sales-order-details/SalesOrderDetails.services";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { createSalesOrder } from "../../../../services/supply-chain/sales-order/modify-sales-order/index.services";
import toast, { Toaster } from "react-hot-toast";
type IdueAmtRcv = {
    paymentMode: string;
    advance: number;
    UPITransactionId?: string;
};

const SalesOrderDetails = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const orderId = searchParams.get("orderId") as string;
    const page = 1;
    const limit = 10;
    const facilityCode = localStorage.getItem("facilityCode") as string;
    // API
    const { data, isLoading, isFetching, isError, error, refetch }: any =
        useGetRequest(["getSalesOrderDetails", page, limit, facilityCode], () =>
            getSalesOrderDetails({
                page,
                limit,
                orderId,
            })
        );

    const salesOrderData = useMemo(() => {
        return data?.data?.data?.order;
    }, [data]);
    const { mutate, isLoading: mutateIsloading } = useMutateRequest(
        (data: any) => createSalesOrder(data)
    );
    // const totalEntites = data?.data?.results;
    // const totalPages = Math.ceil(totalEntites / limit);
    const errorMessage = error?.response?.data?.message;

    const pageRefresh = isLoading || isFetching;
    const handleDeliveryStatus = () => {
        const formData = new FormData();
        formData.append(
            "salesOrderReferenceId",
            _.isArray(salesOrderData) && salesOrderData[0]?._id
        );
        formData.append("facilityCode", facilityCode);

        formData.append("deliveryStatus", "Order Packed");
        mutate(formData, {
            onSuccess: (data: any) => {
                successToastMsg();
                setTimeout(() => {
                    refetch();
                }, 1500);
                setIsModalOpen(false);
            },
            onError: (error: any) => {
                errorToastMsg(error?.response?.data?.message);
            },
        });
    };
    const handleFinalAmountAndDelivery = ({
        advance,
        paymentMode,
        UPITransactionId,
    }: IdueAmtRcv) => {
        const formData = new FormData();
        formData.append(
            "salesOrderReferenceId",
            _.isArray(salesOrderData) && salesOrderData[0]?._id
        );
        formData.append("facilityCode", facilityCode);
        formData.append("amount", JSON.stringify(advance));
        if (advance !== 0) {
            formData.append("paymentType", paymentMode);
        } else {
            // if advance is zero we are sending this as default
            formData.append("paymentType", "Cash");
        }
        if (paymentMode === "UPI") {
            formData.append("UPITransactionId", UPITransactionId as string);
        }
        // formData.append("salesOrderType", formik.values.billingFor);

        // formData.append("paymentStatus", "Paid");
        formData.append("deliveryStatus", "Order Delivered");

        mutate(formData, {
            onSuccess: (data: any) => {
                successToastMsg();
                setIsModalOpen(false);
                setTimeout(() => {
                    refetch();
                }, 1500);
            },
            onError: (error: any) => {
                errorToastMsg(error?.response?.data?.message);
            },
        });
    };
    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Successfully changed the order status!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = (err: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {err}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    return (
        <div className="card text-dark p-3 overflow-y-auto min-vh-100 ">
            <Toaster />
            <div>
                <SalesDetailsInfo salesOrderData={salesOrderData} />
            </div>
            <div className="mt-4">
                <div className="d-flex justify-content-end mb-5">
                    {salesOrderData &&
                        salesOrderData[0]?.customerType === "Patient" && (
                            <a
                                href={
                                    salesOrderData && salesOrderData[0]?.invoice
                                }
                                download="Invoice"
                            >
                                <button
                                    className="btn btn-primary "
                                    data-testid="download-btn"
                                >
                                    Download Invoice
                                </button>
                            </a>
                        )}
                </div>
                <ProductListTable
                    salesOrderData={salesOrderData}
                    pageRefresh={pageRefresh}
                    isError={isError}
                    errorMessage={errorMessage}
                />
            </div>
            <hr className="hr" />
            <div>
                <SalesFinalBill
                    salesOrderProductList={salesOrderData}
                    handleFinalAmountAndDelivery={handleFinalAmountAndDelivery}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    isLoading={mutateIsloading}
                />
            </div>
            {_.isArray(salesOrderData) &&
                salesOrderData[0]?.deliveryStatus === "Order Placed" && (
                    <div className="d-flex" style={{ marginLeft: "1rem" }}>
                        <CheckBox
                            isChecked={isChecked}
                            name="check"
                            onChange={() => setOpen(true)}
                        />
                        <p className="fs-6 ml-4" style={{ marginLeft: "1rem" }}>
                            Is the order packed ?
                        </p>
                        <Modal open={open}>
                            <div className="card p-5 text-dark">
                                <p className="fs-3">
                                    The status will be changed to Order Packed.
                                    Are you sure?
                                </p>
                                <div className="d-flex justify-content-between mt-5">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setIsChecked(
                                                (prevValue) => !prevValue
                                            );
                                            setOpen(false);
                                            handleDeliveryStatus();
                                        }}
                                    >
                                        Change Status
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )}
            <div>
                <TrackingDetails salesOrderData={salesOrderData} />
            </div>
            {/* {_.isArray(salesOrderData) &&
                salesOrderData[0]?.paymentStatus &&
                salesOrderData[0].payments &&
                salesOrderData[0].payments.length > 0 && (
                    <PaymentDetails
                        data={
                            _.isArray(salesOrderData) &&
                            salesOrderData[0].payments
                                ? salesOrderData[0].payments
                                : []
                        }
                    />
                )} */}
        </div>
    );
};

export default SalesOrderDetails;
