import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Formik, Form, FormikValues } from "formik";
import toast, { Toaster } from "react-hot-toast";

import ProfileForm from "./ProfileForm";
import {
  initsForPatientProfileUpdate,
  patientProfileUpdateSchema,
} from "./model/PatientProfileUpdate";
import useMutateRequest from "../../../../hooks/getMutation.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import { updatePatientProfile } from "../../../../services/patient/PatientManagement.hook";

const UpdatePatientProfile = ({ handleProfileUpdate, patientDetails }: any) => {
  const { data, isLoading, error, mutate, isSuccess, isError }: any =
    useMutateRequest((data: any) => updatePatientProfile(data));

  const removeEmpty = (obj: any) => {
    return Object.keys(obj)
      .filter(function (k) {
        return obj[k] != "";
      })
      .reduce(function (acc: any, k) {
        acc[k] = obj[k];
        return acc;
      }, {});
  };

  const [spinner, setSpinner] = useState(false);
  const [msg, setMsg] = useState<boolean>(false);

  // Toast
  const [open, setOpen] = useState<boolean>(false);
  const { pId: id }: any = useSearchParams();

  const submitStep: any = (values: any, actions: FormikValues) => {
    try {
      let customPayload = removeEmpty(values);
      let formData = new FormData();
      formData.append("uhid", id);
      for (const key in customPayload) {
        // console.log(key, customPayload[key]);
        formData.append(key, customPayload[key]);
      }

      mutate(formData, {
        onSuccess: (data: any) => {
          setSpinner(true);
          setOpen(true);
          setMsg(false);
          setTimeout(() => {
            setSpinner(false);
            handleProfileUpdate();
          }, 3000);
        },
        onError: (error: any) => {
          setSpinner(true);
          setOpen(true);
          setMsg(false);
          setTimeout(() => {
            setSpinner(false);
            handleProfileUpdate();
          }, 3000);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully update patient details !!
          <button
            className="ms-15"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "success-toast",
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {/* {errorMsg} */}
          Failed to update patient details !!
          <button
            className="ms-15"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "error-toast",
      }
    );
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Toaster />
      {isSuccess && successToastMsg()}
      {isError && errorToastMsg()}

      <div className="card p-5">
        <div className="mb-5">
          <h1>Update Patient Profile</h1>
        </div>

        {isLoading || spinner === true ? (
          <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
          </div>
        ) : (
          <Formik
            initialValues={initsForPatientProfileUpdate}
            validationSchema={patientProfileUpdateSchema}
            onSubmit={submitStep}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form onKeyDown={handleInputKeyDown}>
                <>
                  <ProfileForm
                    {...formikProps}
                    patientDetails={patientDetails}
                  />
                </>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={!formikProps.isValid}
                    className="btn btn-lg btn-primary me-3"
                  >
                    <span className="indicator-label">Update Profile</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default UpdatePatientProfile;
