import $axiosProtected from "../../common/utils/axiosUtils";

// Get List of all CHW Details
export const getAllUnassignedTasks = async (payload: any) => {
  return await $axiosProtected().get(
    `/scheduler/task/unassigned/get?facilityCode=${payload.facilityCode}&date=${payload.date}`
  );
};

// Get List of all CHW Pending Tasks
export const getCHWPendingTasks = async (payload: any) => {
  return await $axiosProtected().get(
    `/scheduler/task/list/chw?facilityCode=${payload.facilityCode}&page=${payload.page}&limit=${payload.limit}`
  );
};

// Assign CHW To other Facility
export const assignTaskToCHW = async (payload: any) => {
  return await $axiosProtected().patch(`/scheduler/task/assign/chw`, payload);
};
