import React, { useMemo } from "react";
import { aggregateGrossTotal } from "./salesCalcFunctions";
import PaymentDetails from "./PaymentDetails";
import _ from "lodash";

type Props = {
    salesOrderProductList: any[];
    handleFinalAmountAndDelivery: Function;
    setIsModalOpen: Function;
    isModalOpen: boolean;
    isLoading: boolean;
};

const SalesFinalBill = ({
    salesOrderProductList,
    handleFinalAmountAndDelivery,
    isModalOpen,
    setIsModalOpen,
    isLoading,
}: Props) => {
    const customProducts = useMemo(() => {
        return salesOrderProductList
            ?.map((ele: any) => {
                return ele?.products?.map((obj1: any) => {
                    // Finding objects from product data array
                    const matchingObj = ele?.productData?.find((obj2: any) => {
                        return obj2._id === obj1.productId;
                    });
                    // Finding objects from inventoryData Array
                    const matchInventoryObj =
                        ele.customerType === "CHW" || ele.type === "Back Office"
                            ? ele?.inventoryData.find((obj3: any) => {
                                  return obj3._id === obj1.inventoryId;
                              })
                            : ele?.nestedInventoryData.find((obj3: any) => {
                                  return obj3.productId === obj1.productId;
                              });

                    return {
                        ...obj1,
                        ...matchingObj,
                        ratePerUnit: matchInventoryObj?.ratePerUnit,
                        expiry: matchInventoryObj?.expiry,
                    };
                });
            })
            .flat(1);
    }, [salesOrderProductList]);
    console.log(salesOrderProductList);
    return (
        <div className="row">
            <div className="col-sm-6">
                <p className="fs-6 fw-bold">Total Item: 4</p>
            </div>
            <div className="col-sm-6">
                {" "}
                <table
                    className="table table-bordered mb-4"
                    style={{ borderBottom: "1px solid #eff2f5" }}
                >
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                Total
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {/* {aggregateTotal(productsArr)} */}
                                {aggregateGrossTotal(customProducts).grossTotal}
                            </td>
                        </tr>

                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                OverAll Disc Value
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {aggregateGrossTotal(customProducts).grossDisc}
                            </td>
                        </tr>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                <p className="text font-weight-bold">
                                    Total Tax (GST)
                                </p>
                                <p className="text font-weight-bold">IGST</p>
                                <p className="text font-weight-bold">CGST</p>
                                <p className="text font-weight-bold">
                                    SGST/UTGST
                                </p>
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                <p className="text font-weight-bold">
                                    {
                                        aggregateGrossTotal(customProducts)
                                            .gstAmount
                                    }
                                </p>
                                <p className="text font-weight-bold">0.00</p>
                                <p className="text font-weight-bold">
                                    {(
                                        +aggregateGrossTotal(customProducts)
                                            .gstAmount / 2
                                    ).toFixed(2)}
                                </p>
                                <p className="text font-weight-bold">
                                    {(
                                        +aggregateGrossTotal(customProducts)
                                            .gstAmount / 2
                                    ).toFixed(2)}
                                </p>
                            </td>
                        </tr>

                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                Round Off
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {/* {(
                                    Math.round(
                                        +calcPurchaseAmount(productsArr)
                                    ) - +calcPurchaseAmount(productsArr)
                                ).toFixed(2)} */}
                                {(
                                    Math.round(
                                        +aggregateGrossTotal(customProducts)
                                            .totalAmount
                                    ) -
                                    +aggregateGrossTotal(customProducts)
                                        .totalAmount
                                ).toFixed(2)}
                            </td>
                        </tr>
                        <tr className="border" style={{ textAlign: "right" }}>
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ fontWeight: "bold" }}
                            >
                                Net Value
                            </td>
                            <td
                                className="border p-2"
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                }}
                            >
                                {/* {Math.round(+calcPurchaseAmount(productsArr))} */}
                                {Math.round(
                                    +aggregateGrossTotal(customProducts)
                                        .totalAmount
                                ).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {_.isArray(salesOrderProductList) &&
                salesOrderProductList[0]?.customerType !== "CHW" &&
                salesOrderProductList[0]?.paymentStatus &&
                salesOrderProductList[0].payments && (
                    <PaymentDetails
                        data={
                            _.isArray(salesOrderProductList) &&
                            salesOrderProductList[0].payments
                                ? salesOrderProductList[0].payments
                                : []
                        }
                        salesOrderProductList={salesOrderProductList[0]}
                        totalAmount={Math.round(
                            +aggregateGrossTotal(customProducts).totalAmount
                        )}
                        deliveryStatus={
                            _.isArray(salesOrderProductList) &&
                            salesOrderProductList[0].deliveryStatus
                                ? salesOrderProductList[0].deliveryStatus
                                : ""
                        }
                        handleFinalAmountAndDelivery={
                            handleFinalAmountAndDelivery
                        }
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                        isLoading={isLoading}
                    />
                )}
        </div>
    );
};

export default SalesFinalBill;
