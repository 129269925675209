import { useState } from "react";

import Modal from "../../../components/Modal";
import InfoCard, { CardProps } from "../../../components/InfoCard";

import "../../../components/styles/Card.css";
import InsightsSkeleton from "../../../components/skeleton/InsightsSkeleton";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Filter from "./Filter";

const CHWInsights = ({
    chwDetails,
    chwInsights,
    isCHWInsightsLoading,
    isCHWInsightsFetching,
    insightPeriod,
    setInsightPeriod,
    setFilterData,
    filterData,
}: any) => {
    const cardList: CardProps[] = [
        {
            title: "TOTAL VISITS",
            totalCount:
                chwInsights && Object.keys(chwInsights).length === 0
                    ? "No Data"
                    : chwInsights?.totalVisits,
            iconType: "/media/ikure/insights/visits.svg",
        },
        {
            title: "TOTAL TASK ADDED",
            totalCount:
                chwInsights && Object.keys(chwInsights).length === 0
                    ? "No Data"
                    : chwInsights?.totalTaskAdded,
            iconType: "/media/ikure/insights/total-tasks.svg",
        },
        {
            title: "PENDING TASK",
            totalCount:
                chwInsights && Object.keys(chwInsights).length === 0
                    ? "No Data"
                    : chwInsights?.totalPendingTask,
            iconType: "/media/ikure/insights/pending-tasks.svg",
        },
        {
            title: "TASK IN PROGRESS",
            totalCount:
                chwInsights && Object.keys(chwInsights).length === 0
                    ? "No Data"
                    : chwInsights?.totalTaskInProgess,
            iconType: "/media/ikure/insights/inprogress-tasks.svg",
        },
        {
            title: "TASK COMPLETED",
            totalCount:
                chwInsights && Object.keys(chwInsights).length === 0
                    ? "No Data"
                    : chwInsights?.totalTaskCompleted,
            iconType: "/media/ikure/insights/completed-tasks.svg",
        },
        // {
        //   title: "TOTAL PATIENTS REGISTRATION",
        //   totalCount:
        //     chwInsights && Object.keys(chwInsights).length === 0
        //       ? "No Data"
        //       : `${chwInsights?.totalTaskCompleted} / ${5}`,
        //   iconType: "/media/ikure/patient.svg",
        //   // toolTip: true,
        //   toolTipMessage: "hi",
        // },
        // {
        //   title: "TOTAL TELECONSULTATION",
        //   totalCount:
        //     chwInsights && Object.keys(chwInsights).length === 0
        //       ? "No Data"
        //       : `${chwInsights?.totalTaskCompleted} / ${5}`,
        //   iconType: "/media/ikure/patient/teleconsultation.svg",
        //   // toolTip: true,
        //   toolTipMessage: "hi",
        // },
    ];
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const clearData = () => {
        setFilterData({ fromDate: "", toDate: "" });
    };

    return (
        <div className="p-10 card d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between h-auto">
                <div className="">
                    <span className="fs-1 text-dark fw-bolder ">
                        {chwDetails?.firstName.toUpperCase() +
                            " " +
                            chwDetails?.lastName.toUpperCase() +
                            " " +
                            "INSIGHTS"}
                    </span>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                <div className="d-flex align-items-center">
                    {/* <div>
            <button
              className="w-auto 
            btn-flex btn-light btn-primary 
            btn btn-bg-light"
              onClick={handleReport}
            >
              <i className="fas fa-file-contract fs-2x me-2"></i>
              Generate Report
            </button>
          </div> */}
                    <div className="ms-5">
                        <Filter
                            setFilterData={setFilterData}
                            filterData={filterData}
                        />
                        {/* <select
                            onChange={(e) => setInsightPeriod(e.target.value)}
                            value={insightPeriod}
                            className="form-select form-select-light form-select-lg"
                        >
                            <option value="30 Days">30 Days</option>
                            <option value="6 Months">6 Months</option>
                            <option value="1 Year">1 Year</option>
                        </select> */}
                    </div>
                </div>
            </div>
            <div className="row gy-5 gx-xl-8 mt-5">
                {cardList.map((i) => {
                    return isCHWInsightsLoading || isCHWInsightsFetching ? (
                        <div className="col-xl-4">
                            <InsightsSkeleton />
                        </div>
                    ) : (
                        <div className="col-xl-4">
                            <InfoCard
                                className="card-xl-stretch mb-xl-3 mb-5"
                                cardSize={{ height: "200px" }}
                                iconType={toAbsoluteUrl(i.iconType)}
                                title={i.title}
                                totalCount={i.totalCount}
                                toolTip={i.toolTip}
                                toolTipMessage={i.toolTipMessage}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CHWInsights;
