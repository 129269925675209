import { useFilterRange } from "../context/FilterRangeContext";
const FilterRange = () => {
  const { handlePeriod, period } = useFilterRange();
  return (
    <>
      {["Day", "Month", "Year"].map((buttonLabel, i) => (
        <button
          key={i}
          name={buttonLabel}
          onClick={() => handlePeriod(buttonLabel)}
          className={
            buttonLabel === period
              ? "nav-link btn btn-sm btn-color-muted btn-active-primary active"
              : "nav-link btn btn-sm btn-color-muted"
          }
        >
          {buttonLabel}
        </button>
      ))}
    </>
  );
};
export default FilterRange;
