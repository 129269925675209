import { useEffect, useState } from "react";

import InfoCard from "../../../components/InfoCard";
import InsightsSkeleton from "../../../components/skeleton/InsightsSkeleton";
import useMutateRequest from "../../../hooks/getMutation.query";
import useSearchParams from "../../../hooks/useSearchParams";
import { getPatientInsights } from "../../../services/patient/PatientManagement.hook";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Filter from "./Filter";

const PatientInsights = ({
    patientDetails,
    setFilterData,
    filterData,
}: any) => {
    const { pId: id }: any = useSearchParams();
    const fd = localStorage.getItem("facilityCode");
    const [insightPeriod, setInsightPeriod] = useState("30 Days");
    const { data, isLoading, error, mutate }: any = useMutateRequest(
        (data: any) => getPatientInsights(data)
    );

    const patientInsights = data?.data?.data?.patientInsights;

    const getData = () => {
        try {
            const payload = {
                ...filterData,
                uhid: id,
                facilityCode: fd,
            };

            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };
    // useEffect(() => {
    //     if (id) getData();
    // }, [id, filterData]);

    const cardList = [
        {
            title: "TOTAL VISITS",
            totalCount:
                patientInsights && Object.keys(patientInsights).length === 0
                    ? "No Data"
                    : patientInsights?.totalVisits,
            iconType: "/media/ikure/insights/visits.svg",
        },

        {
            title: "PENDING TASK",
            totalCount:
                patientInsights && Object.keys(patientInsights).length === 0
                    ? "No Data"
                    : patientInsights?.totalPendingTask,
            iconType: "/media/ikure/insights/pending-tasks.svg",
        },
    ];

    return (
        <div className="p-10 card d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between h-auto">
                <div>
                    <span className="fs-1 text-dark fw-bolder ">
                        {patientDetails?.name.toUpperCase() + " " + "INSIGHTS"}
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="ms-5">
                        <Filter
                            filterData={filterData}
                            setFilterData={setFilterData}
                            getData={getData}
                        />
                        {/* <select
                            onChange={(e) => setInsightPeriod(e.target.value)}
                            value={insightPeriod}
                            className="form-select form-select-light form-select-lg"
                        >
                            <option value="30 Days">30 Days</option>
                            <option value="6 Months">6 Months</option>
                            <option value="1 Year">1 Year</option>
                        </select> */}
                    </div>
                </div>
            </div>
            <div className="row gy-5 gx-xl-8 mt-5">
                {cardList.map((i) => {
                    return isLoading ? (
                        <div className="col-xl-4">
                            <InsightsSkeleton />
                        </div>
                    ) : (
                        <div className="col-xl-4">
                            <InfoCard
                                className="card-xl-stretch mb-xl-3 mb-5"
                                cardSize={{ height: "200px" }}
                                iconType={toAbsoluteUrl(i.iconType)}
                                title={i.title}
                                totalCount={i.totalCount ? i.totalCount : 0}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PatientInsights;
