const Analytics = ({ onClose, criterias, perfMonitId, styles }: any) => {
  return (
    <>
      <div
        style={{
          backgroundColor: styles?.backgroundColor,
          width: styles?.width,
          height: styles?.msgHeight,
          padding: styles?.padding,
        }}
        className="mt-5 d-flex flex-column justify-content-center align-items-center"
      >
        <h1>No KPI's are available for analytics</h1>
        <button
          onClick={onClose}
          className="mt-5 w-150px btn btn-lg btn-danger me-5"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default Analytics;
