import * as Yup from "yup";

export type attendanceValueTypes = {
  exportFormat: string;
  startDate: string;
  endDate: string;
};

export const initalValues: attendanceValueTypes = {
  exportFormat: "CSV",
  startDate: "",
  endDate: "",
};

export const attendanceValidationSchema = Yup.object({
  exportFormat: Yup.string().required("Facility is Required"),
  startDate: Yup.string().required("Start Date is Required"),
  endDate: Yup.string().required("End Date is Required"),
});
