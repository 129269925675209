import * as Yup from "yup";

export const filterSchema = Yup.object().shape({
    status: Yup.string(),
    fromDate: Yup.date().required("From date is required"),
    toDate: Yup.date()
        .required("To date is required")
        .when("fromDate", (fromDate, schema) => {
            return fromDate
                ? schema.required(
                      "To Date is required when From Date is specified"
                  )
                : schema.nullable();
        })
        .min(Yup.ref("fromDate"), "To date must be greater than From date")
        .max(new Date(), "Date must not be more than the current date"),
    vendorId: Yup.string(),
});
