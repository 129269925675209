import { useEffect, useRef, useState } from "react";
import { Field, ErrorMessage, FormikValues } from "formik";

import CustomSelect from "../../../../../components/CustomSelect";
import useGetRequest from "../../../../../hooks/getRequest.query";
import { getDoctorSpecializations } from "../../../../../services/doctor/DoctorOnboarding.hook";
import { getAllFacilites } from "../../../../../services/facility/Facility.hook";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const Step5 = ({ values, setFieldValue, setFieldTouched }: FormikValues) => {
    const { data, refetch } = useGetRequest(
        "getAllFacilites-step",
        async () => await getAllFacilites(),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    let allFacilites = data?.data?.data?.facility;

    // Render only active facilities
    const getFacilityData = () => {
        return allFacilites
            ?.filter((facility: any) => {
                return facility?.status !== false;
            })
            .map((facility: any) => {
                const facilityCode = facility?.facilityCode;
                const facilityName =
                    facility?.facilityFirstName +
                    " " +
                    facility?.facilityLastName;
                return {
                    value: facilityCode,
                    label: facilityName,
                };
            });
    };
    const facilityData = getFacilityData();

    // Signature

    const fileRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<File | null>();
    const [preview, setPreview] = useState<string | null | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    const { data: doctorSpecializations, refetch: doctorRefetch }: any =
        useGetRequest(
            "getDoctorSpecializations",
            async () => await getDoctorSpecializations(),
            {
                retry: false,
                staleTime: 100000,
                refetchOnWindowFocus: false,
            }
        );

    useEffect(() => {
        doctorRefetch();
    }, []);

    const specializations = doctorSpecializations?.data?.data?.specializations;

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            await setFieldValue("signature", file);
            await setFieldTouched("signature"); // Trigger validation immediately
            setImage(file);
        } else {
            setFieldValue("signature", null);
            setImage(null);
        }
    };
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Doctor Assignment</h2>
            </div>

            {/* Signature */}
            <div className="row mb-10  ">
                <div className="">
                    <label className="form-label required">Signature</label>
                </div>
                <div className="cursor-pointer d-flex col-lg-12">
                    <div className="col-lg-6">
                        {preview ? (
                            <img
                                src={preview}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        ) : (
                            <img
                                src={toAbsoluteUrl("/media/ikure/blank.png")}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        )}
                    </div>
                    <div className="d-flex col-lg-6 justify-content-between align-items-center">
                        <input
                            data-testid="signature"
                            ref={fileRef}
                            hidden
                            type="file"
                            className="form-control form-control-lg  form-control-solid"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        <button
                            className="btn btn-sm"
                            onClick={(event) => {
                                event.preventDefault();
                                fileRef?.current?.click();
                            }}
                            data-testid="signature-btn"
                        >
                            <i className="fs-3x fas fa-cloud-upload-alt"></i>
                        </button>
                    </div>
                </div>

                <div className="text-danger mt-2">
                    <ErrorMessage name="signature" />
                </div>
            </div>

            {/* Facility */}
            <div className="fv-row col-lg-12 mb-10">
                <label className="form-label required">
                    Assign to Faciltiy
                </label>
                <div className="card fv-row bg-transparent">
                    <Field
                        data-testid="facility"
                        className="form-select-solid form-select-lg"
                        name="facility"
                        options={facilityData}
                        component={CustomSelect}
                        placeholder="Select multi facilities..."
                        isMulti={true}
                    />
                    <div className="text-danger mt-2">
                        <ErrorMessage name="facility" />
                    </div>
                </div>
            </div>

            {/* Specialization */}
            <div className="fv-row col-lg-12 mb-10">
                <label className="form-label required">Specialization</label>
                <Field
                    data-testid="specialization"
                    as="select"
                    name="specialization"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your specialization</option>
                    {specializations?.map((specialization: any) => {
                        return (
                            <>
                                <option value={specialization}>
                                    {specialization}
                                </option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="specialization" />
                </div>
            </div>
        </div>
    );
};

export { Step5 };
