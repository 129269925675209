import { Field, ErrorMessage, FormikValues } from "formik";
import { useEffect } from "react";

import CustomSelect from "../../../../components/CustomSelect";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getAllVitalsList } from "../../../../services/focused-group/FocusedGroup.hook";

const Step3 = ({ values }: FormikValues) => {
  let visitNum = Number(values.totalVisits);
  const visitArray =
    visitNum === 0 ? [0] : Array.from({ length: visitNum }, (_, i) => i + 1);
  // const visitArray: any = Array.from(Array(visitNum).keys());
  // console.log(visitNum);
  // API Call

  const { data, isLoading, isFetching, isError, error, refetch }: any =
    useGetRequest("getAllVitalsList", () => getAllVitalsList(), {
      retry: false,
      refetchOnWindowFocus: false,
    });
  const vitals = data?.data?.data?.vitals.map((vital: any) => {
    return {
      label: vital?.name,
      value: vital?._id,
    };
  });
  // console.log(vitals);
  // const { name, _id } = vitals;
  // console.log(name, _id);
  const ErrorMsg = error?.response?.data?.message;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark">{`Select Vitals for ${values?.focusedGroupName}`}</h2>
      </div>
      {visitArray.map((visitInfo: any, index: any) => {
        // console.log(visitInfo);
        return (
          <>
            <div className="fv-row mb-10">
              <label className="form-label fs-4 fw-bolder text-uppercase">
                Visit {visitInfo}
              </label>
              {/* <Field
                as="select"
                name={`info[${index}].selectedVisitNumber`}
                className="form-select form-select-lg form-select-solid"
              >
                <option value={visitInfo} selected disabled>
                  {visitInfo}
                </option>
              </Field> */}
              <div style={{ display: "none" }}>
                <Field
                  readOnly
                  type="text"
                  name={`vitals[${index}].selectedVisitNumber`}
                  value={visitInfo}
                  placeholder={visitInfo}
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
              <Field
                className="mt-3 form-select-solid form-select-stretch"
                name={`vitals[${index}].selectedVitals`}
                options={vitals}
                // options={vitalsOptions}
                component={CustomSelect}
                placeholder="Select vitals..."
                isMulti={true}
              />
              <div className="text-danger mt-2">
                <ErrorMessage name={`vitals[${index}].selectedVitals`} />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Step3;
