import { FC } from "react";
import { Link } from "react-router-dom";
import CustomTooltip from "../../../components/CustomTooltip";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

type Props = {
    icon: any;
    badgeColor: string;
    status: string;
    activityType?: string;
    statusColor: any;
    title: string;
    description: string;
    cancelReason?: any;
    date: string;
    vitalTime?: any;
    budget: string;
    deviceType?: string;
    deviceName?: any;
    progress: number;
    feedBack?: any;
    users?: any;
};

const Card: FC<Props> = ({
    icon,
    badgeColor,
    status,
    activityType,
    statusColor,
    title,
    description,
    cancelReason,
    date,
    vitalTime,
    budget,
    deviceType,
    deviceName,
    progress,
    feedBack,
    users = undefined,
}) => {
    return (
        <div className="card border border-2 border-gray-300 border-hover">
            <div className="card-header border-0 pt-9">
                <div className="card-title m-0">
                    <div className="symbol symbol-50px w-50px bg-light">
                        <img
                            src={toAbsoluteUrl(icon)}
                            alt="card"
                            className="p-3"
                        />
                    </div>
                </div>

                <div className="card-toolbar">
                    <span
                        className={`badge badge-${statusColor} me-auto px-4 py-2`}
                    >
                        {cancelReason !== "" ? (
                            <CustomTooltip
                                title={cancelReason}
                                placement="top"
                                // arrow
                            >
                                <span className="border-none">{status}</span>
                            </CustomTooltip>
                        ) : (
                            <span className="border-none">{status}</span>
                        )}
                    </span>
                </div>

                <div className="card-toolbar">
                    <span
                        className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}
                    >
                        {activityType}
                    </span>
                </div>
            </div>

            <div
                className={`card-body ${status === "Done" ? "p-5" : ""}`}
                style={{
                    textAlign: `${status === "Done" ? "left" : "center"}`,
                    padding: `${status === "Done" ? "3rem" : "6rem"}`,
                }}
            >
                <div className="fs-3 fw-bolder text-dark">{title}</div>
                {activityType === "Vitals" ? (
                    <p className="fw-bold fs-5">{budget}</p>
                ) : (
                    ""
                )}

                <p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">
                    {description}
                </p>

                <div className="d-flex flex-wrap mb-5">
                    {status === "Done" ? (
                        <>
                            <div
                                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2"
                                style={{ height: "10%" }}
                            >
                                <div className="fs-6 text-gray-800 fw-bolder">
                                    <span className="fw-bold text-gray-400">
                                        Date :{" "}
                                    </span>
                                    {date}
                                </div>
                                <div className="fs-6 text-gray-800 fw-bolder">
                                    <span className="fw-bold text-gray-400">
                                        Time :{" "}
                                    </span>
                                    {vitalTime}
                                </div>
                            </div>

                            {activityType === "Vitals" ? (
                                <div
                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2"
                                    style={{ width: "50%" }}
                                >
                                    <div className="fs-6 text-gray-800 fw-bolder justify-content-between">
                                        <span className="fw-bold text-gray-400">
                                            Type :{" "}
                                        </span>
                                        {deviceType}
                                    </div>
                                    <div className="fs-6 text-gray-800 fw-bolder justify-content-between">
                                        <span className="fw-bold text-gray-400">
                                            Device :{" "}
                                        </span>
                                        <CustomTooltip
                                            title={deviceName}
                                            placement="top"
                                        >
                                            <span className="border-none">
                                                {deviceName?.slice(0, 8) +
                                                    (deviceName?.length > 8
                                                        ? "..."
                                                        : "")}
                                            </span>
                                        </CustomTooltip>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="min-w-125px py-3 px-4 me-2"></div>
                                </>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {/* <p className="fs-6 text-gray-800 fw-bolder mt-1 mb-7">
          {status === "Done" ? (
            <>
              <span className="fw-bold text-gray-400">Feed Back : </span>
              <CustomTooltip title={feedBack}>
                <span className="border-none">
                  {feedBack?.slice(0, 25) +
                    (feedBack?.length > 25 ? "..." : "")}
                </span>
              </CustomTooltip>
            </>
          ) : (
            ""
          )}
        </p> */}
            </div>
        </div>
    );
};

export { Card };
