import { Formik, Form, Field, ErrorMessage } from "formik";

type Props = {
    setSearch: Function;
    setPage: Function;
};

const SearchBox = (props: Props) => {
    return (
        <div className="card-toolbar">
            <div className="py-2 me-20">
                <Formik
                    initialValues={{
                        searchBy: "productName",
                        searchName: "",
                    }}
                    // validationSchema={searchSchema}
                    onSubmit={({ searchBy, searchName }, onSubmitProps) => {
                        let genericName = "";
                        let productName = "";
                        let productCode = "";
                        props.setPage(1);
                        if (searchBy === "productName") {
                            props.setSearch({
                                productCode,
                                genericName,
                                productName: searchName?.trim(),
                            });
                            onSubmitProps.resetForm();
                        }
                        if (searchBy === "genericName") {
                            props.setSearch({
                                productCode,
                                genericName: searchName?.trim(),
                                productName,
                            });
                            onSubmitProps.resetForm();
                        }
                        if (searchBy === "productCode") {
                            props.setSearch({
                                productCode: searchName?.trim(),
                                genericName,
                                productName,
                            });
                            onSubmitProps.resetForm();
                        }
                    }}
                >
                    {(formProps) => {
                        const placeholderText =
                            formProps?.values?.searchBy === "productName"
                                ? "Product Name"
                                : formProps?.values?.searchBy === "genericName"
                                ? "Generic Name"
                                : "Product Code";
                        return (
                            <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex">
                                    <div className="d-flex me-5">
                                        <Field
                                            name="searchBy"
                                            as="select"
                                            className="form-control form-select form-control-solid"
                                        >
                                            <option
                                                value="productName"
                                                selected
                                            >
                                                Product Name
                                            </option>
                                            <option value="genericName">
                                                Generic Name
                                            </option>
                                            <option value="productCode">
                                                Product Code
                                            </option>
                                        </Field>
                                    </div>
                                    <div className="d-flex">
                                        <Field
                                            name="searchName"
                                            placeholder={`Search by ${placeholderText}`}
                                            className="form-control form-control-lg form-control-solid"
                                        />
                                        <button
                                            disabled={
                                                formProps.isSubmitting ||
                                                !(
                                                    formProps.isValid &&
                                                    formProps.dirty
                                                )
                                            }
                                            type="submit"
                                            className="ms-3 btn btn-sm btn-primary"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                    <ErrorMessage name="searchName" />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default SearchBox;
