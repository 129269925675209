import dayjs from "dayjs";
// import jwt from "jsonwebtoken";
let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const getElapsedMins = (startTime: number | null, endTime: number) => {
  //@ts-ignore
  let delta = Math.abs((endTime - startTime) / 1000 / 60);
  return delta;
};

export const getAccessTokenElapsedMins = (
  startTime: number | null,
  endTime: number
) => {
  //@ts-ignore
  let delta = (endTime - startTime) / 1000 / 60;

  return delta > 0 ? delta : 0;
};

export const mapGlobalDateRangeToData = (
  globalRange: any,
  data: any,
  type = "default"
) => {
  // if(type==="traffic"){
  //   console.log("DATA MOD AT START = ", globalRange, type);
  // }
  data?.length > 0 &&
    data?.map((val: any) => {
      if (type === "traffic") {
        globalRange[val[0]] = globalRange[val[0]] + val[1];
      } else {
        globalRange[val[0]] = val[1];
      }
      return true;
    });

  const tempData: any[] = [];

  // Object.keys(globalRange).map((val: any) => {
  //     tempData.push([val, globalRange[val]]);
  //     return true;
  //   });

  for (let val of Object.keys(globalRange)) {
    tempData.push([val, globalRange[val]]);
  }

  // if(type==="traffic"){
  // console.log("DATA ORIG = ", data, type);
  // console.log("DATA MOD = ", globalRange, type);
  // console.log("DATA in TEMPDATA = ", tempData, type);
  // }
  return tempData;
};
export const removeNullData = (data: any) => {
  return (
    data?.length > 0 &&
    data?.filter((val: any) => {
      if (val.includes("null") && val.includes(NaN)) {
        return false;
      }
      return true;
    })
  );
};

export const getDateRange = (
  startDate: string,
  endDate: string,
  returnType: "array" | "object"
) => {
  let dateArr: any;

  if (returnType === "array") {
    dateArr = []; //Array where rest of the dates will be stored
  } else {
    dateArr = {}; //Array where rest of the dates will be stored
  }

  //creating JS date objects
  var start = new Date(startDate);
  var end = new Date(endDate);

  //Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
  while (start <= end) {
    if (returnType === "array") {
      dateArr.push(dayjs(start).format("YYYY-MM-DD"));
    } else {
      dateArr[dayjs(start).format("YYYY-MM-DD")] = 0;
    }
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }

  return dateArr;
};

// export const generateAccessToken = (payload: any, expTime: string) => {
//     //Generate access_token with exp of `expTime`
//     return jwt.sign(payload, process.env.REACT_APP_SECERET as string, {
//         expiresIn: expTime,
//     });
// };

// export const domainGetter = (env_val: string) => {
//     switch (env_val) {
//         case "production":
//             return "https://whimslive.com";

//         case "development":
//             return "http://dev.whimslive.com";

//         default:
//             return "http://dev.whimslive.com";
//     }
// };
