import "bootstrap/dist/css/bootstrap.min.css";

const FocusedGroupHeader = ({ fgHeader }: any) => {
  return (
    <div className="card">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="flex-grow-1">
            <div className="d-flex flex-column fw-bolder fs-5 mt-2 mb-4">
              <div className="d-flex align-items-center mb-5 ">
                <label className="me-5 text-gray-600">
                  Focused Group Name :
                </label>
                <span className="text-gray-600 text-hover-primary">
                  {fgHeader?.name ? fgHeader?.name : "N/A"}
                </span>
              </div>

              <div className="d-flex align-items-center mb-5">
                <label className="me-5 text-gray-600">
                  Focused Group Type:
                </label>
                <span
                  className={`fs-4 badge badge-light-${
                    fgHeader?.type === "Visit" ? "success" : "danger"
                  } text-center `}
                >
                  {fgHeader?.type ? fgHeader?.type : "N/A"}
                </span>
              </div>

              <div className="d-flex align-items-center mb-5">
                <label className="me-5 text-gray-600">
                  Total number of visits :
                </label>
                <span className="text-gray-600 text-hover-primary">
                  {fgHeader?.totalVisits ? fgHeader?.totalVisits : "N/A"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/focused-group/${id}/forms` ||
                    location.pathname ===
                      `/focused-group/${id}/form-entries`) &&
                    "active")
                }
                to={`/focused-group/${id}/forms`}
              >
                FORMS
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/focused-group/${id}/vitals` &&
                    "active")
                }
                to={`/focused-group/${id}/vitals`}
              >
                VITALS
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname ===
                    `/focused-group/${id}/entry-details` ||
                    location.pathname ===
                      `/focused-group/${id}/vital-details` ||
                    location.pathname ===
                      `/focused-group/${id}/beneficiary-list`) &&
                    "active")
                }
                to={`/focused-group/${id}/beneficiary-list`}
              >
                BENEFICIARY LIST
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default FocusedGroupHeader;
