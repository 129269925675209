import $axiosProtected from "../../../../common/utils/axiosUtils";

export const createSalesOrder = async (payload: any) => {
    return await $axiosProtected().patch(
        // `/inventory/salesOrder/update`,
        `/inventory/salesOrder/update/web`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};
