import React from "react";
import EditableIndentDetailsTable from "./EditableIndentDetailsTable";

type Props = {};

const CreatePurchaseOrder = () => {
    return (
        <div>
            <EditableIndentDetailsTable />
        </div>
    );
};

export default CreatePurchaseOrder;
