import { GrowthBook } from "@growthbook/growthbook-react";

const { REACT_APP_GB_SDK, REACT_APP_GB_BASE_URL } = process.env;

const growthbook = new GrowthBook({
    apiHost: REACT_APP_GB_BASE_URL, // https://cdn.growthbook.io
    clientKey: REACT_APP_GB_SDK,
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log("Viewed Experiment", {
            experimentId: experiment.key,
            variationId: result.key,
        });
    },
    // attributes: {
    //     inventory: true,
    // },
});

// console.log(growthbook.getAttributes());
// growthbook.setAttributes({
//     id: "foo",
//     deviceId: "foo",
//     company: "foo",
//     loggedIn: true,
//     employee: true,
//     country: "foo",
//     browser: "foo",
//     url: "foo",
// });

export default growthbook;
