import React, { useMemo } from "react";
import dayjs from "dayjs";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import { netRate } from "./salesCalcFunctions";
import { dateFormatter } from "../../../../common/utils";

type Props = {
    salesOrderData: any;
    pageRefresh: boolean;
    isError: boolean;
    errorMessage: string;
};
const tableNames = [
    "Particulars",
    "MFR",
    "Batch",
    "Expiry",
    "Qty",
    "MRP/unit",
    "Dis %",
    "GST %",
    "Net Rate",
    "Amount",
];
const ProductListTable = (props: Props) => {
    const customProducts: any = useMemo(() => {
        return props?.salesOrderData?.map((ele: any) => {
            return ele?.products?.map((obj1: any) => {
                // Finding objects from product data array
                const matchingObj = ele?.productData?.find((obj2: any) => {
                    return obj2._id === obj1.productId;
                });
                // Finding objects from inventoryData Array
                const matchInventoryObj =
                    ele.customerType === "CHW" || ele.type === "Back Office"
                        ? ele?.inventoryData.find((obj3: any) => {
                              return obj3._id === obj1.inventoryId;
                          })
                        : ele?.nestedInventoryData.find((obj4: any) => {
                              return obj4.productId === obj1.productId;
                          });

                return {
                    ...obj1,
                    ...matchingObj,
                    ratePerUnit: matchInventoryObj?.ratePerUnit,
                    expiry: matchInventoryObj?.expiry,
                };
            });
        });
    }, [props?.salesOrderData]);

    return (
        <>
            <Table minHeight="min-h-100px">
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={props?.pageRefresh}
                    isError={props?.isError}
                    errorMessage={props?.errorMessage}
                >
                    {customProducts?.flat(1).map((ele: any, idx: number) => {
                        return (
                            <TableRow collapsible={false}>
                                <TableCell align="center">{idx + 1}</TableCell>
                                <TableCell>{ele?.productName}</TableCell>
                                <TableCell>{ele?.manufacturerName}</TableCell>
                                <TableCell>{ele?.batch}</TableCell>
                                <TableCell>
                                    {ele.expiry
                                        ? dateFormatter(ele.expiry)
                                        : "N/A"}
                                </TableCell>
                                <TableCell> {ele?.totalQuantity}</TableCell>
                                <TableCell>{ele.ratePerUnit}</TableCell>
                                <TableCell>{ele.discount}</TableCell>
                                <TableCell>{ele.GST}</TableCell>
                                <TableCell>
                                    {netRate(
                                        ele.ratePerUnit,
                                        ele.discount,
                                        ele.GST
                                    )}
                                </TableCell>
                                <TableCell>
                                    {(
                                        +netRate(
                                            ele.ratePerUnit,
                                            ele.discount,
                                            ele.GST
                                        ) * ele.totalQuantity
                                    ).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBodyContainer>
            </Table>
        </>
    );
};

export default ProductListTable;
