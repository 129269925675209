import $axiosProtected from "../../common/utils/axiosUtils";

// Onboard new doctor
export const onboardDoctor = async (payload: any) => {
  return await $axiosProtected().post("/acc/doctor/store", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Get list of all doctor specialization
export const getDoctorSpecializations = async () => {
  return await $axiosProtected().get(`/doctor/specialization/list`);
};

// Get list of all doctor qualification
export const getDoctorQualifications = async () => {
  return await $axiosProtected().get(`/doctor/qualification/list`);
};
