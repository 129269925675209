import { useEffect, useRef, useState } from "react";
import { Field, ErrorMessage, FormikValues } from "formik";
import { medicalCouncils } from "../data/medical-councils";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const Step4 = ({ setFieldValue, setFieldTouched }: FormikValues) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<File | null>();
    const [preview, setPreview] = useState<string | null | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            await setFieldValue("license", file);
            await setFieldTouched("license"); // Trigger validation immediately
            setImage(file);
        } else {
            setFieldValue("license", null);
            setImage(null);
        }
    };
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">License Details</h2>
            </div>

            {/* Upload File */}
            <div className="fv-row mb-10">
                <label className="form-label required">Upload License</label>

                <div className="cursor-pointer d-flex col-lg-12">
                    <div className="col-lg-6">
                        {preview ? (
                            <img
                                src={preview}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        ) : (
                            <img
                                src={toAbsoluteUrl("/media/ikure/blank.png")}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        )}
                    </div>
                    <div className="d-flex col-lg-6 justify-content-between align-items-center">
                        <input
                            data-testid="license"
                            ref={fileRef}
                            hidden
                            type="file"
                            className="form-control form-control-lg  form-control-solid"
                            accept="image/*"
                            onChange={handleFileChange}
                            //   onChange={(event: any) => {
                            //     const file = event?.target?.files[0];
                            //     if (file && file.type.substr(0, 5) === "image") {
                            //       setFieldValue("license", event?.target?.files[0]);
                            //       setImage(file);
                            //     } else {
                            //       setImage(null);
                            //     }
                            //   }}
                        />
                        <button
                            className="btn btn-sm"
                            onClick={(event) => {
                                event.preventDefault();
                                fileRef?.current?.click();
                            }}
                            data-testid="license-btn"
                        >
                            <i className="fs-3x fas fa-cloud-upload-alt"></i>
                        </button>
                    </div>
                </div>
                <div className="text-danger mt-2">
                    <ErrorMessage name="license" />
                </div>
            </div>

            {/* License Number */}
            <div className="fv-row mb-10">
                <label className="form-label required">License Number</label>

                <Field
                    data-testid="license-number"
                    name="licenseNumber"
                    placeholder="Please enter your license number"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="licenseNumber" />
                </div>
            </div>

            {/* License Provider */}
            <div className="fv-row mb-10">
                <label className="form-label required">License Provider</label>

                <Field
                    data-testid="license-provider"
                    as="select"
                    name="licenseProvider"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">
                        Select your license provider/medical council
                    </option>
                    <option value="Other">Other</option>
                    {medicalCouncils?.map((medicalCouncil) => {
                        return (
                            <>
                                <option value={medicalCouncil}>
                                    {medicalCouncil}
                                </option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="licenseProvider" />
                </div>
            </div>

            {/* <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label mb-3">
          License Verification
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="License Verification"
          ></i>
        </label>
        <div className="row mb-2">
          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              value="verified"
              name="licenseVerified"
              id="verified"
            />
            <label
              htmlFor="verified"
              className="btn btn-outline btn-outline-dashed btn-outline-default w-100"
            >
              <span className="fw-bolder fs-3">Verified</span>
            </label>
          </div>
          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              value="not-verified"
              name="licenseVerified"
              id="not-verified"
            />
            <label
              htmlFor="not-verified"
              className="btn btn-outline btn-outline-dashed btn-outline-default w-100"
            >
              <span className="fw-bolder fs-3">Not Verified</span>
            </label>
          </div>

          <div className="text-danger mt-2">
            <ErrorMessage name="licenseVerified" />
          </div>
        </div>
      </div> */}
        </div>
    );
};

export { Step4 };
