import { $axiosPublic } from "../../common/utils/axiosUtils";

export const createLogin = (payload: string): any => {
    const realmName = JSON.parse(
        localStorage.getItem("organizationId") || "{}"
    );
    return $axiosPublic.post(
        //TODO: `/auth/realms/${realmName}/protocol/openid-connect/token`,
        `/realms/${realmName}/protocol/openid-connect/token`,
        payload
    );
};
