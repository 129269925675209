import $axiosProtected from "../../../common/utils/axiosUtils";

// Get List of all Indents
export const createGrn = async (payload: any) => {
    return await $axiosProtected().post(`/inventory/grn/store`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
