import $axiosProtected from "../../../common/utils/axiosUtils";

// Get GRN by Purchase Id
export const getGRNByPurchaseId = async ({
    purchaseOrderId,
    facilityCode,
    page,
    limit,
}: {
    purchaseOrderId: string;
    facilityCode: string;
    page: number;
    limit: number;
}) => {
    return await $axiosProtected().get(
        `/inventory/grn/get/id?purchaseOrderId=${purchaseOrderId}&page=${page}&limit=${limit}&facilityCode=${facilityCode}`
    );
};
