import { useState } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

import ClipLoader from "react-spinners/ClipLoader";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { uploadProducts } from "../../../../services/supply-chain/product-master/MasterProduct.services";
import { FileInput } from "./FileInput";

type ExportProps = {
    onClose: () => void;
    styles?: any;
    refetch: Function;
};

const downloadSample = () => {
    const csvData = `productName,genericName,productCode,hsnCode,sku,status,manufacturerName,schedule,type,incentive,quantityThreshold,category,subCategory
    \n zukonorma D-10,gname,PEDW,H55,skuValue,Active,Ajanta Pharma,,Tablet usin BLOB,15,5,Medicine,Tablet usin BLOB`;
    const blob = new Blob([csvData], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "sample_data.csv"; // Append the download link to the document body document.body.appendChild(downloadLink); // Programmatically trigger the download downloadLink.click(); // Clean up by removing the download link document.body.removeChild(downloadLink);
    downloadLink.click();
};

const bulkUploadProductSchema = Yup.object({
    productsFile: Yup.mixed()
        .required("Please upload an csv file")
        .test(
            "fileFormat",
            "Unsupported file format",
            (value) =>
                value &&
                ["application/vnd.ms-excel", "text/csv"].includes(value.type)
        )
        .test(
            "fileSize",
            "File size should be less than 5MB",
            (value) => value && value.size <= 5242880 // 5MB in bytes
        ),
});

const BulkUploadProducts = ({ onClose, styles, refetch }: ExportProps) => {
    const [msg, setMsg] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const { isLoading, mutate, isError } = useMutateRequest((data: any) =>
        uploadProducts(data)
    );
    const onSubmit = (values: any) => {
        const payload = new FormData();
        payload.append("productsFile", values?.productsFile);

        try {
            mutate(payload, {
                onSuccess(data: any) {
                    setSpinner(true);
                    setMsg(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 1500);
                    refetch();
                },
                onError(error: any) {
                    setSpinner(true);
                    setMsg(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 1500);
                },
            });
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <>
            {isLoading || spinner ? (
                <div
                    style={{ backgroundColor: "transparent" }}
                    className="h-225px d-flex flex-column justify-content-center align-items-center"
                >
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : msg ? (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.msgHeight,
                        padding: styles?.padding,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    {isError === true ? (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                {`Failed to bulk upload the products`}
                                {/* {ErrorMsg} */}
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={() => setMsg(false)}
                                    className="w-150px btn btn-lg btn-primary me-5"
                                >
                                    Please Retry
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Successfully uploaded the file
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.height,
                        padding: styles?.padding,
                    }}
                >
                    <Formik
                        initialValues={{
                            productsFile: "",
                        }}
                        validationSchema={bulkUploadProductSchema}
                        onSubmit={onSubmit}
                    >
                        {(formProps: any) => {
                            return (
                                <Form>
                                    <h1 className="text-center">
                                        Bulk Upload Products
                                    </h1>

                                    <div className="d-flex flex-column">
                                        {/* File URl */}
                                        <div className="fv-row my-10">
                                            <label className="form-label required">
                                                Upload File
                                            </label>

                                            <div className="cursor-pointer d-flex col-lg-12">
                                                <div className="d-flex col-lg-6 justify-content-between align-items-center">
                                                    <Field
                                                        id="file"
                                                        name="productsFile"
                                                        component={FileInput}
                                                    />
                                                </div>
                                            </div>

                                            <div className="text-danger mt-2">
                                                <ErrorMessage name="productsFile" />
                                            </div>
                                        </div>
                                        <div className="mt-0">
                                            <button
                                                onClick={downloadSample}
                                                className="w-100 btn btn-md btn-primary"
                                            >
                                                <i className="fa-solid fa-cloud-arrow-down"></i>
                                                Data Template
                                            </button>
                                            <div className="mt-2">
                                                A view a sample data template,
                                                and its format on how to upload
                                                data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-10 d-flex justify-content-center align-items-center">
                                        <button
                                            onClick={onClose}
                                            className="w-150px btn btn-md btn-danger me-5"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={
                                                !formProps.isValid ||
                                                formProps.isSubmitting
                                            }
                                            className="w-150px btn btn-md btn-primary"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default BulkUploadProducts;
