import * as Yup from "yup";

// Export all mime types
export const mimeTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

// User image upload validation schema
export const userImageUploadSchema = Yup.object().shape({
    file: Yup.mixed()
        .required("Please upload an image file")
        .test(
            "fileFormat",
            "Unsupported file format",
            (value) => value && mimeTypes.includes(value.type)
        )
        .test(
            "fileSize",
            "File size should be less than 5MB",
            (value) => value && value.size <= 5242880 // 5MB in bytes
        ),
});

// Document upload validation reusable schema
export const documentUploadSchema = Yup.object().shape({
    file: Yup.mixed()
        .required("Please upload a file")
        .test(
            "fileFormat",
            "Unsupported file format",
            (value) => value && mimeTypes.includes(value.type)
        )
        .test(
            "fileSize",
            "File size should be less than 10MB",
            (value) => value && value.size <= 10485760 // 10MB in bytes
        ),
});
