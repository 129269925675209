import { Field, ErrorMessage, FormikValues } from "formik";
import { useMemo } from "react";
import useGetRequest from "../../../../../hooks/getRequest.query";
import {
    getAllCountries,
    getAllDistricts,
    getAllStates,
    getPostalCodeByDistrict,
} from "../../../../../services/loction/location.services";

const Step3 = ({ values }: FormikValues) => {
    const { data }: any = useGetRequest(
        "getAllCountries",
        async () => await getAllCountries(),
        {
            keepPreviousData: true,
        }
    );
    const { data: statesData }: any = useGetRequest(
        ["getAllStates", values?.country],
        async () =>
            await getAllStates({
                country: values?.country,
            }),
        {
            enabled: !!values?.country,
            keepPreviousData: true,
        }
    );

    const { data: districtData }: any = useGetRequest(
        ["getAllDistricts", values?.state],
        async () =>
            await getAllDistricts({
                country: values?.country,
                state: values?.state?.split(";")[0],
            }),
        {
            enabled: !!values?.country && !!values?.state,
            keepPreviousData: true,
        }
    );

    const { data: postalCodeData }: any = useGetRequest(
        ["getPostalCodeByDistrict", values?.district],
        async () =>
            await getPostalCodeByDistrict({
                country: values?.country,
                state: values?.state?.split(";")[0],
                district: values?.district?.split(";")[0],
            }),
        {
            enabled: !!values?.country && !!values?.state && !!values?.district,
            keepPreviousData: true,
        }
    );

    const countries = useMemo(() => data?.data?.data?.countries, [data]);
    const states = useMemo(() => statesData?.data?.data?.states, [statesData]);
    const districts = useMemo(
        () => districtData?.data?.data?.districts,
        [districtData]
    );
    const postalCodes = useMemo(
        () => postalCodeData?.data?.data?.pincodes,
        [postalCodeData]
    );

    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Additional Details</h2>
            </div>

            <div className="fv-row mb-0">
                <label className="fs-6 fw-bold form-label required">
                    Country
                </label>
                <Field
                    as="select"
                    className="form-select form-select-lg form-select-solid"
                    data-testid="country"
                    name="country"
                    value={values?.country}
                >
                    <option value="">Select the country</option>
                    {countries?.map((item: any) => {
                        return (
                            <>
                                <option value={item}>{item}</option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="country" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    State
                </label>

                <Field
                    as="select"
                    data-testid="state"
                    name="state"
                    className="form-select form-select-lg form-select-solid"
                    value={values?.state}
                >
                    <option>Select your state</option>
                    {states?.map((item: any) => {
                        return (
                            <>
                                <option
                                    value={`${item?.state};${item.stateCode}`}
                                >
                                    {item?.state}
                                </option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="state" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    District
                </label>
                <Field
                    data-testid="district"
                    name="district"
                    as="select"
                    className="form-select form-select-lg form-select-solid"
                    value={values?.district}
                >
                    <option value="">Select the district</option>
                    {districts?.map((item: any) => {
                        return (
                            <>
                                <option
                                    value={`${item?.district};${item?.districtCode}`}
                                >
                                    {item?.district}
                                </option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="district" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    Postal Code
                </label>

                <Field
                    data-testid="postal-code"
                    name="postalCode"
                    as="select"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select the postal code</option>
                    {postalCodes?.map((item: any) => {
                        return (
                            <>
                                <option value={item}>{item}</option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="postalCode" />
                </div>
            </div>
        </div>
    );
};

export { Step3 };
