import TDAuto from "../components/TDAuto";
import TDManual from "../components/TDManual";

const TDStep2 = ({
    selectedCHW,
    allCHW,
    setFieldValue,
    isTargetDistributed,
    approxNumToDistribute,
    remainingTargetsToDistribute,
    kpiTargetData,
    isMessage,
    chwCounterData,
}: any) => {
    return (
        <>
            <div className="">
                {kpiTargetData && kpiTargetData?.totalTargetsRemaining === 0 ? (
                    <>
                        <div className="d-flex h-350px w-100 flex-wrap text-center justify-content-center align-items-center">
                            <h1>{`The target is already distributed for the month ${
                                kpiTargetData && kpiTargetData?.month
                            }`}</h1>
                        </div>
                    </>
                ) : (
                    <div>
                        {isTargetDistributed === false ? (
                            <>
                                <TDAuto
                                    selectedCHW={selectedCHW}
                                    allCHW={allCHW}
                                    setFieldValue={setFieldValue}
                                    approxNumToDistribute={
                                        approxNumToDistribute
                                    }
                                    remainingTargetsToDistribute={
                                        remainingTargetsToDistribute
                                    }
                                    totalNumberOfCHWInFacility={
                                        chwCounterData?.allChwCounter
                                    }
                                />
                            </>
                        ) : (
                            <TDManual
                                selectedCHW={selectedCHW}
                                allCHW={allCHW}
                                setFieldValue={setFieldValue}
                                kpiTargetData={kpiTargetData}
                                isMessage={isMessage}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default TDStep2;
