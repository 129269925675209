import $axiosProtected from "../../../common/utils/axiosUtils";

// Get List of all Purchase Order
export const getAllPurchaseOrderList = async ({
    facilityCode,
    page,
    limit,
    poOrderStatus,
    fromDate,
    toDate,
    vendorId,
}: {
    poOrderStatus?: string;
    fromDate?: string | number;
    toDate?: string | number;
    facilityCode: string;
    vendorId?: string;
    page: number;
    limit: number;
}) => {
    return await $axiosProtected().get(
        `/inventory/purchase-order/get/all?facilityCode=${facilityCode}&page=${page}&limit=${limit}${
            poOrderStatus ? `&poOrderStatus=${poOrderStatus}` : ""
        }${fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ""}${
            vendorId ? `&vendorId=${vendorId}` : ""
        }`
    );
};

// Get purchase order details
export const getPurchaseOrderDetails = async ({
    purchaseOrderId,
}: {
    purchaseOrderId: string;
}) => {
    return await $axiosProtected().get(
        `/inventory/purchase-order/get/id?purchaseOrderId=${purchaseOrderId}`
    );
};

export const getPoBySearch = async (payload: {
    purchaseOrderId: string;
    facilityCode: string;
}) => {
    return await $axiosProtected().post(
        `/inventory/purchase-order/search`,
        payload
    );
};
