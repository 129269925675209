import React, { useMemo, useState } from "react";
import { Menu } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { todayDate } from "../../../../common/utils";
import { Autocomplete, TextField } from "@mui/material";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getAllVendors } from "../../../../services/supply-chain/vendor/Vendor.services";
import { filterSchema } from "./filterSchema";

type Props = {
    setFilterValues: Function;
    filterValues: any;
};

const PurchaseOrderListFilter = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const payload = { page: 1, limit: 100 };

    const { data } = useGetRequest("getVendors", () => getAllVendors(payload));

    const vendorList = useMemo(() => {
        const processedData = data?.data?.data?.vendors.map((ele: any) => {
            return { label: ele.vendorName, vendorId: ele._id };
        });
        return processedData;
    }, [data]);

    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-md btn-primary"
                style={{ marginLeft: "0.8rem" }}
                data-testid="filter-btn"
            >
                {" "}
                <i className="fas fa-filter"></i> FILTER
            </button>
            <Menu
                id="basic-menu"
                data-testid="menu-bar"
                style={{ maxHeight: "550px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Formik
                    initialValues={props.filterValues}
                    validationSchema={filterSchema}
                    onSubmit={(values) => {
                        props.setFilterValues(values);
                        handleClose();
                    }}
                    enableReinitialize
                >
                    {(formProps: any) => (
                        <Form>
                            <div className="py-5 px-8 ">
                                <div className="w-250px mt-5 mb-8">
                                    <label className="form-label fs-4 fw-bolder">
                                        STATUS
                                    </label>
                                    <Field
                                        as="select"
                                        name="status"
                                        className="form-select form-select-lg form-select-solid"
                                        data-testid="status-select"
                                    >
                                        <option value="">All</option>
                                        <option value="2">
                                            Order Completed
                                        </option>
                                        <option value="0">Order Placed</option>

                                        <option value="1">
                                            Order Cancelled
                                        </option>
                                    </Field>
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="grade" />
                                    </div>
                                </div>

                                <div className="w-250px mt-5 mb-5">
                                    <label
                                        className="form-label fs-4 fw-bolder required"
                                        htmlFor="fromDate"
                                    >
                                        FROM DATE
                                    </label>
                                    <Field
                                        id="fromDate"
                                        as="input"
                                        data-testid="from-date required"
                                        type="date"
                                        max={todayDate}
                                        name="fromDate"
                                        className="form-control cursor-pointer"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="fromDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder required">
                                        TO DATE
                                    </label>
                                    <Field
                                        onChange={(e: any) => {
                                            formProps.handleChange(e);
                                            formProps.setFieldTouched(
                                                "fromDate"
                                            );
                                        }}
                                        id="toDate"
                                        data-testid="to-date"
                                        type="date"
                                        name="toDate"
                                        max={todayDate}
                                        className="form-control"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="toDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder">
                                        Vendor Name
                                    </label>
                                    <Autocomplete
                                        data-testid="name"
                                        disablePortal
                                        autoHighlight
                                        id="combo-box-demo"
                                        options={[
                                            ...vendorList,
                                            { label: "All", vendorId: "" },
                                        ]}
                                        style={{ width: "250px" }}
                                        sx={{ width: 300 }}
                                        // className="form-control form-control-solid"
                                        onChange={(e, value: any) => {
                                            formProps?.setFieldValue(
                                                "vendorId",
                                                value?.vendorId
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />

                                    <div className="text-danger mt-2">
                                        <ErrorMessage name="vendorId" />
                                    </div>
                                </div>
                            </div>

                            <div className="my-5 d-flex justify-content-center align-items-center">
                                <button
                                    type="submit"
                                    disabled={formProps.isSubmitting}
                                    className="w-250px btn btn-sm btn-primary"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Menu>
        </>
    );
};

export default PurchaseOrderListFilter;
