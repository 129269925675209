import { Card } from "@mui/material";
import { KTSVG } from "../_metronic/helpers";
import { Tooltip, Zoom, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export type CardProps = {
  title: string;
  className?: string;
  totalCount: number | string;
  description?: string;
  iconType?: any;
  cardSize?: React.CSSProperties;
  arrow?: "up" | "down" | "equal";
  toolTip?: boolean;
  toolTipMessage?: string;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 11,
  },
}));
const InfoCard = ({
  title,
  totalCount,
  description,
  arrow,
  iconType,
  cardSize,
  className,
  toolTip = false,
  toolTipMessage = "",
}: CardProps) => {
  return (
    <div className={`card ${className}`}>
      <Card
        elevation={2}
        style={cardSize}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "20px",
          width: cardSize?.width,
          height: cardSize?.height,
        }}
      >
        <div className="d-flex align-items-center">
          <div className="">
            {iconType && (
              <span className="card-title fs-2 text-hover-primary fw-bolder me-5">
                <img src={iconType} alt={title} style={{ width: "50px" }} />
              </span>
            )}
          </div>

          <div>
            <span className="card-title fs-2 text-hover-primary fw-bolder">
              {title}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center h-auto">
          {toolTip ? (
            <CustomTooltip
              style={{ fontSize: "550px" }}
              TransitionComponent={Zoom}
              title={toolTipMessage}
              placement="top"
              // arrow
            >
              <div className="h-auto align-items-center">
                <span className="card-info text-dark">
                  {totalCount?.toLocaleString()}
                </span>
              </div>
            </CustomTooltip>
          ) : (
            <div className="h-auto align-items-center">
              <span className="text-hover-primary fw-bold card-info text-dark">
                {totalCount?.toLocaleString()}
              </span>
            </div>
          )}
          <div className="h-auto align-items-center">
            {arrow === "up" && (
              <KTSVG
                path="/media/ikure/dashboard/up.svg"
                className="arrow-svg up svg-icon-5x"
              />
            )}
            {arrow === "down" && (
              <KTSVG
                path="/media/ikure/dashboard/down.svg"
                className="arrow-svg down svg-icon-5x"
              />
            )}
            {arrow === "equal" && (
              <KTSVG
                path="/media/ikure/dashboard/equal.svg"
                className="arrow-svg equal svg-icon-5x"
              />
            )}
          </div>
        </div>
        {description && (
          <>
            <p className="card-title fs-5 text-hover-primary fw-bolder">
              {description}
            </p>
          </>
        )}
      </Card>
    </div>
  );
};
export default InfoCard;
