import { Field, ErrorMessage } from "formik";

import { useLocation } from "react-router-dom";

const Step1 = () => {
  const { state }: any = useLocation();
  const acceptedPath = state?.from;

  const focusedGroupName = state?.focusedGroupName;
  const visitNumber = state?.item?.visitNumber;

  // console.log(window.history.state.prevUrl);
  // let prevLocation: any;

  // listen((nextLocation: any) => {
  //   console.log("CurrentLocation", nextLocation);
  //   prevLocation = nextLocation;
  // });
  // console.log("prevLocation", prevLocation);

  return (
    <div className="mx-auto w-600px w-100 pt-10 pb-10">
      {acceptedPath === "focused-group" && (
        <>
          {/*  Focused Group Name  */}
          <div className="fv-row col-lg-12 mb-10">
            <label className="form-label">Selected Focused group</label>

            <Field
              readOnly
              style={{
                cursor: "not-allowed",
              }}
              name="focusedGroupName"
              placeholder={
                focusedGroupName ? focusedGroupName : "Select Group Name"
              }
              className="form-control form-control-lg form-control-solid"
            />
          </div>
          {/* Visit Number */}
          <div className="fv-row col-lg-12 mb-10">
            <label className="form-label">Selected visit number</label>
            <Field
              readOnly
              style={{
                cursor: "not-allowed",
              }}
              name="visitNumber"
              placeholder={visitNumber}
              className="form-control form-control-lg form-control-solid"
            />
          </div>
        </>
      )}
      {/*  Form Name  */}
      <div className="fv-row col-lg-12 mb-10">
        <label className="form-label">Form Name</label>

        <Field
          name="formName"
          placeholder="Enter the Form Name"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="formName" />
        </div>
      </div>

      {/* Description */}
      <div className="fv-row col-lg-12 mb-10">
        <label className="form-label">Description</label>
        <Field
          name="description"
          placeholder="Enter short description"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="description" />
        </div>
      </div>
    </div>
  );
};

export default Step1;
