import { useEffect, useMemo } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useGetRequest from "../../../hooks/getRequest.query";
import useSearchParams from "../../../hooks/useSearchParams";
import { getBatchDetails } from "../../../services/HealthCard.hook";

const BatchDetails = () => {
  const { batchId: id }: any = useSearchParams();
  const facilityCode = localStorage.getItem("facilityCode");

  const {
    data,
    isLoading: isBatchDetailsLoading,
    isFetching: isBatchDetailsFetching,
    refetch,
    isError: isBatchDetailsError,
    error: BatchDetailsError,
  }: any = useGetRequest(
    ["getBatchDetails", id],
    () =>
      getBatchDetails({
        facilityCode,
        batch: id,
      }),
    {
      staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  const batchData = useMemo(() => data?.data?.data?.batch, [data]);
  const ErrorMsg = useMemo(
    () => BatchDetailsError?.response?.data?.message,
    [BatchDetailsError]
  );

  useEffect(() => {
    if (id) refetch();
  }, [id, refetch]);

  return (
    <>
      {isBatchDetailsLoading || isBatchDetailsFetching ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isBatchDetailsError ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <h1 className="text-danger">{ErrorMsg}</h1>
        </div>
      ) : (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Batch Details</h3>
            </div>
          </div>

          <div className="card-body p-9">
            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">Total Cards</label>
              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-dark">
                  {batchData && Object?.keys(batchData)?.length === 0
                    ? "No Data"
                    : batchData?.totalCards}
                </span>
              </div>
            </div>

            <div className="row mb-10">
              <label className="col-lg-4 fw-bold text-muted">
                Active Cards
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-dark">
                  {batchData && Object?.keys(batchData)?.length === 0
                    ? "No Data"
                    : batchData?.countOfActiveCards}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">Used Cards</label>
              <div className="col-lg-8">
                <span className="fw-bolder fs-6">
                  {batchData && Object.keys(batchData).length === 0
                    ? "No Data"
                    : batchData?.countOfUsedCards}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                Unused Cards
              </label>
              <div className="col-lg-8">
                <span className="fw-bolder fs-6">
                  {batchData && Object.keys(batchData).length === 0
                    ? "No Data"
                    : batchData?.countOfUnusedCards}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                Cancelled Cards
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-dark text-hover-primary">
                  {batchData && Object.keys(batchData).length === 0
                    ? "No Data"
                    : batchData?.countOfCancelledCards}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BatchDetails;
