import React, { useMemo, useState } from "react";
import { Menu } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { todayDate } from "../../../../common/utils";
import { Autocomplete, TextField } from "@mui/material";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getAllVendors } from "../../../../services/supply-chain/vendor/Vendor.services";
import * as Yup from "yup";

const filterSchema = Yup.object().shape({
    fromDate: Yup.date(),
    toDate: Yup.date().max(
        new Date(),
        "Date must not be more than the current date"
    ),
    vendorId: Yup.string(),
});

type Props = {
    setFilterValues: Function;
    filterValues: any;
};

const GRNFilterList = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const payload = { page: 1, limit: 100 };

    const { data } = useGetRequest("getVendors", () => getAllVendors(payload));

    const vendorList = useMemo(() => {
        const processedData = data?.data?.data?.vendors.map((ele: any) => {
            return { label: ele.vendorName, vendorId: ele.vendorId };
        });
        return processedData;
    }, [data]);

    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-md btn-primary"
                style={{ marginLeft: "0.8rem" }}
                data-testid="filter-btn"
            >
                {" "}
                <i className="fas fa-filter"></i> FILTER
            </button>
            <Menu
                id="basic-menu"
                data-testid="menu-bar"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Formik
                    initialValues={props.filterValues}
                    validationSchema={filterSchema}
                    onSubmit={(values) => {
                        props.setFilterValues(values);
                        handleClose();
                    }}
                    enableReinitialize
                >
                    {(formProps: any) => (
                        <Form>
                            <div className="py-5 px-8">
                                <div className="w-250px mt-5 mb-5">
                                    <label
                                        className="form-label fs-4 fw-bolder"
                                        htmlFor="fromDate"
                                    >
                                        FROM DATE
                                    </label>
                                    <Field
                                        id="fromDate"
                                        as="input"
                                        data-testid="from-date"
                                        type="date"
                                        max={todayDate}
                                        name="fromDate"
                                        className="form-control cursor-pointer"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="fromDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder">
                                        TO DATE
                                    </label>
                                    <Field
                                        onChange={(e: any) => {
                                            formProps.handleChange(e);
                                            formProps.setFieldTouched(
                                                "fromDate"
                                            );
                                        }}
                                        id="toDate"
                                        data-testid="to-date"
                                        type="date"
                                        name="toDate"
                                        max={todayDate}
                                        className="form-control"
                                    />
                                    <div className="text-danger mt-5">
                                        <ErrorMessage name="toDate" />
                                    </div>
                                </div>
                                <div className="w-250px mt-5">
                                    <label className="form-label fs-4 fw-bolder required">
                                        Vendor Name
                                    </label>
                                    <Autocomplete
                                        data-testid="name"
                                        disablePortal
                                        autoHighlight
                                        id="combo-box-demo"
                                        options={vendorList}
                                        style={{ width: "250px" }}
                                        sx={{ width: 300 }}
                                        // className="form-control form-control-solid"
                                        onChange={(e, value: any) => {
                                            formProps?.setFieldValue(
                                                "vendorId",
                                                value?.vendorId
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />

                                    <div className="text-danger mt-2">
                                        <ErrorMessage name="vendorId" />
                                    </div>
                                </div>
                            </div>

                            <div className="my-5 d-flex justify-content-center align-items-center">
                                <button
                                    type="submit"
                                    disabled={
                                        !formProps.isValid ||
                                        formProps.isSubmitting
                                    }
                                    className="w-250px btn btn-sm btn-primary"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Menu>
        </>
    );
};

export default GRNFilterList;
