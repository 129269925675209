import $axiosProtected from "../../common/utils/axiosUtils";

// Get list of all countries details
export const getAllCountries = async () => {
    return await $axiosProtected().get(`/location/country/list/get`);
};

// Get list of all states details
export const getAllStates = async (payload: any) => {
    return await $axiosProtected().get(
        `/location/states/list/get?country=${payload.country}`
    );
};

// Get list of all districts details
export const getAllDistricts = async (payload: any) => {
    return await $axiosProtected().get(
        `/location/districts/list/get?country=${payload.country}&state=${payload.state}`
    );
};

// Get list of all districts details
export const getPostalCodeByDistrict = async (payload: any) => {
    return await $axiosProtected().get(
        `/location/pincodes/list/get?country=${payload.country}&state=${payload.state}&district=${payload.district}`
    );
};
