import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import useMutateRequest from "../../../../../../hooks/getMutation.query";
import { getPatientVitals } from "../../../../../../services/focused-group/FocusedGroup.hook";
import CardView from "../../../../../focused-group/management/components/CardView";

const PatientVitals = () => {
  const { state }: any = useLocation();

  let taskId = state?.taskId;
  let uhid = state?.uhid;
  let focusedGroupId = state?.focusedGroupId;

  const { data, mutate, isError, error, isLoading }: any = useMutateRequest(
    (data: string) => getPatientVitals(data)
  );

  const getData = () => {
    try {
      let payload = {
        focusedGroupId,
        taskId: taskId,
        uhid: uhid,
      };

      mutate(payload, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [taskId, uhid]);

  const vitalsDetails = data?.data?.data?.vitals?.vitals;
  let k = [vitalsDetails];

  return (
    <>
      <div className="bg-white p-5 mb-5 d-flex justify-content-between h-auto">
        <div className="align-items-center justify-content-center">
          <h1 className="fs-1 text-dark fw-bolder text-center">Vitals Info</h1>
        </div>
        {/* <div className="flex-end">
          <Link
            to={{
              pathname: `/patient/${id}/overview/`,
              search: "?tab=assigned",
            }}
            className="btn btn-md btn-danger w-200px"
          >
            Go Back
          </Link>
        </div> */}
      </div>
      <div className="">
        {isLoading ? (
          <div className="h-100 text-center mt-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
          </div>
        ) : (
          k?.map((items: any, index: any) => {
            // console.log(items);
            return (
              <div className="d-flex flex-wrap p-5">
                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/blood_glucose.svg"
                    vitalName="Blood Glucose"
                    vitalCollectionId={
                      items?.bloodGlucose.length != 0
                        ? items?.bloodGlucose[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.bloodGlucose.length != 0
                        ? items?.bloodGlucose[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/blood_pressure.svg"
                    vitalName="Blood Pressure"
                    vitalCollectionId={
                      items?.bloodPressure.length != 0
                        ? items?.bloodPressure[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.bloodPressure.length != 0
                        ? items?.bloodPressure[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/hemoglobin.svg"
                    vitalName="Hemoglobin"
                    vitalCollectionId={
                      items?.hemoglobin.length != 0
                        ? items?.hemoglobin[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.hemoglobin.length != 0
                        ? items?.hemoglobin[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/o2.svg"
                    vitalName="Oxygen Saturation"
                    vitalCollectionId={
                      items?.oxygenSaturation.length != 0
                        ? items?.oxygenSaturation[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.oxygenSaturation.length != 0
                        ? items?.oxygenSaturation[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                {/* {items.height.length != 0 && ( */}
                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/height.svg"
                    vitalName="Height"
                    vitalCollectionId={
                      items?.height.length != 0
                        ? items?.height[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.height.length != 0
                        ? items?.height[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>
                {/* )} */}

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/weight.svg"
                    vitalName="Weight"
                    vitalCollectionId={
                      items?.weight.length != 0
                        ? items?.weight[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.weight.length != 0
                        ? items?.weight[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/temperature.svg"
                    vitalName="Temperature"
                    vitalCollectionId={
                      items?.temperature.length != 0
                        ? items?.temperature[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.temperature.length != 0
                        ? items?.temperature[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/pulse.svg"
                    vitalName="Pulse"
                    vitalCollectionId={
                      items?.pulse.length != 0
                        ? items?.pulse[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.pulse.length != 0
                        ? items?.pulse[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>

                <div className="me-5 mb-8">
                  <CardView
                    icons="/media/ikure/task/respiratory.svg"
                    vitalName="Respiratory"
                    vitalCollectionId={
                      items?.respiratory.length != 0
                        ? items?.respiratory[index].vitalCollectionId
                        : "N/A"
                    }
                    vitalValue={
                      items?.respiratory.length != 0
                        ? items?.respiratory[index].vitalValue
                        : "N/A"
                    }
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
export default PatientVitals;
