import { Field, ErrorMessage, FieldArray } from "formik";
import MultiQuestion from "./MultiQuestion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { Switch } from "@mui/material";
import Modal from "../../../components/Modal";
import DeleteDialog from "./DeleteDialog";

const Sections = ({ values, index, setFieldValue, remove }: any) => {
  const [open, setOpen] = useState(true);
  const [saveState, setSaveState] = useState(false);
  const [editSectionTitle, setEditSectionTitle] = useState(false);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [clickedIndex, setClickedIndex] = useState(0);

  // Ends Here
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // Handle Title
  const handleSectionTitle = (index: any) => {
    if (values.sections[`${index}`].secId === clickedIndex) {
      // setSaveState(true);
      setEditSectionTitle(!editSectionTitle);
    }
    console.log("Outer Block", clickedIndex);
    console.log("Index", index);
  };
  // Save Btn
  const handleSaveButton = () => {
    if (index === clickedIndex) {
      setSaveState(true);
      setEditSectionTitle(false);
    }
  };
  // Cancel Btn
  const handleCancelButton = (setFieldValue: any) => {
    if (index === clickedIndex) {
      // setOpen(!open);
      setSaveState(false);
      setEditSectionTitle(false);
      setFieldValue(`sections[${index}].sectionName`, ``);
      // setFieldValue(`sections[${index}].sectionName`, `Section ${index + 1}`);
    }
  };
  return (
    <>
      {/* Remove Above Section */}
      <DragDropContext
        onDragEnd={(params) => {
          // console.log(params);
          const sourceIndex = params.source?.index;
          const destinationIndex: any = params.destination?.index;
          const cloneArr = values.sections[`${index}`].questions;

          if (destinationIndex || destinationIndex === 0) {
            [cloneArr[sourceIndex], cloneArr[destinationIndex]] = [
              cloneArr[destinationIndex],
              cloneArr[sourceIndex],
            ];
          }
        }}
      >
        <Droppable key={index} droppableId={"drop-" + index}>
          {(provided, snapshot) => (
            <div
              className={`mt-5 position-relative ${
                open === true ? "h-100" : "h-180px"
              }`}
              style={{
                backgroundColor: "#F0F0F0",
              }}
              ref={provided.innerRef}
              // style={{
              //   backgroundColor: snapshot.isDraggingOver
              //     ? "blue"
              //     : "grey",
              // }}
              {...provided.droppableProps}
            >
              <div
                className={`${
                  (index === clickedIndex) === true
                    ? "px-5 pt-8 w-100"
                    : "d-flex p-5 flex-column"
                }`}
              >
                <div
                  onMouseEnter={() => setHoverEffect(!hoverEffect)}
                  onMouseLeave={() => setHoverEffect(!hoverEffect)}
                  className={`form-label d-flex fw-bolder align-items-center justify-content-between
                                       fs-2`}
                >
                  <div
                    className={`${
                      index === clickedIndex
                        ? ""
                        : "d-flex justify-content-between"
                    }`}
                  >
                    <i
                      onClick={() => {
                        setClickedIndex(index);
                        setOpen(!open);
                      }}
                      className={`cursor-pointer fs-3 ms-5 me-2 fas text-hover-primary fa-chevron-${
                        open && clickedIndex === index ? "up" : "down mt-3"
                      }`}
                    ></i>

                    {(clickedIndex === index) === true && saveState === true
                      ? values.sections[`${index}`].sectionName
                      : `Section ${index + 1}`}

                    {hoverEffect && (
                      <i
                        onClick={() => {
                          setClickedIndex(index);
                          setEditSectionTitle(!editSectionTitle);
                          // console.log("clicked edit icon", index);
                        }}
                        title="Edit Section Name"
                        className="mt-2 ms-2 fs-4 fas fa-edit cursor-pointer text-hover-primary"
                      ></i>
                    )}
                  </div>
                  {/* Grip Lines */}
                  <div className="position-relative"></div>
                  {/* Next Page */}
                  {index !== 0 && (
                    <div
                      style={{
                        cursor: index <= 0 ? "not-allowed" : "pointer",
                      }}
                      role="group"
                      className="fv-row w-140px d-flex form-check form-switch form-check-solid form-check-custom"
                    >
                      <Switch
                        id={`sections[${index}].nextPage`}
                        name={`sections[${index}].nextPage`}
                        value={true}
                        checked={values?.sections[`${index}`].nextPage === true}
                        onChange={(event: any, checked: any) => {
                          setFieldValue(
                            `sections[${index}].nextPage`,
                            checked ? true : false
                          );
                        }}
                      />

                      <label
                        htmlFor={`sections[${index}].nextPage`}
                        className={`fs-2 me-7 fw-bolder  form-check-label text-center`}
                      >
                        {values.sections[`${index}`].nextPage === true
                          ? "New Page"
                          : "Previous Page"}
                      </label>
                    </div>
                  )}
                  {index === 0 && (
                    <div
                      style={{
                        cursor: index <= 0 ? "not-allowed" : "pointer",
                      }}
                      role="group"
                      className="fv-row w-140px d-flex form-check form-switch form-check-solid form-check-custom"
                    >
                      <Switch
                        disabled={index <= 0}
                        id={`sections[${index}].nextPage`}
                        name={`sections[${index}].nextPage`}
                        value={true}
                        checked={values?.sections[`${index}`].nextPage === true}
                        onChange={(event: any, checked: any) => {
                          setFieldValue(
                            `sections[${index}].nextPage`,
                            checked ? true : false
                          );
                        }}
                      />

                      <label
                        style={{
                          cursor: index <= 0 ? "not-allowed" : "pointer",
                        }}
                        htmlFor={`sections[${index}].nextPage`}
                        className={`fs-2 me-7 fw-bolder  form-check-label text-center`}
                      >
                        New Page
                      </label>
                    </div>
                  )}
                </div>

                <div className="d-flex">
                  {index === clickedIndex && editSectionTitle === true && (
                    <>
                      <div className="ms-10  position-relative">
                        <Field
                          name={`sections[${index}].sectionName`}
                          placeholder="Enter the section name"
                          className="form-control form-control-light w-700px h-40px"
                        />
                      </div>
                      <div
                        style={{
                          left: "680px",
                        }}
                        className="position-absolute mt-3 me-10"
                      >
                        <i
                          onClick={() => handleSaveButton()}
                          title="Save"
                          className={`fs-2 fas text-hover-primary fa-check cursor-pointer`}
                        ></i>
                        <i
                          onClick={() => handleCancelButton(setFieldValue)}
                          title="Cancel"
                          className="ms-5 fs-2 fas text-hover-danger fa-times cursor-pointer"
                        ></i>
                      </div>
                    </>
                  )}
                  <div className="text-danger ms-5 mt-3">
                    <ErrorMessage name={`sections[${index}].sectionName`} />
                  </div>
                </div>

                {/* Delete Button */}
                <div
                  style={{
                    position: "absolute",
                    top:
                      open ==
                      // (values.sections[`${index}`].secId === clickedIndex) ==
                      true
                        ? "50%"
                        : "15%",
                    right:
                      open ==
                      // (values.sections[`${index}`].secId === clickedIndex) ==
                      true
                        ? "-35px"
                        : "-20px",
                    // top: open == true ? "50%" : "15%",
                    // right: open == true ? "-35px" : "-20px",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {index > 0 && (
                    <span
                      className="card p-5 fs-2 fas fa-trash-alt text-muted text-hover-danger cursor-pointer"
                      // className="ms-5 btn btn-danger h-45px w-150px"

                      onClick={() => setIsModalOpen(!isModalOpen)}
                    ></span>
                  )}
                </div>
              </div>

              <Collapse
                id={index}
                in={(open && clickedIndex === index) === true ? true : false}
                timeout="auto"
                unmountOnExit
              >
                <div className="mt-5 ms-15">
                  <MultiQuestion sectionIndex={index} />
                  {provided.placeholder}
                </div>
              </Collapse>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* Modal */}
      <Modal open={isModalOpen}>
        <DeleteDialog
          remove={() => remove(index)}
          sectionTitle={
            values?.sections[`${index}`].sectionName || `Section ${index + 1}`
          }
          onClose={handleModal}
          styles={{
            backgroundColor: "white",
            width: "650px",
            height: "380px",
            padding: "50px",
          }}
        />
      </Modal>
    </>
  );
};

export default Sections;
