import React from "react";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "../../../../components/Pagination";
import { calcPurchaseAmount } from "../../goods-receipt-note/grn-list/grnUtils";
import { dateFormatter } from "../../../../common/utils";

type Props = {
    data: any;
    setLimit: Function;
    limit: number;
    page: number;
    totalRecords: number;
    setPage: Function;
    isLoading: boolean;
    isError: boolean;
};

const tableNames = [
    "GRN NO",
    "VENDOR NAME",
    "GRN DATE",
    "TOTAL AMOUNT",
    "INVOICE FILE",
    "PAYMENT STATUS",
];

const PurchaseOrderGrnTable = (props: Props) => {
    const {
        data,
        setLimit,
        limit,
        page,
        setPage,
        isLoading,
        isError,
        totalRecords,
    } = props;

    return (
        <div>
            <div>
                <h4>Good Receipt Note (GRN)</h4>
            </div>
            <div className="mt-5">
                <Table>
                    <TableHeadRow>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>
                        {tableNames?.map((name) => {
                            return (
                                <TableHead width={180} key={name} align="left">
                                    {name.toUpperCase()}
                                </TableHead>
                            );
                        })}
                    </TableHeadRow>
                    <TableBodyContainer
                        isLoading={isLoading}
                        isError={isError}
                        errorMessage={""}
                    >
                        {data?.map((ele: any, idx: number) => {
                            const totalAmount = calcPurchaseAmount(
                                ele?.products
                            );

                            return (
                                <TableRow collapsible={false}>
                                    <TableCell align="center">
                                        {page && (page - 1) * limit + idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/purchase-order/details`}
                                            className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                        >
                                            {ele?.grnId}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {ele?.vendorReferenceId?.vendorName}
                                    </TableCell>
                                    <TableCell>
                                        {ele?.createdAt
                                            ? dateFormatter(ele?.createdAt)
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>{totalAmount}</TableCell>
                                    <TableCell>
                                        {ele?.invoiceFile !== "" ? (
                                            <a
                                                href={ele?.invoiceFile}
                                                target="_blank"
                                            >
                                                <i
                                                    // onClick={handleAlert}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                                ></i>
                                            </a>
                                        ) : (
                                            <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <span
                                            style={{
                                                color:
                                                    ele
                                                        ?.purchaseOrderReferenceId
                                                        ?.paymentStatus ===
                                                    "Due"
                                                        ? "#50cd89"
                                                        : ele
                                                              ?.purchaseOrderReferenceId
                                                              ?.paymentStatus ===
                                                          "Paid"
                                                        ? "#FFA533"
                                                        : "",
                                            }}
                                        >
                                            {
                                                ele?.purchaseOrderReferenceId
                                                    ?.paymentStatus
                                            }
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBodyContainer>
                </Table>
                <Pagination
                    dataCount={totalRecords}
                    handleCallback={(val: number | string) => {
                        setLimit(val);
                    }}
                    handleNext={() =>
                        setPage((prevPageNumber: number) => prevPageNumber + 1)
                    }
                    handlePrev={() =>
                        setPage((prevPageNumber: number) => prevPageNumber - 1)
                    }
                    nextDisabled={page === Math.ceil(totalRecords / limit)}
                    pageNumber={page}
                    pageLimit={limit}
                    prevDisabled={page === 1}
                />
            </div>
        </div>
    );
};

export default PurchaseOrderGrnTable;
