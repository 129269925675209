import React from "react";

type Props = {
    children: React.ReactNode;
    width: number;
    align?: "left" | "right" | "center";
};

const TableHead = (props: Props) => {
    const { children, width, align } = props;
    return (
        <th className={`w-${width}px text-${align ? align : "left"} p-2`}>
            {children}
        </th>
    );
};

export default TableHead;
