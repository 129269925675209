import dayjs from "dayjs";
import React from "react";
import { dateFormatter } from "../../../../common/utils";

type Props = {
    salesOrderData: any;
};

const TrackingDetails = (props: Props) => {
    const salesDetails = props?.salesOrderData?.map((item: any, idx: any) => {
        return {
            orderCreatedDate: item?.createdAt
                ? dayjs(new Date(item?.createdAt)).format("DD MMM,YYYY")
                : "N/A",
            /**
             *  Check if the field `item?.createdAt` is present in the Object.
             *  IF its present make it true or set it to false.
             */
            orderCreatedStatus: true, // TODO : .hasOwnProperty('property1')

            orderPackedDate: item?.orderPackedDate
                ? dayjs(new Date(item?.orderPackedDate)).format("DD MMM,YYYY")
                : "N/A",
            orderPackedStatus: item?.orderPackedDate ? true : false,
            orderShippedDate: item?.orderShippedDate
                ? dayjs(new Date(item?.orderShippedDate)).format("DD MMM,YYYY")
                : "N/A",
            orderShippedStatus: item?.orderShippedDate ? true : false,
            orderDeliveredDate: item?.orderDeliveredDate
                ? dayjs(new Date(item?.orderDeliveredDate)).format(
                      "DD MMM,YYYY"
                  )
                : "N/A",
            orderDeliveredStatus: item?.orderDeliveredDate ? true : false,
        };
    });

    return (
        <div style={{ marginBottom: "5rem", marginTop: "2rem" }}>
            <p className="fw-bolder fs-4">Tracking Details</p>
            <div
                className="p-2 d-flex align-items-center"
                style={{ width: "85%", marginTop: "4rem" }}
            >
                <div>
                    <i
                        className="fa-solid fa-circle-check "
                        style={{
                            color:
                                salesDetails &&
                                salesDetails[0]?.orderCreatedStatus === true
                                    ? "#6be16d"
                                    : "#a1aaa1",
                            fontSize: "2rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <p style={{ position: "absolute" }}>
                        Order created on{" "}
                        {salesDetails &&
                            dateFormatter(salesDetails[0]?.orderCreatedDate)}
                    </p>
                </div>
                <div
                    style={{
                        border: "1px solid black",
                        width: "100%",
                        height: "0.5px",
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                    }}
                />
                <div style={{ position: "relative" }}>
                    <i
                        className="fa-solid fa-circle-check "
                        style={{
                            color:
                                salesDetails &&
                                salesDetails[0]?.orderPackedStatus === true
                                    ? "#6be16d"
                                    : "#a1aaa1",
                            fontSize: "2rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <p
                        style={{
                            position: "absolute",
                            left: "-2rem",
                            width: "20rem",
                        }}
                    >
                        Order packed on{" "}
                        {salesDetails &&
                            dateFormatter(salesDetails[0]?.orderPackedDate)}
                    </p>
                </div>
                <div
                    style={{
                        border: "1px solid black",
                        width: "100%",
                        height: "0.5px",
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                    }}
                />
                <div style={{ position: "relative" }}>
                    <i
                        className="fa-solid fa-circle-check "
                        style={{
                            color:
                                salesDetails &&
                                salesDetails[0]?.orderShippedStatus === true
                                    ? "#6be16d"
                                    : "#a1aaa1",
                            fontSize: "2rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <p
                        style={{
                            position: "absolute",
                            left: "-6rem",
                            width: "20rem",
                        }}
                    >
                        Product shipped on{" "}
                        {salesDetails &&
                            dateFormatter(salesDetails[0]?.orderShippedDate)}
                    </p>
                </div>
                <div
                    style={{
                        border: "1px solid black",
                        width: "100%",
                        height: "0.5px",
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                    }}
                />
                <div style={{ position: "relative" }}>
                    <i
                        className="fa-solid fa-circle-check "
                        style={{
                            color:
                                salesDetails &&
                                salesDetails[0]?.orderDeliveredStatus === true
                                    ? "#6be16d"
                                    : "#a1aaa1",
                            fontSize: "2rem",
                            marginBottom: "1rem",
                            marginLeft: "1rem",
                            marginRight: "1rem",
                        }}
                    />
                    <p
                        style={{
                            position: "absolute",
                            left: "-4rem",
                            width: "20rem",
                        }}
                    >
                        Product delivered on{" "}
                        {salesDetails &&
                            dateFormatter(salesDetails[0]?.orderDeliveredDate)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TrackingDetails;
