import AssignedPatientFocusedGroupTable from "./table-view/AssignedPatientFocusedGroupTable";

const AssignedFocusedGroupPage = () => {
  return (
    <div>
      <AssignedPatientFocusedGroupTable />
    </div>
  );
};

export default AssignedFocusedGroupPage;
