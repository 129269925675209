import React, { useMemo, useState, useEffect } from "react";
import AddSalesProductForm from "./SalesOrderForm/SalesOrderForm";
import { IProductsArr } from "./SalesOrderForm/IForms";
import SalesAddedProductTable from "./SalesOrderProductTable/SalesOrderProductTable";
import { getSalesOrderDetails } from "../../../../services/supply-chain/sales-order/sales-order-details/SalesOrderDetails.services";
import useGetRequest from "../../../../hooks/getRequest.query";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

type Props = {};

const ModifySalesOrder = (props: Props) => {
    const [productsArr, setProductsArr] = useState<IProductsArr>([]);
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId") as string;
    const { data }: any = useGetRequest(["getSalesOrderDetails"], () =>
        getSalesOrderDetails({
            page: 1,
            limit: 100,
            orderId,
        })
    );

    const customProducts = useMemo(() => {
        return data?.data?.data?.order?.map((ele: any) => {
            return ele?.products?.map((obj1: any) => {
                // Finding objects from product data array
                const matchingObj = ele?.productData?.find((obj2: any) => {
                    return obj2._id === obj1.productId;
                });
                // Finding objects from inventoryData Array
                const matchInventoryObj =
                    ele.customerType === "CHW"
                        ? ele?.inventoryData.find((obj3: any) => {
                              return obj3._id === obj1.inventoryId;
                          })
                        : ele?.nestedInventoryData.find((obj3: any) => {
                              return obj3.productId === obj1.productId;
                          });

                return {
                    ...obj1,
                    ...matchingObj,
                    ratePerUnit: matchInventoryObj.ratePerUnit,
                    expiry: matchInventoryObj.expiry,
                };
            });
        });
    }, [data]);

    // Reformatting the object of the api to how we are using in the UI
    useEffect(() => {
        const arr = customProducts?.flat(1)?.map((ele: any) => {
            return {
                product: ele.productName,
                productId: ele.productId,
                manufacturer: ele.manufacturerName,
                batch: ele.batch,
                expiryDate: dayjs(ele?.expiry),
                units: ele.totalQuantity,
                gstTotal: ele.GST,
                mrpPerUnit: ele?.ratePerUnit,
                discountPercent: ele.discount,
                batchArr: [ele.batch],
                vendorObj: { label: "", year: "" },
                productObj: { label: "", year: "" },
                inventoryId: ele.inventoryId,
            };
        });
        setProductsArr(arr);
    }, [customProducts]);

    return (
        <div className="card text-dark p-3">
            <AddSalesProductForm
                setProductsArr={setProductsArr}
                customerType={data?.data?.data?.order[0]?.customerType}
            />
            <div className="mt-5">
                {productsArr?.length > 0 && (
                    <SalesAddedProductTable
                        productsArr={productsArr}
                        setProductsArr={setProductsArr}
                    />
                )}{" "}
            </div>
        </div>
    );
};

export default ModifySalesOrder;
