import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast, { Toaster } from "react-hot-toast";

type Props = {
    mutate: Function;
    setApiData: Function;
    setPage: Function;
};
const searchSchema = Yup.object().shape({
    searchName: Yup.string()
        .matches(
            /^\s*S0\d{7}$/,
            "Search must start with 'S0' followed by exactly 7 digits"
        )
        .label("Search"),
});
const SearchBoxSo = ({ mutate, setApiData, setPage }: Props) => {
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Found Sales Order!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = (err: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {err}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    return (
        <div className="card-toolbar">
            <Toaster />
            <div className="py-2 me-20">
                <Formik
                    initialValues={{
                        searchName: "",
                    }}
                    validationSchema={searchSchema}
                    onSubmit={(values, onSubmitProps) => {
                        const payload = {
                            facilityCode,
                            orderId: values.searchName?.trim(),
                        };
                        setPage(1);
                        mutate(payload, {
                            onSuccess: (data: any) => {
                                successToastMsg();
                                setTimeout(() => {
                                    onSubmitProps.resetForm();
                                }, 1500);
                                setApiData(data?.data);
                            },
                            onError: (error: any) => {
                                errorToastMsg(error?.response?.data?.message);
                                onSubmitProps.resetForm();
                            },
                        });
                    }}
                >
                    {(formProps: any) => (
                        <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex ">
                                <Field
                                    name="searchName"
                                    placeholder="Search ID"
                                    className="form-control form-control-lg form-control-solid"
                                    data-testid="searchBar"
                                />
                                <button
                                    disabled={
                                        formProps.isSubmitting ||
                                        !(formProps.isValid && formProps.dirty)
                                    }
                                    type="submit"
                                    className="ms-3 btn btn-lg btn-primary"
                                    data-testid="search-btn"
                                >
                                    Search
                                </button>
                            </div>
                            <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                <ErrorMessage name="searchName" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SearchBoxSo;
