import { Field, ErrorMessage } from "formik";

const TDManual = ({ selectedCHW, kpiTargetData, isMessage }: any) => {
    return (
        <div>
            <div className="w-100">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {/* Remaining Targets */}
                    <div className="fv-row">
                        <label className="form-label required">
                            Remaining Targets after distribution
                        </label>

                        <Field
                            readOnly
                            name="remainingTargetValue"
                            value={
                                kpiTargetData &&
                                kpiTargetData?.totalTargetsRemaining
                            }
                            className="form-control form-control-lg form-control-solid"
                        />
                    </div>
                    {/* Total assigned Targets */}
                    <div className="fv-row ms-5">
                        <label className="form-label">
                            Already assigned targets to CHW
                        </label>

                        <Field
                            readOnly
                            name="approxNumToDistributeAtFacilityLevel"
                            value={
                                kpiTargetData &&
                                kpiTargetData?.totalTargetsAssigned
                            }
                            className="form-control form-control-lg form-control-solid"
                        />
                    </div>

                    <div className="fv-row ms-5">
                        <label className="form-label">
                            Targets distributed to each CHWS
                        </label>

                        <Field
                            readOnly
                            name="approxNumToDistribute"
                            value={
                                kpiTargetData && kpiTargetData?.avgTargetForChw
                            }
                            className="form-control form-control-lg form-control-solid"
                        />
                    </div>
                </div>
                <div
                    className="mt-5 h-250px p-2"
                    style={{ overflowX: "scroll" }}
                >
                    {selectedCHW?.length > 0 && (
                        <label className="form-label fs-4 fw-bolder">
                            Selected CHW's
                        </label>
                    )}
                    {selectedCHW?.length === 0 && (
                        <div
                            className="ms-5 mb-2 d-flex justify-content-center align-items-center"
                            style={{ height: "30vh" }}
                        >
                            <div className="fs-3  text-danger">
                                Please select CHW's
                            </div>
                        </div>
                    )}
                    {selectedCHW?.map((chw: any, index: any) => {
                        return (
                            <>
                                <div className="ms-5 mb-2 d-flex justify-content-between align-items-center">
                                    <span className="fs-6 fw-bolder w-250px text-capitalize">
                                        {`${index + 1} . ${chw?.name}`}
                                    </span>

                                    <div className="fs-6 w-250px text-danger">
                                        <ErrorMessage
                                            name={`approxNumToDistribute[${index}].targetForChw`}
                                        />
                                    </div>
                                    <Field
                                        placeholder="0"
                                        name={`approxNumToDistribute[${index}].targetForChw`}
                                        className="w-80px text-center form-control form-control-lg form-control-solid"
                                    />
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TDManual;
