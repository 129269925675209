import $axiosProtected from "../../../../common/utils/axiosUtils";

// Get GRN by Purchase Id
export const getSalesOrderDetails = async ({
    page,
    limit,
    orderId,
}: {
    page: number;
    limit: number;
    orderId?: string;
}) => {
    return await $axiosProtected().get(`/inventory/salesOrder/get/id/web`, {
        params: {
            limit,
            page,
            orderId,
        },
    });
};
