import { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player/youtube";
import useIntersectionObserver from "../../../../../hooks/useIntersectionObservable.hook";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const observerConfig = {
  threshold: 0,
};
// Page with observer component
function PageWithObserver({
  pageNumber,
  setPageVisibility,
  ...otherProps
}: any) {
  const [page, setPage] = useState(null);

  const onIntersectionChange = useCallback(
    ([entry]: any) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility]
  );

  useIntersectionObserver(page, observerConfig, onIntersectionChange);

  return (
    <Page
      width={500}
      scale={1.8}
      canvasRef={setPage}
      pageNumber={pageNumber}
      {...otherProps}
    />
  );
}

const ModalUI = ({ onClose, userData, styles }: any) => {
  const [numPages, setNumPages] = useState(null);
  const [visiblePages, setVisiblePages] = useState({});

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const setPageVisibility = useCallback(
    (pageNumber: any, isIntersecting: any) => {
      setVisiblePages((prevVisiblePages) => ({
        ...prevVisiblePages,
        [pageNumber]: isIntersecting,
      }));
    },
    []
  );
  return (
    <div
      style={{
        backgroundColor: styles?.backgroundColor,
        width: styles?.width,
        height: styles?.height,
        padding: styles?.padding,
      }}
      className="card mb-5"
    >
      <div
        className="d-flex justify-content-between align-items-center card-header border-0 cursor-pointer"
        role="button"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0"> Information</h3>
        </div>
        <div className="d-flex justify-content-end">
          <h1 onClick={() => onClose()} className="mt-2">
            <i className="fs-2 text-danger fas fa-window-close cursor-pointer"></i>
          </h1>
        </div>
      </div>

      {/* User Data */}
      <>
        {userData &&
          (userData?.lessonType === "Video" ? (
            <div
              style={{
                width: "100%",
                height: "550px",
                padding: "10px",
              }}
            >
              <ReactPlayer
                url={userData?.videoLink}
                controls
                height="100%"
                width="100%"
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "550px",
                backgroundColor: "rgba(109, 109, 109, 0.5)",
                overflowY: "scroll",
                padding: "5px",
              }}
            >
              <Document
                file={userData?.documentValue}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <PageWithObserver
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    setPageVisibility={setPageVisibility}
                  />
                ))}
              </Document>
            </div>
          ))}
      </>
    </div>
  );
};

export default ModalUI;
