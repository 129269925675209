import React from "react";

type Props = {
    message: string;
};

const ErrorMessageText = ({ message }: Props) => {
    return (
        <div className="text-danger" data-testid="error-text">
            {message}
        </div>
    );
};

export default ErrorMessageText;
