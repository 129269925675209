import React from "react";
import { Menu } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { todayDate } from "../../../common/utils";
import validationSchema from "./formValidationSchema";
import useMutateRequest from "../../../hooks/getMutation.query";
import { generateReport } from "../../../services/report/ReportGeneration.hook";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

type Props = {
    anchorEl: any;
    open: boolean;
    handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

const ExportMenu = ({ open, anchorEl, handleClose }: Props) => {
    const { mutate, isLoading } = useMutateRequest((data: any) =>
        generateReport(data)
    );
    const navigate = useNavigate();
    const facilityCode = localStorage.getItem("facilityCode");

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        let fullPayload = [
            {
                filterName: "startDate",
                filterValue: values?.fromDate,
            },
            {
                filterName: "endDate",
                filterValue: values?.toDate,
            },
            {
                filterName: "paymentType",
                filterValue: values?.transactionType,
            },
            {
                filterName: "salesOrderType",
                filterValue: values?.receivedAt,
            },
            {
                filterName: "facilityCode",
                filterValue: facilityCode,
            },
        ]?.filter((item) => item?.filterValue !== "");

        let payload: any = {
            reportType: "exportSalesOrderPaymentReport",
            reportRepository: "inventory",
            filter: fullPayload,
        };

        mutate(payload, {
            onSuccess: (data: any, onSubmitProps: any) => {
                successToastMsg();
                navigate("/report/report-management?report=8");
                onSubmitProps.resetForm();
            },
            onError: (error: any) => {
                errorToastMsg(error?.response?.data?.message);
            },
        });
    };
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Formik
                initialValues={{
                    fromDate: "",
                    toDate: "",
                    transactionType: "",
                    receivedAt: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onFilterSubmit}
            >
                {(formProps: any) => (
                    <Form>
                        <div className="py-5 px-8">
                            <div className="w-250px mt-5 mb-5">
                                <label
                                    className="form-label fs-4 fw-bolder required"
                                    htmlFor="fromDate"
                                >
                                    FROM DATE
                                </label>
                                <Field
                                    id="fromDate"
                                    as="input"
                                    data-testid="from-date required"
                                    type="date"
                                    max={todayDate}
                                    name="fromDate"
                                    className="form-control cursor-pointer"
                                />
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="fromDate" />
                                </div>
                            </div>
                            <div className="w-250px mt-5">
                                <label className="form-label fs-4 fw-bolder required">
                                    TO DATE
                                </label>
                                <Field
                                    onChange={(e: any) => {
                                        formProps.handleChange(e);
                                        formProps.setFieldTouched("fromDate");
                                    }}
                                    id="toDate"
                                    data-testid="to-date"
                                    type="date"
                                    name="toDate"
                                    max={todayDate}
                                    className="form-control"
                                />
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="toDate" />
                                </div>
                            </div>

                            <div className="w-250px mt-5 mb-8">
                                <label className="form-label fs-4 fw-bolder">
                                    RECEIVED AT
                                </label>
                                <Field
                                    id="receivedAt"
                                    as="select"
                                    name="receivedAt"
                                    className="form-select form-select-light form-select-lg"
                                    defaultValue=""
                                >
                                    <option value="">All</option>
                                    <option value="Clinic">Clinic</option>
                                    <option value="Camp">Camp</option>
                                    <option value="Doorstep">Doorstep</option>
                                </Field>
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="receivedAt" />
                                </div>
                            </div>

                            <div className="w-250px mt-5">
                                <label className="form-label fs-4 fw-bolder">
                                    TRANSACTION TYPE
                                </label>
                                <Field
                                    id="transactionType"
                                    as="select"
                                    name="transactionType"
                                    className="form-select form-select-light form-select-lg"
                                    defaultValue=""
                                >
                                    <option value="">All</option>
                                    <option value="Cash">Cash</option>
                                    <option value="UPI">UPI</option>
                                </Field>
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="transactionType" />
                                </div>
                            </div>
                        </div>

                        <div className="my-5 d-flex justify-content-center align-items-center">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-250px btn btn-sm btn-primary"
                            >
                                EXPORT{" "}
                                {isLoading && (
                                    <ClipLoader size={10} color="#fff" />
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Menu>
    );
};

const successToastMsg = () => {
    toast.success(
        (t) => (
            <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                {/* {successMsg} */}
                Report Processing!
                <button
                    className="ms-15"
                    style={{ backgroundColor: "transparent", border: "0" }}
                    onClick={() => toast.dismiss(t.id)}
                >
                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                </button>
            </span>
        ),
        {
            duration: 5000,
            position: "top-right",
            id: "success-toast",
        }
    );
};

const errorToastMsg = (err: string) => {
    toast.error(
        (t) => (
            <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                {err}
                <button
                    className="ms-15"
                    style={{ backgroundColor: "transparent", border: "0" }}
                    onClick={() => toast.dismiss(t.id)}
                >
                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                </button>
            </span>
        ),
        {
            duration: 5000,
            position: "top-right",
            id: "error-toast",
        }
    );
};

export default ExportMenu;
