import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";

const Step1: FC = () => {
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Basic Info</h2>

                {/* <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please check out
          <a href="/dashboard" className="link-primary fw-bolder">
            {" "}
            Help Page
          </a>
          .
        </div> */}
            </div>

            {/* First Name */}
            <div className="fv-row mb-10">
                <label className="form-label required">
                    Facility First Name
                </label>

                <Field
                    data-testid="facility-first-name"
                    name="facilityFirstName"
                    placeholder="Enter facility first name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="facilityFirstName" />
                </div>
            </div>

            {/* Last Name */}
            <div className="fv-row mb-10">
                <label className="d-flex align-items-center form-label">
                    <span className="required">Facility Last Name</span>
                </label>

                <Field
                    data-testid="facility-last-name"
                    name="facilityLastName"
                    placeholder="Enter facility last name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="facilityLastName" />
                </div>
            </div>

            {/* Phone Number */}
            <div className="fv-row mb-10">
                <label className="form-label required">Phone Number</label>

                <Field
                    data-testid="telecom"
                    name="telecom"
                    placeholder="Enter facility phone number"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="telecom" />
                </div>
            </div>

            <div className="fv-row mb-0">
                <label className="fs-6 fw-bold form-label required">
                    Personal Email
                </label>

                <Field
                    data-testid="email"
                    type="email"
                    name="email"
                    placeholder="Enter facility email id"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="email" />
                </div>
            </div>
        </div>
    );
};

export { Step1 };
