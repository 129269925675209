import { Field } from "formik";

const TDAuto = ({
  approxNumToDistribute,
  remainingTargetsToDistribute,
  totalNumberOfCHWInFacility,
}: any) => {
  return (
    <div className="w-100">
      <div className="d-flex flex-column">
        {/* Remaining Targets */}
        <div className="fv-row mb-8">
          <label className="form-label">
            Remaining target after distribution
          </label>

          <Field
            readOnly
            value={remainingTargetsToDistribute}
            className="form-control form-control-lg form-control-solid"
          />
        </div>

        <div className="fv-row mb-8">
          <label className="form-label">Total number of CHW in facility</label>

          <Field
            readOnly
            name="totalNumberOfCHWInFacility"
            value={totalNumberOfCHWInFacility}
            className="form-control form-control-lg form-control-solid"
          />
        </div>

        {/* Approximate Targets for Each CHW*/}
        <div className="fv-row mb-8">
          <label className="form-label">Approximate target to each CHW</label>

          <Field
            readOnly
            value={approxNumToDistribute}
            className="form-control form-control-lg form-control-solid"
          />
        </div>
      </div>
    </div>
  );
};

export default TDAuto;
