import { Field, ErrorMessage } from "formik";

const Step6 = () => {
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark">Consultation Settings</h2>
      </div>

      {/* Physical Consultation */}
      <div className="fv-row col-lg-12 mb-5">
        <label className="form-label fw-bold fs-3 text-dark mb-5">
          Physical Consultation
        </label>

        <div className="fv-row mb-10">
          <label className="form-label">Consultation Fee</label>
          <Field
            data-testid="physical-consultation-fee"
            type="text"
            placeholder="Please enter your consultation fee for physical"
            className="form-control form-control-lg form-control-solid"
            name="physicalConsultationFee"
          />

          <div className="text-danger mt-2">
            <ErrorMessage name="physicalConsultationFee" />
          </div>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label">Consultation Slot Limit</label>
          <Field
            data-testid="physical-consultation-limit"
            type="text"
            placeholder="Please enter your consultation limit for physical"
            className="form-control form-control-lg form-control-solid"
            name="physicalConsultationLimit"
          />

          <div className="text-danger mt-2">
            <ErrorMessage name="physicalConsultationLimit" />
          </div>
        </div>
      </div>

      {/* Teleconsultation */}
      <div className="fv-row col-lg-12 mb-5">
        <label className="form-label fw-bold fs-3 text-dark mb-5">
          Teleconsultation
        </label>

        <div className="fv-row mb-10">
          <label className="form-label">Consultation Fee</label>
          <Field
            data-testid="online-consultation-fee"
            type="text"
            placeholder="Please enter your consultation fee for online"
            className="form-control form-control-lg form-control-solid"
            name="onlineConsultationFee"
          />

          <div className="text-danger mt-2">
            <ErrorMessage name="onlineConsultationFee" />
          </div>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label">Consultation Slot Limit</label>
          <Field
            data-testid="online-consultation-limit"
            type="text"
            placeholder="Please enter your consultation limit for online"
            className="form-control form-control-lg form-control-solid"
            name="onlineConsultationLimit"
          />

          <div className="text-danger mt-2">
            <ErrorMessage name="onlineConsultationLimit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step6 };
