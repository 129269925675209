import ClipLoader from "react-spinners/ClipLoader";

const Loading = () => {
    return (
        <div className="card w-100 h-550px d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
    );
};

export default Loading;
