import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import * as Yup from "yup";

import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import Loading from "../../../../components/Loader";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getIndentById } from "../../../../services/supply-chain/indent/Indent.services";
import { getAllVendors } from "../../../../services/supply-chain/vendor/Vendor.services";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { createPurchaseOrder } from "../../../../services/supply-chain/purchase-order/CreatePurchaseOrder.services";
import { isArray } from "lodash";

type Props = {};

const formSchema = Yup.object().shape({
    products: Yup.array().of(
        Yup.object().shape({
            unitPerStrip: Yup.number(),
            noOfstrips: Yup.number(),
            stripPrice: Yup.number()
                .min(0, "Must be greater than 0")
                .required("Strip is required"),
            totalUnits: Yup.number(),
            totalAmount: Yup.number(),
        })
    ),
    vendorId: Yup.mixed().required("Vendor is required"),
});

// const formSchema = Yup.object({
//     vendorId: Yup.mixed().required("Vendor is required"),
//     unitPerStrip: Yup.number(),
//     noOfstrips: Yup.number(),
//     stripPrice: Yup.number(),
//     totalUnits: Yup.number(),
//     totalAmount: Yup.number(),
// });

const tableNames = [
    "Product",
    "Manufacturer Name",
    "Units / STRIP",
    "No. of Strip",
    "Price / Strip",
    "Total Unit",
    "Amount",
];

const EditableIndentDetailsTable = (props: Props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const indentId = searchParams.get("id");

    const [currentPageNumber] = useState(1);
    const [pageLimit] = useState(10);
    const facilityCode = localStorage.getItem("facilityCode");

    const { isLoading, mutate, isError, error, reset }: any = useMutateRequest(
        (data: any) => createPurchaseOrder(data)
    );
    const errorMsg = error?.response?.data?.message;

    // Table API
    const { data, isFetching, refetch }: any = useGetRequest(
        ["getIndentById", pageLimit],
        () =>
            getIndentById({
                facilityCode,
                indentId,
                page: currentPageNumber,
                limit: pageLimit,
            }),
        { staleTime: 100000, refetchOnWindowFocus: false }
    );

    const indentList = data?.data?.data?.indent;
    const indentReferenceId = indentList?.indentRequestId;

    // Vendor API

    const { data: vendorsData }: any = useGetRequest(
        ["getAllVendors", pageLimit],
        () =>
            getAllVendors({
                page: 1,
                limit: 100,
            }),
        { staleTime: 100000, refetchOnWindowFocus: false }
    );

    const vendorsList = vendorsData?.data?.data?.vendors;

    const onSubmit = (values: any, onSubmitProps: any) => {
        // case: 1 if some product is enabled
        const selectedProducts = values?.products?.filter(
            (product: any) => product?.isDisabled === false
        );

        const products =
            selectedProducts?.length === 0
                ? values?.products
                : selectedProducts;

        const customProducts = products?.map((product: any) => {
            return {
                productId: product?.productId?._id,
                unitsPerStrip: product?.unitPerStrip,
                totalStrips: product?.noOfstrips,
                totalUnits: product?.unitPerStrip * product?.noOfstrips,
                pricePerStrip: product?.stripPrice,
                totalAmount: product?.stripPrice * product?.noOfstrips,
            };
        });

        const totalAmount = products?.reduce(
            (total: any, item: any) =>
                total + item?.stripPrice * item?.noOfstrips,
            0
        );
        // case: 2 if every product is disabled
        const payload = {
            indentId: indentReferenceId,
            indentReferenceId: indentId,
            facilityCode,
            totalAmount,
            paymentStatus: "Due", // Paid, by default we use Due
            products: customProducts,
            vendorId: values?.vendorId,
        };
        console.log("pay", values);
        mutate(payload, {
            onSuccess: (data: any) => {
                successToastMsg();
                setTimeout(() => {
                    onSubmitProps.resetForm();
                    navigate("/purchase-order/list");
                }, 1500);
            },
            onError: (error: any) => {
                console.log(error?.response);

                errorToastMsg(error?.response?.data?.message);
            },
        });
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Successfully created a Purchase Order
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = (errorStr: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {errorStr}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    const vendorNames = vendorsList?.map((vendor: any) => {
        return {
            label: vendor?.vendorName,
            vendorId: vendor?._id,
        };
    });

    // useEffect(() => {
    //     isSuccess && successToastMsg();
    // }, [isSuccess]);

    // useEffect(() => {
    //     isError && errorToastMsg();
    // }, []);

    return (
        <>
            <Toaster />
            {isLoading ? (
                <Loading />
            ) : (
                <Formik
                    initialValues={{
                        products: indentList?.products?.map((item: any) => ({
                            ...item,
                            stripPrice: 0,
                            isDisabled: true,
                        })),
                        vendorId: "",
                    }}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: any) => {
                        const selectedProducts =
                            isArray(formProps?.values?.products) &&
                            formProps?.values?.products?.filter(
                                (product: any) => product?.isDisabled === false
                            );

                        const products =
                            selectedProducts?.length === 0
                                ? formProps?.values?.products
                                : selectedProducts;

                        const totalAmount = products?.reduce(
                            (total: any, item: any) =>
                                total + item?.stripPrice * item?.noOfstrips,
                            0
                        );

                        const handleCheckbox = (e: any, setFieldValue: any) => {
                            const { name, checked } = e.target;

                            setFieldValue(name, !checked);
                        };
                        return (
                            <Form>
                                <div className="card">
                                    <div className="d-flex justify-content-between p-3">
                                        <h2>
                                            Create Purchase Order
                                            {/* <span
                                                className="text-muted"
                                                style={{
                                                    fontSize: "1rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {indentId}
                                            </span> */}
                                            <span>
                                                <i
                                                    onClick={() => {
                                                        refetch();
                                                        reset();
                                                    }}
                                                    className="fas fa-redo fs-3 cursor-pointer ms-5"
                                                ></i>
                                            </span>
                                        </h2>
                                        <div className="d-flex flex-column">
                                            <Autocomplete
                                                disabled={false}
                                                data-testid="name"
                                                disablePortal
                                                autoHighlight
                                                id="combo-box-demo"
                                                options={vendorNames}
                                                sx={{ width: 300 }}
                                                onChange={(e, value: any) => {
                                                    formProps?.setFieldValue(
                                                        "vendorId",
                                                        `${value?.vendorId}`
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select the vendor"
                                                    />
                                                )}
                                            />
                                            <div className="text-danger mt-1 text-center">
                                                <ErrorMessage name="vendorId" />
                                            </div>
                                        </div>
                                    </div>
                                    <Table>
                                        <TableHeadRow>
                                            <TableHead
                                                width={140}
                                                align="center"
                                            >
                                                ROW
                                            </TableHead>
                                            <TableHead
                                                width={140}
                                                align="center"
                                            >
                                                SL NO
                                            </TableHead>
                                            {tableNames?.map((name) => {
                                                return (
                                                    <TableHead
                                                        width={180}
                                                        key={name}
                                                        align="left"
                                                    >
                                                        {name}
                                                    </TableHead>
                                                );
                                            })}
                                        </TableHeadRow>
                                        <TableBodyContainer
                                            isLoading={isLoading || isFetching}
                                            isError={isError}
                                            errorMessage={errorMsg} // FIXME : fix the proper message
                                        >
                                            {formProps?.values?.products?.map(
                                                (ele: any, idx: number) => {
                                                    return (
                                                        <TableRow
                                                            collapsible={false}
                                                        >
                                                            <TableCell align="center">
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleCheckbox(
                                                                            e,
                                                                            formProps?.setFieldValue
                                                                        )
                                                                    }
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name={`products[${idx}].isDisabled`}
                                                                    checked={
                                                                        !formProps
                                                                            ?.values
                                                                            ?.products[
                                                                            idx
                                                                        ]
                                                                            .isDisabled
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {idx + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {ele?.productId
                                                                    ?.productName
                                                                    ? ele
                                                                          ?.productId
                                                                          ?.productName
                                                                    : "N/A"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {ele?.manufacturerName
                                                                    ? ele?.manufacturerName
                                                                    : "N/A"}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    disabled={
                                                                        ele?.isDisabled
                                                                    }
                                                                    className="w-75px form-control form-control-sm"
                                                                    type="number"
                                                                    name={`products[${idx}].unitPerStrip`}
                                                                    onChange={formProps?.handleChange(
                                                                        `products[${idx}].unitPerStrip`
                                                                    )}
                                                                    defaultValue={
                                                                        ele?.unitPerStrip
                                                                    }
                                                                    min="0"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    disabled={
                                                                        ele?.isDisabled
                                                                    }
                                                                    className="w-75px form-control form-control-sm"
                                                                    type="number"
                                                                    name={`products[${idx}].noOfstrips`}
                                                                    defaultValue={
                                                                        ele?.noOfstrips
                                                                    }
                                                                    onChange={formProps?.handleChange(
                                                                        `products[${idx}].noOfstrips`
                                                                    )}
                                                                    min="0"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    disabled={
                                                                        ele?.isDisabled
                                                                    }
                                                                    className="w-100px form-control form-control-sm"
                                                                    type="number"
                                                                    name={`products[${idx}].stripPrice`}
                                                                    defaultValue={
                                                                        0
                                                                    }
                                                                    min="0"
                                                                />
                                                                <div className="text-danger mt-2">
                                                                    <ErrorMessage
                                                                        name={`products[${idx}].stripPrice`}
                                                                    />
                                                                </div>
                                                            </TableCell>

                                                            <TableCell>
                                                                {formProps
                                                                    ?.values
                                                                    ?.products[
                                                                    idx
                                                                ]?.noOfstrips *
                                                                    formProps
                                                                        ?.values
                                                                        ?.products[
                                                                        idx
                                                                    ]
                                                                        ?.unitPerStrip}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formProps
                                                                    ?.values
                                                                    ?.products[
                                                                    idx
                                                                ]?.noOfstrips *
                                                                    formProps
                                                                        ?.values
                                                                        ?.products[
                                                                        idx
                                                                    ]
                                                                        ?.stripPrice}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            )}
                                        </TableBodyContainer>
                                    </Table>
                                    <div className="p-4 text-end">
                                        <span className="fs-1 fw-bolder">
                                            <h4>
                                                Total Amount: {totalAmount || 0}
                                            </h4>
                                        </span>
                                        <div className="mt-5">
                                            <button
                                                disabled={
                                                    !formProps.isValid ||
                                                    formProps.isSubmitting
                                                }
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};

export default EditableIndentDetailsTable;
