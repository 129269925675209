import React, { useState } from "react";
import dayjs from "dayjs";

import {
    Table,
    TableHeadRow,
    TableBodyContainer,
    TableHead,
    TableRow,
    TableCell,
} from "../../../../../components/Table";
import Pagination from "../../../../../components/Pagination";
import SearchBox from "../SearchBox";

// API
import useGetRequest from "../../../../../hooks/getRequest.query";
import { getFacilityStock } from "../../../../../services/supply-chain/view-stock/facility-stock/FacilityStock.services";
import { customRecords, dateFormatter } from "../../../../../common/utils";
import { useNavbar } from "../../../../../context/NavbarContext";

const tableNames = [
    "PRODUCT NAME",
    "GENERIC NAME",
    "PRODUCT CODE",
    "EXPIRY DATE",
    "FACILITY",
    "MANUFACTURER NAME",
    "BATCH ID",
    "TOTAL QUANTITY",
    "PURCHASE PRICE",
    "COST",
    "MRP",
    "RACK",
    "BOX",
];

const FacilityStockTable = () => {
    const { selectedFacility } = useNavbar();
    // Filter
    const [filterName, setFilterName] = useState<number>(0);

    const handleFilter = (event: any) => {
        setFilterName(event.target.value);
    };
    const tableFilters = [
        { filterName: "All", value: 0 },
        { filterName: "Expiry", value: 1 },
        { filterName: "Reorder", value: 2 },
    ];

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const [search, setSearch] = useState({
        productName: "",
        genericName: "",
        productCode: "",
    });

    // API
    const { data, isLoading, isFetching, isError, error, refetch } =
        useGetRequest(
            [
                "getFacilityStock",
                page,
                limit,
                facilityCode,
                filterName,
                search,
                selectedFacility,
            ],
            () =>
                getFacilityStock({
                    facilityCode,
                    filterOption: filterName,
                    page,
                    limit,
                    productName: search?.productName,
                    genericName: search?.genericName,
                    productCode: search?.productCode,
                }),
            {
                refetchOnWindowFocus: false,
            }
        );
    const facilityStockData = data?.data?.data?.stock;
    const totalEntites = data?.data?.results;
    const totalPages = Math.ceil(totalEntites / limit);
    // @ts-ignore
    const errorMsg = error?.response?.data?.message;
    // const successMsg = data?.data?.data?.message;

    return (
        <div>
            <div className="bg-white border-0 d-flex justify-content-between align-items-center p-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>FACILITY STOCK</h1>
                    <i
                        onClick={() => {
                            refetch();
                            setFilterName(0);
                            setSearch({
                                genericName: "",
                                productName: "",
                                productCode: "",
                            });
                        }}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>

                <SearchBox setSearch={setSearch} setPage={setPage} />

                <select
                    onChange={handleFilter}
                    className="w-200px text-start form-select form-select-lg form-select-solid"
                >
                    {tableFilters?.map((item) => {
                        return (
                            <option
                                selected={filterName === item.value}
                                value={item.value}
                            >
                                {item.filterName}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="card text-dark">
                {!isError ? (
                    <div className="card-body py-3">
                        <Table>
                            <TableHeadRow>
                                <TableHead width={140} align="center">
                                    SL NO
                                </TableHead>
                                {tableNames?.map((name) => {
                                    return (
                                        <TableHead width={200} key={name}>
                                            {name}
                                        </TableHead>
                                    );
                                })}
                            </TableHeadRow>

                            <TableBodyContainer
                                isLoading={isLoading || isFetching}
                                isError={isError}
                                errorMessage={errorMsg}
                            >
                                {facilityStockData?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <TableRow
                                                collapsible={false}
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {page &&
                                                        (page - 1) * limit +
                                                            index +
                                                            1}
                                                </TableCell>

                                                <TableCell hover>
                                                    {item?.productData[0]
                                                        ? item?.productData[0]
                                                              ?.productName
                                                        : "--"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.productData[0]
                                                        ? item?.productData[0]
                                                              ?.genericName
                                                        : "--"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.productData[0]
                                                        ? item?.productData[0]
                                                              ?.productCode
                                                        : "--"}
                                                </TableCell>

                                                <TableCell>
                                                    {item?.expiry
                                                        ? dateFormatter(
                                                              item?.expiry
                                                          )
                                                        : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.facilityData &&
                                                    item?.facilityData[0]
                                                        ? `${item?.facilityData[0].facilityFirstName} ${item?.facilityData[0].facilityLastName}`
                                                        : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.productData[0]
                                                        ? customRecords(
                                                              item
                                                                  ?.productData[0]
                                                                  ?.manufacturerName
                                                          )
                                                        : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {customRecords(item?.batch)}
                                                </TableCell>
                                                <TableCell>
                                                    {customRecords(
                                                        item?.totalQuantity
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.pricePerStrip
                                                        ? item?.pricePerStrip
                                                        : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.totalPriceValue?.toFixed(
                                                        2
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.ratePerStrip
                                                        ? Math.round(
                                                              item?.ratePerStrip
                                                          )
                                                        : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {customRecords(
                                                        item?.rackNo
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {customRecords(item?.boxNo)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBodyContainer>
                        </Table>

                        {/* Pagination */}
                        <Pagination
                            dataCount={totalEntites}
                            handleCallback={(val) => setLimit(+val)}
                            handleNext={() =>
                                setPage(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setPage(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={page === totalPages}
                            prevDisabled={page === 1}
                            pageLimit={limit}
                            pageNumber={page}
                        />
                    </div>
                ) : (
                    <div
                        className=" d-flex align-items-center justify-content-center"
                        style={{ height: "50vh" }}
                    >
                        {facilityCode === "" ? (
                            <p className="text-danger fs-2 fw fw-bolder">
                                {errorMsg}
                            </p>
                        ) : (
                            <p className="text-danger fs-2 fw fw-bolder">
                                {errorMsg}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FacilityStockTable;
