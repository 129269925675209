import React, { useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import PaymentCounters from "./PaymentCounts";
import PaymentTable from "./PaymentTable";
import FilterMenu from "./FilterMenu";
import ExportMenu from "./ExportPaymentList";
import useGetRequest from "../../../hooks/getRequest.query";
import { getPaymentList } from "../../../services/supply-chain/payment-report/payments.services";
import SearchBoxSo from "./SalesOrderSearchBox";
import { useNavbar } from "../../../context/NavbarContext";
import FilterTile from "../../../components/filter-tile";
import { convertToUtcTimestamp } from "../../../common/utils";

type Props = {};

const PaymentList = (props: Props) => {
    const { selectedFacility } = useNavbar();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
        null
    );
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({
        fromDate: "",
        toDate: "",
        transactionType: "",
        receivedAt: "",
    });
    const facilityCode = localStorage.getItem("facilityCode") as string;

    const params = useMemo(() => {
        let obj: any = {
            facilityCode: selectedFacility?.facilityCode
                ? selectedFacility?.facilityCode
                : facilityCode,
            page,
            limit,
        };

        if (filter?.fromDate.length > 0 && filter?.toDate.length > 0) {
            obj.fromDate = convertToUtcTimestamp(filter?.fromDate);
            obj.toDate = convertToUtcTimestamp(filter?.toDate);
        }
        if (filter.transactionType !== "") {
            obj.paymentType = filter.transactionType;
        }
        if (search.length > 0) {
            obj.orderId = search;
        }
        if (filter.receivedAt !== "") {
            obj.salesOrderType = filter.receivedAt;
        }
        return obj;
    }, [
        facilityCode,
        limit,
        page,
        filter,
        search,
        selectedFacility?.facilityCode,
    ]);

    const { data, isLoading, isFetching, isError }: any = useGetRequest(
        ["getPaymentList", params, facilityCode, selectedFacility],
        () => getPaymentList(params),
        {
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    const menuOpen = Boolean(exportAnchorEl);

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportAnchorEl(event.currentTarget);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleExportClose = () => {
        setExportAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Toaster />

            <div>
                <PaymentCounters
                    isFetching={isFetching}
                    isLoading={isLoading}
                    data={data}
                    filter={filter}
                />
            </div>

            <div className="card text-dark">
                <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                    <div>
                        <h1 className="fs-4  text-dark fw-bolder">
                            PAYMENT LIST{" "}
                            <i
                                onClick={() => {
                                    setFilter({
                                        fromDate: "",
                                        toDate: "",
                                        transactionType: "",
                                        receivedAt: "",
                                    });
                                    setPage(1);
                                    setSearch("");
                                }}
                                className=" fas fa-redo fs-3 cursor-pointer ms-5 me-4 "
                            ></i>
                        </h1>
                    </div>

                    <div className="card-toolbar">
                        {/* Search Bar */}
                        <div className="py-2 me-20">
                            <SearchBoxSo setSearch={setSearch} />
                        </div>

                        <button
                            // hidden={!canExport}
                            onClick={handleExportClick}
                            className="btn-light-primary me-5 ms-3 btn btn-md"
                        >
                            <i className="fas fa-download"></i>
                            EXPORT
                        </button>
                        <ExportMenu
                            anchorEl={exportAnchorEl}
                            handleClose={handleExportClose}
                            open={menuOpen}
                        />

                        <button
                            onClick={handleClick}
                            className="btn btn-md btn-primary"
                        >
                            <i className="fas fa-filter"></i>
                            FILTER
                        </button>
                        <FilterMenu
                            filter={filter}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            open={open}
                            setFilter={setFilter}
                            setPage={setPage}
                        />
                    </div>
                </div>
                {
                    // !facilityCode || facilityCode === "All" ? (
                    //     <div
                    //         className=" d-flex align-items-center justify-content-center"
                    //         style={{ height: "50vh" }}
                    //     >
                    //         <p className="text-danger fs-2 fw fw-bolder ">
                    //             Please select a facility
                    //         </p>
                    //     </div>
                    // ) :
                    <>
                        {!isError ? (
                            <>
                                <FilterTile
                                    filterData={filter}
                                    keyNames={{
                                        fromDate: "Start date",
                                        toDate: "End date",
                                        transactionType: "Transaction Type",
                                        receivedAt: "Received At",
                                    }}
                                    clearAll={() =>
                                        setFilter({
                                            fromDate: "",
                                            toDate: "",
                                            transactionType: "",
                                            receivedAt: "",
                                        })
                                    }
                                />
                                <PaymentTable
                                    data={data}
                                    page={page}
                                    limit={limit}
                                    setPage={setPage}
                                    isError={isError}
                                    setLimit={setLimit}
                                    isLoading={isLoading}
                                />
                            </>
                        ) : (
                            <div
                                className=" d-flex align-items-center justify-content-center"
                                style={{ height: "50vh" }}
                            >
                                <p className="text-danger fs-2 fw fw-bolder ">
                                    There is no payment list
                                </p>
                            </div>
                        )}
                    </>
                }
            </div>
        </div>
    );
};

export default PaymentList;
