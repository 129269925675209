export const calcTaxAmount = (gst: number, mrp: number) => {
    const amountBeforeGst = (mrp * 100) / (100 + gst);
    const gstamount = mrp - amountBeforeGst;

    return gstamount;
};

export const totalAmt = (
    mrp: number,
    units: number,
    discountPercent: number
) => {
    const discAmt = units * ((mrp * discountPercent) / 100);
    const amt = mrp * units - discAmt;
    return amt.toFixed(2);
};

export const aggregates = (productsArr: any) => {
    let TotalAmtBeforeTax = 0;
    let totalGstAmt = 0;
    let totalDiscount = 0;

    productsArr.forEach((ele: any) => {
        const priceBeforeTax = (+ele.mrpPerUnit * 100) / (+ele.gstTotal + 100);
        TotalAmtBeforeTax += +ele.units * priceBeforeTax;
        totalGstAmt += +ele.units * (ele.mrpPerUnit - priceBeforeTax);
        totalDiscount +=
            +ele.units * ((+ele.mrpPerUnit * ele.discountPercent) / 100);
    });
    return { TotalAmtBeforeTax, totalGstAmt, totalDiscount };
};

export const aggregateTotal = (productsArr: any) => {
    const { TotalAmtBeforeTax } = aggregates(productsArr);
    return TotalAmtBeforeTax.toFixed(2);
};

export const aggregateGst = (productsArr: any) => {
    const { totalGstAmt } = aggregates(productsArr);

    return totalGstAmt.toFixed(2);
};

export const aggregateDiscount = (productsArr: any) => {
    const { totalDiscount } = aggregates(productsArr);
    return totalDiscount.toFixed(2);
};
