import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";

import {
  patientRegistrationValidationSchema,
  initalValues,
} from "../models/PatientRegistrationModel";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { createTaskTarget } from "../../../../services/task/TaskManage.hook";

const PatientRegistration = ({ patientLimits, refetch, isFetching }: any) => {
  // Toast
  const [open, setOpen] = useState<boolean>(false);
  const [inputEnable, setInputEnable] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [msg, setMsg] = useState<boolean>(false);
  const facilityCode = localStorage.getItem("facilityCode");

  const { isLoading, isError, data, error, mutate, isSuccess }: any =
    useMutateRequest((data: any) => createTaskTarget(data));

  const errorMsg = error?.response?.data?.message;

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully updated patient registration target!!
          <button
            className="ms-5"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "success-toast",
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}
          {/* Failed to update the target !! */}
          <button
            className="ms-5"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "error-toast",
      }
    );
  };

  const onSubmit = (values: any) => {
    try {
      const payload = {
        limit: values.patientRegistration,
        targetName: "PatientRegistration",
        facilityCode: facilityCode,
        limitType: "perDay",
      };

      mutate(payload, {
        onSuccess(data: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(true);

          setTimeout(() => {
            setSpinner(false);
            setInputEnable(!inputEnable);
            refetch();
            // redirectTo.push("/training/content-management");
          }, 3000);
        },
        onError(error: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(false);

          setTimeout(() => {
            setSpinner(false);
            // redirectTo.push("/training/content-management");
          }, 3000);
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputField = () => {
    setInputEnable(!inputEnable);
  };
  return (
    <>
      <Toaster />
      {isSuccess && open === true && successToastMsg()}
      {isError && open === true && errorToastMsg()}

      <div>
        <div className="d-flex justify-content-center">
          <h1 className="fs-1 fw-bolder m-0">Patient Registration Per Day</h1>
        </div>
        <hr className="h-2px" />

        {isLoading || spinner || isFetching === true ? (
          <div className="card w-100 h-50 d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={40} speedMultiplier={0.6} />
          </div>
        ) : (
          <div className="">
            <Formik
              initialValues={initalValues}
              validationSchema={patientRegistrationValidationSchema}
              onSubmit={onSubmit}
            >
              {(formProps) => {
                return (
                  <Form>
                    <div className="p-5">
                      {/* Patient Registration */}
                      <div className="d-flex mb-10 justify-content-center">
                        <label className="w-200px mt-3 fw-bolder form-label required">
                          Patient Registration
                        </label>
                        {inputEnable === true ? (
                          <Field
                            name="patientRegistration"
                            placeholder="ex: per day"
                            className="w-550px form-control form-control-lg form-control-solid"
                          />
                        ) : (
                          <Field
                            disabled
                            value={patientLimits ? patientLimits : "No targets"}
                            className="w-550px form-control form-control-lg form-control-solid"
                            style={{ cursor: "not-allowed" }}
                          />
                        )}

                        <div className="ms-5 mt-4">
                          {inputEnable !== true ? (
                            <i
                              title="Edit the value"
                              onClick={handleInputField}
                              className="fas fs-2x fa-edit cursor-pointer"
                            ></i>
                          ) : (
                            <i
                              title="Cancel"
                              onClick={handleInputField}
                              className="fas fs-2 fa-times text-danger cursor-pointer"
                            ></i>
                          )}
                        </div>
                        {inputEnable === true && (
                          <div className="ms-5">
                            <button
                              type="submit"
                              className="btn btn-md btn-primary w-100px"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      {inputEnable === true && (
                        <div className="text-danger mt-2 text-center">
                          <ErrorMessage name="patientRegistration" />
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientRegistration;
