import { useState } from "react";

import useGetRequest from "../../../../../hooks/getRequest.query";
import { MemberList } from "./MemberList";
import { getBeneficiaryByHealthcard } from "../../../../../services/patient/PatientHealthcard.service";

type Props = {
    healthCardNo: any;
};
const MemberPage = (props: Props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    const {
        data,
        isLoading,
        isFetching,
        refetch,
        isError,
        error: ErrorMsg,
    }: any = useGetRequest(
        [
            "getBeneficiaryByHealthcard",
            pageNumber,
            pageLimit,
            props?.healthCardNo,
        ],
        async () =>
            await getBeneficiaryByHealthcard({
                page: pageNumber,
                limit: pageLimit,
                healthCardNumber: props?.healthCardNo,
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const beneficiaryData = data?.data?.data?.patient;

    const patientErrorMsg = ErrorMsg?.response?.data?.message;

    return (
        <>
            <MemberList
                refetch={refetch}
                healthCardNo={props?.healthCardNo}
                pageLimit={pageLimit}
                currentPageNumber={pageNumber}
                handlePageNumber={setPageNumber}
                isPatientLoader={isLoading}
                isPatientFetcher={isFetching}
                isPatientError={isError}
                patientErrorMsg={patientErrorMsg}
                beneficiaryData={beneficiaryData}
                setPageLimit={setPageLimit}
                setPageNumber={setPageNumber}
            />
        </>
    );
};

export default MemberPage;
