import { useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import {
  confirmValidationSchema,
  initalValues,
  confirmValueTypes,
} from "../../model/DeleteDialogModel";

type ExportProps = {
  onClose: () => void;
  styles: any;
  sectionTitle: any;
  remove: any;
};

const DeleteDialog = ({
  onClose,
  styles,
  sectionTitle,
  remove,
}: ExportProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = () => {
    deleteDialog();
    onClose();
    remove();
  };
  const deleteDialog = () =>
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center">
          Deleted<b className="ms-2 text-dark fw-bolder">{sectionTitle}</b>
          <button
            // className="ms-15"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "success-toast",
      }
    );
  return (
    <>
      <Toaster />
      {isLoading ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={confirmValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formProps: any) => (
              <Form>
                <div className="mt-0">
                  <h2 className="fs-1 fw-bolder text-center text-hover-danger">
                    Delete Section
                  </h2>

                  <span className="fs-5">
                    <p className="mt-5 text-muted fw-bolder mb-0">
                      {`Are you sure you want to delete ${sectionTitle} permanently?`}
                    </p>
                    <p className="mt-2 text-muted fw-bolder">
                      To confirm deletion, enter the phrase {""}
                      <b className="text-dark fw-bolder ms-1">delete me</b>
                    </p>
                  </span>

                  <Field
                    name="confirmDelete"
                    placeholder="delete me"
                    className="form-control form-control-light form-control-lg required"
                  />
                  <div className="text-danger mt-3">
                    <ErrorMessage name="confirmDelete" />
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="w-150px btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={formProps.values.confirmDelete !== "delete me"}
                    className="w-150px btn btn-md btn-primary"
                  >
                    Delete
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default DeleteDialog;
