export const FileInput = ({ field, form }: any) => {
    const handleFileChange = (event: any) => {
        form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
        <input
            type="file"
            onChange={handleFileChange}
            onBlur={field.onBlur}
            name={field.name}
            accept=".csv"
            className="form-control form-control-md"
            style={{
                // Custom styles here
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                // Add any other desired styles
            }}
        />
    );
};
