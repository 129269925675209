import React from "react";

type Props = {
  children: React.ReactNode;
};

const TableHeadRow = (props: Props) => {
  const { children } = props;
  return (
    <thead>
      <tr className="fw-bolder text-muted bg-light">{children}</tr>
    </thead>
  );
};

export default TableHeadRow;
