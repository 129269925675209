import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

type Props = {
    controlId?: string;
    label?: string;
    className?: string;
    type: "text" | "email" | "password" | "number" | "date";
    placeholder?: string;
    name: string;
    defaultValue?: string;
    onChange: (e: string | React.ChangeEvent<any>) => void;
    required?: boolean;
    value: string;
    disabled?: boolean;
    min?: string;
};

const Input = (props: Props) => {
    return (
        // @ts-ignore
        <FloatingLabel
            controlId={props.controlId}
            label={
                <>
                    {props.label}&nbsp;
                    {props.required && (
                        <span className="text-danger font-weight-bold">*</span>
                    )}
                </>
            }
            className={props.className}
        >
            {/* @ts-ignore */}
            <Form.Control
                // type={props.type}
                placeholder={props.placeholder}
                // name={props.name}
                defaultValue={props.defaultValue}
                // onChange={props.onChange}
                // value={props.value}
                {...props}
            />
        </FloatingLabel>
    );
};

export default Input;
