/**
 * User Info validations with regex pattern (RegExp)
 * @author 'Rakshith'
 */

// Phone Number ( Indian with 91...)
export const phoneValidator = /^[6-9]\d{9}$/;
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// username ( ex: snrakshith.97 )
export const userNameValidator =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// number ( ex: 97 )
export const numberValidator = /^[0-9]*$/;

// string ( ex: 'rakshith' )
export const stringValidator = /^[A-Za-z\s]+$/;

// string ( ex: 'rakshith' )
export const alphaNumericValidator = /^[A-Za-z0-9\s]+$/;

// password ( ex: 'rak@97SN' )
// Must Contain 8 Characters, One Uppercase, One Lowercase
export const passwordValidator =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*+/_#?&])[A-Za-z\d@$!%*+/_#?&]{8,}$/;

export const aadharCard = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);

export const voterId = new RegExp(/^[A-Z]{3}[0-9]{7}$/);

export const panCard = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

export const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "application/pdf",
    "application/msword",
];
