import { Field, Form, Formik, ErrorMessage } from "formik";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  exportValidationSchema,
  initalValues,
  exportValueTypes,
} from "../model/CHWExportModel";

type ExportProps = {
  onClose: () => void;
};

const CHWExport = ({ onClose }: ExportProps) => {
  const [msg, setMsg] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // API Call
  // const { data, mutate } = useMutateRequest((data: any) =>
  //   exportCHWDetails(data)
  // );

  // useEffect(() => {}, []);

  // console.log("Data Result:", data);

  const onSubmit = (values: exportValueTypes) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setMsg(true);
    }, 3000);
  };

  return (
    <>
      {isLoading ? (
        <div className="h-225px d-flex flex-column justify-content-center align-items-center">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg ? (
        <div className="h-150px d-flex flex-column justify-content-center align-items-center">
          {errMsg === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Your request to generate report failed.
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Please Retry !
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Your request has been successfully submitted, will be sent to
                your email within 24 hours
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  OK
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="h-225px">
          <Formik
            initialValues={initalValues}
            validationSchema={exportValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formProps: any) => (
              <Form>
                <h1 className="text-center text-hover-primary">
                  EXPORT OPTIONS
                </h1>
                <div className="mt-5">
                  <label
                    htmlFor="exportFormat"
                    className="form-label fs-4 fw-bolder required"
                  >
                    EXPORT FORMAT
                  </label>
                  <Field
                    id="exportFormat"
                    as="select"
                    name="exportFormat"
                    disabled
                    className="form-select form-select-light form-select-lg required"
                    style={{ cursor: "not-allowed" }}
                  >
                    <option value="EXCEL">EXCEL</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                  </Field>
                  <div className="text-danger mt-5">
                    <ErrorMessage name="exportFormat" />
                  </div>
                  <div className="text-muted fw-bolder mt-2 ms-2">
                    By Default the Data would be exported in EXCEL Format.
                  </div>
                </div>

                <div className="mt-10 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    className="btn btn-md btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default CHWExport;
