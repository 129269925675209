import React from "react";

type Props = {
  children: React.ReactNode;
  align?: "center" | "right";
  hover?: boolean;
  isDanger?: boolean;
};

const TableCell = (props: Props) => {
  const { children, align, hover, isDanger } = props;
  return (
    <td
      className={`text-${align ? align : "left"} fw-bolder ${
        hover && "text-hover-primary"
      } ${isDanger && "text-danger"}`}
    >
      {children}
    </td>
  );
};

export default TableCell;
