import $axiosProtected from "../../common/utils/axiosUtils";

// https://dev.api.whimslive.com/analytics/target/stats/month/get

// Get Facility Overview Via Chart
export const getFacilityOverviewViaChart = async (payload: any) => {
  return await $axiosProtected().get(
    `/analytics/target/stats/month/get?facilityCode=${payload.facilityCode}&month=${payload.month}&year=${payload.year}&targetName=${payload.targetName}`
  );
};

// https://dev.api.whimslive.com/analytics/target/stats/all/chw/month/get

// Get Facility Overview Via Table
export const getFacilityOverviewViaTable = async (payload: any) => {
  return await $axiosProtected().get(
    `/analytics/target/stats/all/chw/month/get?facilityCode=${payload.facilityCode}&month=${payload.month}&year=${payload.year}&targetName=${payload.targetName}`
  );
};

// CHW level
// https://dev.api.whimslive.com/analytics/target/stats/chw/month/get

// Table View
export const getCHWOverviewViaTable = async (payload: any) => {
  return await $axiosProtected().get(
    `/analytics/target/stats/chw/month/get?facilityCode=${payload.facilityCode}&month=${payload.month}&year=${payload.year}&moduleName=${payload.moduleName}&chwId=${payload.chwId}`
  );
};

// Chart View
export const getCHWOverviewViaChart = async (payload: any) => {
  return await $axiosProtected().get(
    `/analytics/target/stats/chart/chw/month/get?facilityCode=${payload.facilityCode}&month=${payload.month}&year=${payload.year}&moduleName=${payload.moduleName}&chwId=${payload.chwId}`
  );
};
