import "bootstrap/dist/css/bootstrap.min.css";
import ClipLoader from "react-spinners/ClipLoader";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";

const CHWHeader = ({
  chwDetails,
  id,
  chwInsightsPerDay,
  isCHWInsightsLoading,
  isCHWInsightsFetching,
}: any) => {
  const startTime = chwDetails?.fieldData[0]?.startTime;
  const endTime = chwDetails?.fieldData[0]?.endTime;
  const createdDate = chwDetails?.fieldData[0]?.createdDate;
  const noCHWInfo = chwDetails?.fieldData?.length === 0;
  const fieldInfo =
    chwDetails?.fieldData?.length === 0
      ? "Off Field"
      : chwDetails?.fieldData[0]?.fieldStatus === "OffField"
      ? "Off Field"
      : "On Field";
  const chwLocation =
    chwDetails?.fieldData?.length === 0
      ? "No Data"
      : chwDetails?.fieldData[0]?.street +
        ", " +
        chwDetails?.fieldData[0]?.locality +
        ", " +
        chwDetails?.fieldData[0]?.district +
        ", " +
        chwDetails?.fieldData[0]?.state;
  return (
    <div className="card  bg-white">
      <div className="card-body pt-9 pb-5">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-fixed position-relative">
              <img
                src={
                  chwDetails
                    ? chwDetails?.demographicDetails?.photo
                      ? chwDetails?.demographicDetails?.photo !== ""
                        ? chwDetails?.demographicDetails?.photo
                        : toAbsoluteUrl("/media/ikure/blank.png")
                      : toAbsoluteUrl("/media/ikure/blank.png")
                    : toAbsoluteUrl("/media/ikure/blank.png")
                }
                alt={chwDetails?.name}
                className={
                  noCHWInfo !== true
                    ? "h-225px w-200px mt-5"
                    : "h-175px w-200px mt-5"
                }
              />
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {chwDetails?.name}
                  </span>
                  <span
                    className={`badge badge-light-${
                      fieldInfo === "On Field" ? "success" : "danger"
                    } text-center fw-bolder fs-6 ms-2`}
                  >
                    {fieldInfo}
                  </span>
                </div>
                <div className="d-flex flex-wrap fw-bold fs-5 mt-3 mb-1 pe-2">
                  <span className="d-flex align-items-center text-gray-600 fw-bolder text-hover-primary me-5 mb-2">
                    <KTSVG
                      path="/media/icons/duotune/communication/com006.svg"
                      className="svg-icon-2 me-1"
                    />
                    {chwDetails?.grade}
                  </span>
                  <span className="d-flex align-items-center text-gray-600 fw-bolder text-hover-primary mb-2">
                    <KTSVG
                      path="/media/icons/duotune/communication/com011.svg"
                      className="svg-icon-2 me-1"
                    />
                    {chwDetails?.demographicDetails?.email}
                  </span>
                  <span className="d-flex align-items-center text-gray-600 fw-bolder text-hover-primary ms-5 mb-2">
                    <KTSVG
                      path="/media/icons/duotune/general/gen018.svg"
                      className="svg-icon-2 me-1"
                    />
                    {chwDetails?.demographicDetails?.telecom}
                  </span>
                  <span className="text-gray-600 text-hover-primary fs-4 fw-bolder ms-3">
                    <label className="ms-2">ID :</label>
                    <span className="ms-2 text-hover-primary fs-4">
                      {chwDetails?.chwId}
                    </span>
                  </span>
                </div>
                {noCHWInfo !== true ? (
                  <>
                    <div className="d-flex flex-wrap fw-bolder fs-5 mt-3 mb-1 pe-2">
                      <label className="me-5 text-gray-600">
                        Field Location :
                      </label>
                      <span
                        style={{ textTransform: "capitalize" }}
                        className="d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2"
                      >
                        {chwLocation ? chwLocation : "No Data"}
                      </span>
                    </div>
                    <div className="d-flex fw-bolder justify-content-between fs-5 mt-2 mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="me-5 text-gray-600">
                          Start Time :
                        </label>
                        <span className="text-gray-600 text-hover-primary">
                          {startTime ? startTime : "No Data"}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="me-5 text-gray-600">End Time :</label>
                        <span className="text-gray-600 text-hover-primary">
                          {endTime ? endTime : "No Data"}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="me-5 text-gray-600">
                          Record Date :
                        </label>
                        <span className="text-gray-600 text-hover-primary">
                          {createdDate ? createdDate : "No Data"}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="d-flex flex-wrap fw-bold fs-5 mb-1 pe-2 mt-2">
                  <div className="d-flex ">
                    <div className="w-200px h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-5 border-gray-500 border-dotted">
                      {isCHWInsightsLoading || isCHWInsightsFetching ? (
                        <ClipLoader size={50} speedMultiplier={0.6} />
                      ) : (
                        <>
                          <h3 className="text-primary">
                            {chwInsightsPerDay?.totalTasks}
                          </h3>
                          <h3>TOTAL TASKS</h3>
                        </>
                      )}
                    </div>
                    <div className="w-200px h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-5  border-gray-500 border-dotted">
                      {isCHWInsightsLoading || isCHWInsightsFetching ? (
                        <ClipLoader size={50} speedMultiplier={0.6} />
                      ) : (
                        <>
                          <h3 className="text-danger">
                            {chwInsightsPerDay?.pendingTask}
                          </h3>
                          <h3>PENDING TASKS</h3>
                        </>
                      )}
                    </div>
                    <div className="w-200px h-100px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly  me-5 border-gray-500 border-dotted">
                      {isCHWInsightsLoading || isCHWInsightsFetching ? (
                        <ClipLoader size={50} speedMultiplier={0.6} />
                      ) : (
                        <>
                          <h3 className="text-success">
                            {chwInsightsPerDay?.completedTask}
                          </h3>
                          <h3>COMPLETED TASKS</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/chw/${id}/overview` && "active")
                }
                to={`/chw/${id}/overview`}
              >
                OVERVIEW
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/chw/${id}/overview/tasklist` &&
                    "active") ||
                    (location.pathname ===
                      `/chw/${id}/overview/activity-details` &&
                      "active"))
                }
                to={`/chw/${id}/overview/tasklist`}
              >
                TASK LIST
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/chw/${id}/overview/attendance-list` && "active")
                }
                to={`/chw/${id}/overview/attendance-list`}
              >
                ATTENDANCE LIST
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/chw/${id}/overview/performance-monitoring` && "active")
                }
                to={`/chw/${id}/overview/performance-monitoring`}
              >
                PERFORMANCE MONITORING
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export { CHWHeader };
