import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

type routeProps = {
  id: string;
};
const CardHeader = () => {
  const location = useLocation();
  const { id } = useParams<routeProps>();

  return (
    <div className="card ">
      <div className="card-body pb-0">
        <h1>Batch Number </h1>
        <h1 className="mt-5">{id}</h1>

        {/* From Here */}
        {/* <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"> */}
        {/* Batch Details */}
        {/* <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/batch/${id}/overview/batch-details` && "active")
                }
                to={`/batch/${id}/overview/batch-details`}
              >
                Batch Details
              </Link>
            </li> */}

        {/*List of Health Cards Details */}
        {/* <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/batch/${id}/overview/card-list` &&
                    "active")
                }
                to={`/batch/${id}/overview/card-list`}
              >
                List of Health Cards
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export { CardHeader };
