import * as Yup from "yup";
import dayjs from "dayjs";
import { FacilityManagerOnboardingTypes } from "../../../../types/FacilityManagerOnboarding.types";

const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "application/pdf",
    "application/msword",
];
export const initsForFacilityManagerOnboarding: FacilityManagerOnboardingTypes =
    {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        telecom: "",
        password: "",
        photo: "",
        sex: "",
        birthDate: "",
        age: "",
        languages: "",
        preferredLanguage: "",
        idProofName: "",
        proofLocation: "",
        id: "",
        addressSiteName: "",
        floorNumber: "",
        streetName: "",
        streetNumber: "",
        district: "",
        postalCode: "",
        town: "",
        state: "",
        country: "",
    };

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const userNameRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const numberRegExp = /^[0-9]*$/;

export const createAccountSchemas = [
    // Step 1
    Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
            .required()
            .label("First Name"),
        middleName: Yup.string().label("Middle Name"),
        lastName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid last name")
            .required()
            .label("Last Name"),
        telecom: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            // .oneOf([Yup.ref("userName"), null], "User Name don't match!")
            .required("Phone Number is Required!"),
        email: Yup.string()
            .email("Please enter a vaild email")
            .required()
            .label("Email Id"),
    }),
    // Step 2
    Yup.object({
        photo: Yup.mixed()
            .nullable()
            .required()
            .label("Photo")
            .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*+/_#?&])[A-Za-z\d@$!%*+/_#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            )
            .required("Password is Required!"),
        sex: Yup.string().required().label("Gender"),
    }),

    Yup.object({
        // Step 3
        birthDate: Yup.date()
            .required()
            .label("Birth Date")
            .test(
                "age-match",
                "Age does not match birth date",
                function (value) {
                    const { age } = this.parent;
                    if (!value || age === undefined) {
                        return true;
                    }
                    return dayjs().diff(dayjs(value), "year") === age;
                }
            ),
        age: Yup.number()
            .moreThan(18, "You must be over the age of 18 to register")
            .lessThan(100, "Are you really that old?")
            .typeError("The accepted value is only number")
            .label("Age"),
        languages: Yup.array()
            .min(1, "You can't leave this blank.")
            .required("You can't leave this blank.")
            .nullable()
            .label("Language"),
        preferredLanguage: Yup.string().required().label("Preferred Language"),
    }),
    Yup.object({
        // Step 4
        idProofName: Yup.string().required().label("Proof Name"),
        id: Yup.mixed().required().label("Id Proof"),
        addressSiteName: Yup.string().required().label("Address Site"),
        proofLocation: Yup.mixed()
            .nullable()
            .required()
            .label("Proof")
            .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
    }),
    Yup.object({
        // Step 5
        floorNumber: Yup.string()
            .matches(numberRegExp, "Floor Number is not valid")
            .label("Floor Number"),
        streetName: Yup.mixed().required().label("Street Name"),
        streetNumber: Yup.string()
            .matches(numberRegExp, "Street Number is not valid")
            .label("Street Number"),
        district: Yup.string()
            .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid district name")
            .required()
            .label("District"),
        postalCode: Yup.string()
            .matches(numberRegExp, "Postal Code is not valid")
            .required()
            .label("Postal Code"),
        town: Yup.string().required().label("Town"),
        state: Yup.string().required().label("State"),
        country: Yup.string().required().label("Country"),
    }),
];
