import React from "react";
import { Menu } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { todayDate } from "../../../common/utils";
import validationSchema from "./formValidationSchema";

type Props = {
    anchorEl: any;
    open: boolean;
    handleClose: any;
    setFilter: Function;
    setPage: Function;
    filter: any;
};

type IFilterValues = {
    fromDate: string;
    toDate: string;
    transactionType: string;
    receivedAt: string;
};

const FilterMenu = ({
    open,
    filter,
    anchorEl,
    handleClose,
    setFilter,
    setPage,
}: Props) => {
    const onFilterSubmit = (values: IFilterValues) => {
        setPage(1);
        setFilter(values);
        handleClose();
    };
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Formik
                initialValues={filter}
                validationSchema={validationSchema}
                onSubmit={onFilterSubmit}
                enableReinitialize
            >
                {(formProps: any) => (
                    <Form>
                        <div className="py-5 px-8">
                            <div className="w-250px mt-5 mb-5">
                                <label
                                    className="form-label fs-4 fw-bolder required"
                                    htmlFor="fromDate"
                                >
                                    FROM DATE
                                </label>
                                <Field
                                    id="fromDate"
                                    as="input"
                                    data-testid="from-date required"
                                    type="date"
                                    max={todayDate}
                                    name="fromDate"
                                    className="form-control cursor-pointer"
                                />
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="fromDate" />
                                </div>
                            </div>
                            <div className="w-250px mt-5">
                                <label className="form-label fs-4 fw-bolder required">
                                    TO DATE
                                </label>
                                <Field
                                    onChange={(e: any) => {
                                        formProps.handleChange(e);
                                        formProps.setFieldTouched("fromDate");
                                    }}
                                    id="toDate"
                                    data-testid="to-date"
                                    type="date"
                                    name="toDate"
                                    max={todayDate}
                                    className="form-control"
                                />
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="toDate" />
                                </div>
                            </div>

                            <div className="w-250px mt-5 mb-8">
                                <label className="form-label fs-4 fw-bolder">
                                    RECEIVED AT
                                </label>
                                <Field
                                    id="receivedAt"
                                    as="select"
                                    name="receivedAt"
                                    className="form-select form-select-light form-select-lg"
                                    defaultValue=""
                                >
                                    <option value="">All</option>
                                    <option value="Clinic">Clinic</option>
                                    <option value="Camp">Camp</option>
                                    <option value="Doorstep">Doorstep</option>
                                </Field>
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="receivedAt" />
                                </div>
                            </div>

                            <div className="w-250px mt-5">
                                <label className="form-label fs-4 fw-bolder">
                                    TRANSACTION TYPE
                                </label>
                                <Field
                                    id="transactionType"
                                    as="select"
                                    name="transactionType"
                                    className="form-select form-select-light form-select-lg"
                                    defaultValue=""
                                >
                                    <option value="">All</option>
                                    <option value="Cash">Cash</option>
                                    <option value="UPI">UPI</option>
                                </Field>
                                <div className="text-danger mt-5">
                                    <ErrorMessage name="transactionType" />
                                </div>
                            </div>
                        </div>

                        <div className="my-5 d-flex justify-content-center align-items-center">
                            <button
                                type="submit"
                                // disabled={
                                //     !formProps.isValid || formProps.isSubmitting
                                // }
                                className="w-250px btn btn-sm btn-primary"
                            >
                                SUBMIT
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Menu>
    );
};

export default FilterMenu;
