import React from "react";
import { Tab as EachTab } from "react-bootstrap";
type IProps = {
  children: React.ReactNode;
  eventKey: string;
  title: string;
};

const Tab = (props: IProps) => {
  const { children, eventKey, title } = props;
  return (
    <EachTab eventKey={eventKey} title={title}>
      {children}
    </EachTab>
  );
};

export default Tab;
