import CategoryPage from "./components/CategoryPage";
import SubcategoryPage from "./components/SubcategoryPage";

const CreateCategoryPage = () => {
  return (
    <div>
      <div className="card p-5">
        <CategoryPage />
      </div>
      <div className="card p-5 mt-5">
        <SubcategoryPage />
      </div>
    </div>
  );
};

export default CreateCategoryPage;
