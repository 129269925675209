import { hasAccessToModule } from "../../../rbac";
import { Permissions } from "../../../rbac/types";
import CoursePage from "./components/course/CoursePage";

const ContentManagementPage = () => {
    const showPage = hasAccessToModule("LIST_REPORT", Permissions.read);
    return (
        <div hidden={!showPage} className="card h-100">
            <CoursePage />
        </div>
    );
};

export default ContentManagementPage;
