import { Link } from "react-router-dom";
import TableErrorComponent from "../../../../components/Error/TableError";
import Pagination from "../../../../components/Pagination";

import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";

const KPITable = ({
    isTableFetching,
    isTableLoading,
    successMsg,
    currentPageNumber,
    setCurrentPageNumber,
    setPageLimit,
    pageLimit,
    tableErrorMsg,
    isTableError,
    totalPages,
    totalEntites,
    tableViewData,
}: any) => {
    const tableNames = [
        "TARGET DISTRIBUTED",
        "TARGET REMAINING",
        "TARGET COMPLETED",
        "ACTION",
    ];
    return (
        <>
            {isTableError ? (
                <TableErrorComponent
                    errorMessage={
                        tableErrorMsg &&
                        tableErrorMsg.toLowerCase() === "missing facilitycode"
                            ? "Please Select a facility"
                            : tableErrorMsg
                    }
                />
            ) : (
                <div className="card text-dark">
                    <div className="card-body py-3">
                        <Table>
                            <TableHeadRow>
                                <TableHead width={140} align="center">
                                    SL NO
                                </TableHead>
                                <TableHead width={140} align="left">
                                    CHW NAME
                                </TableHead>
                                {tableNames?.map((name) => {
                                    return (
                                        <TableHead
                                            width={180}
                                            key={name}
                                            align="center"
                                        >
                                            {name}
                                        </TableHead>
                                    );
                                })}
                            </TableHeadRow>

                            <TableBodyContainer
                                isLoading={isTableLoading}
                                isError={isTableError}
                                errorMessage={tableErrorMsg}
                            >
                                {tableViewData?.map((item: any, index: any) => {
                                    return (
                                        <TableRow
                                            collapsible={false}
                                            key={index}
                                        >
                                            <TableCell align="center">
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>

                                            <TableCell hover>
                                                {item?.chwDetails &&
                                                item?.chwDetails.length > 0
                                                    ? item?.chwDetails[0]
                                                          ?.firstName +
                                                      " " +
                                                      item?.chwDetails[0]
                                                          ?.middleName +
                                                      " " +
                                                      item?.chwDetails[0]
                                                          ?.lastName
                                                    : "N/A"}
                                            </TableCell>

                                            <TableCell hover align="center">
                                                {item?.target
                                                    ? item?.target
                                                    : "N/A"}
                                            </TableCell>

                                            <TableCell align="center">
                                                {item?.totalTargetsRemaining
                                                    ? item?.totalTargetsRemaining
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item?.totalTargetsCompleted}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link
                                                    to={{
                                                        pathname: `/chw/overview`,
                                                        search: `?chwId=${item?.chwId}&tab=performance`,
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    DETAILS
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBodyContainer>
                        </Table>

                        {/* Pagination */}
                        <Pagination
                            dataCount={totalEntites}
                            handleCallback={(val) => setPageLimit(+val)}
                            handleNext={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={currentPageNumber === totalPages}
                            prevDisabled={currentPageNumber === 1}
                            pageLimit={pageLimit}
                            pageNumber={currentPageNumber}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default KPITable;
