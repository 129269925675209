import React, { useMemo, useState } from "react";
import SelectMenu from "./PaginationSelect";

type Props = {
  prevDisabled: boolean;
  nextDisabled: boolean;
  handlePrev: () => void;
  handleNext: () => void;
  handleCallback: (ab: string | number) => void; //a callback function which will give a value for setting the pagelimit
  // For using it in the component add this handleCallback= {(val)=> setPageLimit(val)}
  dataCount: number;
  pageLimit: number;
  pageNumber: number;
};

const Pagination = (props: Props) => {
  const {
    nextDisabled,
    prevDisabled,
    handleNext,
    handlePrev,
    handleCallback,
    dataCount,
    pageLimit,
    pageNumber,
  } = props;
  const [selectedVal, setSelectedVal] = useState(1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    setSelectedVal(val);
    handleCallback(val);
  };

  const TotalPage = useMemo(
    () => dataCount && Math.ceil(dataCount / pageLimit),
    [dataCount, pageLimit]
  );
  return (
    <div className="d-flex px-10 py-5 align-items-center justify-content-between">
      <p className="pt-4">
        Showing page {pageNumber} of {TotalPage}{" "}
        {TotalPage === 1 ? "page" : "pages"}
      </p>
      <div className="d-flex">
        <div className="d-flex align-items-center" style={{ width: "200px" }}>
          <p className="pt-4">Entries per page: </p> &nbsp;
          <SelectMenu value={selectedVal} onChange={handleChange}>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </SelectMenu>
        </div>

        <div className="d-flex flex-wrap">
          <div className="d-flex align-items-center justify-content-center">
            {/* ############## NEXT BUTTON ############### */}
            <span>
              <button
                onClick={handlePrev}
                disabled={prevDisabled}
                style={{
                  cursor: prevDisabled ? "not-allowed" : "pointer",
                }}
                className={`bg-transparent border-0 `}
              >
                <i className="fas fa-angle-double-left"></i>
              </button>
            </span>
            {/* ############## PAGE NUMBER ############### */}
            <span
              className="bg-primary d-flex justify-content-center align-items-center text-white border-0 rounded "
              style={{
                width: "30px",
                height: "35px",
              }}
            >
              <h3 className="d-flex justify-content-center align-items-center text-white mb-0 text-center">
                {pageNumber}
              </h3>
            </span>
            {/* ############## PREVIOUS BUTTON ############### */}
            <span>
              <button
                onClick={handleNext}
                className={`bg-transparent border-0 `}
                style={{
                  cursor: nextDisabled ? "not-allowed" : "pointer",
                }}
                disabled={nextDisabled}
              >
                <i className="fas fa-angle-double-right"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
