import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Toaster } from "react-hot-toast";

import { selectorValidationSchema } from "../types/Task.types";
import Modal from "../../../components/Modal";
import TaskListExport from "../exports/TaskListExport";
import CustomTooltip from "../../../components/CustomTooltip";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import TableErrorComponent from "../../../components/Error/TableError";
import { Permissions, hasAccessToModule } from "../../../rbac";
import { dateFormatter, warnUser } from "../../../common/utils";
import FilterTile from "../../../components/filter-tile";

const TaskTable = ({
    filterData,
    bodyProps,
    isLoading,
    isFetching,
    errorMsg,
    successMsg,
    setCurrentPageNumber,
    currentPageNumber,
    totalTaskListPages,
    totalTaskListEntites,
    pageLimit,
    setPageLimit,
    isTaskError,
    setFilterData,
    chwData,
}: any) => {
    const facilityCode: any = localStorage.getItem("facilityCode");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // Begins Menu Here
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                chwId: values.chwId,
                status: values.status,
                fromDate: values.fromDate,
                toDate: values.toDate,
                taskType: values.taskType,
            };
            setFilterData(payload);
            handleClose();
            // onSubmitProps.resetForm();
        } catch (error) {
            handleClose();
            onSubmitProps.resetForm();
        }
    };

    const clearData = () => {
        const payload = {
            chwId: "",
            status: "",
            fromDate: "",
            toDate: "",
            taskType: "",
        };
        setFilterData(payload);
    };

    const tableNames = [
        "PATIENT NAME",
        "CHW NAME",
        "FACILITY",
        "ACTIVITY NAME",
        "TASK TYPE",
        "DATE",
        "STATUS",
        "ACTIONS",
    ];

    // Role Settings
    const canExport = hasAccessToModule("LIST_TASK", Permissions.export_button);

    return (
        <div className="card text-dark">
            <Toaster />
            <Modal
                open={isOpen}
                styles={{ width: "650px", backgroundColor: "white" }}
            >
                <TaskListExport onClose={handleModal} />
            </Modal>
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>TASK LIST</h1>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                {Array.isArray(bodyProps) !== true ||
                    (bodyProps?.length > 0 && (
                        <div className="card-toolbar">
                            <button
                                hidden={!canExport}
                                onClick={handleModal}
                                className="btn btn-sm btn-light-primary me-5"
                            >
                                <i className="fas fa-download"></i>
                                EXPORT
                            </button>
                            <button
                                onClick={handleClick}
                                className="btn btn-sm btn-primary"
                            >
                                <i className="fas fa-filter"></i>
                                FILTER
                            </button>

                            {/* Here */}
                            <div className="">
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <Formik
                                        initialValues={filterData}
                                        validationSchema={
                                            selectorValidationSchema
                                        }
                                        enableReinitialize
                                        onSubmit={onFilterSubmit}
                                    >
                                        <Form>
                                            <div className="py-5 px-8">
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        CHW
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        name="chwId"
                                                        className="form-select form-select-lg form-select-solid"
                                                    >
                                                        <option value="">
                                                            All
                                                        </option>
                                                        {chwData?.map(
                                                            (chw: any) => {
                                                                return (
                                                                    <>
                                                                        <option
                                                                            value={
                                                                                chw?.chwId
                                                                            }
                                                                        >
                                                                            {
                                                                                chw?.name
                                                                            }
                                                                        </option>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Field>
                                                    <div className="text-danger mt-5">
                                                        <ErrorMessage name="chwId" />
                                                    </div>
                                                </div>
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        STATUS
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        className="form-select form-select-lg form-select-solid"
                                                        name="status"
                                                    >
                                                        <option value="">
                                                            All
                                                        </option>
                                                        <option value="To Do">
                                                            Todo
                                                        </option>
                                                        <option value="In Progress">
                                                            In Progress
                                                        </option>
                                                        <option value="Done">
                                                            Done
                                                        </option>
                                                        <option value="Cancelled">
                                                            Cancelled
                                                        </option>
                                                    </Field>
                                                    <div className="text-danger mt-2">
                                                        <ErrorMessage name="status" />
                                                    </div>
                                                </div>
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        TASK TYPE
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        className="form-select form-select-lg form-select-solid"
                                                        name="taskType"
                                                    >
                                                        <option value="">
                                                            All
                                                        </option>
                                                        <option value="Clinic">
                                                            Clinic
                                                        </option>
                                                        <option value="Camp">
                                                            Camp
                                                        </option>
                                                        <option value="Doorstep">
                                                            Doorstep
                                                        </option>
                                                        <option value="Community">
                                                            Community
                                                        </option>
                                                        <option value="Telehealth">
                                                            Telehealth
                                                        </option>
                                                    </Field>
                                                    <div className="text-danger mt-2">
                                                        <ErrorMessage name="status" />
                                                    </div>
                                                </div>
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        FROM DATE
                                                    </label>
                                                    <Field
                                                        id="fromDate"
                                                        as="input"
                                                        type="date"
                                                        name="fromDate"
                                                        className="form-control"
                                                    ></Field>
                                                    <div className="text-danger mt-5">
                                                        <ErrorMessage name="fromDate" />
                                                    </div>
                                                </div>
                                                <div className="w-250px mt-5 mb-8">
                                                    <label className="form-label fs-4 fw-bolder">
                                                        TO DATE
                                                    </label>
                                                    <Field
                                                        id="toDate"
                                                        as="input"
                                                        type="date"
                                                        name="toDate"
                                                        className="form-control"
                                                    ></Field>
                                                    <div className="text-danger mt-5">
                                                        <ErrorMessage name="toDate" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="m-5 d-flex justify-content-center align-items-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm w-250px btn-primary"
                                                >
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </Form>
                                    </Formik>
                                </Menu>
                            </div>
                        </div>
                    ))}
            </div>

            <FilterTile
                filterData={filterData}
                keyNames={{
                    chwId: "CHW",
                    status: "Status",
                    toDate: "End Date",
                    fromDate: "Start Date",
                    taskType: "Task Type",
                }}
                clearAll={() =>
                    setFilterData({
                        chwId: "",
                        status: "",
                        toDate: "",
                        fromDate: "",
                        taskType: "",
                    })
                }
            />
            {isTaskError || bodyProps?.length === 0 ? (
                <TableErrorComponent
                    errorMessage={
                        bodyProps?.length === 0 ? "No data available" : errorMsg
                    }
                />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={140} align="center">
                                SL NO
                            </TableHead>

                            {tableNames?.map((name) => {
                                return (
                                    <TableHead
                                        width={180}
                                        key={name}
                                        align="left"
                                    >
                                        {name}
                                    </TableHead>
                                );
                            })}
                        </TableHeadRow>
                        <TableBodyContainer
                            isLoading={isLoading || isFetching}
                            isError={isTaskError || bodyProps?.length === 0}
                            errorMessage={
                                bodyProps?.length === 0
                                    ? "No data available"
                                    : errorMsg
                            }
                        >
                            {bodyProps?.map((item: any, index: any) => {
                                let activityGroupName = item?.activity?.map(
                                    (activityName: any) => {
                                        return activityName?.activityGroupName;
                                    }
                                )[0];

                                let allVitalNames = item?.activity
                                    ?.map((activityName: any) => {
                                        return activityName?.activityDetails;
                                    })
                                    ?.flat();

                                let vitalNames = allVitalNames?.map(
                                    (allVitalName: any) => {
                                        return `${allVitalName?.vitalName}`;
                                    }
                                );

                                let firstVitalNames = allVitalNames?.map(
                                    (allVitalName: any) => {
                                        return `${allVitalName?.vitalName} ...`;
                                    }
                                )[0];

                                let firstVitalName = allVitalNames?.map(
                                    (allVitalName: any) => {
                                        return `${allVitalName?.vitalName}`;
                                    }
                                )[0];

                                return (
                                    <TableRow collapsible={false} key={index}>
                                        <TableCell>
                                            {(currentPageNumber - 1) *
                                                pageLimit +
                                                index +
                                                1}
                                        </TableCell>
                                        <TableCell>
                                            {item?.patient?.name}
                                        </TableCell>
                                        <TableCell hover>
                                            <CustomTooltip
                                                title={item?.chwData?.chwId}
                                                placement="top"
                                            >
                                                <span className="border-none">
                                                    {item?.chwData?.name ||
                                                        item?.chwData?.chwId}
                                                </span>
                                            </CustomTooltip>
                                        </TableCell>
                                        <TableCell>
                                            {item?.facilityData &&
                                            item?.facilityData[0]
                                                ? `${item?.facilityData[0].facilityFirstName} ${item?.facilityData[0].facilityLastName}`
                                                : "--"}
                                        </TableCell>
                                        <TableCell>
                                            <CustomTooltip
                                                title={vitalNames?.join(", ")}
                                                placement="top"
                                            >
                                                {/* <span className="border-none">
                                {activityGroupName ? activityGroupName : "N/A"}                               
                              </span> */}
                                                <span className="border-none text-capitalize">
                                                    {allVitalNames?.length >
                                                        1 !==
                                                    true
                                                        ? firstVitalName
                                                        : firstVitalNames}
                                                </span>
                                            </CustomTooltip>
                                        </TableCell>
                                        <TableCell>{item?.taskType}</TableCell>
                                        <TableCell>
                                            {dateFormatter(item?.taskDate)}
                                        </TableCell>

                                        <TableCell>
                                            <span
                                                className={`badge badge-light-${
                                                    item?.taskStatus === "Done"
                                                        ? "success"
                                                        : item?.taskStatus ===
                                                          "Cancelled"
                                                        ? "danger"
                                                        : item?.taskStatus ===
                                                          "In Progress"
                                                        ? "info"
                                                        : item?.taskStatus ===
                                                          "To Do"
                                                        ? "primary"
                                                        : null
                                                } text-center `}
                                            >
                                                {item?.taskStatus}
                                            </span>
                                        </TableCell>

                                        <TableCell>
                                            {
                                                // facilityCode ?
                                                <Link
                                                    to={`/task/task-details?taskId=${item?._id}`}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    DETAILS
                                                </Link>
                                                // : (
                                                //     <button
                                                //         onClick={() => warnUser()}
                                                //         className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                //     >
                                                //         DETAILS
                                                //     </button>
                                                // )
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBodyContainer>
                    </Table>
                    {/* Pagination */}
                    <Pagination
                        dataCount={totalTaskListEntites}
                        handleCallback={(val) => setPageLimit(+val)}
                        handleNext={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber + 1
                            )
                        }
                        handlePrev={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber - 1
                            )
                        }
                        nextDisabled={currentPageNumber === totalTaskListPages}
                        prevDisabled={currentPageNumber === 1}
                        pageLimit={pageLimit}
                        pageNumber={currentPageNumber}
                    />
                </div>
            )}
        </div>
    );
};

export { TaskTable };
