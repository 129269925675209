import * as Yup from "yup";

export type patientEntriesValueTypes = {
  // groupName: string;
  // multiGroups: any;
  // multiQuestions: any;
  // questionName: string;
  // questionType: string;
  // description: string;
  // questionRequired: any;
  // respTypes: any;
  // groupRequired: any;
};

export const initalValues: patientEntriesValueTypes = {};

export const formBuilderValidationSchema = Yup.object({
  // groupName: Yup.string().required("Group Name is Required"),
  // questionName: Yup.string().required("Question Name is Required"),
  // questionType: Yup.string().required("Question Type is Required"),
  // description: Yup.string().label("Description is Required"),
});
