import $axiosProtected from "../common/utils/axiosUtils";

export const getDashboardData = async (payload: any) => {
  return await $axiosProtected().get(
    `/aggregation/web-portal/home-page?period=${payload.period}&facilityCode=${payload.facilityCode}&limit=${payload.limit}&page=${payload.page}`
  );
};

export const getDashboardFacilities = async (payload: any) => {
  return await $axiosProtected().get(
    `/chwm/facility/all-chws?page=${payload.page}&limit=${payload.limit}&facilityCode=${payload.facilityCode}`
  );
};
