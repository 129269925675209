import * as Yup from "yup";

export type TeleconsultationDetailsTypes = {
  teleconsultationDetails: any;
};

export const initalValues: TeleconsultationDetailsTypes = {
  teleconsultationDetails: "",
};

export const TeleconsultationDetailsValidationSchema = Yup.object({
  teleconsultationDetails: Yup.number().required(
    "Teleconsultation Details is required"
  ),
});
