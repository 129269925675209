import { useState } from "react";
import useGetRequest from "../../hooks/getRequest.query";
import { getKPIByModules } from "../../services/target-distribution/TargetDistribution.hook";

import KPIAnalysisPage from "./kpi-analysis/KPIAnalysisPage";
import { hasAccessToModule } from "../../rbac";
import { Permissions } from "../../rbac/types";

const PERFORMANCE_MONTIORING = "Performance Monitoring";

const PerformanceAnalysis = () => {
    const [analysis, setAnalysis] = useState<string>("Patient Registration");

    // API to get KPI by module name
    const { data }: any = useGetRequest(
        "getKPIByModules",
        async () =>
            await getKPIByModules({
                name: PERFORMANCE_MONTIORING,
            }),
        {
            retry: false,
            // staleTime: 100000,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const allModules = data?.data?.data?.modules?.criterias;

    const showPage = hasAccessToModule(
        "PERFORMANCE_MONITORING",
        Permissions.read
    );
    return (
        <div hidden={!showPage}>
            <div className="bg-white p-5 d-flex justify-content-between">
                <div className="w-100">
                    <h1 className="fw-bolder">Target vs Achievement</h1>
                </div>

                <div className="d-flex w-25">
                    <select
                        onChange={(e) => setAnalysis(e.target.value)}
                        value={analysis}
                        className="form-select form-select-light form-select-lg"
                    >
                        {allModules?.map((modules: any) => {
                            return (
                                <>
                                    <option value={modules?.criteriaName}>
                                        {modules?.criteriaName}
                                    </option>
                                </>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="mt-5">
                <>
                    <KPIAnalysisPage analysis={analysis} />
                </>
            </div>
        </div>
    );
};

export default PerformanceAnalysis;
