import React from "react";
import { createPortal } from "react-dom";
import "./styles/Modal.css";

type modalProps = {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  styles?: React.CSSProperties;
  overlayStyles?: React.CSSProperties;
};

const Modal = ({
  open,
  onClose,
  children,
  styles,
  overlayStyles,
}: modalProps) => {
  const target = document.getElementById("root-modals") as HTMLDivElement;
  if (open) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  if (!open) return null;
  return createPortal(
    <>
      <div className="overlay" style={overlayStyles}></div>
      <div className="modal-styles" style={styles}>
        {children}
      </div>
    </>,
    target
  );
};

export default Modal;
