import * as Yup from "yup";

export type UnassignedTaskTypes = {
  chwName: string;
  taskDate: any;
};

export const initalValues: UnassignedTaskTypes = {
  chwName: "",
  taskDate: "",
};

export const unassignedTaskValidationSchema = Yup.object({
  chwName: Yup.string().label("CHW Name is Required"),
  taskDate: Yup.string().label("Date is Required"),
});
