import { Field, ErrorMessage } from "formik";
import CustomSelect from "../../../../components/CustomSelect";

const languagesOptions = [
    { label: "English", value: "English" },
    { label: "Kannada", value: "Kannada" },
    { label: "Tamil", value: "Tamil" },
    { label: "Telugu", value: "Telugu" },
    { label: "Hindi", value: "Hindi" },
    { label: "Bengali", value: "Bengali" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Odia", value: "Odia" },
    { label: "Vietnamese", value: "Vietnamese" },
];

const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
};
const maxDate = getTodayDate();

const Step3 = () => {
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Personal Details</h2>

                {/* <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please check out
          <a href="/dashboard" className="link-primary fw-bolder">
            {" "}
            Help Page
          </a>
          .
        </div> */}
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Gender</label>
                <Field
                    data-testid="gender"
                    as="select"
                    name="gender"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                </Field>

                <div className="text-danger mt-2">
                    <ErrorMessage name="gender" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Date of Birth</label>
                {/* <Field
                    data-testid="birth-date"
                    id="birthDate"
                    as="input"
                    type="date"
                    maxDate={new Date()}
                    name="birthDate"
                    className="form-control form-control-lg form-control-solid cursor-pointer"
                /> */}
                <Field name="birthDate">
                    {({ field }: any) => (
                        <input
                            type="date"
                            {...field}
                            max={maxDate}
                            className="form-control form-control-lg form-control-solid cursor-pointer"
                        />
                    )}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="birthDate" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="d-flex align-items-center form-label">
                    <span className="">Age</span>
                </label>

                <Field
                    data-testid="age"
                    name="age"
                    placeholder="Enter your age"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="age" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    Languages
                </label>
                <Field
                    className="form-select-solid form-select-stretch"
                    name="languages"
                    options={languagesOptions}
                    component={CustomSelect}
                    placeholder="Select languages..."
                    isMulti={true}
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="languages" />
                </div>
            </div>
            <div className="fv-row mb-0">
                <label className="fs-6 fw-bold form-label required">
                    Preferred Language
                </label>
                <Field
                    data-testid="preferred-language"
                    as="select"
                    name="preferredLanguage"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your preferred language</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Telugu">Telugu </option>
                    <option value="Tamil">Tamil</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Vietnamese">Vietnamese</option>
                </Field>

                <div className="text-danger mt-2">
                    <ErrorMessage name="preferredLanguage" />
                </div>
            </div>
        </div>
    );
};

export { Step3 };
