import * as Yup from "yup";

export type CustomModulesTypes = {
  analyticsModules: any;
};

export const CustomModulesInitalValues: CustomModulesTypes = {
  // analyticsModules: [
  //   {
  //     moduleName: "",
  //     moduleStatus: true,
  //   },
  // ],
  analyticsModules: [],
};

export const AnalyticsTypesValidationSchema = Yup.object({
  // teleconsultationDetails: Yup.number().required(
  //   "Teleconsultation Details is required"
  // ),
});
