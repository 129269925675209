import React from "react";

type Props = {
  name: string;
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const CheckBox = (props: Props) => {
  const { name, isChecked, onChange } = props;
  return (
    <input
      type="checkbox"
      name={name}
      checked={isChecked}
      className="form-check-input"
      onChange={onChange}
    />
  );
};

export default CheckBox;
