import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InsightsSkeleton = () => {
  return (
    <SkeletonTheme
      baseColor="#f7f0f0"
      highlightColor="#bdb3b3"
      enableAnimation
      duration={2}
    >
      <div className="border border-2 rounded">
        <div className="card card-xl-stretch mb-xl-3 mb-5 p-5">
          <div className="d-flex align-items-center">
            <Skeleton
              circle
              count={1}
              style={{ width: "60px", height: "60px", marginRight: "20px" }}
            />
            <Skeleton count={1} style={{ width: "250px", height: "30px" }} />
          </div>
          <div className="mt-15">
            <Skeleton count={1} style={{ width: "180px", height: "40px" }} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default InsightsSkeleton;
