import CustomModules from "./facility-settings/components/CustomModules";
import SettingsHeader from "./components/SettingsHeader";

export const SettingsPage = () => {
  return (
    <>
      <SettingsHeader />
      <>
        {/* Modules */}
        <CustomModules />
      </>
    </>
  );
};
