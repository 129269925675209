import $axiosProtected from "../../../../common/utils/axiosUtils";

// Get CHW Stock
export const getChwStockList = async ({
    facilityCode,
    page,
    limit,
    chwId,
    productName,
    genericName,
}: {
    facilityCode: string;
    chwId: string;
    page: number;
    limit: number;
    productName: string;
    genericName: string;
}) => {
    return await $axiosProtected().get(
        `/inventory/chw-inventories/get/all/active/web?chwId=${chwId}&facilityCode=${facilityCode}&page=${page}&limit=${limit}&productName=${productName}&genericName=${genericName}`
    );
};
