import * as Yup from "yup";

const IMAGE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
];

const DOC_SUPPORTED_FORMATS = ["application/pdf"];

export const initsForPatientProfileUpdate = {
  email: "",
  maritalStatus: "",
  telecom: "",
  age: "",
  language: "",
  idProofName: "",
  proofId: "",
  addressSiteName: "",
  floorNumber: "",
  streetName: "",
  streetNumber: "",
  district: "",
  postalCode: "",
  town: "",
  state: "",
  country: "",

  // Emergency
  contactRelationship: "",
  contactName: "",
  contactTelecom: "",
  contactGender: "",
  contactAddressSiteName: "",
  contactFloorNumber: "",
  contactStreetName: "",
  contactStreetNumber: "",
  contactDistrict: "",
  contactPostalCode: "",
  contactTown: "",
  contactState: "",
  contactCountry: "",

  consentType: "",
  photo: "",
  consent: "",
  proofLocation: "",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const userNameRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const numberRegExp = /^[0-9]*$/;
const emailRegExp =
  /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const patientProfileUpdateSchema = Yup.object({
  telecom: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  age: Yup.string().matches(numberRegExp, "Age is not valid"),
  email: Yup.string().email("Please enter a valid email").label("Email Id"),
  photo: Yup.mixed()
    .nullable()
    .label("Photo")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value) =>
        !value || (value && IMAGE_SUPPORTED_FORMATS.includes(value?.type))
    ),
  proofLocation: Yup.mixed()
    .nullable()
    .label("Proof")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value) =>
        !value || (value && DOC_SUPPORTED_FORMATS.includes(value?.type))
    ),

  addressSiteName: Yup.string()
    .matches(/^[A-Za-z]*$/, "Please enter valid name")
    .label("Address Site Name"),
  streetName: Yup.string()
    .matches(/^[A-Za-z]*$/, "Please enter valid name")
    .label("Street Name"),
  streetNumber: Yup.string()
    .matches(numberRegExp, "Street Number is not valid")
    .label("Street Number"),
  floorNumber: Yup.string()
    .matches(numberRegExp, "Street Number is not valid")
    .label("Floor Number"),
  postalCode: Yup.string()
    .matches(numberRegExp, "Postal Code is not valid")
    .label("Postal Code"),
  district: Yup.string()
    .matches(/^[A-Za-z0-9]*$/, "Please enter valid district name")
    .label("District"),
  town: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid town name")
    .label("Town"),
  state: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid state name")
    .label("State"),
  country: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid country name")
    .label("Country"),

  // Emergency
  consent: Yup.mixed()
    .nullable()
    .label("Consent")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value) =>
        !value || (value && DOC_SUPPORTED_FORMATS.includes(value?.type))
    ),

  contactName: Yup.string()
    .matches(/^[A-Za-z]*$/, "Please enter valid name")
    .label("Name"),
  contactAddressSiteName: Yup.string()
    .matches(/^[A-Za-z]*$/, "Please enter valid name")
    .label("Address Site Name"),
  contactStreetName: Yup.string()
    .matches(/^[A-Za-z]*$/, "Please enter valid name")
    .label("Street Name"),
  contactTelecom: Yup.string()
    .matches(numberRegExp, "Telecom Number is not valid")
    .label("Street Number"),
  contactStreetNumber: Yup.string()
    .matches(numberRegExp, "Street Number is not valid")
    .label("Street Number"),
  contactFloorNumber: Yup.string()
    .matches(numberRegExp, "Street Number is not valid")
    .label("Floor Number"),
  contactPostalCode: Yup.string()
    .matches(numberRegExp, "Postal Code is not valid")
    .label("Postal Code"),
  contactDistrict: Yup.string()
    .matches(/^[A-Za-z0-9]*$/, "Please enter valid district name")
    .label("District"),
  contactTown: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid town name")
    .label("Town"),
});
