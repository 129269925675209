import * as Yup from "yup";

export type enableVitalsValueTypes = {
  visitNumber: any;
  vitals: any;
};

export const initalValues: enableVitalsValueTypes = {
  visitNumber: 0,
  vitals: [],
};

export const enableVitalsValidationSchema = Yup.object({
  visitNumber: Yup.string().label("Visit Number is Required"),
  vitals: Yup.array().label("Vitals is Required"),
});
