import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import {
    initalValues,
    enableVitalsValidationSchema,
} from "../models/EnableVitalsModel";
import useMutateRequest from "../../../../hooks/getMutation.query";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import {
    getAllVitals,
    attachVitalsToFocusedGroup,
} from "../../../../services/focused-group/FocusedGroup.hook";
import { Permissions, hasAccessToSubModule } from "../../../../rbac";

const EnableVitals = ({ totalVisits }: any) => {
    const { fgId: id }: any = useSearchParams();
    const visitArray = Array.from({ length: totalVisits }, (_, i) => i + 1);
    const [visitNumber, setVisitNumber]: any = useState(
        totalVisits < 1 ? 0 : 1
    );

    // API: get list of all vitals
    const { data, isLoading, isFetching, refetch, error } = useGetRequest(
        ["getAllFocusedGroup", id],
        async () =>
            await getAllVitals({
                focusedGroupId: id,
                visitNumber: visitNumber,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );
    useEffect(() => {
        if (id) refetch();
    }, [totalVisits, visitNumber]);
    const vitalsOptions = data?.data?.data?.focusedGroup?.visits;

    // API: attach list of vitals to focused group
    const {
        isLoading: attachVitalsLoader,
        mutate,
        error: attachVitalError,
        isError,
        isSuccess,
    }: any = useMutateRequest((data: any) => attachVitalsToFocusedGroup(data));
    const errorMsg = attachVitalError?.response?.data?.message;
    const [editVitals, setEditVitals] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        try {
            let payload = {
                visitNumber: String(visitNumber),
                focusedGroupId: id,
                vitals: values.vitals,
            };
            mutate(payload, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        refetch();
                        setEditVitals(!editVitals);
                    }, 1500);
                },
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = () => {
        setEditVitals(!editVitals);
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Updated Vitals
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {errorMsg}
                    {/* Failed to add vitals */}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError]);

    //  Role settings
    const canEdit = hasAccessToSubModule(
        "LIST_FOCUSED_GROUP",
        "LIST_BENEFICIARY_VITALS",
        Permissions.update
    );

    return (
        <div className="w-100 card p-10">
            <>
                <Toaster />

                <div className="pb-lg-12 d-flex justify-content-between">
                    <h1 className="fw-bolder text-dark">Vitals</h1>
                    {editVitals === false ? (
                        <button
                            hidden={!canEdit}
                            className="btn btn-primary"
                            onClick={handleEdit}
                        >
                            <i className="fas fa-edit"></i>
                            Edit
                        </button>
                    ) : (
                        <button className="btn btn-danger" onClick={handleEdit}>
                            Cancel
                        </button>
                    )}
                </div>

                <Formik
                    initialValues={initalValues}
                    validationSchema={enableVitalsValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formProps: any) => {
                        return (
                            <Form>
                                {/* Visit Number */}
                                {visitNumber !== 0 && (
                                    <div className="fv-row col-lg-12 mb-10">
                                        <label className="form-label required">
                                            Visit Number
                                        </label>

                                        <select
                                            onChange={(e: any) => {
                                                return (
                                                    formProps.setFieldValue(
                                                        "visitNumber",
                                                        visitNumber
                                                    ),
                                                    setVisitNumber(
                                                        e.target.value
                                                    )
                                                );
                                            }}
                                            value={visitNumber}
                                            className="form-select form-select-solid form-select-stretch cursor-pointer"
                                            // name="visitNumber"
                                            // as="select"
                                            // disabled={editVitals === false}
                                        >
                                            {visitArray.map(
                                                (visitNumber: any) => {
                                                    return (
                                                        <>
                                                            <option
                                                                value={
                                                                    visitNumber
                                                                }
                                                            >
                                                                {visitNumber}
                                                            </option>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </select>

                                        <div className="text-danger mt-2">
                                            <ErrorMessage name="visitNumber" />
                                        </div>
                                    </div>
                                )}

                                {isLoading || isFetching ? (
                                    <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                                        <ClipLoader
                                            size={70}
                                            speedMultiplier={0.6}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {/* Vitals */}
                                        <div className="fv-row mb-10">
                                            <label className="mb-5 form-label required">
                                                Select Vitals Required
                                            </label>

                                            {vitalsOptions?.map(
                                                (
                                                    vitalOption: any,
                                                    index: any
                                                ) => {
                                                    return (
                                                        <>
                                                            {vitalOption?.vitals?.map(
                                                                (
                                                                    vital: any
                                                                ) => {
                                                                    if (
                                                                        vital?.vitalStatus ===
                                                                        true
                                                                    ) {
                                                                        vital.disabled =
                                                                            true;
                                                                    }

                                                                    return (
                                                                        <>
                                                                            <div
                                                                                role="group"
                                                                                className="d-flex form-check form-switch form-check-custom form-check-solid"
                                                                            >
                                                                                {editVitals ===
                                                                                false ? (
                                                                                    <div
                                                                                        style={{
                                                                                            cursor:
                                                                                                editVitals ===
                                                                                                false
                                                                                                    ? "not-allowed"
                                                                                                    : "pointer",
                                                                                        }}
                                                                                    >
                                                                                        <Field
                                                                                            checked={
                                                                                                vital?.vitalStatus ===
                                                                                                true
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            disabled={
                                                                                                editVitals ===
                                                                                                false
                                                                                            }
                                                                                            type="checkbox"
                                                                                            name={`vitals`}
                                                                                            id={`vital`}
                                                                                            value={
                                                                                                vital?.vitalId
                                                                                            }
                                                                                            className="ms-5 form-check-input mb-5 w-50px h-30px"
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        <Field
                                                                                            disabled={
                                                                                                vital.disabled
                                                                                            }
                                                                                            checked={
                                                                                                vital.disabled
                                                                                            }
                                                                                            type="checkbox"
                                                                                            name={`vitals`}
                                                                                            id="vital"
                                                                                            // value={vital?._id}
                                                                                            value={
                                                                                                vital?.vitalId
                                                                                            }
                                                                                            className="ms-5 form-check-input mb-5 w-50px h-30px"
                                                                                        />
                                                                                    </>
                                                                                )}

                                                                                <label
                                                                                    style={{
                                                                                        cursor:
                                                                                            editVitals ===
                                                                                                false ||
                                                                                            vital?.vitalStatus ===
                                                                                                true
                                                                                                ? "not-allowed"
                                                                                                : "pointer",
                                                                                    }}
                                                                                    htmlFor="vital"
                                                                                    className="ms-5 fs-3 form-check-label text-center"
                                                                                >
                                                                                    {
                                                                                        vital?.vitalName
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    );
                                                }
                                            )}

                                            <div className="text-danger mt-2">
                                                <ErrorMessage name="vitals" />
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="mt-5 d-flex justify-content-center">
                                    {editVitals === true && (
                                        <button
                                            type="submit"
                                            disabled={!formProps.isValid}
                                            className="w-250px btn btn-lg btn-primary me-3"
                                        >
                                            Update Vital List
                                        </button>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </>
        </div>
    );
};

export default EnableVitals;
