import { useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";

import ClipLoader from "react-spinners/ClipLoader";

import {
  initalValues,
  removeCHWValidationSchema,
} from "../model/RemoveCHWModel";
import useMutateRequest from "../../../hooks/getMutation.query";
import { removeCHWFromFacility } from "../../../services/CHWManagement.hook";
import { KTSVG } from "../../../_metronic/helpers";

type ExportProps = {
  onClose: () => void;
  styles?: any;
  chwName?: string;
  chwId?: string;
  refetch?: any;
};

const RemoveCHW = ({
  onClose,
  styles,
  chwName,
  chwId,
  refetch,
}: ExportProps) => {
  // console.log(chwName);
  const [msg, setMsg] = useState<boolean>(false);

  const [spinner, setSpinner] = useState<boolean>(false);
  const facilityName: any = localStorage.getItem("facilityName");
  const facilityCode: any = localStorage.getItem("facilityCode");
  // console.log(facilityCode);

  // Remove a CHW from the facility
  const { isLoading, mutate, isError }: any = useMutateRequest((data: any) =>
    removeCHWFromFacility(data)
  );
  // () => removeCHWFromFacility();

  const onSubmit = (values: any) => {
    // console.log("Form Values", values);
    const payload = {
      facilityCode: String(facilityCode),
      chwId: String(chwId),
    };
    try {
      mutate(payload, {
        onSuccess(data: any) {
          // console.log(data);
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
            refetch();
          }, 1500);
        },

        onError(error: any) {
          console.log(error);
          setMsg(true);
          setSpinner(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
      });
    } catch (error: any) {
      console.log(error);
    }
    console.log(payload);
  };

  return (
    <>
      {isLoading || spinner ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg ? (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.msgHeight,
            padding: styles?.padding,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {isError === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                {`Failed to remove chw from the facility`}
                {/* {ErrorMsg} */}
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-primary me-5"
                >
                  Please Retry
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Successfully removed chw from facility
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={removeCHWValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formProps: any) => (
              <Form>
                <h1 className="text-center">REMOVE CHW</h1>
                <div className="mt-5 notice w-100 d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-2tx svg-icon-warning me-4"
                  />
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <h4 className="text-gray-800 fw-bolder">
                        Carefull, We need your attention!
                      </h4>
                      <div className="fs-6 text-gray-700">
                        {`${chwName} will be removed from ${facilityName} ( ${facilityCode} ) `}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <span className="fs-5 ms-2">
                    <p className="text-muted fw-bolder">
                      To confirm deletion, enter the phrase {""}
                      <b className="text-dark fw-bolder ms-1">remove me</b>
                    </p>
                  </span>

                  <Field
                    name="confirmRemove"
                    placeholder="remove me"
                    className="form-control form-control-light form-control-lg required"
                  />
                  <div className="text-danger mt-3">
                    <ErrorMessage name="confirmRemove" />
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <button
                    onClick={onClose}
                    className="w-150px btn btn-md btn-danger me-5"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    // disabled={!formProps.isValid || formProps.isSubmitting}
                    disabled={formProps?.values?.confirmRemove !== "remove me"}
                    className="w-150px btn btn-md btn-primary"
                  >
                    Remove
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default RemoveCHW;
