import React from "react";
import { Form } from "react-bootstrap";

type Props = {
    children: React.ReactNode;
    value: number;
    onChange: any;
};

const PaginationSelect = (props: Props) => {
    const { children, value, onChange } = props;
    return (
        // @ts-ignore
        <Form.Select className="w-auto" value={value} onChange={onChange}>
            {children}
        </Form.Select>
    );
};

export default PaginationSelect;
