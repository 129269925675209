import { createRoot } from "react-dom/client";
import { App } from "./App";

import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import "react-loading-skeleton/dist/skeleton.css";

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === "prod") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
