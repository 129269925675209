import { useEffect, useState } from "react";
import TDStep1 from "./steps/TDStep1";
import TDStep2 from "./steps/TDStep2";
import { Formik, Form, FormikValues } from "formik";

import ClipLoader from "react-spinners/ClipLoader";

import {
    initalValuesForTargetDistribution,
    TargetDistributionValidationSchema,
} from "../model/TargetDistributionModel";
import useMutateRequest from "../../../hooks/getMutation.query";
import useGetRequest from "../../../hooks/getRequest.query";
import {
    getKPIByModules,
    getKPITargetsForMonth,
    distributeTargetsToCHW,
} from "../../../services/target-distribution/TargetDistribution.hook";

type ExportProps = {
    onClose: () => void;
    styles?: any;
    refetch?: any;
    selectedCHW?: any;
    allCHW?: any;
    chwCounterData?: any;
};

const TargetDistributionPage = ({
    onClose,
    styles,
    refetch,
    selectedCHW,
    allCHW,
    chwCounterData,
}: ExportProps) => {
    // API Begins
    const [formData, setFormData]: any = useState({});

    const PERFORMANCE_MONTIORING = "Performance Monitoring";
    const currentMonth = new Date().getMonth() + 1;

    // getKPIByModules
    const {
        data,
        isLoading,
        isFetching,
        refetch: moduleRefetcher,
    }: any = useGetRequest(
        "getKPIByModules",
        async () =>
            await getKPIByModules({
                name: PERFORMANCE_MONTIORING,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    let splitMonthAndYear = formData?.selectedMonth?.split(" ");
    let splitMonth = splitMonthAndYear && splitMonthAndYear[0];
    let splitYear = splitMonthAndYear && splitMonthAndYear[1];

    // getKPITargetsForMonth
    const {
        data: targetData,
        refetch: targetRefetch,
        isError,
    }: any = useGetRequest(
        "getKPITargetsForMonth",
        async () =>
            await getKPITargetsForMonth({
                facilityCode,
                criteriaId: formData?.selectedKPI,
                month: splitMonth,
                year: Number(splitYear),
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    let kpiTargetData = targetData?.data?.data?.target;
    let kpiTargetId = targetData?.data?.data?.target?._id;
    let currentMonthTarget = targetData?.data?.data?.target?.target;
    let monthlyTarget =
        isError === false
            ? currentMonthTarget && currentMonthTarget
            : "No target set for the above month";
    // : "No target set, Please set the target first..";

    let isS;
    let isTargetDistributed =
        isError === false
            ? targetData?.data?.data?.target?.targetDistributed
            : (isS = false);
    const kpiData = data?.data?.data?.modules?.criterias;

    useEffect(() => {
        targetRefetch();
    }, [formData]);

    // Calculations
    // Logic Behind Calculation: First the Task will be divided based on CHW no.
    // eg. 15 task and 12 chw. In first operation all the 12 chw will get each task that is 12 task for 12 chw
    // Remaining 3  we need to add by selecting the chws from the table then press the distribute button.

    let totalNumberOfCHWInFacility = chwCounterData?.allChwCounter;

    let targetSetForMonth = Number(monthlyTarget);

    let approxNumToDistribute: any = Math.floor(
        targetSetForMonth / totalNumberOfCHWInFacility
    );

    let distributedTargets = approxNumToDistribute * totalNumberOfCHWInFacility;
    let remainingTargetsToDistribute = targetSetForMonth - distributedTargets;

    // Config
    const [msg, setMsg] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const [activeIndex, setActiveIndex] = useState(0);
    const facilityCode: any = localStorage.getItem("facilityCode");

    // Api to distribute the target

    const {
        mutate,
        isError: isDistributeError,
        error,
    }: any = useMutateRequest((data: any) => distributeTargetsToCHW(data));

    const ErrorMsg = error?.response?.data?.message;

    let showError = formData?.approxNumToDistribute?.map((ax: any) => {
        return ax?.targetForChw !== "";
    });

    let isMessage =
        showError?.some((el: any) => el === false) !== false ? true : false;

    const onSubmit = (values: any, actions: FormikValues) => {
        let customPayload = selectedCHW
            ?.map((chw: any, index: any) => {
                if (+values?.approxNumToDistribute[index]?.targetForChw !== 0) {
                    return {
                        chwId: chw?.chwId,
                        targetForChw:
                            values &&
                            Number(
                                values?.approxNumToDistribute[index]
                                    ?.targetForChw
                            ),
                    };
                } else {
                    return null;
                }
            })
            .filter((chw: any, index: any) => chw !== null);

        let autoPayload = {
            facilityCode,
            targetId: kpiTargetId,
            totalChws: totalNumberOfCHWInFacility,
            avgTargetForChw: approxNumToDistribute,
            averageTotalTarget: Number(
                approxNumToDistribute * totalNumberOfCHWInFacility
            ),
            totalTargetsRemaining: remainingTargetsToDistribute,
        };

        let manualPayload = {
            facilityCode,
            targetId: kpiTargetId,
            chws: customPayload,
        };
        let payload =
            isTargetDistributed === false ? autoPayload : manualPayload;
        try {
            mutate(payload, {
                onSuccess: (data: any) => {
                    setSpinner(true);
                    setOpen(true);
                    setMsg(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 3000);
                },
                onError: (error: any) => {
                    setSpinner(true);
                    setOpen(true);
                    setMsg(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 3000);
                },
            });
        } catch (error) {
            // console.log(error);
        }
    };
    let disableSubmit =
        (selectedCHW?.length !== showError?.length) === true ? true : false;
    let autoDisable =
        isTargetDistributed === false ? false : disableSubmit || isMessage;

    return (
        <>
            {isLoading || spinner ? (
                <div
                    style={{ backgroundColor: "transparent" }}
                    className="h-225px d-flex flex-column justify-content-center align-items-center"
                >
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : msg ? (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.msgHeight,
                        padding: styles?.padding,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    {isDistributeError === true ? (
                        <>
                            <p className="fs-1 fw-bolder text-center text-danger">
                                {ErrorMsg}
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Successfully distributed targets to CHW
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div
                        style={{
                            backgroundColor: styles?.backgroundColor,
                            width: styles?.width,
                            height: styles?.height,
                            padding: styles?.padding,
                        }}
                    >
                        <Formik
                            initialValues={initalValuesForTargetDistribution}
                            validationSchema={
                                TargetDistributionValidationSchema
                            }
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                        >
                            {(formProps: any) => {
                                setFormData(formProps?.values);
                                return (
                                    <Form>
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <h1 className="w-100 text-center">
                                                    TARGET DISTRIBUTION
                                                </h1>
                                                <div className="align-self-end">
                                                    <h1
                                                        onClick={() =>
                                                            onClose()
                                                        }
                                                    >
                                                        <i className="fs-2 text-danger fas fa-window-close cursor-pointer"></i>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="p-5">
                                                {activeIndex === 0 ? (
                                                    <TDStep1
                                                        {...formProps}
                                                        kpiData={kpiData}
                                                        PERFORMANCE_MONTIORING={
                                                            PERFORMANCE_MONTIORING
                                                        }
                                                        currentMonthTarget={
                                                            monthlyTarget
                                                        }
                                                        ErrorMsg={ErrorMsg}
                                                        currentMonth={
                                                            currentMonth
                                                        }
                                                    />
                                                ) : (
                                                    <TDStep2
                                                        selectedCHW={
                                                            selectedCHW
                                                        }
                                                        allCHW={allCHW}
                                                        chwCounterData={
                                                            chwCounterData
                                                        }
                                                        {...formProps}
                                                        approxNumToDistribute={
                                                            approxNumToDistribute
                                                        }
                                                        remainingTargetsToDistribute={
                                                            remainingTargetsToDistribute
                                                        }
                                                        kpiTargetData={
                                                            kpiTargetData
                                                        }
                                                        isTargetDistributed={
                                                            isTargetDistributed
                                                        }
                                                        isMessage={
                                                            disableSubmit ||
                                                            isMessage
                                                        }
                                                    />
                                                )}
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <>
                                                    {activeIndex === 0 ? (
                                                        <button
                                                            disabled={
                                                                isError === true
                                                            }
                                                            onClick={() =>
                                                                setActiveIndex(
                                                                    1
                                                                )
                                                            }
                                                            className="btn btn-md btn-primary"
                                                        >
                                                            Continue
                                                            <i className="ms-2 fas fa-long-arrow-alt-right"></i>
                                                        </button>
                                                    ) : (
                                                        <div className="d-flex justify-content-between w-100">
                                                            <div className="">
                                                                <button
                                                                    onClick={() =>
                                                                        setActiveIndex(
                                                                            0
                                                                        )
                                                                    }
                                                                    className="btn btn-md btn-primary"
                                                                >
                                                                    <i className="fas fa-long-arrow-alt-left"></i>
                                                                    Back
                                                                </button>
                                                            </div>
                                                            {kpiTargetData &&
                                                                kpiTargetData?.totalTargetsRemaining !==
                                                                    0 && (
                                                                    <div>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={
                                                                                autoDisable ||
                                                                                !formProps?.isValid
                                                                            }
                                                                            className="btn btn-md btn-primary"
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        </>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </>
            )}
        </>
    );
};

export default TargetDistributionPage;
