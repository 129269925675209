import { useEffect, useState } from "react";
import { useNavbar } from "../../../context/NavbarContext";
import useGetRequest from "../../../hooks/getRequest.query";
import { getTasksTarget } from "../../../services/task/TaskManage.hook";
import PatientRegistration from "./components/PatientRegistration";
import TeleconsultationDetails from "./components/TeleconsultationDetails";

const TaskManagementPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit] = useState(10);
  const { selectedFacility } = useNavbar();
  const facilityCode = localStorage.getItem("facilityCode");
  // const [teleconsultationLimits, setTeleconsultationLimits] = useState(null);
  // const [patientLimits, setPatientLimits] = useState(null);

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ["getTasksTarget", pageNumber],
      async () =>
        await getTasksTarget({
          page: pageNumber,
          limit: pageLimit,
          limitType: "perDay",
          facilityCode,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
      }
    );

  const totalUnassignedTasks = data?.data?.results;
  const taskData = data?.data?.data?.taskManagement;

  const teleconsultationTarget = taskData?.filter((item: any) => {
    return item?.targetName === "Teleconsultation";
  });

  const patientRegistrationTarget = taskData?.filter((item: any) => {
    return item?.targetName === "PatientRegistration";
  });

  let teleconsultationLimits =
    isError === false
      ? teleconsultationTarget && teleconsultationTarget[0]?.limits[0]?.limit
      : 0;

  let patientLimits =
    isError === false
      ? patientRegistrationTarget &&
        patientRegistrationTarget[0]?.limits[0]?.limit
      : 0;

  const totalAvailablePages = Math.ceil(totalUnassignedTasks / pageLimit);
  const errorMsg = error?.response?.data?.message;
  // console.log(teleconsultationLimits);

  useEffect(() => {
    refetch();
    // setTeleconsultationLimits(
    //   teleconsultationTarget && teleconsultationTarget[0]?.limits[0]?.limit
    // );
    // setPatientLimits(
    //   patientRegistrationTarget &&
    //     patientRegistrationTarget[0]?.limits[0]?.limit
    // );
    console.log("Executed");
  }, [selectedFacility]);

  return (
    <>
      <div className="card p-5">
        <PatientRegistration
          patientLimits={patientLimits}
          refetch={refetch}
          isFetching={isFetching}
        />
      </div>
      <div className="card p-5 mt-5">
        <TeleconsultationDetails
          teleconsultationLimits={teleconsultationLimits}
          refetch={refetch}
          isFetching={isFetching}
        />
      </div>
    </>
  );
};

export default TaskManagementPage;
