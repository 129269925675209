import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useNavbar } from "../../../../context/NavbarContext";
import { useRBAC } from "../../../../context/RBACContext";
import { getAllFacilites } from "../../../../services/facility/Facility.hook";
import useGetRequest from "../../../../hooks/getRequest.query";
import { RBACComponent } from "../../../../common/config/rbac/components/RBACComponent";
import { permittedRoles } from "../../../../common/config/rbac/types/rbac.types";
import _ from "lodash";

const Header = () => {
    const redirectTo = useNavigate();
    const { handleSelectedFacility } = useNavbar();
    const facilityCode: any = localStorage.getItem("facilityCode");
    const facilityStatus: any = localStorage.getItem("facilityStatus");

    const { data } = useGetRequest(
        "getAllFacilites-header",
        async () => await getAllFacilites(),
        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    // Render only active facilities
    const { userRole } = useRBAC();
    const facilityData = useMemo(() => {
        return data?.data?.data?.facility
            ?.filter((facility: any) => {
                return facility?.status !== false;
            })
            .map((facility: any) => {
                const facilityCode = facility?.facilityCode;
                const facilityStatus = facility?.status;
                const facilityName =
                    facility?.facilityFirstName +
                    " " +
                    facility?.facilityLastName;
                return {
                    facilityCode,
                    facilityName,
                    facilityStatus,
                };
            });
    }, [data]);

    const inactiveFacilities = useMemo(() => {
        return data?.data?.data?.facility
            ?.filter((facility: any) => {
                return facility?.status === false;
            })
            .map((facility: any) => {
                const facilityCode = facility?.facilityCode;
                const facilityStatus = facility?.status;
                const facilityName =
                    facility?.facilityFirstName +
                    " " +
                    facility?.facilityLastName;
                return {
                    facilityCode,
                    facilityName,
                    facilityStatus,
                };
            });
    }, [data]);

    const [selectorDisabled, setSelectorDisabled] = useState(false);

    const [selectValue, setSelectValue] = useState("");

    const { search } = useLocation();
    const disableChildRoutes = useMemo(
        () => (search !== "" ? true : false),
        [search]
    );

    const handleSelect = (e: any) => {
        let selectedFacility: any = facilityData?.filter((fd: any) => {
            return fd?.facilityCode === e.target.value;
        });
        if (userRole === permittedRoles.organizationAdmin) {
            handleSelectedFacility(selectedFacility[0]);
            localStorage.setItem(
                "facilityCode",
                selectedFacility[0]?.facilityCode
            );
            localStorage.setItem(
                "facilityName",
                selectedFacility[0]?.facilityName
            );
            if (e.target.value === "") {
                localStorage.setItem("facilityCode", "");
                localStorage.setItem("facilityName", "");
            }
        }
        setSelectValue(e.target.value);
    };

    useEffect(() => {
        if (userRole === permittedRoles.facilityManager) {
            setSelectorDisabled(
                userRole === permittedRoles.facilityManager ? true : false
            );
        }

        if (userRole === permittedRoles.organizationAdmin) {
            setSelectorDisabled(disableChildRoutes);
        }
    }, [userRole, disableChildRoutes, selectorDisabled]);

    // Check for facility status
    useEffect(() => {
        if (userRole === permittedRoles.facilityManager) {
            const iFacility: any = inactiveFacilities?.filter((fd: any) => {
                const data =
                    fd?.facilityStatus === false &&
                    fd?.facilityCode === facilityCode;
                return data;
            });

            if (iFacility) {
                localStorage.setItem(
                    "facilityStatus",
                    _.toString(iFacility[0]?.facilityStatus)
                );
            }
        }

        setSelectValue(localStorage.getItem("facilityCode") || "");
    }, [inactiveFacilities, userRole, facilityCode, facilityStatus]);

    const redirectToSettingsPage = () => {
        redirectTo("/settings");
    };

    return (
        <div className="d-flex align-items-center">
            <select
                className="form-select border-0 min-w-200px"
                disabled={selectorDisabled}
                style={{
                    cursor:
                        selectorDisabled === true ? "not-allowed" : "pointer",
                    fontSize: "15px",
                    maxHeight: "50px",
                    overflow: "scroll",
                }}
                onChange={(e) => handleSelect(e)}
                value={selectValue}
            >
                <option value={""}>All</option>
                {selectorDisabled && (
                    <option value={"inActive"}>Inactive Facility</option>
                )}
                {facilityData?.map((data: any, idx: number) => {
                    const name = data?.facilityName
                        ? data?.facilityName.charAt(0).toUpperCase() +
                          data?.facilityName.slice(1)
                        : "";
                    return (
                        <React.Fragment key={idx}>
                            <option
                                value={data?.facilityCode}
                                selected={data?.facilityCode === facilityCode}
                            >
                                {name} {/* {data?.facilityName} */}
                            </option>
                        </React.Fragment>
                    );
                })}
            </select>

            <RBACComponent whichRoles={[permittedRoles?.organizationAdmin]}>
                <div className="ms-5">
                    <i
                        onClick={redirectToSettingsPage}
                        title="Go to Settings Page"
                        className="fs-2x fas fa-cog cursor-pointer text-hover-primary"
                    ></i>
                </div>
            </RBACComponent>
        </div>
    );
};

export { Header };
