import { createContext, useContext, useState } from "react";

type FilterRangeContextProviderProps = {
  children: React.ReactNode;
};

type FilterRangeContextType = {
  period: string;
  handlePeriod: (value: any) => void;
};

const FilterRangeContext = createContext({} as FilterRangeContextType);

export const FilterRangeProvider = ({
  children,
}: FilterRangeContextProviderProps) => {
  const [period, setPeriod] = useState<string>("Day");
  // console.log("api call context");

  const handlePeriod = (updatedPeriod: string) => {
    setPeriod(updatedPeriod);
  };

  const values = {
    period,
    handlePeriod,
  };

  return (
    <FilterRangeContext.Provider value={values}>
      {children}
    </FilterRangeContext.Provider>
  );
};

export const useFilterRange = () => {
  return useContext(FilterRangeContext);
};
