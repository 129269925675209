import React from "react";
import ViewStockTable from "./table/CHWStockTable";

type Props = {};

const CHWStockPage = (props: Props) => {
    return (
        <>
            <ViewStockTable />
        </>
    );
};

export default CHWStockPage;
