import { useEffect, useState } from "react";
import { prevMonth } from "../../../../common/utils/datesUtils";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import { getCHWOverviewViaTable } from "../../../../services/performance-analysis/PerformanceAnalysis.hook";
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
const TableViewPage = ({ chwName }: any) => {
  let defaultData = prevMonth && prevMonth[0]?.monthYear;

  // const { selectedFacility } = useNavbar();
  const [period, setPeriod] = useState<string>(defaultData);

  const moduleName = "Performance Monitoring";
  // const { id }: any = useParams();
  const { chwId: id }: any = useSearchParams();
  const handlePeriod = (event: any) => {
    setPeriod(event.target.value);
  };

  let splitMonthAndYear = period?.split(" ");
  let splitMonth = splitMonthAndYear && splitMonthAndYear[0];
  let splitYear = splitMonthAndYear && splitMonthAndYear[1];
  const facilityCode: any = localStorage.getItem("facilityCode");

  const {
    data: tableData,
    isLoading: isTableLoading,
    // isFetching: isTableFetching,
    isError: isTableError,
    error: tableError,
    refetch: tableRefetcher,
  }: any = useGetRequest(
    ["getCHWOverviewViaTable", id],
    () =>
      getCHWOverviewViaTable({
        facilityCode,
        moduleName,
        month: splitMonth,
        year: splitYear,
        chwId: id,
        page: currentPageNumber,
        limit: pageLimit,
      }),
    {
      staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
      keepPreviousData: true,
    }
  );
  // Table View API
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const tableViewData = tableData?.data?.data?.target;

  const totalKPIEntites = tableData?.data?.results;

  const totalKPIPages = Math.ceil(totalKPIEntites / pageLimit);
  const tableErrorMsg = tableError?.response?.data?.message;
  const successMsg = tableData?.data?.data?.message;

  useEffect(() => {
    tableRefetcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, defaultData]);

  return (
    <>
      <div className="card text-dark">
        <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
          <h1 className="text-uppercase">{chwName} Performance</h1>

          <div className="card-toolbar">
            <select
              onChange={handlePeriod}
              className="w-200px text-start form-select form-select-lg form-select-solid"
            >
              {prevMonth?.map((month: any) => {
                return (
                  <>
                    <option
                      selected={period === month?.monthYear}
                      value={month?.monthYear}
                    >
                      {month?.monthAndYear}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </div>

        <div className="card-body py-3">
          <Table>
            <TableHeadRow>
              <TableHead width={120} align="center">
                SL NO
              </TableHead>
              <TableHead width={120}>KPI NAME</TableHead>
              <TableHead width={120} align="center">
                TARGET DISTRIBUTED
              </TableHead>
              <TableHead width={120} align="center">
                TARGET REMAINING
              </TableHead>
              <TableHead width={120} align="center">
                TARGET COMPLETED
              </TableHead>
            </TableHeadRow>

            {tableViewData?.length < 0 ? (
              <tbody
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  left: "400px",
                  top: "200px",
                }}
              >
                <h1>{successMsg}</h1>
              </tbody>
            ) : (
              <TableBodyContainer
                isLoading={isTableLoading}
                isError={isTableError}
                errorMessage={tableErrorMsg}
              >
                {tableViewData?.map((item: any, index: any) => {
                  return (
                    <TableRow collapsible={false}>
                      <TableCell hover align="center">
                        {currentPageNumber &&
                          (currentPageNumber - 1) * pageLimit + index + 1}
                      </TableCell>

                      <TableCell hover>{item?.targetName}</TableCell>

                      <TableCell hover align="center">
                        {item?.target}
                      </TableCell>

                      <TableCell hover align="center">
                        {item?.totalTargetsRemaining}
                      </TableCell>
                      <TableCell hover align="center">
                        {item?.totalTargetsCompleted}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBodyContainer>
            )}
          </Table>

          {/* Pagination */}

          <Pagination
            dataCount={totalKPIEntites}
            handleCallback={(val) => setPageLimit(+val)}
            handleNext={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber + 1
              )
            }
            handlePrev={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber - 1
              )
            }
            nextDisabled={currentPageNumber === totalKPIPages}
            prevDisabled={currentPageNumber === 1}
            pageLimit={pageLimit}
            pageNumber={currentPageNumber}
          />
        </div>
      </div>
    </>
  );
};

export default TableViewPage;
