import { useEffect, useMemo, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import PatientInsights from "./components/PatientInsights";
import PatientHeader from "./components/PatientHeader";
import PatientTaskListTable from "./table/PatientTaskListTable";
import VitalDetails from "./components/VitalDetails";
import AppointmentTable from "./table/AppointmentTable";

import UpdatePatientProfile from "./features/update-profile/UpdatePatientProfile";
import HealthConditionsPage from "./features/health-conditions/HealthConditionsPage";
import AssignedFocusedGroupPage from "./features/assigned-focused-group/AssignedFocusedGroupPage";
import PatientVitals from "./features/assigned-focused-group/table-view/nested-view/PatientVitals";
import EntriesForm from "./features/assigned-focused-group/table-view/nested-view/PatientEntries";
import TabContainer from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import useSearchParams from "../../hooks/useSearchParams";
import useMutateRequest from "../../hooks/getMutation.query";
import { getPatientByUHID } from "../../services/patient/PatientManagement.hook";
import PatientHealthCard from "./features/healthcard/patientHealthCard";

const PatientOverview = () => {
    const { pId: id, tab }: any = useSearchParams();
    const [tabInitials, setTabInitials] = useState("overview");
    const [filterData, setFilterData]: any = useState({
        fromDate: "",
        toDate: "",
    });

    useEffect(() => {
        if (tab) {
            setTabInitials(tab);
        }
    }, [tab, tabInitials]);

    const { data, isLoading, error, mutate }: any = useMutateRequest(
        (data: any) => getPatientByUHID(data)
    );
    const patientDetails = data?.data?.data?.patient;

    const getData = () => {
        try {
            const payload = {
                uhid: id,
            };
            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };
    const [showUpdate, setShowUpdate] = useState(false);
    const handleProfileUpdate = () => {
        setShowUpdate((prev) => !prev);
    };
    useEffect(() => {
        if (id) getData();
    }, [id, showUpdate]);

    // const healthcard = patientDetails?.healthCardNumber?.filter(
    //     (item: any) => item?.status === true
    // );

    // const healthcardValue = useMemo(
    //     () => healthcard && healthcard[0]?.id,
    //     [healthcard]
    // );

    // initial owner
    const healthcardValue = useMemo(
        () =>
            patientDetails?.healthCardNumber &&
            patientDetails?.healthCardNumber[0]?.id,
        [patientDetails?.healthCardNumber]
    );

    // check if checkBeneficiary field
    function checkBeneficiaryStatus(obj: any): boolean {
        if (obj && obj.beneficiary) {
            return obj.beneficiary.status ? true : false;
        } else {
            return false;
        }
    }

    const isBeneficiaryPatient = checkBeneficiaryStatus(patientDetails);
    const updatedHealthCardValue = isBeneficiaryPatient
        ? patientDetails?.beneficiary?.healthCardNumber
        : healthcardValue;
    // console.log("updatedHealthCardValue", updatedHealthCardValue);
    // console.log(
    //     "beneficiary?.healthCardNumber",
    //     patientDetails?.beneficiary?.healthCardNumber
    // );
    // console.log("healthcardValue", healthcardValue);
    return (
        <>
            {isLoading ? (
                <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <>
                    <div>
                        <PatientHeader
                            //  isBeneficiaryPatient={isBeneficiaryPatient}
                            patientDetails={patientDetails}
                            showUpdate={showUpdate}
                            handleProfileUpdate={handleProfileUpdate}
                            setTabInitials={setTabInitials}
                        />
                    </div>
                    {!showUpdate ? (
                        <TabContainer initialValue={tabInitials}>
                            <Tab eventKey="overview" title="OVERVIEW">
                                <PatientInsights
                                    filterData={filterData}
                                    patientDetails={patientDetails}
                                    setFilterData={setFilterData}
                                />
                            </Tab>

                            <Tab eventKey="vitals" title="VITALS">
                                <VitalDetails patientDetails={patientDetails} />
                            </Tab>
                            <Tab eventKey="appointment" title="APPOINTMENT">
                                <AppointmentTable />
                            </Tab>
                            <Tab eventKey="task" title="TASK">
                                <PatientTaskListTable />
                            </Tab>
                            <Tab eventKey="health" title="HEALTH CONDITION">
                                <HealthConditionsPage />
                            </Tab>
                            <Tab
                                eventKey="assigned"
                                title="ASSIGNED FOCUSED GROUPS"
                            >
                                <AssignedFocusedGroupPage />
                            </Tab>
                            <Tab eventKey="healthcard" title="HEALTH CARD">
                                <PatientHealthCard
                                    patientDetails={patientDetails}
                                    // healthCard={healthcardValue}
                                    healthCard={updatedHealthCardValue}
                                    isBeneficiaryPatient={isBeneficiaryPatient}
                                />
                            </Tab>
                        </TabContainer>
                    ) : (
                        <div className="mt-8">
                            <UpdatePatientProfile
                                handleProfileUpdate={handleProfileUpdate}
                                patientDetails={patientDetails}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
export default PatientOverview;
