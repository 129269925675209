import React from "react";

interface ITableErrorComponent {
  errorMessage: string;
}

const TableErrorComponent = ({ errorMessage }: ITableErrorComponent) => {
  return (
    <div className="card d-flex h-450px justify-content-center align-items-center">
      <h1 className="fs-2 fw fw-bolder text-danger">{errorMessage}</h1>
    </div>
  );
};

export default TableErrorComponent;
