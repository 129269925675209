import * as Yup from "yup";
import { KPIMonth } from "../utils/dateUtils";

export const activeCriterias = {
  PATIENT_REGISTRATION: ["Patient Registration", "patientRegistrationDetails"],
  TELECONSULTATION: ["Teleconsultation", "teleconsultationDetails"],
  BLOOD_GLUCOSE: ["Blood Glucose", "bloodGlucoseDetails"],
  BLOOD_PRESSURE: ["Blood Pressure", "bloodPressureDetails"],
  ECG: ["ECG", "ecgDetails"],
  HEIGHT: ["Height", "heightDetails"],
  WEIGHT: ["Weight", "weightDetails"],
  HEMOGLOBIN: ["Hemoglobin", "hemoglobinDetails"],
  PULSE: ["Pulse", "pulseDetails"],
  RESPIRATORY: ["Respiratory", "respiratoryDetails"],
  TEMPERATURE: ["Temperature", "temperatureDetails"],
  OXYGEN_SATURATION: ["Oxygen Saturation", "oxygenSaturationDetails"],
};

export const initalValuesForPerformanceMonitoring: any = {
  patientRegistration: false,
  teleconsultation: false,
  bloodGlucose: false,
  bloodPressure: false,
  ecg: false,
  height: false,
  hemoglobin: false,
  pulse: false,
  respiratory: false,
  temperature: false,
  weight: false,
  oxygenSaturation: false,

  patientRegistrationDetails: KPIMonth(""),
  teleconsultationDetails: KPIMonth(""),
  bloodGlucoseDetails: KPIMonth(""),
  bloodPressureDetails: KPIMonth(""),
  ecgDetails: KPIMonth(""),
  heightDetails: KPIMonth(""),
  weightDetails: KPIMonth(""),
  hemoglobinDetails: KPIMonth(""),
  pulseDetails: KPIMonth(""),
  respiratoryDetails: KPIMonth(""),
  temperatureDetails: KPIMonth(""),
  oxygenSaturationDetails: KPIMonth(""),
};

export const PerformanceMonitoringTypesValidationSchema = Yup.object({
  teleconsultationDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  patientRegistrationDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  bloodGlucoseDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  bloodPressureDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  ecgDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  heightDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  weightDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  hemoglobinDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  pulseDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  respiratoryDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  temperatureDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
  oxygenSaturationDetails: Yup.array()
    .optional()
    .min(1, "You can't leave this blank.")
    .of(
      Yup.object().shape({
        targetName: Yup.number()
          .positive("Need to be number")
          .integer("It should be number")
          .moreThan(0, "Should be greater than one")
          .typeError("The accepted value is only number"),
      })
    ),
});

export let checkIfDisable = (kpiDetails: any) => {
  return kpiDetails?.map((details: any) => {
    return details?.targetName !== "";
  });
};

export let getKPITargets = (targetDetails: any) => {
  return targetDetails
    ?.filter((kpiDetail: any) => {
      return kpiDetail?.targetName !== "";
    })
    .map((kpiName: any) => {
      return {
        target: Number(kpiName?.targetName),
        month: kpiName?.month,
        year: kpiName?.year,
      };
    });
};
