import { useState } from "react";
import SalesOrderExport from "./sales-order/SalesOrderExport";
import SoldItemsExport from "./sold-items/SoldItemsExport";

export default function ExportSelector({ handleModal }: any) {
    const [selectedOption, setSelectedOption] = useState("");
    return (
        <>
            {selectedOption === "" ? (
                <div className="text-center">
                    <h1 className="text-center text-hover-primary">
                        EXPORT OPTIONS
                    </h1>
                    <div className="mt-5 d-flex justify-content-center align-items-center">
                        <button
                            onClick={() => setSelectedOption("sale_order")}
                            className="btn btn-secondary"
                        >
                            sales order info
                        </button>
                        <button
                            onClick={() => setSelectedOption("sold_items")}
                            className="ms-5 btn btn-secondary"
                        >
                            sold items info
                        </button>
                    </div>
                    <button
                        onClick={handleModal}
                        className="w-200px btn btn-danger btn-lg mt-5"
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <div>
                    {selectedOption === "sale_order" ? (
                        <SalesOrderExport onClose={handleModal} />
                    ) : (
                        <SoldItemsExport onClose={handleModal} />
                    )}
                </div>
            )}
        </>
    );
}
