import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useSearchParams = () => {
  const location = useLocation();
  const [paramsObj, setParamsObj] = useState("");
  useEffect(() => {
    const search = location?.search?.substring(1);
    if (search) {
      const obj = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (obj) {
        setParamsObj(obj);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return paramsObj;
};

export default useSearchParams;
