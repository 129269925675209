import * as Yup from "yup";

export const validationSchemaForAddGrn = Yup.object().shape({
    product: Yup.string().required("Please select a product"),
    vendorName: Yup.string().required("Vendor name is required"),
    invoiceNumber: Yup.number().required("Invoice number is required"),
    invoiceDate: Yup.date()
        .max(new Date(), "Invoice date must be before the current date")
        .required("Invoice date is required"),
    batch: Yup.string().required("Batch is required"),
    expiryDate: Yup.date()
        .test(
            "is-more-than-30-days",
            "Expiry date must be more than 30 days from the current date",
            function (value: any) {
                const currentDate = new Date();
                const thirtyDaysFromNow = new Date();
                thirtyDaysFromNow.setDate(currentDate.getDate() + 30);
                return value > thirtyDaysFromNow;
            }
        )
        .required("Expiry date is required"),
    unitsPerStrip: Yup.number().required("Units per strip is required"),
    noOfStrips: Yup.number().required("No. of strips is required"),
    freeStrips: Yup.number(),
    gstTotal: Yup.string().required("GST total is required"),
    pricePerStrip: Yup.number()
        .test(
            "is-less-than-mrp",
            "Price per strip should be less than MRP per strip",
            function (value: any) {
                const mrpPerStrip = this.parent.mrpPerStrip;
                return value < mrpPerStrip;
            }
        )
        .required("Price per strip is required"),
    mrpPerStrip: Yup.number()
        .test(
            "is-more-than-price-per-strip",
            " MRP should be more than Price per strip",
            function (value: any) {
                const pricePerStrip = this.parent.pricePerStrip;
                return value > pricePerStrip;
            }
        )
        .required("MRP per strip is required"),
    discountPercent: Yup.number().test(
        "is-less-than-100",
        "Discount should be less than 100",
        function (value: any) {
            return value < 100;
        }
    ),

    hsnCode: Yup.string(),
    rackNo: Yup.string(),
    boxNo: Yup.string(),
});
