import ClipLoader from "react-spinners/ClipLoader";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

const KPIChart = ({
    isChartLoading,
    isChartFetching,
    isChartError,
    chartErrorMsg,
    customChartViewData,
}: any) => {
    return (
        <>
            {isChartLoading || isChartFetching ? (
                <div className="w-100 h-400px bg-white p-5 d-flex align-items-center justify-content-center">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : isChartError === true ? (
                <div className="w-100 h-400px bg-white p-5 d-flex align-items-center justify-content-center">
                    <h1 className="fw-bolder text-danger">
                        {chartErrorMsg &&
                        chartErrorMsg.toLowerCase() === "missing facilitycode"
                            ? "Please Select a facility"
                            : chartErrorMsg}
                    </h1>
                </div>
            ) : (
                <div className="w-100 h-400px bg-white p-5 d-flex align-items-center justify-content-center">
                    <ResponsiveContainer width={"50%"}>
                        <BarChart
                            barGap={25}
                            barSize={125}
                            width={450}
                            height={300}
                            data={customChartViewData}
                            margin={{
                                top: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="8 8" />
                            <XAxis dataKey="TargetName" />
                            <YAxis dataKey="" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Target" fill="#82ca9d" />
                            <Bar dataKey="Achievment" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )}
        </>
    );
};

export default KPIChart;
