import { Field, ErrorMessage } from "formik";

const Step2 = () => {
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Address Details</h2>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Address Site Name</label>
                <Field
                    data-testid="address-site-name"
                    name="addressSiteName"
                    placeholder="Enter your address name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="addressSiteName" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label">Floor Number</label>
                <Field
                    data-testid="floor-number"
                    name="floorNumber"
                    placeholder="Enter your floor number"
                    className="form-control form-control-lg form-control-solid"
                />

                <div className="text-danger mt-2">
                    <ErrorMessage name="floorNumber" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Street Name</label>

                <Field
                    data-testid="street-name"
                    name="streetName"
                    placeholder="Enter your street name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="streetName" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="d-flex align-items-center form-label">
                    <span className="">Street Number</span>
                </label>

                <Field
                    data-testid="street-number"
                    name="streetNumber"
                    placeholder="Enter your street number"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="streetNumber" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">Town</label>

                <Field
                    data-testid="town"
                    name="town"
                    placeholder="Enter your town name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="town" />
                </div>
            </div>
        </div>
    );
};

export { Step2 };
