import pkg from "../../../../../package.json";

const OrgFooter = () => {
  return (
    <div className="footer p-10 d-flex flex-center fixed w-50 fw-bold fs-6">
      <span className="text-dark ps-0 pe-2 me-15">Version {pkg?.version}</span>

      <div className="text-dark order-2 order-md-1">
        <span className="text-muted fw-bold me-2">&copy;</span>
        <span className="text-gray-800">
          Ikure Techsoft {new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
};

export default OrgFooter;
