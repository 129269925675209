import ClipLoader from "react-spinners/ClipLoader";
import CustomTooltip from "../../components/CustomTooltip";

const PatientCounterPage = ({
    isLoading,
    isFetching,
    totalPatients,
    isMSD,
}: any) => {
    return (
        <div className="p-10 card">
            <div className="d-flex">
                <h1 className="fs-1 text-dark fw-bolder">PATIENT OVERVIEW</h1>
                <CustomTooltip
                    style={{ fontSize: "550px" }}
                    title="Overview of the Patient Module"
                    placement="right"
                >
                    <i className="fs-2 mt-2 fas fa-info-circle ms-2 bg-transparent border-none"></i>
                </CustomTooltip>
            </div>
            <div className="d-flex mt-5">
                <>
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="w-200px border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-primary">
                                {totalPatients ? totalPatients : "No Data"}
                            </h3>
                            <h3>TOTAL PATIENTS</h3>
                        </div>
                    )}
                </>
                {isMSD && (
                    <>
                        {isLoading || isFetching ? (
                            <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-200px h-125px border-gray-500 border-dotted">
                                <ClipLoader size={50} speedMultiplier={0.6} />
                            </div>
                        ) : (
                            <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-200px h-125px border-gray-500 border-dotted">
                                <h3 className="text-success">
                                    {totalPatients ? totalPatients : "No Data"}
                                </h3>
                                <h3>TOTAL MOTHERS</h3>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PatientCounterPage;
