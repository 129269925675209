import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import Modal from "../../../../components/Modal";
import Pagination from "../../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import { getFormsByFocusedGroup } from "../../../../services/focused-group/FocusedGroup.hook";
import InfoModal from "../components/InfoModal";
import {
    Permissions,
    hasAccessToModule,
    hasAccessToSubModule,
} from "../../../../rbac";
import { warnUser } from "../../../../common/utils";
import { Toaster } from "react-hot-toast";
import { useRBAC } from "../../../../context/RBACContext";
import { permittedRoles } from "../../../../common/config/rbac/types/rbac.types";

const FormsTable = ({ formData }: any) => {
    const { fgId: id }: any = useSearchParams();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const facilityCode: any = localStorage.getItem("facilityCode");
    const { userRole } = useRBAC();
    const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
    const { data, isLoading, isFetching, refetch, isError }: any =
        useGetRequest(
            ["getFormsByFocusedGroup", currentPageNumber, id, pageLimit],
            async () =>
                await getFormsByFocusedGroup({
                    focusedGroupId: id,
                    page: currentPageNumber,
                    limit: pageLimit,
                }),
            {
                retry: false,
                staleTime: 100000,
                refetchOnWindowFocus: false,
                enabled: !!id,
            }
        );

    const showTour = data?.data?.data?.focusedGroup?.visits?.every(
        (visit: any) => visit?.isFormAdded === false
    );

    useEffect(() => {
        showTour && setIsModelOpen(!isModelOpen);
        if (id) refetch();
    }, [showTour]);

    const visitsData = data?.data?.data?.focusedGroup?.visits;
    const totalFormTableEntites = data?.data?.results;
    const totalFormTablePages = Math.ceil(totalFormTableEntites / pageLimit);
    // const errorMsg = error?.response?.data?.message;
    const successMsg = data?.data?.data?.message;
    const handleModal = () => {
        setIsModelOpen(!isModelOpen);
    };

    // Role Settings
    const canCreate = hasAccessToSubModule(
        "LIST_FOCUSED_GROUP",
        "LIST_FORMS",
        Permissions.create
    );

    return (
        <>
            {/* Model */}
            <Toaster />
            <Modal open={isModelOpen}>
                <InfoModal
                    onClose={handleModal}
                    styles={{
                        backgroundColor: "white",
                        width: "650px",
                        height: "200px",
                        padding: "20px",
                    }}
                />
            </Modal>
            <div className="card text-dark">
                <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                    <h1>FORM LIST</h1>
                </div>

                <div className="card-body py-3">
                    {/* <div className="table-responsive h-600px"> */}
                    <Table>
                        <TableHeadRow>
                            <TableHead width={120} align="center">
                                SL NO
                            </TableHead>
                            <TableHead width={120} align="center">
                                VISIT NUMBER
                            </TableHead>
                            <TableHead width={120} align="center">
                                ACTION
                            </TableHead>
                        </TableHeadRow>

                        {isLoading || isFetching ? (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    left: "500px",
                                    top: "50px",
                                }}
                            >
                                <ClipLoader size={70} speedMultiplier={0.6} />
                            </tbody>
                        ) : isError === true ? (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "400px",
                                    top: "200px",
                                }}
                            >
                                <h1 className="text-danger">
                                    {isError
                                        ? "No Data Available"
                                        : "Error in Fetching Data"}
                                </h1>
                            </tbody>
                        ) : visitsData?.length < 0 ? (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "400px",
                                    top: "200px",
                                }}
                            >
                                <h1>{successMsg}</h1>
                            </tbody>
                        ) : (
                            <TableBodyContainer isLoading={false}>
                                {visitsData?.map((item: any, index: any) => {
                                    return (
                                        <TableRow collapsible={false}>
                                            <TableCell align="center" hover>
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>

                                            <TableCell align="center" hover>
                                                {item?.visitNumber +
                                                    " " +
                                                    " Visit "}
                                            </TableCell>

                                            <TableCell align="center">
                                                {item?.isFormAdded === true ? (
                                                    <Link
                                                        state={{
                                                            formId: item?.formId
                                                                ?._id,
                                                        }}
                                                        to={{
                                                            pathname: `/focused-group/form-entries`,
                                                            search: `?fgId=${id}`,
                                                        }}
                                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    >
                                                        View Form
                                                    </Link>
                                                ) : userRole ===
                                                  permittedRoles.facilityManager ? (
                                                    <Link
                                                        state={{
                                                            from: "focused-group",
                                                            item,
                                                            focusedGroupName:
                                                                formData?.name,
                                                            focusedGroupId: id,
                                                        }}
                                                        hidden={!canCreate}
                                                        to={{
                                                            pathname:
                                                                "/form-management",
                                                        }}
                                                        className="btn btn-bg-light btn-color-primary btn-sm px-4 me-2"
                                                    >
                                                        Create Form
                                                    </Link>
                                                ) : (
                                                    <div className="btn btn-bg-light btn-color-primary btn-sm px-4 me-2">
                                                        Form not available
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBodyContainer>
                        )}
                    </Table>

                    {/* </div> */}
                    {/* Pagination */}
                    {!isError && (
                        <Pagination
                            dataCount={totalFormTableEntites}
                            handleCallback={(val: number | string) => {
                                setPageLimit(+val);
                            }}
                            handleNext={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setCurrentPageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={
                                currentPageNumber === totalFormTablePages
                            }
                            pageNumber={currentPageNumber}
                            pageLimit={pageLimit}
                            prevDisabled={currentPageNumber === 1}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default FormsTable;
