import $axiosProtected from "../../common/utils/axiosUtils";

// Get List of all Modules Details
export const getAllModules = async () => {
    return await $axiosProtected().get(
        `/organization/facility/modules/get/active/all`
    );
};

// Enable Modules Status
export const enableModulesStatus = async (payload: any) => {
    return await $axiosProtected().post(
        `/organization/facility/modules/status`,
        payload
    );
};

// Set CHW Targets
export const setCHWTargets = async (payload: any) => {
    return await $axiosProtected().post(
        `/organization/facility/modules/target/store`,
        payload
    );
};

// Get Modules by Facility
export const getAllModulesByFacility = async (payload: any) => {
    return await $axiosProtected().get(
        `/organization/facility?facilityCode=${payload.facilityCode}`
    );
};
