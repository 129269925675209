import * as Yup from "yup";

export type dataProps = {
  paginationInfo?: {
    count?: number;
    pages?: number;
    next?: string | null;
    prev?: string | null;
  };
  isLoading?: boolean;
  isFetching?: boolean;
  isTaskError: boolean;
  errorMsg?: any;
  successMsg?: any;
  currentPageNumber?: any;
  handlePageNumber?: any;
  totalTaskListPages: number;
  totalTaskListEntites: number;
  pageLimit: any;
  setFilterData: any;
  bodyProps: {
    taskName: string;
    taskType: string;
    chwData: any;
    patient: any;
    taskDate: string;
    taskStatus: string;
    _id: string;
  }[];
};

export type selectorValues = {
  chwId: string;
  status: string;
  fromDate: string;
  toDate: string;
};

export const initalValues: selectorValues = {
  chwId: "",
  status: "",
  fromDate: "",
  toDate: "",
};

export const selectorValidationSchema = Yup.object().shape({
  // chwId: Yup.string().required("chwId"),
  // status: Yup.string().required("Status"),
  // fromDate: Yup.string().required("From Date"),
  // toDate: Yup.string().required("To Date"),
});
