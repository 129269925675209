import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    toDate: Yup.string().label("To Date"),
    fromDate: Yup.string().when("toDate", {
        is: (toDate: any) => {
            return !!toDate;
        },
        then: Yup.string().required("From Date is required"),
    }),
    transactionType: Yup.string().oneOf(
        ["", "Cash", "UPI"],
        "Transaction Type must be either all, cash, or UPI"
    ),
    receivedAt: Yup.string().oneOf(
        ["", "Clinic", "Camp", "Doorstep"],
        "Received At must be either all, clinic, camp, or doorstep"
    ),
});

export default validationSchema;
