/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from "react";
import CustomTooltip from "../../../../components/CustomTooltip";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const CardView = ({ vitalName, icons, vitalCollectionId, vitalValue }: any) => {
  // console.log(vitalCollectionId);
  const [hoverEffect, setHoverEffect] = useState(false);
  return (
    <div
      onMouseEnter={() => setHoverEffect(!hoverEffect)}
      onMouseLeave={() => setHoverEffect(!hoverEffect)}
      style={{
        border: hoverEffect === true ? "2px solid blue" : "0px",
      }}
      className="card p-5 w-375px h-300px"
    >
      <div className="pt-9">
        <div className="d-flex w-100">
          <div className="w-50px">
            <img src={toAbsoluteUrl(icons)} alt="card" className="p-3" />
          </div>
          <div className="fs-3 fw-bolder text-dark">
            {vitalName}

            <p className="fw-bold fs-5">{vitalValue}</p>
          </div>
        </div>
      </div>
      {vitalCollectionId != "" ? (
        <>
          <div className="d-flex mb-5">
            <>
              <div
                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2"
                style={{ height: "10%" }}
              >
                <div className="fs-6 text-gray-800 fw-bolder">
                  <span className="fw-bold text-gray-400">Date : </span>
                  {vitalCollectionId?.vitalCollectedDate
                    ? vitalCollectionId?.vitalCollectedDate
                    : "N/A"}
                </div>
                <div className="fs-6 text-gray-800 fw-bolder">
                  <span className="fw-bold text-gray-400">Time : </span>
                  {vitalCollectionId?.vitalCollectedTime
                    ? vitalCollectionId?.vitalCollectedTime
                    : "N/A"}
                </div>
              </div>

              <div
                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2"
                style={{ width: "50%" }}
              >
                <div className="fs-6 text-gray-800 fw-bolder justify-content-between">
                  <span className="fw-bold text-gray-400">Type : </span>
                  {vitalCollectionId?.ReadingMethod
                    ? vitalCollectionId?.ReadingMethod
                    : "N/A"}
                </div>
                <div className="fs-6 text-gray-800 fw-bolder justify-content-between">
                  <span className="fw-bold text-gray-400">Device : </span>
                  <CustomTooltip
                    title={vitalCollectionId?.deviceName}
                    placement="top"
                    // arrow
                  >
                    <span className="border-none">
                      {vitalCollectionId?.deviceName
                        ? vitalCollectionId?.deviceName?.slice(0, 8) +
                          (vitalCollectionId?.deviceName?.length > 8
                            ? "..."
                            : "")
                        : "N/A"}
                    </span>
                  </CustomTooltip>
                </div>
              </div>
            </>
          </div>
          <p className="fs-6 text-gray-800 fw-bolder mt-1">
            <>
              <span className="fw-bold text-gray-400">Comment : </span>
              <CustomTooltip title={vitalCollectionId?.comment} placement="top">
                <span className="border-none">
                  {vitalCollectionId?.comment
                    ? vitalCollectionId?.comment?.slice(0, 25) +
                      (vitalCollectionId?.comment?.length > 25 ? "..." : "")
                    : "N/A"}
                </span>
              </CustomTooltip>
            </>
          </p>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <h1>No Data</h1>
        </div>
      )}
    </div>
  );
};

export default CardView;
