import $axiosProtected from "../../common/utils/axiosUtils";

// Get list of all doctor details
export const getAllDoctorsWithPagination = async (payload: any) => {
    return await $axiosProtected().get(
        `/doctor/all?page=${payload.page}&limit=${payload.limit}`
    );
};

export const getAllDoctors = async () => {
    return await $axiosProtected().get(`/doctor/all`);
};
