import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import Analytics from "./Analytics";
import PerformanceMonitoring from "./PerformanceMonitoring";
import Modal from "../../../../components/Modal";

import {
    CustomModulesInitalValues,
    AnalyticsTypesValidationSchema,
} from "../models/CustomModulesModel";
import useGetRequest from "../../../../hooks/getRequest.query";
import { useNavbar } from "../../../../context/NavbarContext";
import useMutateRequest from "../../../../hooks/getMutation.query";
import {
    getAllModules,
    getAllModulesByFacility,
    enableModulesStatus,
} from "../../../../services/settings/Settings.hook";

const activeModules = {
    PERFORMANCE_MONITORING: "Performance Monitoring",
    ANALYTICS: "Analytics",
};

const isEqual = (first: any, second: any) => {
    return JSON.stringify(first) === JSON.stringify(second);
};

const CustomModules: React.FC = () => {
    // API
    const { selectedFacility } = useNavbar();
    const facilityCode = localStorage.getItem("facilityCode");

    const {
        data: moduleData,
        isLoading: moduleLoader,
        isFetching,
        refetch,
        isError: isAllModuleError,
        error: getAllModulesError,
    }: any = useGetRequest("getAllModules", async () => await getAllModules(), {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
    });

    const {
        data: facilityModule,
        isLoading: facilityLoader,
        isFetching: facilityFetcher,
        refetch: facilityRefetch,
        isError: isModuleError,
        error: ErrorMsg,
    }: any = useGetRequest(
        "getAllModulesByFacility",
        async (data: any) =>
            await getAllModulesByFacility({
                facilityCode: facilityCode,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const errorModuleMsg = ErrorMsg?.response?.data?.message;
    const errorAllModuleMsg = getAllModulesError?.response?.data?.message;

    const activeModulesForFacility =
        facilityModule?.data?.data?.facility?.modules;
    const modulesList = moduleData?.data?.data?.modules;

    const customModulesList = moduleData?.data?.data?.modules?.map(
        (module: any) => {
            return module?._id;
        }
    );

    const customActiveModulesInFacility =
        facilityModule &&
        facilityModule?.data?.data?.facility?.modules?.map((module: any) => {
            return module?.moduleId;
        });

    const result = customActiveModulesInFacility?.map(
        (customActiveModule: any, index: any) => {
            return (
                customModulesList &&
                customModulesList?.includes(
                    customActiveModule && customActiveModule
                )
            );
        }
    );

    const perfMonit = moduleData?.data?.data?.modules
        ?.filter(
            (modules: any) =>
                modules?.name === activeModules?.PERFORMANCE_MONITORING
        )
        ?.map((x: any) => {
            return x?.criterias;
        })
        ?.flat();

    const perfMonitId = moduleData?.data?.data?.modules
        ?.filter(
            (modules: any) =>
                modules?.name === activeModules?.PERFORMANCE_MONITORING
        )
        ?.map((x: any) => {
            return x?._id;
        })[0];

    const AnalyticsId = moduleData?.data?.data?.modules
        ?.filter((modules: any) => modules?.name === activeModules?.ANALYTICS)
        ?.map((x: any) => {
            return x?._id;
        })[0];

    let patientEnabled = customActiveModulesInFacility?.includes(perfMonitId);
    let analyticsEnabled = customActiveModulesInFacility?.includes(AnalyticsId);

    let showPatientConfigBtn =
        activeModulesForFacility?.length !== 0 && patientEnabled === true;

    let showAnalyticsConfigBtn =
        activeModulesForFacility?.length !== 0 && analyticsEnabled === true;

    // let enableFlag = analyticsEnabled === true ? "enable" : "disable";

    // other
    const [open, setOpen] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const { isLoading, isError, data, error, mutate, isSuccess }: any =
        useMutateRequest((data: any) => enableModulesStatus(data));

    const errorMsg = error?.response?.data?.message;

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Successfully enabled the KPI !!
                    <button
                        className="ms-5"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {/* {errorMsg} */}
                    Failed to enable the KPI !!
                    <button
                        className="ms-5"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [openPerformanceMonitoringModal, setOpenPerformanceMonitoringModal] =
        useState(false);

    const clickPerformanceMonitoringModal = () => {
        setOpenPerformanceMonitoringModal(!openPerformanceMonitoringModal);
    };
    const click = () => {
        setModalOpen(!modalOpen);
    };

    const getModuleId = (idName: any) => {
        if (idName?.includes(activeModules?.PERFORMANCE_MONITORING) === true) {
            return modulesList
                ?.filter(
                    (modules: any) =>
                        modules?.name === activeModules?.PERFORMANCE_MONITORING
                )
                ?.map((x: any) => {
                    return x?._id;
                })[0];
        } else {
            return modulesList
                ?.filter(
                    (modules: any) => modules?.name === activeModules?.ANALYTICS
                )
                ?.map((x: any) => {
                    return x?._id;
                })[0];
        }
    };
    const getModuleStatus = (id: any) => {
        activeModulesForFacility?.map((modules: any) => {
            return modules?.moduleId === id;
        });
    };

    const onSubmit = (values: any) => {
        const payload = {
            id: getModuleId(values?.analyticsModules),
            status: "enable",
            facilityCode,
        };

        try {
            mutate(payload, {
                onSuccess(data: any) {
                    setSpinner(true);
                    setOpen(true);

                    setTimeout(() => {
                        setSpinner(false);
                        // redirectTo.push("/");
                        refetch();
                        window.location.reload();
                    }, 3000);
                },
                onError(error: any) {
                    setSpinner(true);
                    setOpen(true);

                    setTimeout(() => {
                        setSpinner(false);
                    }, 3000);
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        refetch();
        facilityRefetch();
    }, [facilityCode, selectedFacility]);

    return (
        <>
            <Modal
                open={openPerformanceMonitoringModal}
                overlayStyles={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                styles={{
                    width: "850px",
                }}
            >
                <PerformanceMonitoring
                    onClose={clickPerformanceMonitoringModal}
                    criterias={perfMonit}
                    perfMonitId={perfMonitId}
                    styles={{
                        backgroundColor: "white",
                        width: "",
                        height: "",
                        msgHeight: "200px",
                        padding: "50px",
                    }}
                />
            </Modal>
            <Modal
                open={modalOpen}
                overlayStyles={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                styles={{
                    width: "700px",
                }}
            >
                <Analytics
                    onClose={click}
                    styles={{
                        backgroundColor: "white",
                        width: "",
                        height: "",
                        msgHeight: "200px",
                        padding: "50px",
                    }}
                />
            </Modal>
            <Toaster />
            {isSuccess && open === true && successToastMsg()}
            {isError && open === true && errorToastMsg()}
            <div className="card mb-5 mb-xl-10">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                >
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Modules</h3>
                    </div>
                </div>

                <div className="collapse show">
                    <div className="card-body border-top p-9">
                        {moduleLoader ||
                        isFetching ||
                        isLoading ||
                        spinner === true ? (
                            <div className="card w-100 h-200px d-flex flex-column align-items-center justify-content-center p-10">
                                <ClipLoader size={50} speedMultiplier={0.6} />
                            </div>
                        ) : isModuleError === true ||
                          isAllModuleError === true ? (
                            <div className="card w-100 h-200px d-flex flex-column align-items-center justify-content-center p-10">
                                <h1 className="text-danger">
                                    {errorAllModuleMsg
                                        ? errorAllModuleMsg
                                        : facilityCode
                                        ? "Something went wrong"
                                        : "Please select a facility"}
                                </h1>
                            </div>
                        ) : (
                            <>
                                <Formik
                                    initialValues={CustomModulesInitalValues}
                                    validationSchema={
                                        AnalyticsTypesValidationSchema
                                    }
                                    onSubmit={onSubmit}
                                >
                                    {(formProps) => {
                                        return (
                                            <Form className="d-flex justify-content-between">
                                                <div className="w-100">
                                                    {modulesList?.map(
                                                        (
                                                            module: any,
                                                            index: any
                                                        ) => {
                                                            return (
                                                                <div className="d-flex flex-stack mb-5">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="fs-5 text-dark text-hover-primary fw-bolder">
                                                                            {
                                                                                module?.name
                                                                            }
                                                                        </span>
                                                                    </div>

                                                                    <div
                                                                        role="group"
                                                                        className="fv-row w-140px d-flex form-check form-switch form-check-solid form-check-custom"
                                                                    >
                                                                        {/* Patient Config Btn */}

                                                                        {module?.name ===
                                                                            activeModules?.PERFORMANCE_MONITORING && (
                                                                            <>
                                                                                {showPatientConfigBtn ===
                                                                                true ? (
                                                                                    <div>
                                                                                        <button
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                clickPerformanceMonitoringModal();
                                                                                            }}
                                                                                            className="ms-5 btn btn-primary"
                                                                                        >
                                                                                            Configure
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            name={`analyticsModules`}
                                                                                            id={`analyticsModules`}
                                                                                            value={
                                                                                                module?.name
                                                                                            }
                                                                                            className="ms-5 form-check-input mb-5 w-50px h-30px"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {/* Analytics Config Btn */}

                                                                        {module?.name ===
                                                                            activeModules?.ANALYTICS && (
                                                                            <>
                                                                                {showAnalyticsConfigBtn ===
                                                                                true ? (
                                                                                    <div>
                                                                                        <button
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                click();
                                                                                            }}
                                                                                            className="ms-5 btn btn-primary"
                                                                                        >
                                                                                            Configure
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            name={`analyticsModules`}
                                                                                            id={`analyticsModules`}
                                                                                            value={
                                                                                                module?.name
                                                                                            }
                                                                                            className="ms-5 form-check-input mb-5 w-50px h-30px"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-md btn-primary w-100px"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomModules;
