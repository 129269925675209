export default function FilterTile(props: any) {
    const { filterData = {}, clearAll, keyNames } = props;
    const showText = Object?.values(filterData)?.some((value) => value !== "");

    return (
        <div className="p-2 d-flex w-full justify-content-end align-items-center">
            {showText && (
                <div className="align-self-center">
                    <h4>Selected filters</h4>
                </div>
            )}
            {createFilterTiles({ filterData, keyNames })}
            {showText && (
                <span
                    onClick={clearAll}
                    className={`ms-5 align-self-center text-hover-danger cursor-pointer underline-hover`}
                >
                    clear all
                </span>
            )}
        </div>
    );
}

function createFilterTiles(props: any) {
    const { filterData, keyNames } = props;
    return Object.keys(filterData).map((key) => {
        const value = filterData[key];
        if (value !== "") {
            const displayName = keyNames && keyNames[key] ? keyNames[key] : key; // Use custom name if provided
            return (
                <div
                    className="ms-5 border border-dark rounded-pill p-2"
                    key={key}
                >
                    <span className={`text-center text-capitalize`}>
                        {displayName}
                        {/* :{" "} */}
                    </span>
                    {/* <span className={`badge badge-light-success text-center `}>
                        {value}
                    </span> */}
                </div>
            );
        }
        return null; // Added to handle the case where value is empty
    });
}
