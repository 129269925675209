import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface Props {
    label: string;
    name: string;
    onChange: any;
    required?: boolean;
    optionsArr: any[];
    className?: string;
    value: { label: string; year: string };
}

export default function AutoCompleteInput(props: Props) {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.optionsArr}
            // onChange={props.onChange}
            className={props.className}
            // value={props.value}
            {...props}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={
                        <>
                            {props.label}&nbsp;
                            {props.required && (
                                <span className="text-danger font-weight-bold">
                                    *
                                </span>
                            )}
                        </>
                    }
                    variant="outlined"
                    color="info"
                    name={props.name}
                />
            )}
        />
    );
}
