import moment from "moment";

function daysInMonth(month: any, year: any) {
  return new Date(year, month, 0).getDate();
}

export let month: any = [];
let monthsRequired = 9;
let currentYear = new Date().getFullYear();

for (let i = 0; i <= monthsRequired; i++) {
  month.push({
    format: moment().add(i, "months").format("MMM YY"),
    daysInMonth: daysInMonth(
      moment().add(i, "months").format("M"),
      currentYear
    ),
    month: moment().add(i, "months").format("MMMM"),
    year: moment().add(i, "months").format("YYYY"),
    monthAndYear: moment().add(i, "months").format("MMMM ( YYYY )"),
    monthYear: moment().add(i, "months").format("MMMM YYYY"),
  });
}

export let KPIMonth = (targetName: any) => {
  return month?.map((mName: any, index: any) => {
    return {
      targetName,
      monthNameAndCurrentYear: mName?.format,
      daysInMonth: mName?.daysInMonth,
      month: mName?.month,
      year: mName?.year,
    };
  });
};
