export type ModuleType =
  | "FocusedGroup"
  | "Forms"
  | "Task"
  | "Organization"
  | "Report"
  | "CMS"
  | "Patient"
  | "OrganizationAdmin"
  | "HealthCard"
  | "Doctor"
  | "Onboarding"
  | "Project"
  | "Consent"
  | "Feedback"
  | "FacilityManager"
  | "CHWManagement"
  | "Consultation"
  | "Vitals"
  | "Facility";

export type SubModuleType = "chwAttendence" | "chwProfile" | "chwOnboarding";

export type ModuleScopesType = "Create" | "Read" | "Update" | "Delete";

export const ModuleScopes: ModuleScopesType[] = [
  "Create",
  "Read",
  "Update",
  "Delete",
];

export const permittedRoles = {
  chw: "CHW",
  doctor: "DOCTOR",
  facilityManager: "FACILITY_MANAGER",
  organizationAdmin: "ORGANIZATION_ADMIN",
  patient: "PATIENT",
};

export const ROLES: ROLESType[] = [
  "CHW",
  "DOCTOR",
  "FACILITY_MANAGER",
  "ORGANIZATION_ADMIN",
  "PATIENT",
];

export const allRoles = ROLES;

export type ROLESType =
  | "CHW"
  | "DOCTOR"
  | "FACILITY_MANAGER"
  | "ORGANIZATION_ADMIN"
  | "PATIENT";

// type ModuleType = {
//   FocusedGroup: {};
//   Forms: {};
//   Task: {};
//   Organization: {};
//   Report: {};
//   CMS: {};
//   Patient: {};
//   OrganizationAdmin: {};
//   HealthCard: {};
//   Doctor: {};
//   Onboarding: {};
//   Project: {};
//   Consent: {};
//   Feedback: {};
//   FacilityManager: {};
//   CHWManagement: {};
//   Consultation: {};
//   Vitals: {};
//   Facility: {};
// };
