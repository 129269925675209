// Not in use
import { useEffect } from "react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import growthbook from "./growthBookSetup";

const { REACT_APP_GB_API_KEY, REACT_APP_GB_BASE_URL } = process.env;

const endpoint = `${REACT_APP_GB_BASE_URL}/api/features/${REACT_APP_GB_API_KEY}`;

/**
 * @description Note do not use this Direct API Growthbook Wrapper
 * @deprecated since version 0.2.5
 * @copyright Ikure Pvt 2023
 */
export default function GrowthBookAPI({
    children,
}: {
    children: React.ReactNode;
}) {
    useEffect(() => {
        fetch(endpoint)
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                growthbook.setFeatures(json.features);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    // console.log(growthbook.getAllResults());

    if (children) {
        console.warn(
            "GrowthBookAPI is depricated, Please use GrowthBookWrapper"
        );
    }
    return (
        <GrowthBookProvider growthbook={growthbook}>
            {children}
        </GrowthBookProvider>
    );
}
