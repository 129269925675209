import React, { useRef, useState } from "react";
// import { TextArea } from "../../../../../components/Inputs";
import { IProductsArr } from "..";
import {
    aggregateDiscount,
    // aggregateGrossAmt,
    aggregateTax,
    aggregateTotal,
    calcPurchaseAmount,
} from "./calcFunction";
import useMutateRequest from "../../../../../hooks/getMutation.query";
import { createGrn } from "../../../../../services/supply-chain/grn/modifyGrn.services";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
type Props = {
    productsArr: IProductsArr;
    radioValue: string;
    inputVal: string;
    amtInputVal: string;
    setRadioValue: Function;
    setInputVal: Function;
    setAmtInputVal: Function;
    apiProductArr: any;
    invoiceDetails: {
        invoiceDate: string;
        invoiceNumber: string;
        vendorId: string;
    };
};

const FinalBillSummary = ({
    productsArr,
    amtInputVal,
    inputVal,
    radioValue,
    setAmtInputVal,
    setInputVal,
    apiProductArr,
    setRadioValue,
    invoiceDetails,
}: Props) => {
    const [doc, setDoc] = useState<Blob | string>("");

    const [docName, setDocName] = useState("");
    const fileRef = useRef<HTMLInputElement>(null);
    const [searchParams] = useSearchParams();
    const purchaseOrderIdForApi = searchParams.get("purchaseOrderId") as string;
    const orderId = searchParams.get("orderId") as string;
    const facilityCode = localStorage.getItem("facilityCode") as string;
    const { isLoading, mutate } = useMutateRequest((data: any) =>
        createGrn(data)
    );

    const navigate = useNavigate();

    const addGrn = () => {
        const formData = new FormData();
        formData.append("purchaseOrderId", purchaseOrderIdForApi);
        formData.append("status", "Active");
        formData.append("facilityCode", facilityCode);
        formData.append("vendorId", invoiceDetails.vendorId);
        formData.append("products", JSON.stringify(apiProductArr));
        formData.append("invoiceFile", doc);
        formData.append("invoiceNumber", invoiceDetails.invoiceNumber);
        formData.append(
            "invoiceDate",
            dayjs(invoiceDetails.invoiceDate).format("YYYY-MM-DD")
        );

        mutate(formData, {
            onSuccess(data: any) {
                toast.success(
                    (t) => (
                        <span
                            data-testid="success-msg"
                            className="p-2 fs-4 d-flex align-items-center justify-content-center "
                        >
                            {/* {successMsg} */}
                            Successfully created GRN
                            <button
                                className="ms-15"
                                style={{
                                    backgroundColor: "transparent",
                                    border: "0",
                                }}
                                onClick={() => toast.dismiss(t.id)}
                            >
                                <i className="fs-4 fas fa-times text-hover-primary"></i>
                            </button>
                        </span>
                    ),
                    {
                        duration: 5000,
                        position: "top-right",
                        id: "success-toast",
                    }
                );
                setTimeout(() => {
                    navigate(
                        `/purchase-order/details?purchaseOrderId=${orderId}&orderId=${purchaseOrderIdForApi}`
                    );
                }, 1500);
            },
            onError(error: any) {
                toast.error(
                    (t) => (
                        <span
                            data-testid="error-msg"
                            className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                        >
                            {error?.response?.data?.message}
                            <button
                                className="ms-15"
                                style={{
                                    backgroundColor: "transparent",
                                    border: "0",
                                }}
                                onClick={() => toast.dismiss(t.id)}
                            >
                                <i className="fs-4 fas fa-times text-hover-primary"></i>
                            </button>
                        </span>
                    ),
                    {
                        duration: 5000,
                        position: "top-right",
                        id: "error-toast",
                    }
                );
            },
        });
    };

    return (
        <div className="row">
            <Toaster />
            <div className="col-3">
                {/* <TextArea /> */}
                <div>
                    <input
                        data-testid="photo"
                        ref={fileRef}
                        hidden
                        type="file"
                        className="form-control form-control-lg  form-control-solid"
                        accept="application/*"
                        onChange={(event: any) => {
                            const file = event?.target?.files[0];

                            setDocName(event?.target?.files[0].name);
                            setDoc(file);
                        }}
                    />

                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            fileRef?.current?.click();
                        }}
                        className="btn btn-md btn-primary"
                        style={{ marginRight: "1rem" }}
                    >
                        <i className="bi bi-upload"></i>
                        Upload Invoice
                    </button>
                    {docName !== "" && <p>File: {docName}</p>}
                </div>
            </div>
            <div className="col-4 d-flex justify-content-center">
                {/* <div>
                    <button
                        className={`btn ${radioValue === "1" && "btn-success"}`}
                        onClick={() => setRadioValue("1")}
                        data-testid="paid-btn"
                    >
                        Paid
                    </button>
                </div>
                <div>
                    <button
                        className={`btn ${radioValue === "2" && "btn-success"}`}
                        onClick={() => setRadioValue("2")}
                        data-testid="unpaid-btn"
                    >
                        UnPaid
                    </button>
                </div> */}
            </div>
            <div className="col-sm">
                <table
                    className="table table-bordered mb-4"
                    style={{ borderBottom: "1px solid #eff2f5" }}
                >
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                Total
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {aggregateTotal(productsArr)}
                            </td>
                        </tr>
                        {/* <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                }}
                            >
                                Discount
                            </td>
                            <td className="border p-2">
                                 {" "}
                                <div className="row">
                                    <div className="col-sm d-flex align-items-center ">
                                        <div className="d-flex">
                                            <input
                                                type="radio"
                                                id="html"
                                                name="fav_language"
                                                value={"percent"}
                                                checked={
                                                    radioValue === "percent"
                                                }
                                                onClick={(e: any) => {
                                                    setRadioValue(
                                                        e.target.value
                                                    );
                                                }}
                                            />{" "}
                                            &nbsp;
                                            <label htmlFor="html">%</label>{" "}
                                            &nbsp;
                                        </div>
                                        <TextInput
                                            type="number"
                                            value={inputVal}
                                            onChange={(e: any) => {
                                                setInputVal(e.target.value);
                                                setAmtInputVal(
                                                    (
                                                        (+aggregateGrossAmt(
                                                            productsArr
                                                        ) *
                                                            +e.target.value) /
                                                        100
                                                    ).toString()
                                                );
                                            }}
                                            disabled={radioValue === "rs"}
                                            name="percent"
                                        />
                                    </div>
                                    <div className="col-sm d-flex align-items-center">
                                        <div className="d-flex">
                                            <input
                                                type="radio"
                                                id="html"
                                                name="fav_language"
                                                value={"rs"}
                                                checked={radioValue === "rs"}
                                                onClick={(e: any) => {
                                                    setRadioValue(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            &nbsp;
                                            <label htmlFor="html">
                                                Rs
                                            </label>{" "}
                                            &nbsp;
                                        </div>
                                        <TextInput
                                            type="number"
                                            disabled={radioValue === "percent"}
                                            value={amtInputVal}
                                            onChange={(e: any) => {
                                                setAmtInputVal(e.target.value);
                                                const percent = (
                                                    (+e.target.value * 100) /
                                                    +aggregateGrossAmt(
                                                        productsArr
                                                    )
                                                ).toFixed(2);
                                                setInputVal(percent);
                                            }}
                                            name="rupees"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr> */}
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                OverAll Disc Value
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {aggregateDiscount(productsArr)}
                            </td>
                        </tr>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                <p className="text font-weight-bold">
                                    Total Tax (GST)
                                </p>
                                <p className="text font-weight-bold">IGST</p>
                                <p className="text font-weight-bold">CGST</p>
                                <p className="text font-weight-bold">
                                    SGST/UTGST
                                </p>
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                <p className="text font-weight-bold">
                                    {aggregateTax(productsArr)}
                                </p>
                                <p className="text font-weight-bold">0.00</p>
                                <p className="text font-weight-bold">
                                    {(+aggregateTax(productsArr) / 2).toFixed(
                                        2
                                    )}
                                </p>
                                <p className="text font-weight-bold">
                                    {(+aggregateTax(productsArr) / 2).toFixed(
                                        2
                                    )}
                                </p>
                            </td>
                        </tr>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                Purchase Amount
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {calcPurchaseAmount(productsArr)}
                            </td>
                        </tr>
                        <tr className="border">
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ textAlign: "right" }}
                            >
                                Round Off
                            </td>
                            <td
                                className="border p-2"
                                style={{ textAlign: "right" }}
                            >
                                {(
                                    Math.round(
                                        +calcPurchaseAmount(productsArr)
                                    ) - +calcPurchaseAmount(productsArr)
                                ).toFixed(2)}
                            </td>
                        </tr>
                        <tr className="border" style={{ textAlign: "right" }}>
                            <td
                                className="border p-2 font-weight-bold"
                                style={{ fontWeight: "bold" }}
                            >
                                Net Value
                            </td>
                            <td
                                className="border p-2"
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                }}
                            >
                                {Math.round(+calcPurchaseAmount(productsArr))}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ textAlign: "right" }}>
                    <button
                        className="btn btn-light"
                        style={{ marginRight: "1rem" }}
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button>

                    <button
                        className="btn btn-primary"
                        onClick={addGrn}
                        disabled={isLoading || !doc}
                    >
                        Add GRN
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalBillSummary;
