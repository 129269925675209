import $axiosProtected from "../../../common/utils/axiosUtils";

// Get GRN by Purchase Id
export const getGRNs = async ({
    active,
    facilityCode,
    page,
    limit,
    fromDate,
    toDate,
    vendorId,
}: {
    active: string;
    facilityCode: string;
    page: number;
    limit: number;
    fromDate: any;
    toDate: any;
    vendorId: string;
}) => {
    return await $axiosProtected().get(
        `/inventory/grn/get/all?active=${active}&page=${page}&limit=${limit}&facilityCode=${facilityCode}&fromDate=${fromDate}&toDate=${toDate}&vendorId=${vendorId}`
    );
};
