import $axiosProtected from "../../common/utils/axiosUtils";

// Get Targets By KPI
export const getKPITargetsForMonth = async (payload: any) => {
    return await $axiosProtected().get(
        `/organization/facility/modules/target/get?criteriaId=${payload.criteriaId}&month=${payload.month}&year=${payload.year}&facilityCode=${payload.facilityCode}`
    );
};

// distribute Targets To CHW ( Manually or Automatic )
export const distributeTargetsToCHW = async (payload: any) => {
    return await $axiosProtected().post(
        `/organization/facility/modules/target/distribute`,
        payload
    );
};

// Get Modules by Facility
export const getKPIByModules = async (payload: any) => {
    return await $axiosProtected().get(
        `/organization/facility/modules/get?name=${payload.name}`
    );
};
