import { useState } from "react";
import { Switch } from "@mui/material";
import { ErrorMessage, Field, FieldArray } from "formik";

import { Draggable } from "react-beautiful-dnd";
import { KTSVG } from "../../../_metronic/helpers";

const Questions = ({
  sectionIndex,
  index,
  values,
  push,
  remove,
  questionArray,
  setFieldValue,
  sections,
}: any) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  const [clickEffect, setClickEffect] = useState(false);

  return (
    <>
      <Draggable key={index} draggableId={"drag-" + index} index={index}>
        {(provided, snapshot) => (
          <>
            <div
              className="d-flex w-100"
              style={{
                ...provided.draggableProps.style,
                boxShadow: snapshot.isDragging ? "0 0 5.4rem #f33d3d" : "none",
                backgroundColor: snapshot.isDragging ? "red" : "none",
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              // {...provided.dragHandleProps}
            >
              <div
                //  style={{
                //   backgroundColor: "#F0F0F0",
                // }}
                onFocus={() => setClickEffect(!clickEffect)}
                onBlur={() => setClickEffect(!clickEffect)}
                onMouseEnter={() => setHoverEffect(!hoverEffect)}
                onMouseLeave={() => setHoverEffect(!hoverEffect)}
                style={{
                  // margin: "10px",
                  border:
                    clickEffect === false && hoverEffect
                      ? "2px solid blue"
                      : "0px",
                  boxShadow: clickEffect ? "0 0 .4rem #3b3838" : "none",
                }}
                // key={sectionIndex}
                className="card w-100 me-15 mb-5 p-5 d-flex flex-column position-relative"
              >
                <div className="d-flex w-100">
                  <div
                    className={`fs-2 form-label mb-5 ${
                      values.sections[`${sectionIndex}`].questions[`${index}`]
                        .questionRequired === true
                        ? "required"
                        : null
                    }`}
                  >
                    Question {index + 1}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      // ...provided.draggableProps.style,
                    }}
                    {...provided.dragHandleProps}
                  >
                    <i
                      style={{
                        position: "absolute",
                        left: "400px",
                        cursor: "move",
                        // ...provided.draggableProps.style,
                      }}
                      // className="fs-2 fas fa-grip-horizontal text-muted"
                      className="fs-2 fas fa-grip-lines text-muted"
                    ></i>
                  </div>
                </div>
                <div className="d-flex fv-row h-60px">
                  {/* Question Name */}
                  <div className="fv-row col-lg-8">
                    <Field
                      name={`sections[${sectionIndex}].questions[${index}].questionName`}
                      placeholder="Enter the question"
                      className="form-control form-control-lg form-control-solid"
                    />

                    <div className="text-danger mb-5">
                      <ErrorMessage
                        name={`sections[${sectionIndex}].questions[${index}].questionName`}
                      />
                    </div>
                  </div>
                  {/* Question Type */}
                  <div className="fv-row w-450px ms-5">
                    <Field
                      as="select"
                      name={`sections[${sectionIndex}].questions[${index}].questionType`}
                      className="form-select form-select-lg form-select-solid cursor-pointer"
                    >
                      <option value="TextField">TextField</option>
                      <option value="Date">Date</option>
                      <option value="Image">Image</option>
                      <option value="Dropdown">Dropdown</option>
                      <option value="Checkbox">Checkbox</option>
                      <option value="Radio">Radio</option>
                    </Field>
                    <div className="text-danger mt-2">
                      <ErrorMessage
                        name={`sections[${sectionIndex}].questions[${index}].questionType`}
                      />
                    </div>
                  </div>
                  {/* Here Buttons */}

                  {hoverEffect && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        right: "-25px",
                        // position: "sticky",
                      }}
                      className="card p-5 d-flex align-items-center justify-content-center"
                    >
                      <i
                        onClick={() =>
                          push({
                            questionName: "",
                            questionType: "TextField",
                            validations: {
                              dataType: "Text",
                              minValue: "",
                              maxValue: "",
                              minLength: "",
                              maxLength: "",
                            },
                            description: "",
                            options: [
                              {
                                option: "",
                              },
                            ],
                            // Switches
                            questionRequired: false,
                            descriptionRequired: false,
                            validateTextField: false,
                            isConditionalQuestion: false,
                            conditions: [
                              {
                                // conditionType: "", ( Number || Text )
                                textCondition: "",
                                numberCondition: "",
                                conditionValue: "",
                                questionNumber: "",
                              },
                            ],
                          })
                        }
                        className="fs-2 fas fa-plus text-hover-primary cursor-pointer"
                      ></i>
                    </div>
                  )}
                </div>

                {/* Options */}
                {values.sections[`${sectionIndex}`].questions[`${index}`]
                  .questionType !== "TextField" &&
                  values.sections[`${sectionIndex}`].questions[`${index}`]
                    .questionType !== "Date" &&
                  values.sections[`${sectionIndex}`].questions[`${index}`]
                    .questionType !== "Image" && (
                    <>
                      <div className="ms-5 d-flex">
                        <FieldArray
                          name={`sections[${sectionIndex}].questions[${index}].options`}
                        >
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values } = form;

                            return (
                              <div className="d-flex flex-column">
                                {values.sections[`${sectionIndex}`].questions[
                                  `${index}`
                                ].options?.map(
                                  (category: any, valueIndex: any) => (
                                    <div className="d-flex mb-5 align-items-center">
                                      <span className="me-5 mt-2">
                                        {values.sections[`${sectionIndex}`]
                                          .questions[`${index}`]
                                          .questionType === "Dropdown" && (
                                          <div className="fs-3 fw-bold text-dark">
                                            {`${valueIndex + 1}`}
                                          </div>
                                        )}

                                        {values.sections[`${sectionIndex}`]
                                          .questions[`${index}`]
                                          .questionType === "Checkbox" && (
                                          <i className="fs-2x fas fa-check-square"></i>
                                        )}

                                        {values.sections[`${sectionIndex}`]
                                          .questions[`${index}`]
                                          .questionType === "Radio" && (
                                          <span className="">
                                            <KTSVG
                                              path="/media/ikure/form-builder/radio.svg"
                                              className="svg-icon svg-icon-3x"
                                            />
                                          </span>
                                        )}
                                      </span>

                                      <Field
                                        name={`sections[${sectionIndex}].questions[${index}].options[${valueIndex}].option`}
                                        placeholder="Enter the value"
                                        className="form-control form-control-lg form-control-solid w-500px"
                                      />
                                      <button
                                        className="ms-5 btn btn-primary d-flex h-40px w-140px"
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        Add Option
                                      </button>
                                      {valueIndex > 0 && (
                                        <button
                                          className="ms-5 btn btn-danger d-flex h-40px w-140px"
                                          type="button"
                                          onClick={() => remove(valueIndex)}
                                        >
                                          Cancel Option
                                        </button>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            );
                          }}
                        </FieldArray>
                        <div className="text-danger mt-5 bg-danger">
                          <ErrorMessage name="respTypes" />
                        </div>
                      </div>
                    </>
                  )}

                {/* Description */}
                {values.sections[`${sectionIndex}`].questions[`${index}`]
                  .descriptionRequired === true && (
                  <div className="d-flex align-items-center mb-5">
                    <div className="fv-row col-lg-12">
                      <Field
                        name={`sections[${sectionIndex}].questions[${index}].description`}
                        placeholder="Description for the question"
                        className="form-control form-control-lg form-control-solid"
                      />
                      <div className="text-danger mt-2">
                        <ErrorMessage name="description" />
                      </div>
                    </div>
                  </div>
                )}

                {/* Validation */}
                {values.sections[`${sectionIndex}`].questions[`${index}`]
                  .validateTextField === true &&
                  values.sections[`${sectionIndex}`].questions[`${index}`]
                    .questionType == "TextField" && (
                    <>
                      <div className="d-flex mb-5">
                        <div className="w-250px">
                          <Field
                            as="select"
                            name={`sections[${sectionIndex}].questions[${index}].validations.dataType`}
                            className="form-select form-select-lg form-select-solid"
                          >
                            <option value="Text" selected>
                              Text
                            </option>
                            <option value="Number">Number</option>
                          </Field>
                          <div className="text-danger mt-5">
                            <ErrorMessage
                              name={`sections[${sectionIndex}].questions[${index}].validations.dataType`}
                            />
                          </div>
                        </div>
                        <div className="ms-15 w-450px">
                          <Field
                            placeholder={
                              values?.sections[`${sectionIndex}`].questions[
                                `${index}`
                              ].validations.dataType === "Text"
                                ? "Minimum accepted length ( required )"
                                : "Minimum accepted value ( required )"
                            }
                            name={
                              values?.sections[`${sectionIndex}`].questions[
                                `${index}`
                              ].validations.dataType === "Text"
                                ? `sections[${sectionIndex}].questions[${index}].validations.minLength`
                                : `sections[${sectionIndex}].questions[${index}].validations.minValue`
                            }
                            className="form-control form-control-lg form-control-solid"
                          />
                          <div className="text-danger mt-5">
                            <ErrorMessage
                              name={
                                values?.sections[`${sectionIndex}`].questions[
                                  `${index}`
                                ].validations.dataType === "Text"
                                  ? `sections[${sectionIndex}].questions[${index}].validations.minLength`
                                  : `sections[${sectionIndex}].questions[${index}].validations.minValue`
                              }
                            />
                          </div>
                        </div>
                        <div className="ms-15 w-450px">
                          <Field
                            placeholder={
                              values?.sections[`${sectionIndex}`].questions[
                                `${index}`
                              ].validations.dataType === "Text"
                                ? "Maximum accepted length ( required )"
                                : "Maximum accepted value ( required )"
                            }
                            name={
                              values?.sections[`${sectionIndex}`].questions[
                                `${index}`
                              ].validations.dataType === "Text"
                                ? `sections[${sectionIndex}].questions[${index}].validations.maxLength`
                                : `sections[${sectionIndex}].questions[${index}].validations.maxValue`
                            }
                            className="form-control form-control-lg form-control-solid"
                          />
                          <div className="text-danger mt-5">
                            <ErrorMessage
                              name={
                                values?.sections[`${sectionIndex}`].questions[
                                  `${index}`
                                ].validations.dataType === "Text"
                                  ? `sections[${sectionIndex}].questions[${index}].validations.maxLength`
                                  : `sections[${sectionIndex}].questions[${index}].validations.maxValue`
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* Skip Logic */}
                {values.sections[`${sectionIndex}`].questions[`${index}`]
                  .isConditionalQuestion === true && (
                  <>
                    <FieldArray
                      name={`sections[${sectionIndex}].questions[${index}].conditions`}
                    >
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { sections } = values;
                        // console.log(sections);
                        return (
                          <div className="">
                            {sections[`${sectionIndex}`].questions[
                              `${index}`
                            ].conditions?.map(
                              (condition: any, conditionIndex: any) => {
                                return (
                                  <div className="d-flex mb-5">
                                    <div className="d-flex">
                                      <Field
                                        as="select"
                                        name={`sections[${sectionIndex}].questions[${index}].conditions[${conditionIndex}].questionNumber`}
                                        className="form-select form-select-lg form-select-solid cursor-pointer"
                                      >
                                        <option>Select Question Number</option>
                                        {questionArray.map(
                                          (qA: any, index: any) => {
                                            return (
                                              <>
                                                <option value={qA}>
                                                  Question {qA}
                                                </option>
                                              </>
                                            );
                                          }
                                        )}
                                      </Field>
                                    </div>

                                    <div className="d-flex ms-5">
                                      {values?.sections[`${sectionIndex}`]
                                        .questions[
                                        values?.sections[`${sectionIndex}`]
                                          .questions[`${index}`].conditions[
                                          `${conditionIndex}`
                                        ]?.questionNumber
                                      ]?.questionType === "TextField" ? (
                                        <Field
                                          as="select"
                                          name={`sections[${sectionIndex}].questions[${index}].conditions[${conditionIndex}].numberCondition`}
                                          className="w-225px form-select form-select-lg form-select-solid cursor-pointer"
                                        >
                                          <option>Select Conditions</option>
                                          <option value="GreaterThan">
                                            Greater than
                                          </option>
                                          <option value="GreaterThanOrEqualTo">
                                            Greater than or equal to
                                          </option>
                                          <option value="LessThan">
                                            Less than
                                          </option>
                                          <option value="LessThanOrEqualTo">
                                            Less than or equal to
                                          </option>
                                          <option value="EqualTo">
                                            Equal to
                                          </option>
                                          <option value="NotEqualTo">
                                            Not equal to
                                          </option>
                                        </Field>
                                      ) : (
                                        <Field
                                          as="select"
                                          name={`sections[${sectionIndex}].questions[${index}].conditions[${conditionIndex}].textCondition`}
                                          className="w-225px form-select form-select-lg form-select-solid cursor-pointer"
                                        >
                                          <option>Select Conditions</option>
                                          <option value="WasAnswered">
                                            Was answered
                                          </option>
                                          <option value="WasNotAnswered">
                                            Was not answered
                                          </option>
                                          <option value="EqualTo">
                                            Equal to
                                          </option>
                                          <option value="NotEqualTo">
                                            Not equal to
                                          </option>
                                        </Field>
                                      )}
                                    </div>
                                    <div className="d-flex ms-5">
                                      <Field
                                        placeholder="Enter value"
                                        name={`sections[${sectionIndex}].questions[${index}].conditions[${conditionIndex}].conditionValue`}
                                        className="form-control form-control-lg form-control-solid"
                                      />
                                    </div>

                                    <div className="">
                                      <button
                                        className="ms-5 d-flex btn btn-primary h-40px w-50px"
                                        type="button"
                                        onClick={() =>
                                          push({
                                            // Condition Type ( Number || Text )
                                            conditionType: "",
                                            textCondition: "",
                                            numberCondition: "",
                                            conditionValue: "",
                                            questionNumber: "",
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <div className="">
                                      {conditionIndex > 0 && (
                                        <button
                                          className="ms-5 btn btn-danger h-40px w-50px"
                                          type="button"
                                          onClick={() => remove(conditionIndex)}
                                        >
                                          -
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }}
                    </FieldArray>
                  </>
                )}
                <hr className="text-muted my-2" />

                {/* Switches */}
                <div className="d-flex mt-2 ms-5">
                  {/* Required  */}
                  <div
                    role="group"
                    className="fv-row col-lg-2 d-flex align-items-center form-check form-switch form-check-solid form-check-custom"
                  >
                    <Switch
                      id={`sections[${sectionIndex}].questions[${index}].questionRequired`}
                      name={`sections[${sectionIndex}].questions[${index}].questionRequired`}
                      value={true}
                      checked={
                        values.sections[`${sectionIndex}`].questions[`${index}`]
                          .questionRequired === true
                      }
                      onChange={(event: any, checked: any) => {
                        setFieldValue(
                          `sections[${sectionIndex}].questions[${index}].questionRequired`,
                          checked ? true : false
                        );
                      }}
                    />

                    <label
                      htmlFor={`sections[${sectionIndex}].questions[${index}].questionRequired`}
                      className="fs-4 fw-bolder w-100px form-check-label text-center"
                    >
                      Required
                    </label>
                  </div>

                  {/* Skip */}
                  <div
                    style={{
                      cursor: index <= 0 ? "not-allowed" : "pointer",
                    }}
                    role="group"
                    className="ms-10 fv-row col-lg-2 d-flex align-items-center form-check form-switch form-check-solid form-check-custom"
                  >
                    <Switch
                      id={`sections[${sectionIndex}].questions[${index}].isConditionalQuestion`}
                      disabled={index <= 0}
                      name={`sections[${sectionIndex}].questions[${index}].isConditionalQuestion`}
                      value={true}
                      checked={
                        values.sections[`${sectionIndex}`].questions[`${index}`]
                          .isConditionalQuestion === true
                      }
                      onChange={(event, checked) => {
                        setFieldValue(
                          `sections[${sectionIndex}].questions[${index}].isConditionalQuestion`,
                          checked ? true : false
                        );
                      }}
                    />

                    <label
                      style={{
                        cursor: index <= 0 ? "not-allowed" : "pointer",
                      }}
                      htmlFor={`sections[${sectionIndex}].questions[${index}].isConditionalQuestion`}
                      className="fs-4 fw-bolder w-350px form-check-label text-center"
                    >
                      Dependent
                    </label>
                  </div>

                  {/* Validate */}
                  <div
                    style={{
                      cursor:
                        sections[`${sectionIndex}`].questions[`${index}`]
                          .questionType !== "TextField"
                          ? "not-allowed"
                          : "pointer",
                    }}
                    role="group"
                    className="ms-10 fv-row col-lg-2 d-flex align-items-center form-check form-switch form-check-solid form-check-custom"
                  >
                    <Switch
                      id={`sections[${sectionIndex}].questions[${index}].validateTextField`}
                      disabled={
                        values.sections[`${sectionIndex}`].questions[`${index}`]
                          .questionType !== "TextField"
                      }
                      name={`sections[${sectionIndex}].questions[${index}].validateTextField`}
                      value={true}
                      checked={
                        values.sections[`${sectionIndex}`].questions[`${index}`]
                          .validateTextField === true
                      }
                      onChange={(event, checked) => {
                        setFieldValue(
                          `sections[${sectionIndex}].questions[${index}].validateTextField`,
                          checked ? true : false
                        );
                      }}
                    />

                    <label
                      style={{
                        cursor:
                          sections[`${sectionIndex}`].questions[`${index}`]
                            .questionType !== "TextField"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      htmlFor={`sections[${sectionIndex}].questions[${index}].validateTextField`}
                      className="fs-4 fw-bolder w-100px form-check-label text-center"
                    >
                      Validate
                    </label>
                  </div>

                  {/* Description */}
                  <div
                    role="group"
                    className="ms-10 fv-row col-lg-2 d-flex align-items-center form-check form-switch form-check-solid form-check-custom"
                  >
                    <Switch
                      id={`sections[${sectionIndex}].questions[${index}].descriptionRequired`}
                      name={`sections[${sectionIndex}].questions[${index}].descriptionRequired`}
                      value={true}
                      checked={
                        values.sections[`${sectionIndex}`].questions[`${index}`]
                          .descriptionRequired === true
                      }
                      onChange={(event, checked) => {
                        setFieldValue(
                          `sections[${sectionIndex}].questions[${index}].descriptionRequired`,
                          checked ? true : false
                        );
                      }}
                    />

                    <label
                      htmlFor={`sections[${sectionIndex}].questions[${index}].descriptionRequired`}
                      className="fs-4 fw-bolder w-100px form-check-label text-center"
                    >
                      Description
                    </label>
                  </div>

                  {/* Delete Icon */}
                  <div className="ms-15 fv-row w-150px d-flex align-items-center form-check form-switch form-check-solid form-check-custom">
                    {index > 0 && (
                      <span onClick={() => remove(index)}>
                        <i className="fs-2 fas fa-trash-alt text-hover-danger cursor-pointer"></i>
                        <label className="fs-4 fw-bolder w-100px form-check-label text-center">
                          Delete
                        </label>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Draggable>
    </>
  );
};

export default Questions;
