import * as Yup from "yup";

export type formBuilderValueTypes = {
  focusedGroupName: string;
  visitNumber: string;
  formName: string;
  description: string;
  sections: any;
};

export const initalValues: formBuilderValueTypes = {
  focusedGroupName: "",
  visitNumber: "",
  formName: "",
  description: "",
  sections: [
    {
      secId: 0,
      sectionName: "",
      nextPage: false,
      questions: [
        {
          questionName: "",
          questionType: "TextField",
          validations: {
            dataType: "Text",
            minValue: "",
            maxValue: "",
            minLength: "",
            maxLength: "",
          },
          description: "",
          // Options Accepted
          options: [
            {
              option: "",
            },
          ],
          // Switches
          questionRequired: false,
          descriptionRequired: false,
          validateTextField: false,
          isConditionalQuestion: false,
          conditions: [
            {
              // conditionType: "", ( Number || Text )
              textCondition: "",
              numberCondition: "",
              conditionValue: "",
              questionNumber: "",
            },
          ],
        },
      ],
    },
  ],
};

export const formBuilderValidationSchema = [
  Yup.object({
    formName: Yup.string().label("Form Name is Required"),
    description: Yup.string().label("Description is Required"),
  }),
  Yup.object({
    sections: Yup.array().of(
      Yup.object().shape({
        sectionName: Yup.string().required("Section Name required"),
        nextPage: Yup.string().label("Next Page required"),
        questions: Yup.array().of(
          Yup.object().shape({
            questionName: Yup.string().required("Question Name required"),
            questionType: Yup.string().label("Question Type required"),
            description: Yup.string().label("Description required"),
            // Switches
            questionRequired: Yup.string().label("Question required"),
            descriptionRequired: Yup.string().label("Description required"),
            validateTextField: Yup.boolean().label(
              "TextField Validatation required"
            ),
            isConditionalQuestion: Yup.string().label(
              "isConditional Question required"
            ),
            // Validations
            validations: Yup.object().when("validateTextField", {
              is: (validateTextField: any) => validateTextField === true,
              then: Yup.object().shape({
                dataType: Yup.string(),
                // Required if dataType === 'Number'
                minValue: Yup.string().when("dataType", {
                  is: "Number",
                  then: Yup.string().required("min value is required"),
                }),
                maxValue: Yup.string().when("dataType", {
                  is: "Number",
                  then: Yup.string().required("max value is required"),
                }),

                // Required if dataType === 'Text'
                minLength: Yup.string().when("dataType", {
                  is: "Text",
                  then: Yup.string().required("min length is required"),
                }),
                maxLength: Yup.string().when("dataType", {
                  is: "Text",
                  then: Yup.string().required("max length is required"),
                }),
              }),
              otherwise: Yup.object().shape({
                // Required if dataType === 'Number'
                minValue: Yup.string(),
                maxValue: Yup.string(),
                // Required if dataType === 'Text'
                minLength: Yup.string(),
                maxLength: Yup.string(),
              }),
            }),
            conditions: Yup.array().of(
              Yup.object().shape({
                // ( Number || Text )
                // conditionType: Yup.string().label("Condition Type required"),
                textCondition: Yup.string().label("Text Condition required"),
                numberCondition: Yup.string().label(
                  "Number Condition required"
                ),
                conditionValue: Yup.string().label("Condition Value required"),
                questionNumber: Yup.string().label("Question Number required"),
              })
            ),
          })
        ),
      })
    ),
  }),
];
