import React, { useEffect } from "react";
import "./styles.css";
type Props = {
  isClicked: boolean;
};

const CollapsibleTableIcon = ({ isClicked }: Props) => {
  const [clicked, setIsClicked] = React.useState(false);
  useEffect(() => {
    setIsClicked(isClicked);
  }, [isClicked]);
  return (
    <div className={`rotate ${clicked && "down"} `}>
      {" "}
      <i className="fs-5 fas fa-greater-than "></i>
    </div>
  );
};

export default CollapsibleTableIcon;
