import React, { useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { permittedRoles } from "../common/config/rbac/types/rbac.types";
import RedirectsConfig from "../common/config/routing/RedirectsConfig";
import RoutesConfig from "../common/config/routing/RoutesConfig";
import { useRBAC } from "../context/RBACContext";
import useGetRequest from "../hooks/getRequest.query";
import { getAllFacilites } from "../services/facility/Facility.hook";
import { MasterInit } from "../_metronic/layout/MasterInit";
import Login from "../modules/auth/org/Login";
import OrgSignIn from "../modules/auth/org/OrgSignIn";
import { ErrorsPage } from "../modules/errors/ErrorsPage";

import PrivateRoutes from "./PrivateRoutes";
import InvalidFacilityScreen from "../components/FallbackScreens/InvalidFacilityScreen";
import TokenService from "../common/utils/TokenService";

const accessToken = localStorage.getItem("access_token");

const CustomRoutes = () => {
    const { userRole } = useRBAC();
    const facilityCode = localStorage.getItem("facilityCode") || "";
    const loggedInUser = localStorage.getItem("userRole") || "";
    const { data: facilityData, refetch } = useGetRequest(
        "getAllFacilites-onRoutesLoad",
        async () => await getAllFacilites(),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (accessToken) refetch();
    }, [refetch]);

    useEffect(() => {
        // Checking role of the user before setting the default facility config
        if (userRole === permittedRoles.organizationAdmin) {
            const filteredData = facilityData?.data?.data?.facility
                ?.filter((facility: any) => {
                    return facility?.status !== false;
                })
                .map((facility: any) => {
                    const facilityCode = facility?.facilityCode;
                    const facilityName =
                        facility?.facilityFirstName +
                        " " +
                        facility?.facilityLastName;
                    return {
                        facilityCode,
                        facilityName,
                    };
                });

            if (filteredData && !facilityCode) {
                localStorage.setItem("facilityCode", "");
                localStorage.setItem("facilityName", "");
            }
        }
    }, [facilityData, userRole, facilityCode]);

    return (
        <>
            <Routes>
                {/*************** PUBLIC ROUTES *************/}
                {TokenService.getCurrentFacilityStatus() === false ? (
                    <Route
                        path="/dashboard"
                        element={<InvalidFacilityScreen />}
                    />
                ) : (
                    <>
                        <Route path="/auth/login" element={<Login />} />
                        <Route path="/auth/check-org" element={<OrgSignIn />} />

                        {/*************** PRIVATE ROUTES ************/}
                        <Route element={<PrivateRoutes />}>
                            {RoutesConfig.map((ele) => {
                                return (
                                    ele.canDisplay && (
                                        <Route
                                            key={ele.path}
                                            path={ele.path}
                                            element={React.createElement(
                                                ele.component
                                            )}
                                        />
                                    )
                                );
                            })}
                        </Route>

                        {/*************** REDIRECTS ***************/}
                        {RedirectsConfig.map((route: any) => {
                            const { from, to } = route;
                            // return <Redirect from={from} to={to} {...rest} key={from} />;
                            return (
                                <Route
                                    key={to}
                                    path={from}
                                    element={<Navigate to={to} replace />}
                                />
                            );
                        })}
                        <Route
                            path={"/auth/*"}
                            element={
                                accessToken ? (
                                    <Navigate to="/dashboard" replace />
                                ) : (
                                    <Navigate to="/auth/check-org" replace />
                                )
                            }
                        />
                    </>
                )}
                <Route path="*" element={<ErrorsPage />} />
            </Routes>

            <MasterInit />
        </>
    );
};

export default CustomRoutes;
