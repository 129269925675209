import { FieldArray } from "formik";
import { useEffect, useState, useRef } from "react";

import Sections from "./Sections";

const MultiSection = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Ends Here
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="d-flex flex-column">
        {/* Section Name  */}
        <div className="mt-5 d-flex flex-column">
          <div className="fv-row">
            <FieldArray name="sections">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values, setFieldValue } = form;
                const { sections } = values;
                // console.log(form);

                return (
                  <div className="mb-5">
                    {sections?.map((section: any, index: any) => {
                      return (
                        <>
                          <Sections
                            remove={remove}
                            values={values}
                            index={index}
                            setFieldValue={setFieldValue}
                          />
                          {/* Add Button */}
                          <div className="">
                            {values?.sections.length - 1 === index && (
                              <div
                                style={{
                                  border: "1.5px solid black",
                                  borderStyle: "dashed",
                                }}
                                className="h-200px mt-15 d-flex align-items-center justify-content-center"
                                // className="w-100"
                              >
                                <i
                                  title="Add new section"
                                  className="fs-5x fas fa-plus-circle text-hover-primary cursor-pointer"
                                  onClick={() =>
                                    push({
                                      secId: index + 1,
                                      sectionName: "",
                                      questions: [
                                        {
                                          questionName: "",
                                          questionType: "Document",
                                          documentValue: "",
                                          videoLink: "",
                                        },
                                      ],
                                    })
                                  }
                                ></i>
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSection;
