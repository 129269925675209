import React, { useEffect, useState } from "react";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getIndentById } from "../../../../services/supply-chain/indent/Indent.services";
import { dateFormatter } from "../../../../common/utils";

type Props = {};
const tableNames = [
    "Product",
    "Manufacturer Name",
    "Unit/Strip",
    "No. of Strip",
    "Total Unit",
];

const IndentDetailsTable = (props: Props) => {
    const [searchParams] = useSearchParams();
    const indentId = searchParams.get("id");

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const facilityCode = localStorage.getItem("facilityCode");
    // Table API
    const { data, isLoading, isFetching, isError, error, refetch }: any =
        useGetRequest(
            ["getIndentById", pageLimit],
            () =>
                getIndentById({
                    facilityCode,
                    indentId,
                    page: currentPageNumber,
                    limit: pageLimit,
                }),
            { staleTime: 100000, refetchOnWindowFocus: false }
        );

    const indentList = data?.data?.data?.indent;
    const totalEntites = data?.data?.results;
    const totalPages = Math.ceil(totalEntites / pageLimit);
    const errorMsg = error?.response?.data?.message;
    const successMsg = data?.data?.data?.message;

    useEffect(() => {
        refetch();
    }, [indentId]);

    return (
        <>
            <div className="mt-5 mb-4">
                {/* <h4>
                    Indent ID :{" "}
                    <span
                        className="text-muted"
                        style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                        {indentId}
                    </span>
                </h4> */}
                <h4>
                    Created Date:{" "}
                    <span
                        className="text-muted"
                        style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                        {indentList?.createdAt
                            ? dateFormatter(indentList?.createdAt)
                            : "N/A"}
                    </span>
                </h4>
            </div>
            <Table>
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                </TableHeadRow>

                <TableBodyContainer
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    errorMessage={errorMsg}
                >
                    {indentList?.products.map((ele: any, idx: number) => {
                        return (
                            <TableRow collapsible={false}>
                                <TableCell align="center">{idx + 1}</TableCell>
                                <TableCell>
                                    {ele?.productId?.productName
                                        ? ele?.productId?.productName
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {ele?.manufacturerName
                                        ? ele?.manufacturerName
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {ele?.unitPerStrip
                                        ? ele?.unitPerStrip
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {ele?.noOfstrips ? ele?.noOfstrips : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {ele?.totalUnit ? ele?.totalUnit : "N/A"}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBodyContainer>
            </Table>
            <Pagination
                dataCount={10}
                handleCallback={(val: number | string | any) => {
                    setPageLimit(val);
                }}
                handleNext={() =>
                    setCurrentPageNumber(
                        (prevPageNumber: number) => prevPageNumber + 1
                    )
                }
                handlePrev={() =>
                    setCurrentPageNumber(
                        (prevPageNumber: number) => prevPageNumber - 1
                    )
                }
                nextDisabled={currentPageNumber === totalPages}
                pageNumber={currentPageNumber}
                pageLimit={pageLimit}
                prevDisabled={currentPageNumber === 1}
            />
        </>
    );
};

export default IndentDetailsTable;
