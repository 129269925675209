import { Field, ErrorMessage, FormikValues } from "formik";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getAllCategory } from "../../../../services/focused-group/FocusedGroup.hook";
import toast, { Toaster } from "react-hot-toast";
const Step2 = ({ values }: FormikValues) => {
    const { data, isError, error }: any = useGetRequest(
        "getAllCategory",
        () => getAllCategory(),
        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {error?.response?.data?.message}
                    {/* Failed to Create Focused Group !! */}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };
    return (
        <div className="w-100">
            {" "}
            <Toaster />
            {isError && errorToastMsg()}
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Focused Group Details</h2>
            </div>
            <div className="fv-row mb-10">
                <label className="form-label required">
                    Focused Category Name
                </label>

                <Field
                    name="categoryId"
                    as="select"
                    className="form-control form-control-lg form-control-solid"
                >
                    {" "}
                    <option value={""}>Select</option>
                    {data?.data?.data?.category &&
                        data?.data?.data?.category.map(
                            (ele: { categoryName: string; _id: string }) => (
                                <option key={ele?._id} value={ele._id}>
                                    {ele.categoryName}
                                </option>
                            )
                        )}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="categoryId" />
                </div>
            </div>
            {/*  Focused Group Name  */}
            <div className="fv-row mb-10">
                <label className="form-label required">
                    Focused Group Name
                </label>

                <Field
                    name="focusedGroupName"
                    placeholder="Example: ( Hypertension )"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="focusedGroupName" />
                </div>
            </div>
            {/* Visit Number */}
            {values.focusedGroupType === "Visit" && (
                <>
                    <div className="fv-row col-lg-12 mb-10">
                        <label className="form-label required">
                            Total No of Visits
                        </label>
                        <Field
                            name="totalVisits"
                            placeholder="Enter total number visits"
                            className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                            <ErrorMessage name="totalVisits" />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step2;
