import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

type Props = {
    children: React.ReactNode;
    collapsible: boolean;
    collapsibleBody?: React.ReactNode;
    onClick?: () => void;
    show?: boolean;
};

const TableRow = (props: Props) => {
    const {
        children,
        collapsible,
        collapsibleBody,
        onClick,
        show: isOpen,
    } = props;

    const handleClick = () => {
        // @ts-ignore
        if (onClick) onClick();
    };
    return (
        <>
            <tr
                aria-controls="collapse-text"
                aria-expanded={isOpen}
                onClick={handleClick}
            >
                {children}
            </tr>
            {collapsible && (
                <tr id="collapse-text" className="p-0 m-0">
                    <td colSpan={8} className="p-0 m-0">
                        <Collapse in={isOpen}>
                            <div id="collapse-text">{collapsibleBody}</div>
                        </Collapse>
                    </td>
                </tr>
            )}
        </>
    );
};

export default TableRow;
