import dayjs from "dayjs";
import React, { useState } from "react";

import Modal from "../../../../components/Modal";
import AddSubscription from "./forms/addSubcription";
import MemberPage from "./table/MemberPage";
import useGetRequest from "../../../../hooks/getRequest.query";
import { getHealthCardInfo } from "../../../../services/patient/PatientHealthcard.service";
import { Toaster } from "react-hot-toast";

type Props = {
    patientDetails: any;
    healthCard: string;
    isBeneficiaryPatient: boolean;
};

const PatientHealthCard = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [healthCardNo, setHealthCardNo] = useState(props?.healthCard);

    const { data }: any = useGetRequest(
        ["getHealthCardInfo", healthCardNo],
        async () =>
            healthCardNo &&
            (await getHealthCardInfo({
                healthCard: healthCardNo,
            })),
        {
            retry: false,
        }
    );
    const healthCardInfo = data?.data?.data?.healthCard;

    // const assignDate = new Date(
    //     healthCardInfo?.healthCardDetails?.validity?.startDate
    // );
    // const validDate = new Date(
    //     healthCardInfo?.healthCardDetails?.validity?.endDate
    // );

    const assignDate = dayjs(
        new Date(healthCardInfo?.healthCardDetails?.validity?.startDate)
    ).format("DD-MM-YYYY");

    const validDate = dayjs(
        new Date(healthCardInfo?.healthCardDetails?.validity?.endDate)
    ).format("DD-MM-YYYY");

    const renewalDate = dayjs(
        new Date(healthCardInfo?.healthCardDetails?.validity?.endDate)
    )
        .add(1, "day")
        .format("DD-MM-YYYY");

    const planName = healthCardInfo?.healthCardDetails?.planName;

    return (
        <>
            <Toaster />
            <div className="card d-flex flex-column p-10">
                {/* Header container */}
                <div className="d-flex ">
                    <h3>Health Card Information</h3>
                </div>
                {/* Card INfo */}
                <div className="d-flex  mt-3">
                    <div style={{ marginRight: "10rem" }}>
                        <table style={{ width: "300px" }}>
                            <tbody>
                                <tr className="align-items-center">
                                    <th className="fw-bold">
                                        {/* <p className="fw-bold"> */}
                                        Health Card No :{/* </p> */}
                                    </th>
                                    <td className="p-2">
                                        <span className="ms-2 fw-normal">
                                            {healthCardInfo?.healthCardDetails
                                                ? healthCardInfo
                                                      ?.healthCardDetails
                                                      ?.healthCardNumber
                                                : "N/A"}
                                            {props.isBeneficiaryPatient && (
                                                <span
                                                    className={`badge badge-success text-center ms-5`}
                                                >
                                                    Beneficiary
                                                </span>
                                            )}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="fw-bold">
                                        Health Card Status :
                                    </th>
                                    <td className="p-2">
                                        {" "}
                                        <span
                                            className="fw-normal"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            {healthCardInfo?.healthCardDetails
                                                ? healthCardInfo
                                                      ?.healthCardDetails
                                                      ?.status
                                                : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="fw-bold">Plan :</th>
                                    <td className="p-2">
                                        <span
                                            className={`badge badge-light-${
                                                planName === "Gold"
                                                    ? "success"
                                                    : planName === "Silver"
                                                    ? "info"
                                                    : planName === "Premium"
                                                    ? "dark"
                                                    : "danger"
                                            } text-center fw-normal align-items-center d-flex w-60px`}
                                        >
                                            {healthCardInfo?.healthCardDetails
                                                ? planName
                                                : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="d-flex justify-content-between">
                            <p className="fw-bold">Health Card No : </p>
                            <span className="ms-2 fw-normal">
                                {healthCardInfo?.healthCardDetails
                                    ? healthCardInfo?.healthCardDetails
                                          ?.healthCardNumber
                                    : "N/A"}
                                {props.isBeneficiaryPatient && (
                                    <span
                                        className={`badge badge-success text-center ms-5`}
                                    >
                                        Beneficiary
                                    </span>
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="fw-bold ">Health Card Status : </p>
                            &nbsp;
                            <span className="fw-normal">
                                {healthCardInfo?.healthCardDetails
                                    ? healthCardInfo?.healthCardDetails?.status
                                    : "N/A"}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="fw-bold">Plan : </p>

                            <span
                                className={`badge badge-light-${
                                    planName === "Gold"
                                        ? "success"
                                        : planName === "Silver"
                                        ? "info"
                                        : planName === "Premium"
                                        ? "dark"
                                        : "danger"
                                } text-center fw-normal align-items-center d-flex`}
                            >
                                {healthCardInfo?.healthCardDetails
                                    ? planName
                                    : "N/A"}
                            </span>
                        </div> */}
                    </div>
                    <div>
                        <table style={{ width: "300px" }}>
                            <tbody>
                                <tr className="align-items-center">
                                    <th className="fw-bold">Assign Date :</th>
                                    <td className="p-2">
                                        <span className="ms-5 fw-normal">
                                            {healthCardInfo?.healthCardDetails
                                                ?.planName !== "Default"
                                                ? assignDate
                                                : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="fw-bold">Valid Upto :</th>
                                    <td className="p-2">
                                        {" "}
                                        <span className="ms-5 fw-normal">
                                            {healthCardInfo?.healthCardDetails
                                                ?.planName !== "Default"
                                                ? validDate
                                                : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="fw-bold">Renewal Date :</th>
                                    <td className="p-2">
                                        <span className="ms-5 fw-normal">
                                            {healthCardInfo?.healthCardDetails
                                                ?.planName !== "Default"
                                                ? renewalDate
                                                : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="d-flex justify-content-between">
                            <p className="fw-bold">Assign Date : </p>
                            <span className="ms-5 fw-normal">
                                {healthCardInfo?.healthCardDetails?.planName !==
                                "Default"
                                    ? assignDate
                                    : "N/A"}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="fw-bold">Valid Upto : </p>
                            <span className="ms-5 fw-normal">
                                {healthCardInfo?.healthCardDetails?.planName !==
                                "Default"
                                    ? validDate
                                    : "N/A"}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="fw-bold">Renewal Date : </p>&nbsp;
                            <span className="fw-normal">
                                {healthCardInfo?.healthCardDetails?.planName !==
                                "Default"
                                    ? renewalDate
                                    : "N/A"}
                            </span>
                        </div> */}
                    </div>
                </div>
                {/* Add Plan */}

                {props.isBeneficiaryPatient === false &&
                    healthCardInfo?.healthCardDetails?.planName ===
                        "Default" && (
                        <div className="d-flex mt-3">
                            <button
                                className="btn btn-primary w-25"
                                onClick={() => setIsOpen((prev) => !prev)}
                            >
                                Add Subscription
                            </button>
                        </div>
                    )}
                <Modal
                    open={isOpen}
                    styles={{
                        width: "650px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            minWidth: "550px",
                            minHeight: "550px",
                            // msgHeight: "200px",
                            padding: "30px",
                        }}
                    >
                        <AddSubscription
                            setHealthCardNo={setHealthCardNo}
                            setIsOpen={setIsOpen}
                            patientDetails={props.patientDetails}
                        />
                    </div>
                </Modal>
            </div>
            {planName !== "Default" &&
                planName !== undefined &&
                !props.isBeneficiaryPatient && (
                    <MemberPage healthCardNo={healthCardNo} />
                )}
        </>
    );
};

export default PatientHealthCard;
