import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useGetRequest from "../../../hooks/getRequest.query";
import useSearchParams from "../../../hooks/useSearchParams";
import { getTaskDetails } from "../../../services/TaskManagement.hook";
import { Card } from "./Card";
import { PatientTaskPage } from "./PatientDetailsTask";

const TaskDetails = () => {
    const { taskId: id }: any = useSearchParams();
    const [activity, setActivity] = useState("");
    const { data, isLoading, isFetching }: any = useGetRequest(
        ["getTask", id],
        () =>
            getTaskDetails({
                taskId: id,
            }),
        { refetchOnWindowFocus: false, enabled: !!id }
    );

    const taskDetails = data?.data?.data?.task && data?.data?.data?.task[0];
    const taskActivities = taskDetails?.activity;

    const handleActivity = (event: any) => {
        setActivity(event.target.value);
    };

    return (
        <>
            <div>
                <PatientTaskPage data={taskDetails} />
            </div>
            <div className="d-flex flex-wrap flex-stack mb-6">
                <h3 className="fw-bolder my-2">Task Details</h3>

                <div className="d-flex flex-wrap my-2">
                    <div className="me-4">
                        <select
                            name="activity"
                            data-control="select2"
                            data-hide-search="true"
                            className="form-select form-select-sm form-select-white w-145px"
                            defaultValue="Active"
                            onChange={handleActivity}
                        >
                            <option value="">All</option>
                            {taskActivities?.map((item: any) => {
                                return (
                                    <option value={item?.activityGroupName}>
                                        {item?.activityGroupName}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row g-6 g-xl-9">
                {isLoading || isFetching ? (
                    <div className="col-xl-stretch">
                        <ClipLoader size={70} speedMultiplier={0.6} />
                    </div>
                ) : (
                    taskActivities
                        ?.filter((item: { activityGroupName: string }) =>
                            activity !== ""
                                ? item.activityGroupName === activity
                                : item
                        )
                        .map((item: any, index: any) => {
                            return (
                                <>
                                    {item?.activityDetails.map(
                                        (items: any, index: any) => {
                                            let icons =
                                                "/media/ikure/task/default.svg";

                                            if (
                                                items?.vitalName ===
                                                "Blood Pressure"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/blood_pressure.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Temperature"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/temperature.svg";
                                            }
                                            if (items?.vitalName === "Height") {
                                                icons =
                                                    "/media/ikure/task/height.svg";
                                            }
                                            if (items?.vitalName === "Weight") {
                                                icons =
                                                    "/media/ikure/task/weight.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Oxygen Saturation"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/o2.svg";
                                            }
                                            if (items?.vitalName === "Pulse") {
                                                icons =
                                                    "/media/ikure/task/pulse.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Respiratory"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/respiratory.svg";
                                            }
                                            if (
                                                items?.vitalName === "Diabetes"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/diabetes.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Hypertension"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/hypertension.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Maternal Health"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/pregnant.svg";
                                            }
                                            // Pathology
                                            if (
                                                items?.vitalName ===
                                                "Blood Glucose"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/blood_glucose.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Hemoglobin"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/hemoglobin.svg";
                                            }
                                            if (
                                                items?.vitalName ===
                                                "Doctor Check Up"
                                            ) {
                                                icons =
                                                    "/media/ikure/task/doctor_check_up.svg";
                                            }

                                            const color =
                                                items?.vitalStatus === "Done"
                                                    ? "success"
                                                    : items?.vitalStatus ===
                                                      "Cancelled"
                                                    ? "danger"
                                                    : items?.vitalStatus ===
                                                      "In Progress"
                                                    ? "info"
                                                    : items?.vitalStatus ===
                                                      "To Do"
                                                    ? "primary"
                                                    : null;
                                            return (
                                                <div className="col-md-6 col-xl-4">
                                                    <Card
                                                        icon={icons}
                                                        badgeColor="primary"
                                                        status={
                                                            items?.vitalStatus
                                                        }
                                                        activityType={
                                                            items?.activityGroup
                                                        }
                                                        statusColor={color}
                                                        title={items?.vitalName}
                                                        description={
                                                            items?.activityDescription
                                                        }
                                                        cancelReason={
                                                            items?.activityCancellationReason
                                                                ? items?.activityCancellationReason
                                                                : ""
                                                        }
                                                        date={
                                                            items?.vitalCollectedDate
                                                                ? items?.vitalCollectedDate
                                                                : "N/A"
                                                        }
                                                        vitalTime={
                                                            items?.vitalCollectedTime
                                                                ? items?.vitalCollectedTime
                                                                : "N/A"
                                                        }
                                                        budget={
                                                            items?.vitalReading
                                                        }
                                                        deviceType={
                                                            items?.ReadingMethod
                                                                ? items?.ReadingMethod
                                                                : "N/A"
                                                        }
                                                        deviceName={
                                                            items?.deviceName
                                                                ? items?.deviceName
                                                                : "N/A"
                                                        }
                                                        progress={
                                                            items?.vitalStatus ===
                                                            "Done"
                                                                ? 100
                                                                : 0
                                                        }
                                                        feedBack={
                                                            items?.comment
                                                                ? items?.comment
                                                                : "N/A"
                                                        }
                                                        // users={users1}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </>
                            );
                        })
                )}
            </div>
        </>
    );
};
export default TaskDetails;
