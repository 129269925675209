import dayjs from "dayjs";
import _, { isArray } from "lodash";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { dateFormatter } from "../../../../common/utils";

type Props = {
    salesOrderData: any;
};

const SalesDetailsInfo = (props: Props) => {
    const [searchParams] = useSearchParams();
    const deliveryStatus = useMemo(
        () =>
            props?.salesOrderData && _.isArray(props?.salesOrderData)
                ? props?.salesOrderData[0]?.deliveryStatus
                : "--",
        [props?.salesOrderData]
    );
    const orderId = searchParams.get("orderId");
    const paymentStatus = useMemo(
        () =>
            props?.salesOrderData &&
            _.isArray(props?.salesOrderData) &&
            props?.salesOrderData[0]?.paymentStatus,
        [props?.salesOrderData]
    );
    const salesDetails: any = useMemo(() => {
        return (
            props?.salesOrderData &&
            props?.salesOrderData
                ?.map((item: any, idx: any) => {
                    return {
                        chwName: item?.chwData[idx]?.name,
                        patientName: item?.patientData[idx]?.name,
                        createdDate: item?.createdAt,
                        customerType: item?.customerType,
                    };
                })
                .flat(1)
        );
    }, [props?.salesOrderData]);
    if (props?.salesOrderData && _.isArray(props?.salesOrderData)) {
        return (
            <div className="row">
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 mr-4">Order ID: </p>&nbsp;&nbsp;
                    <p className="fs-5">{orderId}</p>
                </div>
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 ">Order Date: </p>&nbsp;&nbsp;
                    <p className="fs-5">
                        {props?.salesOrderData && _.isArray(salesDetails)
                            ? dateFormatter(salesDetails[0]?.createdDate)
                            : "--"}
                    </p>
                </div>
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 ">
                        {isArray(salesDetails) &&
                        _.startCase(salesDetails[0]?.chwName)
                            ? `CHW Name:`
                            : `Order Place:`}
                    </p>
                    &nbsp;&nbsp;
                    <p className="fs-5">
                        {isArray(salesDetails) &&
                        _.startCase(salesDetails[0]?.chwName)
                            ? _.startCase(salesDetails[0]?.chwName)
                            : _.startCase(
                                  props.salesOrderData[0]?.salesOrderType
                              )}
                    </p>
                </div>
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 ">Delivery Status: </p>
                    &nbsp;&nbsp;
                    <p
                        className="fs-5"
                        style={{
                            color:
                                deliveryStatus === "Order Delivered"
                                    ? "#50cd89"
                                    : deliveryStatus === "Shipped"
                                    ? "#FFA533"
                                    : "",
                        }}
                    >
                        {deliveryStatus}
                    </p>
                </div>
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 ">Payment Status: </p>
                    &nbsp;&nbsp;
                    <p
                        className="fs-5"
                        style={{
                            color:
                                paymentStatus === "Paid"
                                    ? "#50cd89"
                                    : paymentStatus === "Partially Paid"
                                    ? "#FFA533"
                                    : "",
                        }}
                    >
                        {paymentStatus}
                    </p>
                </div>
                <div className="col-sm-4 d-flex align-items-end">
                    <p className="fw-bold fs-4 ">Customer: </p>&nbsp;&nbsp;
                    <p className="fs-5">
                        {/* {isArray(salesDetails) &&
                        _.startCase(salesDetails[0]?.chwName)} */}
                        {/* {isArray(salesDetails) && !salesDetails[0]?.patientName
                        ? _.startCase(salesDetails[0]?.chwName)
                        : _.startCase(salesDetails[0]?.patientName)} */}
                        {isArray(salesDetails) &&
                            salesDetails[0]?.customerType === "CHW" &&
                            _.startCase(salesDetails[0]?.chwName)}
                        {isArray(salesDetails) &&
                            salesDetails[0]?.customerType !== "CHW" &&
                            _.startCase(salesDetails[0]?.patientName)}
                    </p>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default SalesDetailsInfo;
