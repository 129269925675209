import $axiosProtected from "../../../common/utils/axiosUtils";

// Get List of all Indents
export const getAllIndents = async (payload: any) => {
    return await $axiosProtected().get(
        `/inventory/indent/get/all?facilityCode=${payload.facilityCode}&indentStatus=${payload.indentStatus}&page=${payload.page}&limit=${payload.limit}&fromDate=${payload.fromDate}&
        toDate=${payload.toDate}`
    );
};

// Get Indent by id
export const getIndentById = async (payload: any) => {
    return await $axiosProtected().get(
        `/inventory/indent/get/id?indentId=${payload.indentId}&facilityCode=${payload.facilityCode}&page=${payload.page}&limit=${payload.limit}`
    );
};

// Create Indent
export const createIndent = async (payload: any) => {
    return await $axiosProtected().post(`/inventory/indent/store`, payload);
};

// Update Indent
export const updateIndent = async (payload: any) => {
    return await $axiosProtected().patch(`/inventory/indent/update`, payload);
};

// Get Purchase order by indentReferenceId
export const getPOByIndentReferenceId = async (payload: any) => {
    return await $axiosProtected().get(
        `/inventory/purchase-order/indent/get?indentReferenceId=${payload.indentReferenceId}&page=${payload.page}&limit=${payload.limit}`
    );
};
