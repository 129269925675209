import * as Yup from "yup";

export type createSubcategoryTypes = {
  categoryName: string;
  subcategoryName: string;
};

export const initalValues: createSubcategoryTypes = {
  categoryName: "",
  subcategoryName: "",
};

export const subcategoryValidationSchema = Yup.object({
  categoryName: Yup.string().required("Category Name is required"),
  subcategoryName: Yup.string().required("Subcategory Name is required"),
});
