import * as Yup from "yup";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import toast, { Toaster } from "react-hot-toast";

import CustomTooltip from "../../../../../components/CustomTooltip";

import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import Pagination from "../../../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";
import {
    dateFormatter,
    stringTruncate,
    todayDate,
} from "../../../../../common/utils";
import { Permissions, hasAccessToSubModule } from "../../../../../rbac";

const filterSchema = Yup.object().shape({
    fromDate: Yup.string().label("From Date"),
    toDate: Yup.string().label("To Date"),
    status: Yup.string().label("Status"),
});

const DoctorAppointments = ({
    filterData,
    setCurrentPageNumber,
    currentPageNumber,
    setFilterData,
    patientConsultationDetails,
    errorMsg,
    setPageLimit,
    isLoading,
    pageLimit,
    totalPatientConsultationPages,
    isPatientConsultationError,
    totalPatientConsultationEntites,
}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // Begins Menu Here
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                fromDate: values.fromDate,
                toDate: values.toDate,
            };

            setFilterData(payload);
            handleClose();
        } catch (error) {
            handleClose();
            console.log(error);
            onSubmitProps.resetForm();
        }
    };

    const clearData = () => {
        const payload = {
            fromDate: "",
            toDate: "",
        };
        setFilterData(payload);
    };

    const downloadPresciption = () =>
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    Presciption Downloaded
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
            }
        );

    const tableNames = [
        "SL NO",
        "DOCTOR NAME",
        "PATIENT NAME",
        "CONSULTATION TYPE",
        "STATUS",
        "SYMPTOMS",
        "SPECIALIZATION",
        "DATE",
        "TIME SLOTS / TOKEN NO",
        "SCHEDULED BY",
        "PRESCRIPTION",
    ];

    // Role Setting
    const canDownload = hasAccessToSubModule(
        "LIST_DOCTOR",
        "LIST_APPOINTMENT",
        Permissions.export_button
    );
    return (
        <div className="card text-dark">
            <Toaster />

            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>APPOINTMENTS LIST</h1>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                <div className="card-toolbar">
                    <button
                        onClick={handleClick}
                        className="btn btn-sm btn-primary"
                    >
                        <i className="fas fa-filter"></i>
                        FILTER
                    </button>

                    {/* Filter Options */}
                    <div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <Formik
                                initialValues={filterData}
                                validationSchema={filterSchema}
                                onSubmit={onFilterSubmit}
                                enableReinitialize
                            >
                                {(formProps: any) => (
                                    <Form>
                                        <div className="py-5 px-8">
                                            <div className="w-250px mt-5 mb-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    FROM DATE
                                                </label>
                                                <Field
                                                    id="fromDate"
                                                    as="input"
                                                    type="date"
                                                    name="fromDate"
                                                    max={todayDate}
                                                    className="form-control cursor-pointer"
                                                />
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="fromDate" />
                                                </div>
                                            </div>
                                            <div className="w-250px mt-5">
                                                <label className="form-label fs-4 fw-bolder">
                                                    TO DATE
                                                </label>
                                                <Field
                                                    id="toDate"
                                                    type="date"
                                                    name="toDate"
                                                    max={todayDate}
                                                    className="form-control"
                                                />
                                                <div className="text-danger mt-5">
                                                    <ErrorMessage name="toDate" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-5 d-flex justify-content-center align-items-center">
                                            <button
                                                type="submit"
                                                disabled={
                                                    !formProps.isValid ||
                                                    formProps.isSubmitting
                                                }
                                                className="w-250px btn btn-sm btn-primary"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Menu>
                    </div>
                    {/* End Here */}
                </div>
            </div>

            <div className="card-body py-3">
                <Table>
                    <TableHeadRow>
                        {/* <TableHead width={20} align="center">
                            SL NO
                        </TableHead> */}
                        {tableNames?.map((name) => {
                            return (
                                <TableHead width={160} key={name}>
                                    {name}
                                </TableHead>
                            );
                        })}
                    </TableHeadRow>

                    <TableBodyContainer
                        isLoading={isLoading}
                        isError={isPatientConsultationError}
                        errorMessage={errorMsg}
                    >
                        {patientConsultationDetails?.map(
                            (item: any, index: any) => {
                                let symptoms = stringTruncate(
                                    item?.symptoms,
                                    5
                                );

                                return (
                                    <TableRow collapsible={false} key={index}>
                                        <TableCell align="center">
                                            {currentPageNumber &&
                                                (currentPageNumber - 1) *
                                                    pageLimit +
                                                    index +
                                                    1}
                                        </TableCell>

                                        <TableCell>
                                            {item?.doctorName?.name}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                to={`/patient/overview?pId=${item?.patient?.uhid}`}
                                                className="text-dark text-dark fw-bolder text-hover-primary"
                                            >
                                                {item?.patient?.name}
                                            </Link>
                                        </TableCell>
                                        <CustomTooltip
                                            sx={{ textTransform: "capitalize" }}
                                            title={item?.consultationType}
                                            placement="top"
                                        >
                                            <TableCell>
                                                <img
                                                    width="30px"
                                                    height="30px"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                    src={
                                                        item?.consultationType ===
                                                        "physical consultation"
                                                            ? toAbsoluteUrl(
                                                                  "/media/ikure/patient/physical.jpeg"
                                                              )
                                                            : toAbsoluteUrl(
                                                                  "/media/ikure/patient/online.jpeg"
                                                              )
                                                    }
                                                    alt={item?.consultationType}
                                                />
                                            </TableCell>
                                        </CustomTooltip>
                                        <TableCell>
                                            <span
                                                className={`badge badge-light-${
                                                    item?.consultationStatus ===
                                                    "Scheduled"
                                                        ? "primary"
                                                        : item?.consultationStatus ===
                                                          "Cancelled"
                                                        ? "danger"
                                                        : item?.consultationStatus ===
                                                          "In Progress"
                                                        ? "info"
                                                        : item?.consultationStatus ===
                                                          "Rescheduled"
                                                        ? "secondary"
                                                        : item?.consultationStatus ===
                                                          "Done"
                                                        ? "success"
                                                        : item?.consultationStatus ===
                                                          "Payment Pending"
                                                        ? "dark"
                                                        : null
                                                } text-center `}
                                            >
                                                {item?.consultationStatus}
                                            </span>
                                        </TableCell>
                                        <CustomTooltip
                                            sx={{ textTransform: "capitalize" }}
                                            title={item?.symptoms}
                                            placement="top"
                                        >
                                            <TableCell>{symptoms}</TableCell>
                                        </CustomTooltip>
                                        <TableCell>
                                            {item?.specialization}
                                        </TableCell>
                                        <TableCell>
                                            {item?.taskDate
                                                ? dateFormatter(item?.taskDate)
                                                : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {item?.consultationType ===
                                            "physical consultation"
                                                ? item?.token
                                                : item?.taskTime}
                                        </TableCell>
                                        <TableCell hover>
                                            <Link
                                                style={{
                                                    color: "black",
                                                    cursor: "pointer",
                                                }}
                                                to={`/chw/overview?chwId=${item?.consultationScheduledBy?.schedulerId}`}
                                            >
                                                <span className="text-hover-primary">
                                                    {
                                                        item
                                                            ?.consultationScheduledBy
                                                            ?.schedulerName
                                                    }
                                                </span>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {item?.consultationStatus ===
                                            "Done" ? (
                                                <>
                                                    <a
                                                        hidden={!canDownload}
                                                        href={
                                                            item?.prescription
                                                                ?.prescriptionFile
                                                        }
                                                        target="_blank"
                                                    >
                                                        <i
                                                            onClick={
                                                                downloadPresciption
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                                        ></i>
                                                    </a>
                                                </>
                                            ) : (
                                                <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBodyContainer>
                </Table>
                {/* Pagination */}
                <Pagination
                    dataCount={totalPatientConsultationEntites}
                    handleCallback={(val) => setPageLimit(+val)}
                    handleNext={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber + 1
                        )
                    }
                    handlePrev={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber - 1
                        )
                    }
                    nextDisabled={
                        currentPageNumber === totalPatientConsultationPages
                    }
                    prevDisabled={currentPageNumber === 1}
                    pageLimit={pageLimit}
                    pageNumber={currentPageNumber}
                />
            </div>
            {/* end::Table container */}
        </div>
    );
};

export default DoctorAppointments;
