import ClipLoader from "react-spinners/ClipLoader";
import CustomTooltip from "../../components/CustomTooltip";

const TaskCounterPage = ({
    data,
    isLoading,
    isFetching,
    filterData,
    totalCount,
}: any) => {
    const showSimpleCounter = Object?.values(filterData)?.some(
        (value) => value !== ""
    );
    return (
        <div className="p-10 card">
            <div className="d-flex">
                <h1 className="fs-1 text-dark fw-bolder">TASK MANAGEMENT</h1>
                <CustomTooltip
                    style={{ fontSize: "550px" }}
                    title="Overview of all the Task"
                    placement="right"
                >
                    <i className="fs-2x fas fa-info-circle ms-2 bg-transparent border-none"></i>
                </CustomTooltip>
            </div>
            {showSimpleCounter ? (
                <>
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <h3 className="text-success">
                                {totalCount ? totalCount : "No Data"}
                            </h3>
                            <h3>Total Count</h3>
                        </div>
                    )}
                </>
            ) : (
                <div className="d-flex mt-5">
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div
                            className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 h-125px border-gray-500 border-dotted"
                            style={{ width: "100%" }}
                        >
                            <h3 className="text-success">{data?.totalTask}</h3>
                            <h3>Total Task</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div
                            className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 h-125px border-gray-500 border-dotted"
                            style={{ width: "100%" }}
                        >
                            <h3 className="text-danger">
                                {data?.totalPending}
                            </h3>
                            <h3>Pending Task</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div
                            className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 h-125px border-gray-500 border-dotted"
                            style={{ width: "100%" }}
                        >
                            <h3 className="text-success">
                                {data?.totalCompleted}
                            </h3>
                            <h3>Completed Task</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div
                            className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 h-125px border-gray-500 border-dotted"
                            style={{ width: "100%" }}
                        >
                            <h3 className="text-warning">
                                {data?.totalInprogress}
                            </h3>
                            <h3>In Progress Task</h3>
                        </div>
                    )}
                    {isLoading || isFetching ? (
                        <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-150px h-125px border-gray-500 border-dotted">
                            <ClipLoader size={50} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <div
                            className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 h-125px border-gray-500 border-dotted"
                            style={{ width: "100%" }}
                        >
                            <h3 className="text-danger">
                                {data?.totalCancelled}
                            </h3>
                            <h3>Cancelled Task</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TaskCounterPage;
