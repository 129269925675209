import * as Yup from "yup";

export type taskListExportValueTypes = {
  exportFormat: string;
  facilityCode: string;
  chwId: string;
  activityList: string;
  fromDate: string;
  toDate: string;
};

export const taskListInitalValues: taskListExportValueTypes = {
  exportFormat: "CSV",
  facilityCode: "",
  chwId: "",
  activityList: "",
  fromDate: "",
  toDate: "",
};

export const taskListExportValidationSchema = Yup.object({
  exportFormat: Yup.string().label("Export format is required"),
  facilityCode: Yup.string().label("Facility name is required"),
  chwId: Yup.string().label("CHW name is required"),
  activityList: Yup.string().label("Doctor name is required"),
  fromDate: Yup.string().required("From date is required"),
  toDate: Yup.string().required("To date is required"),
});
