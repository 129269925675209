import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import IndentDetailsTable from "./IndentDetailsTable";
import PurchaseOrderTable from "./purchaseOrderTable";
import { Permissions, hasAccessToSubModule } from "../../../../rbac";
import { useRBAC } from "../../../../context/RBACContext";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { updateIndent } from "../../../../services/supply-chain/indent/Indent.services";
import Modal from "../../../../components/Modal";
import { RBACComponent } from "../../../../common/config/rbac/components/RBACComponent";
import { permittedRoles } from "../../../../common/config/rbac/types/rbac.types";

type Props = {};

const IndentDetails = (props: Props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { userRole } = useRBAC();
    const status = searchParams.get("status");
    const indentId = searchParams.get("id");
    const [indentStatus, setIndentStatus]: any = useState(status);
    const [open, setOpen] = useState(false);

    const showPoButton = hasAccessToSubModule(
        "LIST_INDENT",
        "CREATE_PURCHASE_ORDER",
        Permissions.create
    );

    const facilityCode: any = localStorage.getItem("facilityCode");

    const { isSuccess, isError, error, mutate, isLoading }: any =
        useMutateRequest((data: any) => updateIndent(data));
    const errorMsg = error?.response?.data?.message;

    const changeStatusAPI = () => {
        try {
            const payload = {
                indentStatus,
                indentId,
                facilityCode,
            };

            if (indentId) {
                mutate(payload, {
                    onSuccess: (data: any) => {
                        navigate(
                            `/indent/indent-details?id=${indentId}&status=${payload?.indentStatus}`
                        );
                    },
                    onError: (error: any) => {
                        console.log(error);
                    },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {/* {successMsg} */}
                    Successfully changed a Indent status
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                    {errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError]);

    return (
        <div>
            <Toaster />
            <div className="d-flex justify-content-between">
                <h1>Indent Details</h1>
                <div>
                    {/* TODO: rbac for only admin */}
                    <RBACComponent
                        whichRoles={[permittedRoles?.organizationAdmin]}
                    >
                        {status === "Accept" && (
                            <Link
                                to={`/indent/create-po?id=${indentId}&status=${status}`}
                                hidden={showPoButton}
                                className="btn btn-primary btn-md"
                            >
                                Create PO
                            </Link>
                        )}
                    </RBACComponent>
                    <span className="btn btn-bg-light btn-color-muted btn-md">
                        Status:
                        {status === "Submitted" ? (
                            <select
                                onChange={(e) => {
                                    setOpen(true);
                                    setIndentStatus(e.target.value);
                                }}
                                value={indentStatus}
                                className="form-select form-select-light form-select-lg"
                            >
                                {userRole !== "ORGANIZATION_ADMIN" ? (
                                    <>
                                        <option value="Draft">Draft</option>
                                        <option value="Canceled">
                                            Canceled
                                        </option>
                                        <option value="Submitted">
                                            Submitted
                                        </option>
                                    </>
                                ) : (
                                    <>
                                        <option value="Submitted" disabled>
                                            Submitted
                                        </option>
                                        <option value="Accept">Accepted</option>
                                        <option value="Reject">Rejected</option>
                                    </>
                                )}
                            </select>
                        ) : (
                            <span
                                className="fw-bolder"
                                style={{
                                    color:
                                        status === "Accept"
                                            ? "#50cd89"
                                            : // : status === "Canceled"
                                            // ? "#FFA533"
                                            status === "Reject"
                                            ? "#FF4933"
                                            : status === "Submitted"
                                            ? "#FFDA33"
                                            : status === "Draft"
                                            ? "#ff7433"
                                            : "",
                                }}
                            >
                                {status}
                            </span>
                        )}
                    </span>
                    <div className="d-flex" style={{ marginLeft: "1rem" }}>
                        <Modal open={open}>
                            <div className="card p-5 text-dark">
                                <p className="fs-3">
                                    {`The status will be changed to ${indentStatus}.
                                    Are you sure?`}
                                </p>
                                <div className="d-flex justify-content-between mt-5">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => {
                                            setIndentStatus(status);
                                            setOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setIndentStatus(indentStatus);
                                            setOpen(false);
                                            changeStatusAPI();
                                        }}
                                    >
                                        Change Status
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>

            <div className="card text-dark p-3 mt-3">
                <IndentDetailsTable />
            </div>
            <div className="card text-dark p-3 mt-3">
                <PurchaseOrderTable />
            </div>
        </div>
    );
};

export default IndentDetails;
