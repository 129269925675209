import { ErrorMessage, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { todayDate } from "../../../../common/utils";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const languagesOptions = [
  { label: "English", value: "English" },
  { label: "Kannada", value: "Kannada" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Hindi", value: "Hindi" },
  { label: "Bengali", value: "Bengali" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Odia", value: "Odia" },
  { label: "Vietnamese", value: "Vietnamese" },
];
const ProfileForm = ({
  setFieldValue,
  values,
  patientDetails,
  errors,
  dirty,
  touched,
}: FormikValues) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview] = useState<string | null | undefined>();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const locationFileRef = useRef<HTMLInputElement>(null);
  const [location, setLocation] = useState<File | null>();
  const [previewLocation, setPreviewLocation] = useState<
    string | null | undefined
  >();

  useEffect(() => {
    if (location) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewLocation(reader.result as string);
      };
      reader.readAsDataURL(location);
    } else {
      setPreviewLocation(null);
    }
  }, [location]);

  let title = values?.idProofName;

  // User Photo

  const userPhotoRef = useRef<HTMLInputElement>(null);
  const [userImage, setUserImage] = useState<File | null>();
  const [userPreview, setUserPreview] = useState<string | null | undefined>();

  useEffect(() => {
    if (userImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserPreview(reader.result as string);
      };
      reader.readAsDataURL(userImage);
    } else {
      setUserPreview(null);
    }
  }, [userImage]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const patientAge = patientDetails?.demographicDetails?.age;
  const patientBirthDate = patientDetails?.demographicDetails?.birthDate;
  const patientCurrentAge = (patientBirthDate: number) =>
    Math.floor(
      (Number(new Date()) - Number(new Date(patientBirthDate).getTime())) /
        3.15576e10
    );
  const showAge =
    patientDetails?.demographicDetails?.birthDate !== ""
      ? patientCurrentAge(patientBirthDate)
      : patientAge;
  return (
    <div>
      <div className="mb-8">
        <div className="col-lg-6">
          <label className="form-label">User Photo</label>
          <div className="cursor-pointer d-flex col-lg-12">
            <div className="col-lg-6">
              {userPreview ? (
                <img
                  src={userPreview}
                  alt="preview"
                  width="150px"
                  height="150px"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <img
                  src={
                    patientDetails?.demographicDetails?.photo
                      ? patientDetails?.demographicDetails?.photo
                      : toAbsoluteUrl("/media/ikure/blank.png")
                  }
                  alt="preview"
                  width="150px"
                  height="150px"
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>
            <div className="d-flex col-lg-6 justify-content-between align-items-center">
              <input
                ref={userPhotoRef}
                hidden
                type="file"
                className="form-control form-control-lg form-control-solid"
                accept="image/*"
                onChange={(event: any) => {
                  const file = event?.target?.files[0];
                  if (file && file.type.substr(0, 5) === "image") {
                    setFieldValue("photo", event?.target?.files[0]);
                    setUserImage(file);
                  } else {
                    setUserImage(null);
                  }
                }}
              />
              <button
                className="btn btn-sm"
                onClick={(event) => {
                  event.preventDefault();
                  userPhotoRef?.current?.click();
                }}
              >
                <i className="fs-3x fas fa-cloud-upload-alt"></i>
              </button>
              <div className="text-danger mt-2">{errors?.photo}</div>
            </div>
          </div>
        </div>
      </div>
      {/* Email & Telecom */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="form-label">Email</label>

          <input
            name="email"
            placeholder="Enter your email"
            className="form-control form-control-lg form-control-solid"
            defaultValue={patientDetails?.demographicDetails?.email}
            onChange={(event: any) => {
              return setFieldValue("email", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">{errors?.email}</div>
        </div>
        <div className="col-lg-6">
          <label className="form-label">Telecom</label>
          <input
            name="telecom"
            defaultValue={patientDetails?.demographicDetails?.telecom}
            placeholder="Enter your telecom number"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("telecom", event?.target?.value);
            }}
          />

          <div className="text-danger mt-2">{errors?.telecom}</div>
        </div>
      </div>
      {/* Marital Status & Language */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="form-label">Marital Status</label>
          <select
            defaultValue={patientDetails?.demographicDetails?.maritalStatus}
            name="maritalStatus"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("maritalStatus", event?.target?.value);
            }}
          >
            <option value="">Select your status</option>
            <option value="married">Married</option>
            <option value="single">Single</option>
            <option value="divorced">Divorced</option>
            <option value="widow">Widow</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label">Language</label>
          <select
            defaultValue={
              patientDetails?.demographicDetails?.communication?.language
            }
            name="language"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("language", event?.target?.value);
            }}
          >
            <option value="">Select your language</option>
            {languagesOptions?.map((options, index) => {
              return (
                <>
                  <option key={index} value={options?.value}>
                    {options?.label}
                  </option>
                </>
              );
            })}
          </select>
        </div>
      </div>
      {/* Id Proof Name & Value */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="form-label">Proof Name</label>
          <select
            defaultValue={
              patientDetails?.demographicDetails?.uniqueIdentificationId
                ?.idProofName
            }
            name="idProofName"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("idProofName", event?.target?.value);
            }}
          >
            <option value="">Select your proof type</option>
            <option value="Aadhar card">Aadhar Card</option>
            <option value="Driving License">Driving License</option>
            <option value="VoterID">Voter Card</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="form-label">{title} Details</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.uniqueIdentificationId?.id
            }
            name="id"
            onChange={(event: any) => {
              return setFieldValue("id", event?.target?.value);
            }}
            placeholder={`Please enter the ${title} Details`}
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="id" />
          </div>
        </div>
      </div>
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Proof </label>
          <input
            ref={locationFileRef}
            // hidden
            type="file"
            className="form-control form-control-lg  form-control-solid"
            accept="application/*"
            onChange={(event: any) => {
              const file = event?.target?.files[0];
              if (file && file.type.substr(0, 11) === "application") {
                setFieldValue("proofLocation", event?.target?.files[0]);
                setPreviewLocation(file);
              } else {
                setPreviewLocation(null);
              }
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="proofLocation" />
          </div>
        </div>
        {/* Age and Birth date */}
        <>
          {patientBirthDate !== "" ||
          (patientAge === "" && patientBirthDate === "") ? (
            <div className="col-lg-6">
              <label className="form-label">Date of birth</label>
              <input
                defaultValue={patientDetails?.demographicDetails?.birthDate}
                name="birthDate"
                type="date"
                max={todayDate}
                className="form-control form-control-lg form-control-solid date-picker"
                onChange={(event: any) => {
                  return setFieldValue("birthDate", event?.target?.value);
                }}
              />

              <div className="text-danger mt-2">{errors?.birthDate}</div>
            </div>
          ) : (
            <div className="col-lg-6">
              <label className="form-label">Age</label>
              <input
                name="age"
                defaultValue={showAge}
                placeholder="Enter your age number"
                className="form-control form-control-lg form-control-solid"
                onChange={(event: any) => {
                  return setFieldValue("age", event?.target?.value);
                }}
              />

              <div className="text-danger mt-2">{errors?.age}</div>
            </div>
          )}
        </>
      </div>

      {/* Address Site Name & Floor Number */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Address</label>
          <input
            defaultValue={
              patientDetails?.addresses?.addressLines?.addressSiteName
            }
            onChange={(event: any) => {
              return setFieldValue("addressSiteName", event?.target?.value);
            }}
            name="addressSiteName"
            placeholder="Enter your address site name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="addressSiteName" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Floor Number</label>
          <input
            defaultValue={patientDetails?.addresses?.addressLines?.floorNumber}
            onChange={(event: any) => {
              return setFieldValue("floorNumber", event?.target?.value);
            }}
            name="floorNumber"
            placeholder="Enter your floor number"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="floorNumber" />
          </div>
        </div>
      </div>
      {/* Street Name & Street Number */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Street Name</label>
          <input
            defaultValue={patientDetails?.addresses?.addressLines?.streetName}
            onChange={(event: any) => {
              return setFieldValue("streetName", event?.target?.value);
            }}
            name="streetName"
            placeholder="Enter your street name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="streetName" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Street Number</label>
          <input
            defaultValue={patientDetails?.addresses?.addressLines?.streetNumber}
            onChange={(event: any) => {
              return setFieldValue("streetNumber", event?.target?.value);
            }}
            name="streetNumber"
            placeholder="Enter your street number"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="streetNumber" />
          </div>
        </div>
      </div>
      {/* District & PostalCode */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">District</label>
          <input
            defaultValue={patientDetails?.addresses?.district}
            name="district"
            placeholder="Enter your district"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("district", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="district" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Postal Code</label>
          <input
            defaultValue={patientDetails?.addresses?.postalCode}
            name="postalCode"
            placeholder="Enter your postal code"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("postalCode", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="postalCode" />
          </div>
        </div>
      </div>
      {/* Town & State */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Town</label>
          <input
            defaultValue={patientDetails?.addresses?.town}
            name="town"
            placeholder="Enter your town name"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("town", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="town" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">State</label>
          <input
            defaultValue={patientDetails?.addresses?.state}
            onChange={(event: any) => {
              return setFieldValue("state", event?.target?.value);
            }}
            name="state"
            placeholder="Enter your state name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="state" />
          </div>
        </div>
      </div>
      {/* Country */}
      <div className="col-lg-6 mb-10">
        <label className="fs-6 fw-bold form-label">Country</label>
        <select
          defaultValue={patientDetails?.addresses?.country}
          name="country"
          className="form-select form-select-lg form-select-solid"
          onChange={(event: any) => {
            return setFieldValue("country", event?.target?.value);
          }}
        >
          <option value="">Select your country</option>
          <option value="India">India</option>
          <option value="Vietnam">Vietnam</option>
        </select>
      </div>
      <h1 className="fs-1 mb-10">Emergency Details</h1>
      {/* Contact Name & Gender */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="form-label">Name</label>
          <input
            defaultValue={patientDetails?.demographicDetails?.contact?.name}
            name="contactName"
            placeholder="Enter your name"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("contactName", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactName" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="form-label">Gender</label>
          <select
            defaultValue={patientDetails?.demographicDetails?.contact?.gender}
            name="contactGender"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("contactGender", event?.target?.value);
            }}
          >
            <option value="">Select your gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
      {/* Phone & Relationship */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Relationship</label>
          <select
            defaultValue={
              patientDetails?.demographicDetails?.contact?.relationship
            }
            name="contactRelationship"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("contactRelationship", event?.target?.value);
            }}
          >
            <option value="">Select your Relationship</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Uncle">Uncle</option>
            <option value="Aunty">Aunty</option>
            <option value="Wife">Wife</option>
            <option value="Husband">Husband</option>
            <option value="Children">Children</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Phone</label>
          <input
            defaultValue={patientDetails?.demographicDetails?.contact?.telecom}
            name="contactTelecom"
            placeholder="Enter your phone number"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("contactTelecom", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">{errors?.contactTelecom}</div>
        </div>
      </div>
      {/* Address Site Name & Floor Number */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Address</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.addressLines
                ?.addressSiteName
            }
            onChange={(event: any) => {
              return setFieldValue(
                "contactAddressSiteName",
                event?.target?.value
              );
            }}
            name="contactAddressSiteName"
            placeholder="Enter your address site name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactAddressSiteName" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Floor Number</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.addressLines
                ?.floorNumber
            }
            onChange={(event: any) => {
              return setFieldValue("contactFloorNumber", event?.target?.value);
            }}
            name="contactFloorNumber"
            placeholder="Enter your floor number"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactFloorNumber" />
          </div>
        </div>
      </div>
      {/* Street Name & Street Number */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Street Name</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.addressLines
                ?.streetName
            }
            onChange={(event: any) => {
              return setFieldValue("contactStreetName", event?.target?.value);
            }}
            name="contactStreetName"
            placeholder="Enter your street name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactStreetName" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Street Number</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.addressLines
                ?.streetNumber
            }
            onChange={(event: any) => {
              return setFieldValue("contactStreetNumber", event?.target?.value);
            }}
            name="contactStreetNumber"
            placeholder="Enter your street number"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactStreetNumber" />
          </div>
        </div>
      </div>
      {/* District & PostalCode */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">District</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.district
            }
            name="contactDistrict"
            placeholder="Enter your district"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("contactDistrict", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactDistrict" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Postal Code</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.postalCode
            }
            name="contactPostalCode"
            placeholder="Enter your postal code"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("contactPostalCode", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactPostalCode" />
          </div>
        </div>
      </div>
      {/* Town & State */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Town</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.town
            }
            name="contactTown"
            placeholder="Enter your town name"
            className="form-control form-control-lg form-control-solid"
            onChange={(event: any) => {
              return setFieldValue("contactTown", event?.target?.value);
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactTown" />
          </div>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">State</label>
          <input
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.state
            }
            onChange={(event: any) => {
              return setFieldValue("contactState", event?.target?.value);
            }}
            name="contactState"
            placeholder="Enter your state name"
            className="form-control form-control-lg form-control-solid"
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="contactState" />
          </div>
        </div>
      </div>
      {/* Country & consentType */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Country</label>
          <select
            defaultValue={
              patientDetails?.demographicDetails?.contact?.address?.country
            }
            name="contactCountry"
            className="form-select form-select-lg form-select-solid"
            onChange={(event: any) => {
              return setFieldValue("contactCountry", event?.target?.value);
            }}
          >
            <option value="">Select your country</option>
            <option value="India">India</option>
            <option value="Vietnam">Vietnam</option>
          </select>
        </div>
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Consent Type</label>
          <select
            defaultValue={patientDetails?.consent?.consentType}
            onChange={(event: any) => {
              return setFieldValue("consentType", event?.target?.value);
            }}
            name="consentType"
            className="form-select form-select-lg form-select-solid"
          >
            <option value="">Select your consent type</option>
            <option value="signature">Signature</option>
            <option value="video">Video</option>
          </select>
        </div>
      </div>
      {/* consent */}
      <div className="fv-row row mb-10">
        <div className="col-lg-6">
          <label className="fs-6 fw-bold form-label">Consent</label>
          <input
            ref={fileRef}
            // hidden
            type="file"
            className="form-control form-control-lg  form-control-solid"
            accept="application/*"
            onChange={(event: any) => {
              const file = event?.target?.files[0];
              if (file && file.type.substr(0, 11) === "application") {
                setFieldValue("concent", event?.target?.files[0]);
                setImage(file);
              } else {
                setImage(null);
              }
            }}
          />
          <div className="text-danger mt-2">
            <ErrorMessage name="concent" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
