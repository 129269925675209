import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { isPDF } from "../../../../../common/utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AssetViewer = ({ onClose, userData, styles }: any) => {
    const [numPages, setNumPages]: any = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: any) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const pdfFile = isPDF(userData?.prescriptionFile || userData?.testFile);

    return (
        <div
            style={{
                backgroundColor: styles?.backgroundColor,
                width: styles?.width,
                height: styles?.height,
                padding: styles?.padding,
            }}
            className="card mb-5"
        >
            <div
                className="d-flex justify-content-between align-items-center card-header border-0 cursor-pointer"
                role="button"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                        Health Condition Information
                    </h3>
                </div>
                <div className="d-flex justify-content-end">
                    <h1 onClick={() => onClose()} className="mt-2">
                        <i className="fs-2 text-danger fas fa-window-close cursor-pointer"></i>
                    </h1>
                </div>
            </div>

            {/* prescriptionData */}
            <>
                {userData &&
                    (pdfFile === false ? (
                        <div
                            style={{
                                width: "500px",
                                height: "500px",
                                // backgroundColor: "rgba(109, 109, 109, 0.5)",
                                padding: "10px",
                            }}
                        >
                            <img
                                // className="my-4"
                                // src={userData?.file}
                                src={
                                    userData?.prescriptionFile ||
                                    userData?.testFile
                                }
                                alt={
                                    userData?.prescriptionFile ||
                                    userData?.testFile
                                }
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "500px",
                                backgroundColor: "rgba(109, 109, 109, 0.5)",
                                padding: "5px",
                            }}
                        >
                            <div
                                style={{
                                    overflowY: "scroll",
                                    height: "450px",
                                    // scrollbarWidth: "thin",
                                }}
                            >
                                <Document
                                    rotate={0}
                                    className="d-flex justify-content-center"
                                    file={
                                        userData?.prescriptionFile ||
                                        userData?.testFile
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber || 1} />
                                </Document>
                                <div className="d-flex justify-content-between align-items-between">
                                    <div className="mt-5">
                                        <p className="fs-6 fw-bolder">
                                            Page{" "}
                                            {pageNumber ||
                                                (numPages ? 1 : "--")}{" "}
                                            of {numPages || "--"}
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <button
                                            className="ms-3 btn btn-lg btn-primary"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            Previous
                                        </button>

                                        <button
                                            className="ms-3 btn btn-lg btn-primary"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </>
        </div>
    );
};

export default AssetViewer;
