import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Form, Formik } from "formik";

import { initalValues } from "../models/PatientEntriesModel";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import { getFormEntriesByPatient } from "../../../../services/focused-group/FocusedGroup.hook";

const BeneficiaryEntries = () => {
  const { fgId: id }: any = useSearchParams();
  const { state }: any = useLocation();

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ["getFormEntriesByPatient", id],
      async () =>
        await getFormEntriesByPatient({
          id: state?.id,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
        enabled: !!id,
      }
    );

  const patientEntryDetails = data?.data?.data?.formData;
  const patientFormStructure = data?.data?.data?.formData?.sections;
  const errorMsg = error?.response?.data?.message;

  const formId = patientEntryDetails?.formId;

  useEffect(() => {
    refetch();
  }, []);

  const onSubmit = (values: any, actions: any) => {
    // console.log(values);
  };

  return (
    <>
      {isLoading || isFetching ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isError ? (
        <div className="card mb-5 d-flex justify-content-center align-items-center w-100 h-350px mb-xl-10">
          <h1 className="text-danger">{errorMsg}</h1>
        </div>
      ) : (
        <div className="mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="bg-white p-5 d-flex justify-content-between h-auto">
            <div className="">
              <h1 className="fs-1 text-dark fw-bolder text-center">
                Filled Form
              </h1>
            </div>
            <div className="flex-end">
              <Link
                to={{
                  pathname: `/focused-group/forms`,
                  search: `?fgId=${id}&tab=beneficiary`,
                }}
                className="btn btn-md btn-danger w-200px"
              >
                Go To Beneficiary List
              </Link>
            </div>
          </div>
          <Formik
            initialValues={initalValues}
            //   validationSchema={categoryValidationSchema}
            onSubmit={onSubmit}
          >
            {(formProps: any) => (
              <Form>
                <div className="mt-5">
                  {patientFormStructure?.map((section: any, index: any) => {
                    return (
                      <>
                        <div className="card mb-5 p-5">
                          <div className="d-flex align-items-center fs-2 fw-bolder">
                            <h2 className="">Section Name:</h2>
                            <span className="ms-5">{section?.sectionName}</span>
                          </div>
                          <div className="mt-5 d-flex flex-column">
                            <div className="mb-5">
                              <h2>Questions</h2>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <span>
                                <h4>SL No</h4>
                              </span>
                              <span>
                                <h4>Questions</h4>
                              </span>
                              <span>
                                <h4>Answer</h4>
                              </span>
                            </div>
                            <hr />
                            <div className="mt-2">
                              {section?.questions?.map(
                                (question: any, index: any) => {
                                  return (
                                    <div className="d-flex flex-column">
                                      <div className="d-flex justify-content-between">
                                        <div className="fw-bolder">
                                          <h4 className="text-hover-primary">
                                            {index + 1}
                                          </h4>
                                        </div>
                                        <div className="fw-bolder">
                                          <h4 className="text-hover-primary">
                                            {question?.questionName}
                                          </h4>
                                        </div>
                                        <div className="d-flex">
                                          {question?.answers.map(
                                            (answer: any) => {
                                              return (
                                                <>
                                                  <div className="fw-bolder text-hover-primary">
                                                    {answer?.answer}
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* <div className="mt-5">
                    <button
                      type="submit"
                      disabled={!formProps.isValid}
                      className="btn btn-lg btn-primary me-3"
                    >
                      Submit
                    </button>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default BeneficiaryEntries;
