import { ErrorMessage, FieldArray } from "formik";
import { useState } from "react";
import Questions from "./Questions";

const MultiQuestion = ({ sectionIndex }: any) => {
  const [editMode, setEditMode] = useState(false);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  return (
    <>
      {/* Question Group  */}
      <div className="fv-row col-lg-12">
        <FieldArray name={`sections[${sectionIndex}].questions`}>
          {(QuestionProps) => {
            const { push, remove, form } = QuestionProps;
            const { values, setFieldValue } = form;
            const { sections } = values;
            const totalNumberOfQuestions =
              // sections[sectionIndex]?.questions?.length;
              sections[sectionIndex]?.questions?.length + 1;

            let questionArray: any[] = [];
            for (let i = 1; i < totalNumberOfQuestions; i++) {
              questionArray.push(i);
            }

            return (
              <div className="d-flex flex-column">
                {sections[sectionIndex]?.questions?.map(
                  (multiQuestion: any, index: any) => {
                    return (
                      <>
                        <Questions
                          sectionIndex={sectionIndex}
                          index={index}
                          push={push}
                          remove={remove}
                          values={values}
                          questionArray={questionArray}
                          setFieldValue={setFieldValue}
                          sections={sections}
                        />
                      </>
                    );
                  }
                )}
              </div>
            );
          }}
        </FieldArray>
        <div className="text-danger mt-5 bg-danger">
          <ErrorMessage name="questions" />
        </div>
      </div>
    </>
  );
};

export default MultiQuestion;
