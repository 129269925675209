import * as Yup from "yup";

export type soldItemsExportValueTypes = {
    exportFormat: string;
    facilityCode: string;
    chwId: string;
    fromDate: string;
    toDate: string;
};

export const soldItemsInitalValues: soldItemsExportValueTypes = {
    exportFormat: "CSV",
    facilityCode: "",
    chwId: "",
    fromDate: "",
    toDate: "",
};

export const soldItemsExportValidationSchema = Yup.object({
    exportFormat: Yup.string().label("Export format is required"),
    facilityCode: Yup.string().label("Facility name is required"),
    chwId: Yup.string().label("CHW name is required"),
    fromDate: Yup.string().required("From date is required"),
    toDate: Yup.string().required("To date is required"),
});
