import React from "react";
import GRNList from "./table-view/GRNList";

type Props = {};

const GRNListPage = (props: Props) => {
    return (
        <div>
            <GRNList />
        </div>
    );
};

export default GRNListPage;
