import { createContext, useContext, useEffect, useState } from "react";

type AuthContextProviderProps = {
  children: React.ReactNode;
};

type AuthContextType = {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  handleAuth: (value: any) => void;
};

const AuthContext = createContext({} as AuthContextType);
const accessToken = localStorage.getItem("access_token");
export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    checkUserSession();
  }, []);

  const checkUserSession = async () => {
    // Pull saved login state from localStorage

    if (accessToken) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };

  const handleAuth = (value: boolean) => {
    return setIsAuthorized(value);
  };

  const value = {
    setIsAuthorized,
    isAuthorized,
    handleAuth,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
