import $axiosProtected from "../../../common/utils/axiosUtils";

// Get List of all Products
export const getAllProducts = async (payload: any) => {
    return await $axiosProtected().get(`/inventory/products/get/all`, {
        params: payload,
    });
};

// Products bulk upload api
export const uploadProducts = async (payload: any) => {
    return await $axiosProtected().post(`inventory/products/store`, payload);
};

// Search Products
export const getProductsBySearch = async (payload: any) => {
    return await $axiosProtected().post(`inventory/products/search`, payload);
};
