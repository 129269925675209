import * as Yup from "yup";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";

import { ErrorMessage, Field, Formik, Form } from "formik";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import Modal from "../../../components/Modal";

import PatientListExport from "../features/exports/PatientListExport";
import {
    convertToUtcTimestamp,
    convertUtcTimestampToLocalDate,
    dateFormatter,
    todayDate,
    warnUser,
} from "../../../common/utils";
import Pagination from "../../../components/Pagination";
import TableErrorComponent from "../../../components/Error/TableError";
import { Permissions, hasAccessToModule } from "../../../rbac";
import { Toaster } from "react-hot-toast";
import FilterTile from "../../../components/filter-tile";

const searchSchema = Yup.object().shape({
    searchName: Yup.string()
        .min(3, "Minimum 3 Letters Required")
        .label("Search"),
});

const filterSchema = Yup.object().shape({
    status: Yup.string().label("Status"),
    toDate: Yup.string().label("To Date"),
    fromDate: Yup.string().when("toDate", {
        is: (toDate: any) => {
            return !!toDate;
        },
        then: Yup.string().required("From Date is required"),
    }),
    chwId: Yup.string().label("CHW Id"),
});

const PatientTable = ({
    filterData,
    bodyProps,
    isPatientLoader,
    isPatientFetcher,
    isPatientError,
    patientErrorMsg,
    currentPageNumber,
    handlePageNumber,
    totalPatientEntites,
    totalPages,
    setFilterData,
    pageLimit,
    chwData,
    isCHWFetching,
    setPageLimit,
    setPageNumber,
}: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // Setting the Search term
    const [searchData, setSearchData] = useState([]);
    const [searchFormProps, setSearchFormProps] = useState("");
    // Begins Menu Here
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };
    const onSearchSubmit = (values: any, onSubmitProps: any) => {
        try {
            setSearchFormProps(onSubmitProps);
            // Change Here
            const payload = {
                name: values.searchName?.trim(),
                status: "",
                fromDate: "",
                toDate: "",
                chwId: "",
                patientType: "",
            };

            setFilterData(payload);
            onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
        }
    };
    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                status: values.status,
                fromDate: values.fromDate,
                toDate:
                    values.fromDate !== "" && values.toDate === ""
                        ? todayDate
                        : values.toDate,
                chwId: values.chwId,
                patientType: values.patientType,
                name: "",
            };

            setFilterData(payload);
            handleClose();
            // onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            // onSubmitProps.resetForm();
        }
    };

    const clearData = (searchFormProps: any) => {
        if (searchFormProps) {
            searchFormProps.resetForm();
            setSearchData([]);
        }
        const payload = {
            fromDate: "",
            toDate: "",
            status: "",
            chwId: "",
            name: "",
            patientType: "",
        };
        setPageNumber(1);
        setFilterData(payload);
    };

    let allPatient;
    if (searchData?.length > 0) {
        allPatient = searchData;
    } else {
        allPatient = bodyProps;
    }

    // Role settings
    const canExport = hasAccessToModule(
        "LIST_PATIENT",
        Permissions.export_button
    );

    return (
        <div className="card text-dark">
            <Toaster />
            <Modal
                open={isOpen}
                styles={{
                    width: "650px",
                    backgroundColor: "white",
                }}
            >
                <PatientListExport onClose={handleModal} />
            </Modal>
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>PATIENT LIST</h1>
                    <i
                        onClick={() => clearData(searchFormProps)}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                {Array?.isArray(allPatient) !== true ||
                    (allPatient?.length > 0 && (
                        <div className="card-toolbar">
                            {/* Search Bar */}
                            <div className="py-2 me-20">
                                <Formik
                                    initialValues={{
                                        facilityCode: "",
                                        searchName: "",
                                    }}
                                    validationSchema={searchSchema}
                                    onSubmit={onSearchSubmit}
                                >
                                    {(formProps: any) => (
                                        <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex ">
                                                <Field
                                                    name="searchName"
                                                    placeholder="Search Patient"
                                                    className="form-control form-control-lg form-control-solid"
                                                />
                                                <button
                                                    disabled={
                                                        formProps.isSubmitting ||
                                                        !(
                                                            formProps.isValid &&
                                                            formProps.dirty
                                                        )
                                                    }
                                                    type="submit"
                                                    className="ms-3 btn btn-lg btn-primary"
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                                <ErrorMessage name="searchName" />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            {/* END:: Search Bar */}
                            <button
                                hidden={!canExport}
                                onClick={handleModal}
                                className="btn btn-sm btn-light-primary me-5"
                            >
                                <i className="fas fa-download"></i>
                                EXPORT
                            </button>
                            <button
                                onClick={handleClick}
                                className="btn btn-sm btn-primary"
                            >
                                <i className="fas fa-filter"></i>
                                FILTER
                            </button>

                            {/* Filter Options */}
                            <div>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <Formik
                                        initialValues={filterData}
                                        validationSchema={filterSchema}
                                        onSubmit={onFilterSubmit}
                                        enableReinitialize
                                    >
                                        {(formProps) => (
                                            <Form>
                                                <div className="py-5 px-8">
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            CHW NAME
                                                        </label>

                                                        <Field
                                                            as="select"
                                                            name="chwId"
                                                            className="form-select form-select-lg form-select-solid"
                                                        >
                                                            <option value="">
                                                                All
                                                            </option>
                                                            {chwData?.map(
                                                                (chw: any) => {
                                                                    return (
                                                                        <Fragment
                                                                            key={
                                                                                chw?.chwId
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    chw?.chwId
                                                                                }
                                                                            >
                                                                                {
                                                                                    chw?.name
                                                                                }
                                                                            </option>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </Field>
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="chwId" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            REG TYPE
                                                        </label>

                                                        <Field
                                                            as="select"
                                                            name="patientType"
                                                            className="form-select form-select-lg form-select-solid"
                                                        >
                                                            <option value="">
                                                                All
                                                            </option>
                                                            <option value="Doorstep">
                                                                Doorstep
                                                            </option>
                                                            <option value="Clinic">
                                                                Clinic
                                                            </option>
                                                            <option value="Camp">
                                                                Camp
                                                            </option>
                                                        </Field>
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="patientType" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            STATUS
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            name="status"
                                                            className="form-select form-select-lg form-select-solid"
                                                        >
                                                            <option value="">
                                                                All
                                                            </option>
                                                            <option value="Verified">
                                                                Verified
                                                            </option>
                                                            <option value="Unverified">
                                                                Unverified
                                                            </option>
                                                        </Field>
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="status" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            FROM DATE
                                                        </label>
                                                        <Field
                                                            id="fromDate"
                                                            as="input"
                                                            type="date"
                                                            max={todayDate}
                                                            name="fromDate"
                                                            className="form-control cursor-pointer"
                                                        />
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="fromDate" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            TO DATE
                                                        </label>
                                                        <Field
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                formProps.handleChange(
                                                                    e
                                                                );
                                                                formProps.setFieldTouched(
                                                                    "fromDate"
                                                                );
                                                            }}
                                                            id="toDate"
                                                            type="date"
                                                            name="toDate"
                                                            max={todayDate}
                                                            className="form-control"
                                                        />
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="toDate" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="my-5 d-flex justify-content-center align-items-center">
                                                    <button
                                                        type="submit"
                                                        disabled={
                                                            !formProps.isValid ||
                                                            formProps.isSubmitting
                                                        }
                                                        className="w-250px btn btn-sm btn-primary"
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Menu>
                            </div>
                            {/* End Here */}
                        </div>
                    ))}
            </div>

            <FilterTile
                filterData={filterData}
                keyNames={{
                    status: "Status",
                    fromDate: "Start Date",
                    toDate: "End Date",
                    chwId: "CHW Name",
                    patientType: "Patient Type",
                    name: "Name",
                }}
                clearAll={() => {
                    setFilterData({
                        status: "",
                        fromDate: "",
                        toDate: "",
                        chwId: "",
                        patientType: "",
                        name: "",
                    });
                }}
            />
            {isPatientError || Array?.isArray(allPatient) !== true ? (
                <TableErrorComponent
                    errorMessage={patientErrorMsg || allPatient}
                />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={50} align="center">
                                SL NO
                            </TableHead>
                            <TableHead width={140}>PATIENT NAME</TableHead>
                            <TableHead width={140}>CHW NAME</TableHead>
                            <TableHead width={140}>AGE</TableHead>
                            <TableHead width={120}>GENDER</TableHead>
                            <TableHead width={120}>PHONE NO</TableHead>
                            <TableHead width={120}>FACILITY</TableHead>
                            <TableHead width={120}>REG DATE</TableHead>
                            <TableHead width={120}>REG TYPE</TableHead>
                            {/* <TableHead width={120}>RISK FACTOR</TableHead> */}
                            <TableHead width={120}>ACTION</TableHead>
                        </TableHeadRow>

                        {isPatientError === true ? (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "500px",
                                    top: "200px",
                                }}
                            >
                                <h1 className="text-danger">
                                    {isPatientError
                                        ? patientErrorMsg
                                        : "Something went wrong!!"}
                                </h1>
                            </tbody>
                        ) : Array?.isArray(allPatient) ? (
                            <TableBodyContainer
                                isLoading={isPatientLoader}
                                isError={isPatientError}
                            >
                                {allPatient?.map((item: any, index: any) => {
                                    // const dateFormat = new Date(
                                    //     item?.createdAt
                                    // );
                                    const dateFormat =
                                        convertUtcTimestampToLocalDate(
                                            item?.createdAt
                                        );

                                    return (
                                        <TableRow
                                            collapsible={false}
                                            key={index}
                                        >
                                            <TableCell align="center">
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>
                                            <TableCell hover>
                                                <Link
                                                    className="text-dark text-dark fw-bolder text-hover-primary"
                                                    to={`/patient/overview?pId=${item?.uhid}`}
                                                >
                                                    {item?.name
                                                        ? item?.name
                                                        : "N/A"}
                                                </Link>
                                            </TableCell>
                                            <TableCell hover>
                                                {item?.addedByChwName
                                                    ? item?.addedByChwName
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {item?.demographicDetails?.age
                                                    ? `${item?.demographicDetails?.age} years`
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {item?.demographicDetails?.sex
                                                    ? item?.demographicDetails
                                                          ?.sex
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {item?.demographicDetails
                                                    ?.telecom
                                                    ? item?.demographicDetails
                                                          ?.telecom
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell>
                                                {item?.facilityData &&
                                                item?.facilityData[0]
                                                    ? `${item?.facilityData[0].facilityFirstName} ${item?.facilityData[0].facilityLastName}`
                                                    : "--"}
                                            </TableCell>
                                            <TableCell>
                                                {/* {dateFormatter(dateFormat)} */}
                                                {dateFormat}
                                            </TableCell>
                                            <TableCell>
                                                {item?.patientType
                                                    ? item?.patientType
                                                    : "--"}
                                            </TableCell>
                                            {/* <TableCell>
                                                <span
                                                    style={{
                                                        color:
                                                            item?.riskStatus ===
                                                            "Normal"
                                                                ? "#50cd89"
                                                                : item?.riskStatus ===
                                                                  "Moderate Risk"
                                                                ? "#FFA533"
                                                                : item?.riskStatus ===
                                                                  "High-Risk"
                                                                ? "#FF4933"
                                                                : item?.riskStatus ===
                                                                  "Low-Risk"
                                                                ? "#FFDA33"
                                                                : "",
                                                    }}
                                                >
                                                    {item?.riskStatus
                                                        ? item?.riskStatus
                                                        : "N/A"}
                                                </span>
                                            </TableCell> */}

                                            <TableCell>
                                                {
                                                    // facilityCode ?
                                                    <Link
                                                        to={`/patient/overview?pId=${item?.uhid}`}
                                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    >
                                                        DETAILS
                                                    </Link>
                                                    // : (
                                                    //     <button
                                                    //         onClick={() =>
                                                    //             warnUser()
                                                    //         }
                                                    //         className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    //     >
                                                    //         DETAILS
                                                    //     </button>
                                                    // )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBodyContainer>
                        ) : (
                            <tbody
                                style={{
                                    height: "400px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    left: "500px",
                                    top: "200px",
                                }}
                            >
                                <h1 className="text-danger text-nowrap">
                                    {allPatient}
                                </h1>
                            </tbody>
                        )}
                        {/* </table> */}
                    </Table>

                    {!isPatientError && (
                        <Pagination
                            dataCount={totalPatientEntites}
                            handleCallback={(val: number | string) => {
                                setPageLimit(+val);
                            }}
                            handleNext={() =>
                                handlePageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                handlePageNumber(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={currentPageNumber === totalPages}
                            pageNumber={currentPageNumber}
                            pageLimit={pageLimit}
                            prevDisabled={currentPageNumber === 1}
                        />
                    )}
                    {/* end::Table */}
                </div>
            )}
        </div>
    );
};

export { PatientTable };
