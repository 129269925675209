import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { getRefreshedAccess, payload } from "../common/utils/axiosUtils";

const PrivateRoutes = ({ component: Component, ...rest }: any) => {
    const accessToken = localStorage.getItem("access_token");
    const [isIdle, setIsIdle] = useState(false);

    useIdleTimer({
        onIdle: () => setIsIdle(true),
        timeout: 1000 * 60 * 27,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            !isIdle && getRefreshedAccess(payload);
        }, 1000 * 60 * 15);

        return () => clearInterval(interval);
    }, [isIdle]);
    // return (
    //   <React.Fragment>
    //     <Route
    //       {...rest}
    //       render={() =>
    //         accessToken ? (
    //           <MasterLayout>
    //             <Component />
    //           </MasterLayout>
    //         ) : (
    //           // <Redirect from="/auth" exact to="/auth/check-org" />
    //           <Navigate state={{ from: "/auth" }} replace to="/auth/check-org" />
    //         )
    //       }
    //     />
    //   </React.Fragment>
    // );
    return accessToken ? (
        <MasterLayout>
            <Outlet />
        </MasterLayout>
    ) : (
        <Navigate to="/auth/check-org" />
    );
};

export default PrivateRoutes;
