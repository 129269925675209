import { useEffect, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";

import {
    createAccountSchemas,
    initsForFacilityOnboarding,
} from "../models/FacilityOnboarding";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { createNewFacility } from "../../../../services/facility/Facility.hook";
import Stepper from "../../../../components/Stepper";
import { splitString } from "../../../../common/utils/stringUtils";

const FacilityOnboarding = () => {
    const { isError, error, mutate, isSuccess, isLoading }: any =
        useMutateRequest((data: any) => createNewFacility(data));
    const errorMsg = error?.response?.data?.message;

    const navigate = useNavigate();

    const submitStep = (values: any, actions: FormikValues) => {
        // console.log(splitString(values?.state, 1));
        try {
            const payload = {
                facilityFirstName: values.facilityFirstName,
                facilityLastName: values.facilityLastName,
                email: values.email,
                telecom: values.telecom,
                floorNumber: values.floorNumber,
                streetNumber: values.streetNumber,
                postalCode: values.postalCode,
                addressSiteName: values.addressSiteName,
                streetName: values.streetName,
                town: values.town,
                district: values?.district?.split(";")[0],
                districtCode: values?.district?.split(";")[1],
                state: values?.state?.split(";")[0],
                stateCode: values?.state?.split(";")[1],
                country: values.country,
            };
            mutate(payload, {
                onSuccess: (data: any) => {
                    navigate("/facility/facility-management");
                },
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span
                    data-testid="success-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center "
                >
                    {/* {successMsg} */}
                    Successfully Created Facility!!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span
                    data-testid="error-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                >
                    {errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    const handleInputKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const formSteps = [
        {
            title: "Basic Info",
            subTitle: "Your Basic Details",
        },
        {
            title: "Address Details",
            subTitle: "Provide Valid Address Information",
        },
        {
            title: "Additional Details",
            subTitle: "Profile Details",
        },

        {
            title: "Completed",
            subTitle: "Woah, we are here",
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    const StepsContent = (activeStep: number, formikProps: any) => {
        switch (activeStep) {
            case 0:
                return (
                    <div>
                        <Step1 />
                    </div>
                );

            case 1:
                return (
                    <div>
                        <Step2 />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <Step3 {...formikProps} />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Step4 />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>No Step</h1>
                    </div>
                );
        }
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError]);

    return (
        <div>
            <Toaster />

            <div className="d-flex flex-row-fluid flex-center rounded">
                {/* Spinner */}
                {isLoading ? (
                    <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                        <ClipLoader size={70} speedMultiplier={0.6} />
                    </div>
                ) : (
                    // Form
                    <Formik
                        validationSchema={createAccountSchemas[activeIndex]}
                        initialValues={initsForFacilityOnboarding}
                        onSubmit={submitStep}
                        enableReinitialize={true}
                    >
                        {(formikProps) => (
                            <Form onKeyDown={handleInputKeyDown}>
                                <Stepper
                                    steps={formSteps}
                                    activeIndex={activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    stepComponent={
                                        <div className="py-20 w-100 w-xl-750px min-vh-50 px-9">
                                            {StepsContent(
                                                activeIndex,
                                                formikProps
                                            )}
                                        </div>
                                    }
                                    formikProps={formikProps}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default FacilityOnboarding;
