/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";

import useMutateRequest from "../../../hooks/getMutation.query";
import { checkOrgLogin } from "../../../services/auth/OrgLogin.hook";
import OrgFooter from "./components/OrgFooter";
import { useEffect } from "react";

import {
    useFeatureIsOn,
    IfFeatureEnabled,
    useFeatureValue,
} from "@growthbook/growthbook-react";

interface IOrgAccount {
    org: string;
}
const loginSchema = Yup.object().shape({
    org: Yup.string().required("ORGANIZATION ID"),
});

const initialValues: IOrgAccount = {
    org: "",
};

const OrgSignIn = () => {
    // const enabled = useFeatureIsOn("new-org-btn");
    // const value = useFeatureValue("change-color", "grey");
    // console.log("Dash", enabled);
    // console.log("Color", value);
    const navigate = useNavigate();
    const { REACT_APP_ENV } = process.env;
    const envURL =
        REACT_APP_ENV === "dev" || REACT_APP_ENV === "local"
            ? "whims"
            : "ikure";

    const { isLoading, isError, error, mutate }: any = useMutateRequest(
        (data: string) => checkOrgLogin(data)
    );

    const errorMsg = error?.response?.data?.message;

    const onSubmit = (values: IOrgAccount, submitProps: any) => {
        try {
            const payload = {
                organizationId:
                    process.env.REACT_APP_ENV === "prod"
                        ? values?.org?.toLowerCase()
                        : `dev-${values?.org?.toLowerCase()}`,
            };

            mutate(payload, {
                onSuccess: (data: any) => {
                    const resp = data?.data?.data?.organization;

                    if (resp) {
                        let orgName = resp?.organizationName;
                        let organizationId = resp?.organizationId;
                        let organizationStatus = resp?.status;
                        let organizationLogo = resp?.logo;
                        let organizationPlan = resp?.plan;
                        let organizationEmail = resp?.organizationEmail;
                        localStorage.setItem(
                            "orgName",
                            JSON.stringify(orgName)
                        );
                        localStorage.setItem(
                            "organizationPlan",
                            JSON.stringify(organizationPlan)
                        );
                        localStorage.setItem(
                            "organizationId",
                            JSON.stringify(organizationId)
                        );
                        localStorage.setItem(
                            "organizationStatus",
                            JSON.stringify(organizationStatus)
                        );
                        localStorage.setItem(
                            "organizationEmail",
                            JSON.stringify(organizationEmail)
                        );
                        localStorage.setItem(
                            "organizationLogo",
                            JSON.stringify(organizationLogo)
                        );
                    }

                    navigate(`/auth/login`);
                },
                onError: (error: unknown) => {
                    console.log(error);
                    submitProps.resetForm();
                },
            });
        } catch (error) {
            console.log(error);
        }

        submitProps.resetForm();
    };

    const errorToastMsg = (str: string) => {
        toast.error(
            (t) => (
                <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
                    {str
                        ? str
                        : "Something went wrong, Our team looking into this."}
                    <button
                        className="ms-10"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 3000,
                position: "top-right",
                id: "random-id",
            }
        );
    };

    useEffect(() => {
        if (isError) {
            errorToastMsg(isError && errorMsg);
        }
    }, [isError, errorMsg]);

    return (
        <div className=" d-flex flex-column justify-content-center align-items-center h-100 bg-white">
            <Toaster />

            {isLoading === true ? (
                <div className="card w-100 h-550px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <>
                    <Formik
                        validationSchema={loginSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >
                        {(formProps) => (
                            <Form>
                                <div
                                    // style={{ backgroundColor: value }}
                                    className="w-100 h-100 d-flex flex-column align-items-center p-15"
                                >
                                    <div className="w-800px">
                                        <h1
                                            style={{ fontSize: "48px" }}
                                            className="text-dark mb-10 fw-bold text-center w-100"
                                        >
                                            Sign in to your organization
                                        </h1>
                                    </div>
                                    <div className="mb-10">
                                        <span className="fs-2 fw-bold text-dark text-center">
                                            Enter your organization Id
                                        </span>
                                    </div>
                                    {/* {isError === true && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {ErrMsg}
                      </div>
                    </div>
                  )} */}
                                    <div className="d-flex flex-column align-items-center w-450px mb-2">
                                        <div className="input-group d-flex justify-content-center align-items-center">
                                            <Field
                                                type="text"
                                                name="org"
                                                id="org"
                                                placeholder="your-org-id"
                                                className="form-control text-center border-2"
                                            />
                                            <span className="input-group-text text-dark fs-4 fw-bolder bg-transparent">
                                                {`.${envURL}live.com`}
                                            </span>
                                        </div>
                                        <div className="text-center text-danger mt-5">
                                            <ErrorMessage name="org" />
                                        </div>
                                    </div>

                                    <div className="d-flex aligin-items-center">
                                        <button
                                            type="submit"
                                            disabled={
                                                formProps.values.org === "" ||
                                                !formProps.isValid ||
                                                formProps.isSubmitting
                                            }
                                            className={`text-white btn btn-lg btn-primary fs-4 fw-bolder w-450px p-4`}
                                        >
                                            Continue
                                        </button>
                                    </div>

                                    {/* <IfFeatureEnabled feature="new-org-btn">
                                        <div className="mt-5 d-flex aligin-items-center">
                                            <a href="#" className="me-5">
                                                Register new Org
                                            </a>
                                            <a href="#">Forgot org id</a>
                                        </div>
                                    </IfFeatureEnabled> */}
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <OrgFooter />
                </>
            )}
        </div>
    );
};

export default OrgSignIn;
