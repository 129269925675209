import ClipLoader from "react-spinners/ClipLoader";
import CustomTooltip from "../../../components/CustomTooltip";

const FocusedGroupCounterPage = ({
  countersData,
  isLoading,
  isFetching,
}: any) => {
  return (
    <div className="p-10 card">
      <div className="d-flex">
        <h1 className="fs-1 text-dark fw-bolder">FOCUSED GROUP OVERVIEW</h1>
        <CustomTooltip
          style={{ fontSize: "550px" }}
          title="Overview of Focused Group Details"
          placement="right"
          // arrow
        >
          <i className="fs-2x fas fa-info-circle ms-2 bg-transparent border-none"></i>
        </CustomTooltip>
      </div>
      <div className="d-flex mt-5">
        {isLoading || isFetching ? (
          <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-250px h-125px border-gray-500 border-dotted">
            <ClipLoader size={50} speedMultiplier={0.6} />
          </div>
        ) : (
          <div className="border-2 border-light rounded d-flex flex-column align-items-center justify-content-evenly me-15 w-250px h-125px border-gray-500 border-dotted">
            <h3 className="text-success">
              {countersData ? countersData : "No Data"}
            </h3>
            <h3 className="text-uppercase">TOTAL FOCUSED GROUPS</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default FocusedGroupCounterPage;
