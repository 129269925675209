import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import {
    DrawerComponent,
    ScrollComponent,
    ToggleComponent,
} from "../../../assets/ts/components";
import { useNavbar } from "../../../../context/NavbarContext";
import { useLocation } from "react-router-dom";
import { MenuMain } from "./MenuMain";

type Props = {
    asideMenuCSSClasses: string[];
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const { pathname } = useLocation();
    const { userRole } = useNavbar();

    useEffect(() => {
        setTimeout(() => {
            DrawerComponent.reinitialization();
            ToggleComponent.reinitialization();
            ScrollComponent.reinitialization();
        }, 50);
    }, [pathname]);

    return (
        <div
            style={{ overflowX: "hidden" }}
            id="kt_aside_menu_wrapper"
            ref={scrollRef}
            className="hover-scroll-overlay-y my-5 my-lg-5"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0"
        >
            <div
                id="#kt_aside_menu"
                data-kt-menu="true"
                className={clsx(
                    "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
                    asideMenuCSSClasses.join(" ")
                )}
            >
                <MenuMain />
            </div>
        </div>
    );
};

export { AsideMenu };
