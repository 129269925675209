import { useState } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import AsyncSelect from "react-select/async";

import {
  initalValues,
  unassignedTaskValidationSchema,
} from "../models/UnassignedTaskModel";
import useMutateRequest from "../../../hooks/getMutation.query";
import useGetRequest from "../../../hooks/getRequest.query";
import {
  getCHWPendingTasks,
  assignTaskToCHW,
} from "../../../services/task/UnassignedTasks.hook";

type ExportProps = {
  onClose: () => void;
  styles?: any;
  allTasks: any;
  refetch: any;
};

const AssignTask = ({ onClose, styles, allTasks, refetch }: ExportProps) => {
  const [msg, setMsg] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [chwName, setCHWName] = useState("");
  const facilityCode = localStorage.getItem("facilityCode");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit] = useState(20);

  // Get list of all chws
  const { data, isLoading }: any = useGetRequest(
    ["getCHWPendingTasks", pageNumber],
    (data: any) =>
      getCHWPendingTasks({
        facilityCode,
        page: pageNumber,
        limit: pageLimit,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  const chwData = data?.data?.data?.chws;

  const checkCHWTasks = allTasks?.filter((task: any) => {
    return task?.isChecked === true;
  });

  const taskIds = checkCHWTasks?.map((task: any) => {
    return task?._id;
  });

  const selectedCHWId = chwData?.filter((task: any) => {
    return task?.name === chwName;
  });

  const chwId = selectedCHWId?.map((selectedId: any) => {
    return selectedId?.chwId;
  });

  // Assign a CHW a tasks
  const {
    isLoading: assignCHWLoader,
    mutate,
    isError,
    error,
  }: any = useMutateRequest((data: any) => assignTaskToCHW(data));

  const ErrorMsg = error?.response?.data?.message;

  const onSubmit = () => {
    const payload = {
      taskIds: taskIds,
      chwId: String(chwId),
    };

    try {
      mutate(payload, {
        onSuccess(data: any) {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },

        onError(error: any) {
          setMsg(true);
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
          }, 1500);
        },
      });
    } catch (error: any) {
      console.log(error);
    }
  };
  const colorStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: "none" }),
    option: (styles: any, { data, isFocused, isSelected, isDisabled }: any) => {
      return { ...styles, color: data?.color };
    },
  };

  const transformedText = chwData?.map((chw: any) => {
    const pendingTasks = chw?.pendingTasksCount;
    let label = `${chw?.name} ( ${pendingTasks} pending tasks )`;
    return {
      label: label,
      value: chw?.name,
      // color: "#fefdfd",
    };
  });

  const loadOptions = (searchValue: any, callback: any) => {
    setTimeout(() => {
      const filteredOptions = transformedText?.filter((chw: any) =>
        chw?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      callback(filteredOptions);
    }, 2000);
  };

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      refetch();
    }, 1500);
  };

  return (
    <>
      {isLoading || assignCHWLoader || spinner ? (
        <div
          style={{ backgroundColor: "transparent" }}
          className="h-225px d-flex flex-column justify-content-center align-items-center"
        >
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : msg ? (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.msgHeight,
            padding: styles?.padding,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {isError === true ? (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                {`Failed to assign the task`}
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
                <button
                  onClick={() => setMsg(false)}
                  className="w-150px btn btn-lg btn-primary me-5"
                >
                  Please Retry
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="fs-1 fw-bolder text-center text-dark">
                Successfully assigned tasks to chw
              </p>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  onClick={onModalClose}
                  className="w-150px btn btn-lg btn-danger me-5"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: styles?.backgroundColor,
            width: styles?.width,
            height: styles?.height,
            padding: styles?.padding,
          }}
        >
          <Formik
            initialValues={initalValues}
            validationSchema={unassignedTaskValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formProps: any) => {
              setCHWName(formProps?.values?.chwName);
              return (
                <Form>
                  <h1 className="text-center">ASSIGN TASK TO CHW</h1>
                  <div className="mt-8">
                    <label
                      htmlFor="chwName"
                      className="form-label fs-5 fw-bolder required"
                    >
                      CHW NAME
                    </label>

                    <AsyncSelect
                      isSearchable
                      isLoading={isLoading}
                      loadOptions={loadOptions}
                      defaultOptions
                      name="chwName"
                      onChange={(selectedOption: any) =>
                        formProps.setFieldValue("chwName", selectedOption.value)
                      }
                      styles={colorStyles}
                      placeholder="Select the CHW"
                    />

                    <div className="text-danger mt-5">
                      <ErrorMessage name="chwName" />
                    </div>
                  </div>

                  <div className="mt-5 text-center">
                    <h2 className="fs-3 fw-bolder">
                      {checkCHWTasks?.length} Patinets Selected
                    </h2>
                  </div>

                  <div className="mt-10 d-flex justify-content-center align-items-center">
                    <button
                      onClick={onClose}
                      className="w-150px btn btn-md btn-danger me-5"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={
                        formProps.isSubmitting ||
                        !(formProps.isValid && formProps.dirty)
                      }
                      className="w-150px btn btn-md btn-primary"
                    >
                      Assign
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AssignTask;
