import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { prevMonth } from "../../../../common/utils/datesUtils";
import { useNavbar } from "../../../../context/NavbarContext";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import { getCHWOverviewViaChart } from "../../../../services/performance-analysis/PerformanceAnalysis.hook";

const ChartViewPage = () => {
  let defaultData = prevMonth && prevMonth[0]?.monthYear;
  const [period, setPeriod] = useState<string>(defaultData);
  const handlePeriod = (event: any) => {
    setPeriod(event.target.value);
  };

  let splitMonthAndYear = period?.split(" ");
  let splitMonth = splitMonthAndYear && splitMonthAndYear[0];
  let splitYear = splitMonthAndYear && splitMonthAndYear[1];
  const facilityCode: any = localStorage.getItem("facilityCode");
  const moduleName = "Performance Monitoring";

  const { chwId: id }: any = useSearchParams();
  const {
    data: chartData,
    isLoading: isChartLoading,
    isFetching: isChartFetching,
    isError: isChartError,
    error: chartError,
    refetch: chartRefetcher,
  }: any = useGetRequest(
    ["getCHWOverviewViaChart", id],
    () =>
      getCHWOverviewViaChart({
        facilityCode,
        moduleName,
        month: splitMonth,
        year: splitYear,
        chwId: id,
      }),
    { staleTime: 100000, refetchOnWindowFocus: false, enabled: !!id }
  );

  // Chart View API

  const chartViewData = chartData?.data?.data?.target;
  const chartErrorMsg = chartError?.response?.data?.message;

  const custuomChartViewData = chartViewData?.map((cd: any) => {
    return {
      TargetName: cd?.targetName,
      Target: cd?.target,
      Achievment: cd?.achievment,
      totalTargetsRemaining: cd?.totalTargetsRemaining,
      Name: cd?.name,
    };
  });
  const { selectedFacility } = useNavbar();

  useEffect(() => {
    if (id) chartRefetcher();
  }, [period, selectedFacility]);

  return (
    <>
      {/* Month Selection */}
      <div className="fv-row d-flex align-items-end card p-5">
        <select
          onChange={handlePeriod}
          className="w-200px text-start form-select form-select-lg form-select-solid"
        >
          {prevMonth?.map((month: any) => {
            return (
              <>
                <option
                  selected={period === month?.monthYear}
                  value={month?.monthYear}
                >
                  {month?.monthAndYear}
                </option>
              </>
            );
          })}
        </select>
      </div>
      {isChartLoading || isChartFetching ? (
        <div className="w-100 h-400px bg-white p-5 d-flex align-items-center justify-content-center">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isChartError === true ? (
        <div className="w-100 h-400px bg-white p-5 d-flex align-items-center justify-content-center">
          <h1 className="fw-bolder text-danger">{chartErrorMsg}</h1>
        </div>
      ) : (
        <div className="w-100 bg-white p-5 d-flex flex-wrap align-items-center justify-content-center">
          {custuomChartViewData?.map((data: any) => {
            let dArray = [data];

            return (
              <ResponsiveContainer
                height={300}
                width="40%"
                key={data?.TargetName}
              >
                <BarChart
                  barGap={25}
                  barSize={125}
                  width={450}
                  height={300}
                  data={dArray}
                  margin={{
                    top: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis dataKey="TargetName" />
                  <YAxis dataKey="" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Target" fill="#82ca9d" />
                  <Bar dataKey="Achievment" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ChartViewPage;
