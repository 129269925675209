/* eslint-disable jsx-a11y/anchor-is-valid */
import Tab from "../../components/Tabs/Tab";
import TabContainer from "../../components/Tabs/Tabs";
import BatchDetails from "./components/BatchDetails";
import { CardHeader } from "./components/CardHeader";

import HealthCardPage from "./HealthCardPage";

const BatchListOverview = () => {
  return (
    <>
      <CardHeader />

      <TabContainer initialValue="batch-details">
        <Tab eventKey="batch-details" title="BATCH DETAILS">
          <BatchDetails />
        </Tab>
        <Tab eventKey="health-cards" title="LIST OF HEALTH CARDS">
          <HealthCardPage />
        </Tab>
      </TabContainer>
      {/* <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route
            path="/batch/:id/overview/batch-details"
            exact
            component={BatchDetails}
          />
          <Route
            path="/batch/:id/overview/card-list"
            component={HealthCardPage}
          />
          <Route
            path="/batch/:id/overview/edit-card-details"
            component={EditCardDetails}
          /> */}

      {/* <Redirect
            from="/batch/:id/overview"
            to="/batch/:id/overview/batch-details"
          /> */}
      {/* <Redirect from="/batch" to="/batch/batch-details" />
          <Redirect to="error/404" /> */}
      {/* </Switch>
      </Suspense> */}
    </>
  );
};

export default BatchListOverview;
