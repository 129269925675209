import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useLayout } from "../core";
import { DrawerComponent } from "../../assets/ts/components";

type Props = {
  children: React.ReactNode;
};

const Content = ({ children }: Props) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return <div className="w-100">{children}</div>;
};

export { Content };
