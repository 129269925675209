import * as Yup from "yup";
import dayjs from "dayjs";
import {
    SUPPORTED_FORMATS,
    addressSchema,
    numberValidator,
    passwordValidator,
    phoneValidator,
    stringValidator,
} from "../../../../../common/validators";

export const initsForDoctor = {
    // Step 1
    userName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    email: "",
    phoneNumber: "",

    // Step 2
    photo: "",
    sex: "",
    qualification: "",
    birthdate: "",
    age: "",
    language: "",

    // Step 3
    floorNumber: "",
    addressSiteName: " ",
    streetName: "",
    streetNumber: "",
    district: "",
    postalCode: "",
    town: "",
    state: "",
    country: "",

    // Step 4
    licenseNumber: "",
    licenseLocation: "",
    licenseProvider: "",
    // licenseVerified: "",

    // Step 5
    facility: "",
    organization: "",
    specialization: "",

    // Step 6
    physicalConsultationFee: "150",
    physicalConsultationLimit: "20",
    onlineConsultationFee: "100",
    onlineConsultationLimit: "10",
};

export const doctorOnboardingSchemas = [
    // Step 1
    Yup.object({
        userName: Yup.string(),
        firstName: Yup.string()
            .matches(stringValidator, "Please enter valid first name")
            .required()
            .label("First Name"),
        middleName: Yup.string()
            .matches(stringValidator, "Please enter valid middle name")
            .label("Middle Name"),
        lastName: Yup.string()
            .matches(stringValidator, "Please enter valid last name")
            .required()
            .label("Last Name"),
        password: Yup.string()
            .matches(
                passwordValidator,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            )
            .required()
            .label("Password"),
        email: Yup.string()
            .email("Please enter a vaild email")
            .required()
            .label("Email Id"),
        phoneNumber: Yup.string()
            .matches(
                phoneValidator,
                "Phone number is not valid, must be only digits"
            )
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits")
            // .oneOf([Yup.ref("userName"), null], "User Name don't match!")
            .required("Phone Number is Required!")
            .label("Phone Number"),
    }),
    // Step 2
    Yup.object({
        photo: Yup.mixed()
            .nullable()
            .required()
            .label("Photo")
            .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        qualification: Yup.string().required().label("Qualification"),
        // birthdate: Yup.string().label("Date of Birth"),
        // age: Yup.number().moreThan(0, "Greater than zero").label("Age"),
        birthdate: Yup.date()
            .required()
            .label("Birth Date")
            .test(
                "age-match",
                "Age does not match birth date",
                function (value) {
                    const { age } = this.parent;
                    if (!value || age === undefined) {
                        return true;
                    }
                    return dayjs().diff(dayjs(value), "year") === age;
                }
            ),
        age: Yup.number()
            .moreThan(18, "You must be over the age of 18 to register")
            .lessThan(100, "Are you really that old?")
            .typeError("The accepted value is only number")
            .label("Age"),
        sex: Yup.string().required().label("Gender"),
        language: Yup.string().required().label("Language"),
    }),
    // Step 3
    addressSchema.shape({
        addressSiteName: Yup.string()
            .required("Address site name is required")
            .label("Address Site Name"),
    }),
    // Yup.object({
    //     floorNumber: Yup.string().label("Floor Number"),
    //     addressSiteName: Yup.string()
    //         .required("Address site name is required")
    //         .label("Address Site Name"),
    //     streetName: Yup.string().label("Street Name"),
    //     streetNumber: Yup.string().label("Street Number"),
    //     district: Yup.string().required().label("District"),
    //     postalCode: Yup.string()
    //         .matches(numberValidator, "Postal Code is not valid")
    //         .required()
    //         .label("Postal Code"),
    //     town: Yup.string().required().label("Town"),
    //     state: Yup.string().required().label("State"),
    //     country: Yup.string().required().label("Country"),
    // }),
    // Step 4
    Yup.object({
        license: Yup.mixed()
            .nullable()
            .required("License")
            .label("License")
            .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        licenseNumber: Yup.string()
            .required("License Number is required")
            .label("License Number"),
        licenseProvider: Yup.string().required().label("License Provider"),
        // licenseVerified: Yup.string().required().label("Verification"),
    }),
    // Step 5
    Yup.object({
        facility: Yup.array()
            .min(1, "You can't leave this blank.")
            .required("You can't leave this blank.")
            .nullable()
            .label("Facility"),
        organization: Yup.string().label("Organization"),
        signature: Yup.mixed()
            .nullable()
            .required()
            .label("Signature")
            .test(
                "FILE_SIZE",
                "Uploaded file is too big.",
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                "FILE_FORMAT",
                "Uploaded file has unsupported format.",
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        specialization: Yup.string()
            .required("Specialization is required.")
            .label("Specialization"),
    }),
    // Step 6
    Yup.object({
        physicalConsultationFee: Yup.string()
            .matches(numberValidator, "Fee's is not valid")
            .min(1, "Must be greater than 1")
            .label("Physical Consultation Fee"),
        physicalConsultationLimit: Yup.string()
            .matches(numberValidator, "Limit is not valid type")
            .label("Physical Consultation Limit"),
        onlineConsultationFee: Yup.string()
            .matches(numberValidator, "Fee's is not valid")
            .label("Online Consultation Fee"),
        onlineConsultationLimit: Yup.string()
            .matches(numberValidator, "Limit is not valid type")
            .label("Online Consultation Limit"),
    }),
];
