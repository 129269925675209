import $axiosProtected from "../../common/utils/axiosUtils";

// Get Patient Consultation Details by Facility Code
export const getPatientConsultationsByFacility = async (payload: any) => {
    return await $axiosProtected().post(
        `/scheduler/schedule-consultation/facility/status/web?page=${payload.page}&limit=${payload.limit}&consultationType=${payload.consultationType}&specialization=${payload.specialization}&status=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
        payload
    );
};

// Get list of doctor specialization

export const getDoctorSpecializations = async () => {
    return await $axiosProtected().get(`/doctor/specialization/list`);
};
