import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import MultiSection from "./components/MultiSection";

import {
  initalValues,
  formBuilderValidationSchema,
  formBuilderValueTypes,
} from "./models/FormBuilderModel";
import useMutateRequest from "../../hooks/getMutation.query";
import { createForm } from "../../services/formbuilder/Formbuilder.hook";
import { StepperComponent } from "../../_metronic/assets/ts/components";
import { KTSVG } from "../../_metronic/helpers";

const CreateForm: any = () => {
  const navigate = useNavigate();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const [isSubmitButton, setSubmitButton] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState<boolean>(false);

  // Toast
  const [open, setOpen] = useState<boolean>(false);
  const [initValues] = useState<formBuilderValueTypes>(initalValues);
  const [currentSchema, setCurrentSchema] = useState(
    formBuilderValidationSchema[0]
  );
  const [spinner, setSpinner] = useState(false);

  const { state }: any = useLocation();
  const focusedGroupId = state?.focusedGroupId;
  const visitNumber = state?.item?.visitNumber;
  // API Call
  const { mutate, isError, error, isLoading }: any = useMutateRequest(
    (data: string) => createForm(data)
  );
  const errorMsg = error?.response?.data?.message;

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1
    );

    stepper.current.goPrev();

    setCurrentSchema(
      formBuilderValidationSchema[stepper.current.currentStepIndex - 1]
    );
  };

  const submitStep = (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1
    );

    setCurrentSchema(
      formBuilderValidationSchema[stepper.current.currentStepIndex]
    );

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);

      let isFormPublished: any = true;
      const facilityCode: any = localStorage.getItem("facilityCode");

      const sections: any = values.sections.map((section: any) => {
        const v = {
          secId: section.secId,
          sectionName: section.sectionName,
          nextPage: section.nextPage,
          questions: section.questions.map((question: any, index: any) => {
            const q = {
              questionName: question.questionName,
              questionType: question.questionType,
              questionNumber: index + 1,
              description: question.description,
              // Switches
              questionRequired: question.questionRequired,
              descriptionRequired: question.descriptionRequired,
              validateTextField: question.validateTextField,
              isConditionalQuestion: question.isConditionalQuestion,
              // Vaildation
              validations: {
                dataType: question.validations.dataType,
                minValue: question.validations.minValue,
                maxValue: question.validations.maxValue,
                minLength: question.validations.minLength,
                maxLength: question.validations.maxLength,
              },
              options: question.options.map((optionItem: any) => {
                return { option: optionItem.option };
              }),
              conditions: question.conditions.map((conditionItem: any) => {
                let condition =
                  conditionItem.textCondition !== ""
                    ? conditionItem.textCondition
                    : conditionItem.numberCondition;
                return {
                  condition,
                  conditionValue: conditionItem.conditionValue,
                  questionNumber: conditionItem.questionNumber,
                };
              }),
              status: true,
            };
            return q;
          }),
        };
        return v;
      });
      try {
        let payload = {
          focusedGroupId: focusedGroupId,
          visitNumber: visitNumber,
          facilityCode: facilityCode,
          publish: isFormPublished,
          formName: values.formName,
          description: values.description,
          sections,
        };

        mutate(payload, {
          onSuccess: (data: any) => {
            setSpinner(true);
            setOpen(true);
            setMsg(true);
            setTimeout(() => {
              setSpinner(false);
              setOpen(false);
              actions.resetForm();
              navigate("/focused-group/management");
            }, 3000);
          },
          onError: (error: any) => {
            if (isError === true) {
              setSpinner(true);
              setOpen(true);
              setMsg(false);
              setTimeout(() => {
                setSpinner(false);
                setOpen(false);
                actions.resetForm();
                navigate("/form-management");
              }, 3000);
            }
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully Created Form !!
          <button
            className="ms-15"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "success-toast",
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}

          <button
            className="ms-15"
            style={{ backgroundColor: "transparent", border: "0" }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: "top-right",
        id: "error-toast",
      }
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <>
            <Toaster />
            {open && msg === true && successToastMsg()}
            {open && isError === true && errorToastMsg()}
            <div
              ref={stepperRef}
              className="stepper stepper-links d-flex flex-column"
              id="kt_create_account_stepper"
            >
              <div className="stepper-nav w-100 d-flex">
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Form Details</h3>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <h3 className="stepper-title">Form Builder</h3>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <h3 className="stepper-title">Completed</h3>
                </div>
              </div>

              <div className="d-flex">
                {/* Spinner */}
                {isLoading === true ? (
                  <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                  </div>
                ) : (
                  <Formik
                    validationSchema={currentSchema}
                    initialValues={initValues}
                    onSubmit={submitStep}
                    enableReinitialize={true}
                  >
                    {(formikProps) => {
                      return (
                        <Form className="w-100">
                          <div
                            className="current"
                            data-kt-stepper-element="content"
                          >
                            <div className="w-100">
                              <Step1 />
                            </div>
                          </div>

                          <div data-kt-stepper-element="content">
                            <div className="w-100">
                              <MultiSection />
                            </div>
                          </div>

                          <div data-kt-stepper-element="content">
                            <div className="w-100">
                              <Step2 />
                            </div>
                          </div>

                          <div className="w-100 mt-4">
                            <div className="d-flex flex-stack w-650px mx-auto p-5">
                              <div className="mr-2">
                                <button
                                  onClick={prevStep}
                                  type="button"
                                  className="btn btn-lg btn-light-primary me-3"
                                  data-kt-stepper-action="previous"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/arrows/arr063.svg"
                                    className="svg-icon-4 me-1"
                                  />
                                  Back
                                </button>
                              </div>

                              <div className="">
                                <button
                                  type="submit"
                                  disabled={!formikProps.isValid}
                                  className="btn btn-lg btn-primary me-3"
                                >
                                  <span className="indicator-label">
                                    {!isSubmitButton && "Continue"}
                                    {isSubmitButton && "Publish"}
                                    <KTSVG
                                      path="/media/icons/duotune/arrows/arr064.svg"
                                      className="svg-icon-3 ms-2 me-0"
                                    />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default CreateForm;
