export const Permissions = {
    read: "READ",
    update: "UPDATE",
    create: "CREATE",
    search_bar: "SEARCH_BAR",

    assign_button: "ASSIGN_BUTTON",
    reassign_button: "REASSIGN_BUTTON",
    distribute_button: "DISTRIBUTE_BUTTON",
    export_button: "EXPORT_BUTTON",
    delete_button: "DELETE_BUTTON",
    filter_button: "FILTER_BUTTON",
    detail_button: "DETAIL_BUTTON",

    create_form: "CREATE_FORM",
    view_form: "VIEW_FORM",
};

export type IPermission =
    | "READ"
    | "WRITE"
    | "UPDATE"
    | "DELETE"
    | "EXPORT"
    | "CREATE";

export type IModules =
    | "DASHBOARD"
    | "SETTINGS"
    | "PERFORMANCE_MONITORING"
    | "LIST_REPORT"
    | "CREATE_COURSE"
    | "CREATE_CATEGORY_SUBCATEGORY"
    | "LIST_CATEGORY_SUBCATEGORY"
    | "INSIGHTS_TASK"
    | "LIST_COURSE"
    | "LIST_FACILITY"
    | "ONBOARD_FACILITY_MANAGER"
    | "ONBOARD_FACILITY"
    | "ONBOARD_CHW"
    | "LIST_TASK"
    | "LIST_UNASSIGNED_TASK"
    | "LIST_CHW"
    | "LIST_DOCTOR"
    | "ONBOARD_DOCTOR"
    | "LIST_BATCH"
    | "LIST_UNUSED_HEALTHCARDS"
    | "LIST_PATIENT"
    | "LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT"
    | "LIST_FOCUSED_GROUP"
    | "GENERATE_HEALTHCARDS"
    | "CREATE_FOCUSED_GROUP"
    | "FORM_BUILDER"
    | "LIST_INDENT"
    | "LIST_PURCHASE_ORDER"
    | "LIST_GRN"
    | "LIST_MASTER_PRODUCT"
    | "LIST_FACILITY_STOCK"
    | "LIST_CHW_STOCK"
    | "LIST_SALES_ORDER";

type DashboardSubtypes = "CHARTS" | "TABLES";
type SettingsSubtypes = "USER" | "APP";
type CHWSubtypes = "CONTAINER_CHW" | "LIST_ATTENDANCE" | "TASK_LIST";
type DoctorSubtypes = "CONTAINER_DOCTOR" | "LIST_APPOINTMENT";
type PatientSubtypes =
    | "CONTAINER_PATIENT"
    | "LIST_PATIENT_APPOINTMENT"
    | "LIST_ASSIGNED_FOCUSED_GROUPS";
type HealthCardSubtypes = "CONTAINER_BATCH" | "LIST_HEALTHCARDS";
type TaskSubtypes = "CONTAINER_TASK";
type TrainingSubtypes = "CONTAINER_COURSE";
type FocusedGroupSubtypes =
    | "CONTAINER_FOCUSED_GROUP"
    | "LIST_FORM_ENTRIES"
    | "LIST_BENEFICIARY_VITALS"
    | "LIST_BENEFICIARY_ENTRIES"
    | "LIST_FORMS";

type IndentSubtypes =
    | "MODIFY_INDENT"
    | "INDENT_DETAILS"
    | "CREATE_PURCHASE_ORDER";

type PurchaseOrderSubtypes = "PURCHASE_ORDER_DETAILS" | "CREATE_GRN";
type SalesOrderSubtypes = "SALE_ORDER_DETAILS";

export type MainType = IModules;
export type SubType<Main extends IModules> = Main extends "DASHBOARD"
    ? DashboardSubtypes
    : Main extends "SETTINGS"
    ? SettingsSubtypes
    : Main extends "LIST_CHW"
    ? CHWSubtypes
    : Main extends "LIST_DOCTOR"
    ? DoctorSubtypes
    : Main extends "LIST_COURSE"
    ? TrainingSubtypes
    : Main extends "LIST_TASK"
    ? TaskSubtypes
    : Main extends "LIST_BATCH"
    ? HealthCardSubtypes
    : Main extends "LIST_FOCUSED_GROUP"
    ? FocusedGroupSubtypes
    : Main extends "LIST_PATIENT"
    ? PatientSubtypes
    : Main extends "LIST_INDENT"
    ? IndentSubtypes
    : Main extends "LIST_PURCHASE_ORDER"
    ? PurchaseOrderSubtypes
    : Main extends "LIST_SALES_ORDER"
    ? SalesOrderSubtypes
    : never;

const {
    distribute_button,
    filter_button,
    detail_button,
    assign_button,
    create,
    create_form,
    delete_button,
    export_button,
    read,
    reassign_button,
    search_bar,
    update,
    view_form,
} = Permissions;

export const defaultActions = [];

export const tablePermissions = [
    read,
    search_bar,
    export_button,
    filter_button,
    detail_button,
];

export const chwTablePermissions = [
    read,
    search_bar,
    export_button,
    filter_button,
    detail_button,
    distribute_button,
    reassign_button,
    delete_button,
];
