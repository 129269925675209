import $axiosProtected from "../../common/utils/axiosUtils";

// Get List of all Facilites Details
export const getAllFacilites = async () => {
    return await $axiosProtected().get(`/organization/facility/all`);
};

export const getFacilityList = async (payload: any) => {
    return await $axiosProtected().get(
        `/organization/facility/web-portal/all?page=${payload.page}&limit=${payload.limit}`
    );
};

// Create a new facility
export const createNewFacility = async (payload: any) => {
    return await $axiosProtected().post(`/organization/facility`, payload);
};

// Onboard new facility manager
export const onboardFacilityManager = async (payload: any) => {
    return await $axiosProtected().post(
        `/acc/facility-manager/store`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};
