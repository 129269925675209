import FormBuilder from "./FormBuilder";

const FormPage = () => {
  return (
    <div>
      <FormBuilder />
    </div>
  );
};

export default FormPage;
