import { useState } from "react";
import Categories from "./components/Categories";

const CategoryList = () => {
  return (
    <>
      <div className="card p-5 d-flex justify-content-between">
        <h1 className="fw-bolder text-center">
          List of Category and Sub Category
        </h1>
      </div>

      <div className="mt-5">
        <>
          <Categories />
        </>
      </div>
    </>
  );
};

export default CategoryList;
