import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import toast from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import ClipLoader from "react-spinners/ClipLoader";

import useMutateRequest from "../../../../../../hooks/getMutation.query";
import { addMember } from "../../../../../../services/patient/PatientHealthcard.service";
import { getPatientByUHID } from "../../../../../../services/patient/PatientManagement.hook";

type Props = {
    onClose: Function;
    healthCardNo: any;
};

const validationSchema = Yup.object().shape({
    patientUHID: Yup.number()
        .typeError("Patient UHID must be a number")
        .required("Patient UHID Number is required"),
});

const AddBeneficiaries = (props: Props) => {
    const [patientUHID, setPatientUHID] = useState(0);

    // Get healthcard detials
    const { data, mutate: getPatientInfo }: any = useMutateRequest(
        (data: any) => getPatientByUHID(data)
    );
    const getData = () => {
        try {
            const payload = {
                uhid: patientUHID,
            };
            getPatientInfo(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    setPatientUHID(0);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (patientUHID) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientUHID]);

    const patientDetails = data?.data?.data?.patient;

    // const filteredArray = patientDetails?.healthCardNumber?.filter(
    //     (item: any) => item?.status === true
    // );
    // const healthcard = filteredArray && filteredArray[0]?.id;
    const healthcard = props.healthCardNo;

    // Add healthcard subscription
    const { isError, error, mutate, isSuccess, isLoading }: any =
        useMutateRequest((data: any) => addMember(data));
    const errorMsg = error?.response?.data?.message;

    const submitStep = (values: any, actions: FormikValues) => {
        try {
            const payload = {
                uhid: patientUHID,
                healthCard: healthcard,
            };

            mutate(payload, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        props.onClose();
                    }, 3000);
                },
                onError: (error: any) => {
                    setTimeout(() => {
                        actions.resetForm();
                    }, 3000);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span
                    data-testid="success-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center "
                >
                    Successfully added beneficiary!!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = () => {
        toast.error(
            (t) => (
                <span
                    data-testid="error-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                >
                    {errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);
    return (
        <>
            {isLoading ? (
                <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader
                        data-testid="spinner"
                        size={70}
                        speedMultiplier={0.6}
                    />
                </div>
            ) : (
                <div>
                    <h1 className="text-center text-bolder">Add Beneficiary</h1>
                    <div className="mt-5" style={{ width: "100%" }}>
                        <Formik
                            initialValues={{
                                patientUHID: "",
                            }}
                            validationSchema={validationSchema}
                            // onSubmit={(values) => console.log(values)}
                            onSubmit={submitStep}
                        >
                            {(formik) => (
                                <Form>
                                    <div className=" d-flex justify-content-between">
                                        <p className="fw-bold  fs-5">
                                            Health card :
                                        </p>
                                        <p className="fs-5">
                                            {healthcard ? healthcard : "N/A"}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div
                                            className="fs-5 fw-bold text-dark required mr-10"
                                            style={{ width: "100%" }}
                                        >
                                            Patient UHID :
                                        </div>
                                        <div>
                                            <Field
                                                placeholder="Patient UHID"
                                                style={{ width: "350px" }}
                                                className="form-control form-control-lg form-control-solid "
                                                type="text"
                                                name="patientUHID"
                                                autoComplete="off"
                                                onChange={(e: any) => {
                                                    formik.handleChange(e);
                                                    _.debounce(
                                                        // @ts-ignore
                                                        () =>
                                                            setPatientUHID(
                                                                e.target.value
                                                            ),
                                                        2000
                                                    )();
                                                }}
                                                value={
                                                    formik.values.patientUHID
                                                }
                                            />
                                            <div className="text-danger text-center mt-2">
                                                <ErrorMessage name="patientUHID" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <p className="fw-bold  fs-5">Name :</p>
                                        <p className="fs-5">
                                            {patientDetails
                                                ? patientDetails?.name
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <p className="fw-bold  fs-5">Age :</p>
                                        <p className="fs-5">
                                            {patientDetails
                                                ? patientDetails
                                                      ?.demographicDetails?.age
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <p className="fw-bold  fs-5">
                                            Gender :
                                        </p>
                                        <p className="fs-5">
                                            {patientDetails
                                                ? patientDetails
                                                      ?.demographicDetails?.sex
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button
                                            className="btn btn-lg btn-danger fs-4 fw-bolder w-100 p-4"
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => props.onClose()}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={
                                                !formik.isValid ||
                                                formik.isSubmitting
                                            }
                                            type="submit"
                                            className="btn btn-lg btn-primary fs-4 fw-bolder w-100 p-4"
                                        >
                                            Add beneficiary
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddBeneficiaries;
