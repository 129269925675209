import { useEffect } from "react";
import CourseSettings from "./components/CourseSettings";
import IntendedLearners from "./components/IntendedLearners";
import CourseLandingPage from "./components/CourseLandingPage";
import PublishCourse from "./components/publish/PublishCourse";
import CourseCurriculum from "./components/course-curriculum/CourseCurriculum";
import EditCoursePage from "./components/edit-publish/EditCoursePage";
import TabContainer from "../../../components/Tabs/Tabs";
import Tab from "../../../components/Tabs/Tab";
import useSearchParams from "../../../hooks/useSearchParams";
import useGetRequest from "../../../hooks/getRequest.query";
import { getCourseContentById } from "../../../services/web-content/WebContent.hook";

const ContentOverview = () => {
  const { courseId: id }: any = useSearchParams();
  // Get Course content
  const {
    data: courseContent,
    isLoading,
    isFetching,
    refetch: courseContentRefetch,
    error: courseContentError,
  }: any = useGetRequest(
    ["getCourseContentById", id],
    async () =>
      await getCourseContentById({
        courseId: String(id),
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (id) courseContentRefetch();
  }, [id, courseContentRefetch]);

  return (
    <>
      <TabContainer initialValue="course">
        <Tab eventKey="course" title="COURSE CURRICULUM">
          <CourseCurriculum
            courseContent={courseContent}
            courseContentRefetch={courseContentRefetch}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        </Tab>
        <Tab eventKey="content" title="CONTENT BUILDER">
          <PublishCourse courseContentRefetch={courseContentRefetch} />
        </Tab>
      </TabContainer>
    </>
  );
};

export default ContentOverview;
