// Copyright 2023 Ikure Pvt

/**
 * To create reusable Yup schema validations for fields like,
 *  - first name
 *  - middle name
 *  - last name
 *  - email
 *  - phone number
 *  - password
 *  - username
 *
 *  you can use the `yup` library and define the schema,
 *  using the `object()` method.
 *
 *  Here's an example:
 */

import * as Yup from "yup";
import { alphaNumericValidator, numberValidator } from "../validators";

export const anySchema = Yup.string();

export const alphaNumericSchema = Yup.string()
    .matches(alphaNumericValidator, "Only alphabets and numbers allowed")
    .max(50, "Maximum 50 characters allowed")
    .required("This field is required");

export const stringSchema = Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces allowed")
    .max(50, "Maximum 50 characters allowed")
    .required("This field is required");

const emailSchema = Yup.string()
    .email("Invalid email address")
    .required("This field is required");

const phoneSchema = Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number should contain 10 digits only")
    .required("This field is required");

const passwordSchema = Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("This field is required");

const usernameSchema = Yup.string()
    .min(3, "Username must be at least 3 characters")
    .max(50, "Maximum 50 characters allowed")
    .required("This field is required");

export const numberSchema = Yup.string().matches(
    numberValidator,
    "Number is not valid, must be only digits"
);

const schema = Yup.object().shape({
    firstName: stringSchema,
    middleName: stringSchema,
    lastName: stringSchema,
    email: emailSchema,
    phone: phoneSchema,
    password: passwordSchema,
    username: usernameSchema,
});

/**
 * In this example,
 * we're defining schema validations for each field using the `string()` method
 * and chaining the required validation using the `required()` method.
 * We're also using additional methods like `matches()` to validate the format of the fields.
 *
 *
 * To omit a field from the schema or extend it with a new field,
 * you can use the `omit()` and `concat()` methods respectively.
 *
 *
 * For example,
 * to omit the middle name field from the above schema, you can do:
 */

// const newSchema = schema.omit('middleName');

/**
 * And to extend the schema with a new `confirmPassword` field,
 * you can do:
 */

const confirmPasswordSchema = Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("This field is required");

const extendedSchema = schema.concat(
    Yup.object().shape({
        confirmPassword: confirmPasswordSchema,
    })
);

/**
 * Here, we're defining the new `confirmPasswordSchema` separately
 * and using the `concat()` method to extend,
 * the existing schema with the new field.
 */
