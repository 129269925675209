export const statesOptions = [
  { stateName: "An Giang" },
  { stateName: "Ba Ria - Vung Tau" },
  { stateName: "Bac Lieu" },
  { stateName: "Bac Kan" },
  { stateName: "Bac Giang" },
  { stateName: "Bac Ninh" },
  { stateName: "Ben Tre" },
  { stateName: "Binh Duong" },
  { stateName: "Binh Dinh" },
  { stateName: "Binh Phuoc" },
  { stateName: "Binh Thuan" },
  { stateName: "Ca Mau" },
  { stateName: "Cao Bang" },
  { stateName: "Can Tho (City)" },
  { stateName: "Da Nang (City)" },
  { stateName: "Dak Lak" },
  { stateName: "Dak Nong" },
  { stateName: "Dien Bien" },
  { stateName: "Dong Nai" },
  { stateName: "Dong Thap" },
  { stateName: "Gia Lai" },
  { stateName: "Ha Giang" },
  { stateName: "Ha Nam" },
  { stateName: "Ha Noi (Capital)" },
  { stateName: "Ha Tay" },
  { stateName: "Ha Tinh" },
  { stateName: "Hai Duong" },
  { stateName: "Hai Phong (City)" },
  { stateName: "Hoa Binh" },
  { stateName: "Ho Chi Minh (City)" },
  { stateName: "Hau Giang" },
  { stateName: "Hung Yen" },
  { stateName: "Khanh Hoa" },
  { stateName: "Kien Giang" },
  { stateName: "Kon Tum" },
  { stateName: "Lai Chau" },
  { stateName: "Lao Cai" },
  { stateName: "Lang Son" },
  { stateName: "Lam Dong" },
  { stateName: "Long An" },
  { stateName: "Nam Dinh" },
  { stateName: "Nghe An" },
  { stateName: "Ninh Binh" },
  { stateName: "Ninh Thuan" },
  { stateName: "Phu Tho" },
  { stateName: "Phu Yen" },
  { stateName: "Quang Binh" },
  { stateName: "Quang Nam" },
  { stateName: "Quang Ngai" },
  { stateName: "Quang Ninh" },
  { stateName: "Quang Trị" },
  { stateName: "Soc Trang" },
  { stateName: "Son La" },
  { stateName: "Tay Ninh" },
  { stateName: "Thai Binh" },
  { stateName: "Thai Nguyen" },
  { stateName: "Thanh Hoa" },
  { stateName: "Thua Thien - Hue" },
  { stateName: "Tien Giang" },
  { stateName: "Tra Vinh" },
  { stateName: "Tuyen Quang" },
  { stateName: "Vinh Long" },
  { stateName: "Vinh Phuc" },
  { stateName: "Yen Bai" },
];
