import React from "react";
import { Field, ErrorMessage } from "formik";

const Step5 = () => {
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Address Details</h2>

                {/* <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please check out
          <a href="/dashboard" className="link-primary fw-bolder">
            {" "}
            Help Page
          </a>
          .
        </div> */}
            </div>

            <div className="fv-row mb-10">
                <label className="form-label">Floor Number</label>
                <Field
                    data-testid="floor-number"
                    name="floorNumber"
                    placeholder="Enter your floor number"
                    className="form-control form-control-lg form-control-solid"
                />

                <div className="text-danger mt-2">
                    <ErrorMessage name="floorNumber" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="form-label required">Street Name</label>

                <Field
                    data-testid="street-name"
                    name="streetName"
                    placeholder="Enter your street name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="streetName" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="d-flex align-items-center form-label">
                    <span className="">Street Number</span>
                </label>

                <Field
                    data-testid="street-number"
                    name="streetNumber"
                    placeholder="Enter your street number"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="streetNumber" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">Town</label>

                <Field
                    data-testid="town"
                    name="town"
                    placeholder="Enter your town name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="town" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    District
                </label>
                <Field
                    data-testid="district"
                    name="district"
                    placeholder="Enter your district name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="district" />
                </div>
            </div>
            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    Postal Code
                </label>

                <Field
                    data-testid="postal-code"
                    name="postalCode"
                    placeholder="Enter your postal code"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="postalCode" />
                </div>
            </div>

            <div className="fv-row mb-10">
                <label className="fs-6 fw-bold form-label required">
                    State
                </label>

                <Field
                    data-testid="state"
                    as="select"
                    name="state"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your state</option>
                    <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">
                        Dadra and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Orissa">Orissa</option>
                    <option value="Pondicherry">Pondicherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttaranchal">Uttaranchal</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="West Bengal">West Bengal</option>
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="state" />
                </div>
            </div>
            <div className="fv-row mb-0">
                <label className="fs-6 fw-bold form-label required">
                    Country
                </label>

                <Field
                    data-testid="country"
                    name="country"
                    placeholder="Enter your country name"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="country" />
                </div>
            </div>
        </div>
    );
};

export { Step5 };
