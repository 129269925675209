// ******************* ADD PRODUCT TABLE FORMULA **********************************

const calGrossAmount = (noOfStrips: number, pricePerStrip: number) => {
    const grossAmount = noOfStrips * pricePerStrip;
    return grossAmount;
};

export const CalcDiscount = (
    noOfStrips: number,
    pricePerStrip: number,
    discountPercent: number
) => {
    const grossAmount = calGrossAmount(noOfStrips, pricePerStrip);
    const discountAmount = (grossAmount * discountPercent) / 100;
    return discountAmount.toFixed(2);
};

export const calcTotalUnits = (
    noOfStrips: number = 0,
    freeStrips: number = 0,
    unitsPerStrip: number = 0
) => {
    const totalUnits = (noOfStrips + freeStrips) * unitsPerStrip;
    return totalUnits;
};

export const calSchemeDisc: any = (
    noOfStrips: number,
    pricePerStrip: number,
    discountPercent: number,
    schemeDisc: number
) => {
    // const grossAmount = calGrossAmount(noOfStrips, pricePerStrip);
    // const discountAmount = CalcDiscount(
    //     noOfStrips,
    //     pricePerStrip,
    //     discountPercent
    // );
    // const amountAfterDiscount = grossAmount - parseInt(discountAmount);
    // const schemeDiscountAmount = (amountAfterDiscount * schemeDisc) / 100;
    return 0;
};

export const calcTaxAmt = (
    noOfStrips: number,
    pricePerStrip: number,
    discountPercent: number,
    schemeDisc: number,
    gstTotal: number
) => {
    const grossAmount = calGrossAmount(noOfStrips, pricePerStrip);
    const discountAmount = +CalcDiscount(
        noOfStrips,
        pricePerStrip,
        discountPercent
    );
    // const amountAfterDiscount = grossAmount - discountAmount;
    const taxAmt = ((grossAmount - discountAmount) * gstTotal) / 100;
    return taxAmt.toFixed(2);
};

export const calcTotalPrice = (
    noOfStrips: number,
    pricePerStrip: number,
    discountPercent: number,
    schemeDisc: number,
    gstTotal: number
) => {
    const grossAmount = calGrossAmount(noOfStrips, pricePerStrip);
    const discountAmount = +CalcDiscount(
        noOfStrips,
        pricePerStrip,
        discountPercent
    );
    const schemeDiscountAmount = +calSchemeDisc(
        noOfStrips,
        pricePerStrip,
        discountPercent,
        schemeDisc
    );
    // const taxAmt = calcTaxAmt(
    //     noOfStrips,
    //     pricePerStrip,
    //     discountPercent,
    //     schemeDisc,
    //     gstTotal
    // );
    const totalPrice = grossAmount - discountAmount - schemeDiscountAmount;

    return totalPrice.toFixed(2);
};

// ************************************* FINAL BILL FORMULA ***************************************

const aggregates = (productsArr: any) => {
    let aggregateGrossAmount = 0;
    let aggregateDiscountAmount = 0;
    let aggregateSchemeDiscount = 0;
    let aggregateTotalTax = 0;

    productsArr.forEach((ele: any) => {
        aggregateGrossAmount += calGrossAmount(
            ele.noOfStrips,
            ele.pricePerStrip
        );

        aggregateDiscountAmount += +CalcDiscount(
            ele.noOfStrips,
            ele.pricePerStrip,
            ele.discountPercent
        );

        aggregateSchemeDiscount += +calSchemeDisc(
            ele.noOfStrips,
            ele.pricePerStrip,
            ele.discountPercent,
            ele.schemeDisc
        );

        aggregateTotalTax += +calcTaxAmt(
            ele.noOfStrips,
            ele.pricePerStrip,
            ele.discountPercent,
            ele.schemeDisc,
            ele.gstTotal
        );
    });

    return {
        aggregateGrossAmount,
        aggregateDiscountAmount,
        aggregateSchemeDiscount,
        aggregateTotalTax,
    };
};

export const aggregateTotal = (productsArr: any) => {
    const {
        aggregateGrossAmount,
        aggregateDiscountAmount,
        aggregateSchemeDiscount,
    } = aggregates(productsArr);

    const total =
        aggregateGrossAmount -
        aggregateDiscountAmount -
        aggregateSchemeDiscount;
    return total.toFixed(2);
};

export const aggregateDiscount = (productsArr: any) => {
    const { aggregateDiscountAmount } = aggregates(productsArr);
    return aggregateDiscountAmount.toFixed(2);
};

export const aggregateTax = (productsArr: any) => {
    const { aggregateTotalTax } = aggregates(productsArr);
    return aggregateTotalTax.toFixed(2);
};

export const calcPurchaseAmount = (productsArr: any) => {
    const {
        aggregateGrossAmount,
        aggregateTotalTax,
        aggregateDiscountAmount,
        aggregateSchemeDiscount,
    } = aggregates(productsArr);
    const total =
        aggregateGrossAmount +
        aggregateTotalTax -
        aggregateDiscountAmount -
        aggregateSchemeDiscount;
    return total.toFixed(2);
};

export const aggregateGrossAmt = (productsArr: any) => {
    const { aggregateGrossAmount } = aggregates(productsArr);

    return aggregateGrossAmount.toFixed(2);
};
