import { KTSVG } from "../../../_metronic/helpers";

const Step2 = () => {
  return (
    <div className="mx-auto w-600px w-100 pt-10 pb-10">
      <div className="">
        <h2 className="fw-bolder text-dark">Your Are Done!</h2>
      </div>

      <div className="mb-0">
        <div className="fs-6 text-gray-800 mb-5">
          You have successfully created form
        </div>

        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-2tx svg-icon-warning me-4"
          />
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <h4 className="text-gray-800 fw-bolder">
                We need your attention!
              </h4>
              <div className="fs-6 text-gray-600">
                To start using our great platform.
                <a href="/dashboard" className="fw-bolder">
                  {" "}
                  Create New Form
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
