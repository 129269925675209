import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import {
  getFormByVisit,
  getFocusedGroupById,
} from "../../../../services/focused-group/FocusedGroup.hook";

import FocusedGroupHeader from "../components/FocusedGroupHeader";
import VisitFormDetails from "./VisitFormDetails";

const VisitFormPage = () => {
  const { state }: any = useLocation();

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      "getAllFocusedGroup",
      async () =>
        await getFormByVisit({
          formId: state?.formId,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    refetch();
  }, []);

  const formData = data?.data?.data?.form;
  const errorMsg = error?.response?.data?.message;

  // API for Focused group header

  const { fgId: id }: any = useSearchParams();

  const {
    data: focusedGroupData,
    isLoading: isFocusedGroupLoading,
    refetch: focusedGroupRefetcher,
  }: any = useGetRequest(
    ["getFocusedGroupById", id],
    async () =>
      await getFocusedGroupById({
        focusedGroupId: id,
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  const fgHeader = useMemo(
    () => focusedGroupData?.data?.data?.focusedGroup,
    [focusedGroupData]
  );

  useEffect(() => {
    if (id) focusedGroupRefetcher();
  }, [id, focusedGroupRefetcher]);

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="bg-white h-100 w-100 d-flex justify-content-center align-items-center">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : isError === true ? (
        <div className="">
          <h1 className="fs-4 fw-bolder text-danger">{errorMsg}</h1>
        </div>
      ) : (
        <div className="">
          <FocusedGroupHeader fgHeader={fgHeader} />
          <VisitFormDetails formData={formData} />
        </div>
      )}
    </div>
  );
};

export default VisitFormPage;
