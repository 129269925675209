/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const SettingsHeader: React.FC = () => {
  const location = useLocation();

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-3 pb-0">
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "/settings" && "active")
                }
                to="/settings"
              >
                Facility Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SettingsHeader;
