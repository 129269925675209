import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { Toaster } from "react-hot-toast";

import { dataProps } from "../types/FocusedGroup.types";
import useMutateRequest from "../../../../hooks/getMutation.query";
import { searchFocusedGroupByName } from "../../../../services/focused-group/FocusedGroup.hook";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import { warnUser } from "../../../../common/utils";

const searchSchema = Yup.object().shape({
    searchKey: Yup.string()
        .min(3, "Minimum 3 Letters Required")
        .label("Search"),
});

const FocusedGroupTable = ({
    bodyProps,
    isLoading,
    isFetching,
    isError,
    FgErrorMsg,
    currentPageNumber,
    handlePageNumber,
    totalFocusedGroupsEntites,
    totalAvailablePages,
    pageLimit,
    refetch,
    setPageLimit,
}: dataProps) => {
    const facilityCode: any = localStorage.getItem("facilityCode");
    // Setting the Search term
    const [searchData, setSearchData] = useState([]);
    const [searchPageNumber] = useState(1);
    const [searchFormProps, setSearchFormProps] = useState("");

    // API For Search CHW
    const {
        data,
        mutate,
        isError: isSearchError,
        error: ErrorMsg,
        isLoading: searchFocusedGroupLoader,
        reset,
    }: any = useMutateRequest((data: string) => searchFocusedGroupByName(data));

    const totalSearchEntites = data?.data?.results;
    const totalSearchCHWPages = Math.ceil(totalSearchEntites / pageLimit);
    const totalPages =
        searchData?.length > 0 ? totalSearchCHWPages : totalAvailablePages;

    const searchErrorMsg = ErrorMsg?.response?.data?.message;

    const onSearchSubmit = (values: any, onSubmitProps: any) => {
        try {
            setSearchFormProps(onSubmitProps);
            const payload = {
                searchKey: values.searchKey?.trim(),
                limit: pageLimit,
                page: searchPageNumber,
            };

            mutate(payload, {
                onSuccess: (data: any) => {
                    const searchTerm = data?.data?.data?.focusedGroup;
                    setSearchData(searchTerm);
                    handlePageNumber(1);
                    onSubmitProps.resetForm();
                },
                onError: (error: any) => {
                    console.log(error);
                    setSearchData([]);
                    onSubmitProps.resetForm();
                },
            });
        } catch (error) {
            console.log(error);
            setSearchData([]);
        }
    };

    const clearData = (searchFormProps: any) => {
        if (searchFormProps) {
            searchFormProps.resetForm();
            setSearchData([]);
            reset();
        }
        handlePageNumber(1);
        refetch();
    };

    // let allChw: any = dummyData;
    let allFocusedGroups: any;
    if (searchData?.length > 0) {
        allFocusedGroups = searchData;
    } else {
        allFocusedGroups = bodyProps;
    }

    return (
        <div className="card text-dark">
            <Toaster />
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1 className="text-uppercase">FOCUSED GROUP LIST</h1>
                    <i
                        onClick={() => clearData(searchFormProps)}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                <div className="card-toolbar">
                    {/* Search Bar */}
                    <div className="py-2 me-20">
                        <Formik
                            initialValues={{
                                searchKey: "",
                            }}
                            validationSchema={searchSchema}
                            onSubmit={onSearchSubmit}
                        >
                            {(formProps: any) => (
                                <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                                    <div className="d-flex ">
                                        <Field
                                            name="searchKey"
                                            placeholder="Search Focused Group"
                                            className="form-control form-control-md form-control-solid w-325px"
                                        />
                                        <button
                                            disabled={
                                                formProps.isSubmitting ||
                                                !(
                                                    formProps.isValid &&
                                                    formProps.dirty
                                                )
                                            }
                                            type="submit"
                                            className="ms-10 btn btn-lg btn-primary w-150px"
                                        >
                                            Search
                                        </button>
                                    </div>
                                    <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                        <ErrorMessage name="searchName" />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="card-body py-3">
                {/* begin::Table container */}

                {/* begin::Table */}
                <Table>
                    {/* begin::Table head */}
                    <TableHeadRow>
                        <TableHead width={50} align="center">
                            SL NO
                        </TableHead>
                        <TableHead width={140}>FOCUSED GROUP NAME</TableHead>
                        <TableHead width={140}>CATEGORY NAME</TableHead>
                        <TableHead width={140}>FOCUSED GROUP TYPE</TableHead>
                        <TableHead width={140}>FOCUSED GROUP ID</TableHead>
                        <TableHead width={140} align={"center"}>
                            Total Number of Visits
                        </TableHead>

                        <TableHead width={100}>ACTION</TableHead>
                    </TableHeadRow>

                    {searchFocusedGroupLoader ||
                    isLoading ||
                    isFetching === true ? (
                        <tbody
                            style={{
                                height: "400px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                left: "500px",
                                top: "50px",
                            }}
                        >
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </tbody>
                    ) : isSearchError || isError === true ? (
                        <tbody
                            style={{
                                height: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: "500px",
                                top: "200px",
                            }}
                        >
                            <h1 className="text-danger">
                                {isSearchError
                                    ? searchErrorMsg
                                    : isError
                                    ? "No Data Available"
                                    : "Something went wrong!!"}
                            </h1>
                        </tbody>
                    ) : Array?.isArray(allFocusedGroups) ? (
                        <TableBodyContainer isLoading={false}>
                            {allFocusedGroups
                                ?.reverse()
                                .map((item: any, index: any) => {
                                    return (
                                        <TableRow
                                            collapsible={false}
                                            key={index}
                                        >
                                            <TableCell align="center">
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    className="text-dark text-dark fw-bolder text-hover-primary"
                                                    to={`/focused-group/forms?fgId=${item?._id}`}
                                                >
                                                    {item?.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <span className="text-dark text-dark fw-bolder text-hover-primary">
                                                    {
                                                        item?.categoryId
                                                            ?.categoryName
                                                    }
                                                </span>
                                            </TableCell>

                                            <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        item?.type === "Visit"
                                                            ? "success"
                                                            : "danger"
                                                    } text-center `}
                                                >
                                                    {item?.type
                                                        ? item?.type
                                                        : "N/A"}
                                                </span>
                                            </TableCell>

                                            <TableCell>
                                                {item?.focusedGroupGeneratedId
                                                    ? item?.focusedGroupGeneratedId
                                                    : "N/A"}
                                            </TableCell>

                                            <TableCell align="center">
                                                {item?.totalVisits}
                                            </TableCell>

                                            <TableCell>
                                                {
                                                    // facilityCode ?
                                                    <Link
                                                        to={`/focused-group/forms?fgId=${item?._id}`}
                                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    >
                                                        DETAILS
                                                    </Link>
                                                    //  : (
                                                    //     <button
                                                    //         onClick={() =>
                                                    //             warnUser()
                                                    //         }
                                                    //         className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                    //     >
                                                    //         DETAILS
                                                    //     </button>
                                                    // )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBodyContainer>
                    ) : (
                        <tbody
                            style={{
                                height: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: "500px",
                                top: "200px",
                            }}
                        >
                            <h1 className="text-danger">{allFocusedGroups}</h1>
                        </tbody>
                    )}
                </Table>

                {/* Pagination */}

                {!isSearchError && !isError && (
                    <Pagination
                        dataCount={
                            searchData?.length > 0
                                ? totalSearchEntites
                                : totalFocusedGroupsEntites
                        }
                        handleCallback={(val: number | string) => {
                            setPageLimit(+val);
                        }}
                        handleNext={() =>
                            handlePageNumber(
                                (prevPageNumber: number) => prevPageNumber + 1
                            )
                        }
                        handlePrev={() =>
                            handlePageNumber(
                                (prevPageNumber: number) => prevPageNumber - 1
                            )
                        }
                        nextDisabled={
                            currentPageNumber ===
                            (searchData?.length > 0
                                ? totalPages
                                : totalAvailablePages)
                        }
                        pageNumber={currentPageNumber}
                        pageLimit={pageLimit}
                        prevDisabled={currentPageNumber === 1}
                    />
                )}
                {/* end::Table */}
            </div>
            {/* end::Table container */}
        </div>
    );
};

export default FocusedGroupTable;
