import Axios from "axios";
import { getElapsedMins } from "./choresUtils";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import TokenService from "./TokenService";

const qs = require("qs");

const publicHeaders = {
    "Content-Type": "application/x-www-form-urlencoded",
};

const orgSelectorHeaders = {
    "Content-Type": "application/json;charset=UTF-8",
};

const protectedHeadersContent = {
    // 'Content-Type' : 'application/octet-stream',
    "Content-Disposition": "attachment;filename=TrafficSummaryReport.xlsx",
    "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

// ************ Refresh token api payload *********
export const payload = qs.stringify({
    grant_type: "refresh_token",
    client_id: "chw-fe-360",
    refresh_token: JSON.parse(localStorage.getItem("refresh_token") || "{}"),
});

const { REACT_APP_BASE_URL, REACT_APP_AUTH_URL } = process.env;

const authUrl = REACT_APP_AUTH_URL;
const baseUrl = REACT_APP_BASE_URL;

export const $axiosPublicContentJSON = Axios.create({
    headers: { ...orgSelectorHeaders },
    timeout: 10000,
    baseURL: baseUrl,
});

export const $axiosPublic = Axios.create({
    headers: { ...publicHeaders },
    timeout: 10000,
    baseURL: authUrl,
});

export const getRefreshedAccess = async (payload: string) => {
    const realmName = JSON.parse(
        localStorage.getItem("organizationId") || "{}"
    );
    try {
        const resp: any = await $axiosPublic.post(
            `/realms/${realmName}/protocol/openid-connect/token`,
            payload
        );

        //@ts-ignore
        localStorage.setItem(
            "access_token",
            JSON.stringify(resp.data.access_token)
        );
        //@ts-ignore
        localStorage.setItem(
            "refresh_token",
            JSON.stringify(resp.data.refresh_token)
        );
    } catch (error) {
        TokenService.removeUser();
        window.location.href = "/";
    }
};

export const $axiosProtected = () => {
    const accessToken = JSON.parse(
        localStorage.getItem("access_token") || "{}"
    );
    const protectedHeaders = {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
    };

    // ***************** Axios instance *********
    const instance = Axios.create({
        headers: { ...protectedHeaders },
        timeout: 1000000,
        baseURL: baseUrl,
    });

    // **************** Interceptor logic **********
    instance.interceptors.request.use(
        async (config: any) => {
            if (!accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            const user: any = jwt_decode(accessToken);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
            if (!isExpired) {
                return config;
            } else {
                const response: any = await getRefreshedAccess(payload);
                if (response) {
                    config.headers.Authorization = `Bearer ${response?.data?.data.token}`;
                }
                const remainingTime = getElapsedMins(
                    JSON.parse(localStorage.getItem("logginSession") || "{}"),
                    new Date().getTime()
                );

                if (remainingTime >= 45) {
                    TokenService.removeUser();
                    window.location.href = "/";
                }
                localStorage.setItem(
                    "logginSession",
                    JSON.stringify(new Date().getTime())
                );

                return config;
            }
        },
        async function (error) {
            TokenService.removeUser();
            window.location.href = "/";

            return Promise.reject(error);
        }
    );
    return instance;
};

export const $axiosProtectedContent = () => {
    const instance = Axios.create({
        headers: { ...protectedHeadersContent },
        timeout: 10000,
        baseURL: baseUrl,
        responseType: "blob",
    });
    instance.interceptors.request.use(async (config) => {
        //@ts-ignore
        const remainingTime = getElapsedMins(
            JSON.parse(localStorage.getItem("logginSession") || "{}"),
            new Date().getTime()
        );
        if (remainingTime >= 45) {
            TokenService.removeUser();
            window.location.href = "/login";
        }
        localStorage.setItem(
            "logginSession",
            JSON.stringify(new Date().getTime())
        );
        return config;
    });

    return instance;
};

export default $axiosProtected;
