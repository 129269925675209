import React, { FC } from "react";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import TokenService from "../../common/utils/TokenService";
import { useRBAC } from "../../context/RBACContext";

type Props = {};

const InvalidFacilityScreen: FC<Props> = () => {
  const { getFacilityStatus } = useRBAC();
  const textMessage =
    getFacilityStatus() === "active"
      ? "Facility Inactive!"
      : "Facility has not been assigned";
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/ikure/invalid_facility.svg")}
          className="w-50 h-80"
        />
        <div
          style={{ position: "absolute", left: "5%", top: "30%", width: "30%" }}
        >
          <h1 className="fs-4x">{textMessage}</h1>
          <p className="fs-4 w-450px py-5 text-bolder">
            {getFacilityStatus() === "active" &&
              "Your facility is currently inactive."}{" "}
            Kindly contact admin for more details
          </p>
          <div className="d-flex justify-content-between align-item-center">
            <div className="">
              <span className="fs-2x fas fa-envelope"></span>&nbsp;
              <a
                className="fs-3 mx-2 text-black text-hover-primary"
                href="mailto:tech.admin@ikure.in"
              >
                Contact us
              </a>
            </div>

            <div>
              <span className="fs-2x fas fa-sign-out-alt"></span>&nbsp;
              <span
                className="fs-3 mx-2 align-item-center cursor-pointer text-black text-hover-primary"
                onClick={() => {
                  TokenService.removeUser();
                  window.location.reload();
                }}
              >
                Log out
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvalidFacilityScreen;
