import * as Yup from "yup";

export type assignCHWTypes = {
  facility: string;
};

export const initalValues: assignCHWTypes = {
  facility: "",
};

export const assignCHWValidationSchema = Yup.object({
  facility: Yup.string().required("Facility is Required"),
});
