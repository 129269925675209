import * as Yup from "yup";
import { numberSchema, stringSchema } from "../../../../common/schemas";

export const IndentInitalValues = {
    searchType: "generic",
    productName: "",
    name: "",
    label: "",
    genericName: "",
    manufactureName: "",
    unitPerStrip: "",
    noOfStrips: "",
    product: { label: "", year: "" },
};

export const IndentSchema = Yup.object().shape({
    searchType: stringSchema,
    // name: alphaNumericSchema,
    name: Yup.string().required("name is required"),
    unitPerStrip: numberSchema.required("unit per strip is required"),
    noOfStrips: numberSchema.required("number of strip is required"),
});
