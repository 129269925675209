import $axiosProtected from "../../common/utils/axiosUtils";

// Get List of all Task Target Details
export const getTasksTarget = async (payload: any) => {
  return await $axiosProtected().get(
    `/scheduler/task-management/target/get?facilityCode=${payload.facilityCode}&limitType=${payload.limitType}&limit=${payload.limit}&page=${payload.page}`
  );
};

// Create a new task
export const createTaskTarget = async (payload: any) => {
  return await $axiosProtected().post(
    `/scheduler/task-management/target`,
    payload
  );
};

// Get List of all Activities
export const getAllActivities = async () => {
  return await $axiosProtected().get(
    `/scheduler/task/activity/activitylist/organization/all/get`
  );
};
